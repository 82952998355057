<template>
  <div>
    <div v-if="!readonly">
      <div v-if="noGoods" class="my-2">
        Схоже компонентів у вас поки немає... <v-btn color="primary" variant="text" rounded :to="{ name: 'new-good-component' }">Створити</v-btn>
      </div>
      <div style="width: 250px">
        <GoodSelector
          :defaultFilters="[{ field: 'Type', operation: 'equals', value: '2' }]"
          @good-selected="onComponentAdd"
          placeholder="Додати компонент"
          @no-goods="noGoods = true"
        />
      </div>
    </div>
    <div class="d-flex flex-column gap16 mt-10">
      <h5 class="subtitle2 op07">Компоненти товару</h5>
      <v-data-table :headers="headers" :items="modelValue">
        <template v-slot:[`item.goodComponent.pictureUrl`]="{ item }">
          <div class="my-1">
            <ImageViewer v-if="item.raw.goodComponent.pictureUrl" :src="item.raw.goodComponent.pictureUrl" height="64" width="64" rounded />
            <v-img v-else src="../../../assets/good_no_picture.png" height="64" width="64" />
          </div>
        </template>
        <template v-slot:[`item.goodComponent.title`]="{ item }">
          <div>
            <router-link :to="{ name: 'good-details', params: { id: item.raw.goodComponent.id } }" target="_blank"
              >{{ item.raw.goodComponent.title }}
            </router-link>
          </div>
          <div class="text--disabled">{{ item.raw.goodComponent.inventoryNumber }}</div>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <div style="width: 100px" v-if="!readonly">
            <v-text-field v-model="item.raw.quantity" label="Кількість" density="compact" variant="outlined" hide-details type="number" :disabled="readonly">
              <template #append-inner>
                {{ measureUnits.find((x) => x.id == item.raw.goodComponent.measureUnit).title }}
              </template>
            </v-text-field>
          </div>
          <div v-else>{{ item.raw.quantity }} {{ measureUnits.find((x) => x.id == item.raw.goodComponent.measureUnit).title }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="!readonly" color="red" @click="removeComponent(item.raw)">custom:trashIcon</v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref } from "vue";
import GoodSelector from "./GoodSelector.vue";
import { measureUnits } from "../views/goods.config";
import ImageViewer from "@/components/ImageViewer.vue";

const props = defineProps({
  goodId: {},
  readonly: {
    type: Boolean,
    default: false,
  },
  modelValue: {},
});
const emits = defineEmits(["update:modelValue"]);
const headers = [
  {
    title: "Фото",
    key: "goodComponent.pictureUrl",
    value: "goodComponent.pictureUrl",
  },
  {
    title: "Товар",
    key: "goodComponent.title",
    value: "goodComponent.title",
  },
  {
    title: "Кількість",
    key: "quantity",
    value: "quantity",
  },
];

const noGoods = ref(false);

onMounted(() => {
  if (!props.readonly) {
    headers.push({
      title: "Дії",
      key: "actions",
      value: "",
    });
  }
});

const onComponentAdd = (item) => {
  console.log(item);
  const index = props.modelValue.findIndex((x) => x.goodComponent?.id == item.id);

  if (index != -1) {
    props.modelValue[index].quantity += 1;
  } else {
    emits("update:modelValue", [...props.modelValue, { goodComponentId: item.id, goodComponent: item, quantity: 1 }]);
  }
};

const removeComponent = (component) => {
  emits(
    "update:modelValue",
    props.modelValue.filter((x) => x !== component)
  );
};
</script>
