<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" sm="4">
        <label>{{ $t("Settings.Integrations.NovaPoshtaAPI.Name") }}</label>
        <v-text-field v-model="template.name" variant="outlined" density="compact" :rules="[rules.required]" />
      </v-col>
    </v-row>
    <h5 class="my-4">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.SenderInfo") }}</h5>
    <NovaPoshtaPersonInfo isSender :person="template.deliveryInfo.sender" :template="template" :accounts="accounts" />
    <h5 class="my-4">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryInfo") }}</h5>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Weight") }}</label>
        <v-text-field v-model="template.deliveryInfo.weight" density="compact" variant="outlined" type="number" hide-details placeholder="Введіть вагу товару">
          <template #append> {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.KG") }}</template>
        </v-text-field>
      </v-col>
      <!-- <v-col cols="12" sm="6" md="4">
        <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.AnnouncementPrice") }}</label>
        <v-text-field
          v-model="template.deliveryInfo.cost"
          density="compact"
          variant="outlined"
          type="number"
          hide-details
          placeholder="Введіть вартість товару"
        >
          <template #append> {{ $t("Common.Label.Currency") }}</template>
        </v-text-field>
      </v-col> -->

      <v-col cols="12" sm="6" md="4"> <OrderDescriptionComponent v-model="template.deliveryInfo.description" :skipValidation="true" /> </v-col>
      <v-col cols="12" sm="6" md="4">
        <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Payer") }}</label>
        <v-select
          density="compact"
          variant="outlined"
          :items="deliveryInfo.payerTypes"
          v-model="template.deliveryInfo.payerType"
          hide-details
          item-title="text"
          item-value="value"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox inset hide-details v-model="infoRegClientBarcodes" color="primary">
          <template #label>
            {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.InfoRegClientBarcodes") }}
            <Tooltipped :text="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.InfoRegClientBarcodesInfo')">
              <v-icon>custom:infoIcon</v-icon>
            </Tooltipped>
          </template>
        </v-checkbox>

        <v-checkbox inset hide-details v-model="additionalInformation" color="primary">
          <template #label>
            {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.AdditionalInformation") }}
            <Tooltipped :text="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.AdditionalInformationInfo')">
              <v-icon>custom:infoIcon</v-icon>
            </Tooltipped>
          </template>
        </v-checkbox>
      </v-col>
      <v-col v-if="isBusinessAccountSelected" cols="12" sm="6" md="4" class="d-flex align-self-center">
        <v-checkbox
          color="primary"
          label="Контроль оплати ?"
          density="compact"
          variant="outlined"
          v-model="template.deliveryInfo.paymentControl"
          hide-details
        />
      </v-col>
    </v-row>
    <NovaPoshtaSeat :seats="template.deliveryInfo.seats" :templateId="template.id" />
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-switch
          v-model="template.deliveryInfo.remittance.active"
          hide-details
          :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Remittance')"
          color="primary"
        >
        </v-switch>
      </v-col>
      <v-col cols="12" v-if="template.deliveryInfo.remittance.active">
        <v-row>
          <!-- <v-col cols="12" sm="6" md="4">
            <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.RemittanceSum") }}</label>
            <v-text-field
              variant="outlined"
              density="compact"
              type="number"
              v-model="template.deliveryInfo.remittance.total"
              hide-details
              placeholder="Введіть суму переказу"
            >
              <template #append> {{ $t("Common.Label.Currency") }}</template>
            </v-text-field>
          </v-col> -->
          <v-col cols="12" sm="6" md="4">
            <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.RemittanceDeliveryPayer") }}</label>
            <v-select
              density="compact"
              variant="outlined"
              :items="deliveryInfo.payerTypes.filter((x) => x.value != 'ThirdPerson')"
              v-model="template.deliveryInfo.remittance.payer"
              hide-details
              item-title="text"
              item-value="value"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import NovaPoshtaPersonInfo from "@/modules/orders/components/NovaPoshtaPersonInfo.vue";
import Tooltipped from "@/components/Tooltiped.vue";
import { deliveryInfo } from "@/modules/orders/models/novaposhta";
import NovaPoshtaSeat from "@/modules/orders/components/NovaPoshtaSeat.vue";
import OrderDescriptionComponent from "@/modules/orders/components/OrderDescriptionComponent.vue";
import { requiredRule } from "@/services/field.rules";
import { defineProps, watch, ref, onMounted, computed } from "vue";
import i18n from "@/plugins/i18n";

const props = defineProps(["template", "accounts"]);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
};

const infoRegClientBarcodes = ref(false);
const additionalInformation = ref(false);

onMounted(() => {
  infoRegClientBarcodes.value = !!props.template.deliveryInfo.infoRegClientBarcodes;
  additionalInformation.value = !!props.template.deliveryInfo.additionalInformation;
});

const isBusinessAccountSelected = computed(() => props.accounts.find((a) => a.personId === props.template.deliveryInfo.sender.id)?.isBusiness);

watch(infoRegClientBarcodes, (value) => {
  props.template.deliveryInfo.infoRegClientBarcodes = value ? "{{ order.OrderNumber }}" : "";
});

watch(additionalInformation, (value) => {
  props.template.deliveryInfo.additionalInformation = value ? "{{ for item in order.OrderItems }}{{item.Title}} ({{item.Quantity}});{{ end }}" : "";
});

watch(
  () => props.template.deliveryInfo.sender.id,
  (value) => {
    if (!props.accounts.find((a) => a.personId === value)?.isBusiness) {
      props.template.deliveryInfo.remittance.recieveForm = deliveryInfo.remittanceReceiveForm.filter((x) => x.value !== "Card")[0].value;
    }
  }
);
</script>
