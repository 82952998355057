<template>
  <Tooltiped v-if="members[userId]" :text="userFullName">
    <v-avatar :color="color" variant="elevated">
      <span class="text-white">{{ userInitials }}</span>
    </v-avatar>
  </Tooltiped>

  <Tooltiped v-else text="Діалог не закріплений за менеджером  ">
    <v-avatar variant="tonal">
      <span class="text-white"></span>
    </v-avatar>
  </Tooltiped>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import Tooltiped from "./Tooltiped.vue";
import { stringToHslColor } from "@/services/util";
import { useStore } from "vuex";

const props = defineProps(["userId"]);
const store = useStore();

const members = store.getters.members;
const color = ref("");
const userInitials = ref("");
const userFullName = ref("");

onMounted(() => {
  if (!members[props.userId]) return;
  color.value = stringToHslColor(members[props.userId].initials);
  userInitials.value = members[props.userId].initials;
  userFullName.value = members[props.userId].fullName;
});
</script>
