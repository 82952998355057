<template>
  <v-menu v-if="!loading" class="pa-0 ma-0" :items="statuses">
    <template #activator="{ props }">
      <div v-bind="props">
        <v-select
          class="conversation-username"
          :model-value="modelValue"
          :items="[modelValue]"
          readonly
          variant="outlined"
          hide-details
          color="primary"
          :loading="changinStatus"
          menu-icon=""
        >
          <template #selection="{ item }">
            <div class="d-flex gap8 align-center">
              <v-chip :color="item.raw.color">
                <span>{{ item.title[0] }}</span>
              </v-chip>

              <span class="selected-status">{{ item.title }} </span>
            </div>
          </template>
        </v-select>
      </div>
    </template>

    <div class="statuses-container">
      <div v-for="status in statuses" :key="status.id" class="d-flex align-center status-item gap8" @click="onValueChanged(status)">
        <v-chip :color="status.color">
          <span>{{ status.title[0] }}</span>
        </v-chip>

        <span class="selected-status">{{ status.title }}</span>
      </div>
    </div>
  </v-menu>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from "vue";
import { funnelService } from "../../services/funnel.service";
import { inboxService } from "../../services/inbox.service";

const props = defineProps(["modelValue", "threadId"]);
const emits = defineEmits(["update:modelValue"]);

const statuses = ref([]);
const selected = ref(null);
const loading = ref(false);
const changinStatus = ref(false);
onMounted(async () => {
  loading.value = true;
  statuses.value = await funnelService.getAllStatuses();
  selected.value = statuses.value.find((x) => x.id == props.modelValue.id);

  loading.value = false;
});

const onValueChanged = async (val) => {
  if (props.modelValue.id === val.id) return;

  changinStatus.value = true;
  const patchItem = { path: "StatusId", op: "replace", value: val.id };
  await inboxService.updateThread(props.threadId, [patchItem]);
  changinStatus.value = false;

  emits("update:modelValue", val);
};
</script>

<style scoped>
.selected-status {
  white-space: nowrap;
  width: min-content;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
}

.status-item {
  height: 40px;
  padding: 16px;

  color: var(--main-secondary, #5a5858);
  border-radius: 16px;
  border: 1px solid var(--divider, rgba(90, 88, 88, 0.1));
  background: var(--white, #fff);

  /* Drop Shadow */
  box-shadow: 0px 4px 20px 0px rgba(90, 88, 88, 0.05);
}

@media only screen and (max-width: 600px) {
  .selected-status {
    max-width: 100px;
  }
}
</style>

<style>
.status-selector.v-select {
  max-width: 240px;
}

.status-selector.v-select .v-field {
  border: 1px solid var(--divider, rgba(90, 88, 88, 0.1));
  border-radius: 16px;
  box-shadow: none;
}

.status-selector .v-select__content {
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.status-item:hover {
  cursor: pointer;

  filter: brightness(85%);
}

.statuses-container {
  max-height: 250px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  overflow: auto;
}

.conversation-username.v-select .v-field__input,
.conversation-status.v-autocomplete .v-field__input {
  padding: 0 12px;
}
</style>
