<template>
  <v-card class="pa-6">
    <div class="d-flex flex-column gap8">
      <h4 class="title">{{ $t("Orders.NewOrder.Label.Goods") }}</h4>
      <div class="subtitle op05">Додайте потрібний товар з випадаючого списку (можна додати декілька)</div>
      <div v-if="noGoods">
        Схоже товарів у вас поки немає... <v-btn color="primary" variant="text" :to="{ name: 'new-good', force: true }" rounded>Створити</v-btn>
      </div>
    </div>
    <template v-if="modelValue.length">
      <div v-for="(good, i) in goods" :key="i" class="good">
        <div class="d-flex my-3 gap16 flex-wrap">
          <v-img v-if="good.pictureUrl" :src="good.pictureUrl" height="100" width="100" max-width="100px" />
          <v-img v-else src="../../../assets/good_no_picture.png" height="100" width="100" max-width="100px" />

          <div class="d-flex flex-column gap24">
            <div class="d-flex flex-column gap16">
              <div class="d-flex gap16 align-center">
                <h3 class="title">
                  <router-link :to="{ name: 'good-details', params: { id: good.productId || good.id } }">{{ good.title }}</router-link>
                </h3>

                <Prompt :text="removePromptText" :title="removePromptTitle" @confirmed="removeOrderLineItem(good)" v-if="modelValue.length > 1">
                  <Tooltipped :text="$t('Common.Button.Remove')" class="mt-1 align-start">
                    <v-btn variant="text" size="small" icon="custom:trashIcon" color="red"></v-btn>
                  </Tooltipped>
                </Prompt>
              </div>

              <p class="subtitle op07">Артикул: {{ good.inventoryNumber || good.productNumber }}</p>
            </div>
            <div class="d-flex align-center gap16">
              <div class="d-flex align-center gap8 good-amount">
                <v-btn icon size="24" @click="decrementQuantity(i)"> <v-icon size="16"> custom:minusIcon </v-icon></v-btn>
                <v-text-field
                  v-model="modelValue[i].quantity"
                  density="compact"
                  variant="outlined"
                  type="number"
                  min="1"
                  hide-details
                  rounded
                  style="max-width: 84px"
                />
                <v-btn icon size="24" @click="incrementQuantity(i)"> <v-icon size="16" class="secondary-icon"> custom:plusIcon </v-icon></v-btn>
              </div>
              <div class="h3 d-flex gap8">{{ (good.unitPrice * modelValue[i].quantity).toFixed(2) }} <span>₴</span></div>
            </div>
          </div>
        </div>

        <v-divider v-if="i < goods.length - 1"></v-divider>
      </div>
    </template>

    <v-row>
      <v-col cols="12" sm="6">
        <GoodSelector
          class="mt-2"
          @good-selected="onGoodSelected"
          :errored="touched && !modelValue.length"
          :defaultFilters="[{ field: 'Type', operation: 'equals', value: '1' }]"
          @no-goods="noGoods = true"
        />
      </v-col>
    </v-row>
    <h3 class="text-right title" v-html="$t('Orders.Label.OrderTotal', { total: orderTotal.toFixed(2) })"></h3>
  </v-card>
</template>

<script setup>
import Tooltipped from "@/components/Tooltiped.vue";
import Prompt from "@/components/Prompt.vue";
import GoodSelector from "@/modules/warehouse/components/GoodSelector.vue";
import { computed, onMounted, ref, defineProps, defineEmits } from "vue";
import i18n from "@/plugins/i18n";
import { forEach } from "underscore";

const props = defineProps(["modelValue", "touched", "value"]);
const emits = defineEmits(["update:modelValue"]);

const goods = ref([]);
const noGoods = ref(false);
const removePromptTitle = i18n.global.t("Orders.NewOrder.Label.RemoveElementPrompt");
const removePromptText = "";

const orderTotal = computed(() => props.modelValue.reduce((prev, current) => prev + current.quantity * current.unitPrice, 0));

const removeOrderLineItem = (item) => {
  goods.value.splice(goods.value.indexOf(item), 1);
  emits("update:modelValue", [...props.modelValue.filter((x) => x.productId !== item.id)]);
};

onMounted(() => {
  goods.value = [...props.modelValue];
});

const onGoodSelected = (item) => {
  if (goods.value.indexOf(item) == -1) goods.value.push(item);

  const index = props.modelValue.findIndex((x) => x.productId == item.id);
  if (index != -1) {
    incrementQuantity(index);
  } else {
    const newElement = { title: item.title, unitPrice: item.unitPrice, quantity: 1, productId: item.id };
    emits("update:modelValue", [...props.modelValue, newElement]);
  }
};

const incrementQuantity = (index) => {
  const orderItem = { ...props.modelValue[index], quantity: props.modelValue[index].quantity + 1 };

  emits(
    "update:modelValue",
    props.modelValue.map((x) => (x === props.modelValue[index] ? orderItem : x))
  );
};

const decrementQuantity = (index) => {
  const quantity = props.modelValue[index].quantity - 1;

  if (quantity <= 0) {
    emits("update:modelValue", [...props.modelValue.filter((x) => x !== props.modelValue[index])]);
  } else {
    const orderItem = { ...props.modelValue[index], quantity: props.modelValue[index].quantity - 1 };
    emits(
      "update:modelValue",
      props.modelValue.map((x) => (x === props.modelValue[index] ? orderItem : x))
    );
  }
};
</script>

<style scoped>
.good .title {
  color: var(--main-primary, #2999fd);
}

.good-amount button {
  border-radius: 8px;
  border: 1px solid var(--divider, rgba(90, 88, 88, 0.1));
  background: var(--white, #fff);
  box-shadow: 0px 7px 64px 0px rgba(0, 0, 0, 0.07);
}

.v-img {
  border-radius: 16px;
  border: 1px solid var(--divider, rgba(90, 88, 88, 0.1));
}
</style>
