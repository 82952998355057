<template>
  <v-menu v-model="menu" :close-on-click="false" :close-on-content-click="false">
    <template #activator="{ props }">
      <v-list-item v-if="icon" link v-bind="props">
        <div class="d-flex justify-center">
          <v-avatar size="22" :image="require('../../../assets/novaposhta-icon.png')"> </v-avatar>
        </div>
      </v-list-item>
      <Tooltip v-else :text="$t('Orders.Label.SelectAtLeastOneOrder')">
        <v-btn rounded color="primary" height="36" :disabled="disabled" v-bind="props">
          <v-img style="border-radius: 100px" width="22" src="../../../assets/novaposhta-icon.png" class="mr-1" />
          <span class="group-action-item"> Нова Пошта </span>
        </v-btn>
      </Tooltip>
    </template>
    <v-list>
      <Tooltiped :text="selected.some(selector) && $t('Orders.Delivery.Tooltip.SomeSelectedOrdersWithTTN')">
        <v-list-item link :disabled="selected.some(selector) || selected.some((o) => o.statusId >= 3)" @click="setAction('generate')">
          <v-list-item-title><v-icon>mdi-clipboard-list-outline</v-icon> {{ $t("Orders.Delivery.Tooltip.GenerateTTN") }}</v-list-item-title>
        </v-list-item>
      </Tooltiped>
      <Tooltiped :text="!selected.every(selector) && $t('Orders.Delivery.Tooltip.SomeSelectedOrdersWithoutTTN')">
        <v-list-item link :disabled="!selected.every(selector)" @click="setAction('refresh')">
          <v-list-item-title><v-icon>custom:rotateRightIcon</v-icon> {{ $t("Orders.Delivery.Tooltip.RefreshTTN") }}</v-list-item-title>
        </v-list-item>
      </Tooltiped>
      <Tooltiped :text="!selected.every(selector) && $t('Orders.Delivery.Tooltip.SomeSelectedOrdersWithoutTTN')">
        <v-list-item link :disabled="!selected.every(selector)" @click="print('markings')">
          <v-list-item-title><v-icon>mdi-printer-outline</v-icon> {{ $t("Orders.Delivery.Tooltip.PrintingMarkings") }}</v-list-item-title>
        </v-list-item>
      </Tooltiped>
      <Tooltiped :text="!selected.every(selector) && $t('Orders.Delivery.Tooltip.SomeSelectedOrdersWithoutTTN')">
        <v-list-item link :disabled="!selected.every(selector)" @click="print('ttn')">
          <v-list-item-title><v-icon>mdi-printer-outline</v-icon> {{ $t("Orders.Delivery.Tooltip.PrintingTTN") }}</v-list-item-title>
        </v-list-item>
      </Tooltiped>
      <Tooltiped :text="!selected.every(selector) && $t('Orders.Delivery.Tooltip.SomeSelectedOrdersWithoutTTN')">
        <v-list-item link :disabled="!selected.every(selector)" @click="print('zebra')">
          <v-list-item-title><v-icon>mdi-printer-outline</v-icon> {{ $t("Orders.Delivery.Tooltip.PrintingZebraMarkings") }}</v-list-item-title>
        </v-list-item>
      </Tooltiped>
      <Tooltiped :text="!selected.every(selector) && $t('Orders.Delivery.Tooltip.SomeSelectedOrdersWithoutTTN')">
        <v-list-item link :disabled="!selected.every(selector)" @click="print('zebra100')">
          <v-list-item-title><v-icon>mdi-printer-outline</v-icon>{{ $t("Orders.Delivery.Tooltip.Printing100ZebraMarkings") }}</v-list-item-title>
        </v-list-item>
      </Tooltiped>

      <Tooltiped :text="!selected.every(selector) && $t('Orders.Delivery.Tooltip.SomeSelectedOrdersWithoutTTN')">
        <v-list-item link :disabled="!selected.every(selector) || selected.some((o) => o.statusId >= 3)" @click="setAction('delete')">
          <v-list-item-title><v-icon>custom:trashIcon</v-icon>{{ $t("Orders.Delivery.Tooltip.RemoveTTN") }}</v-list-item-title>
        </v-list-item>
      </Tooltiped>
    </v-list>
  </v-menu>
  <NovaPoshtaInternetGenerationProgress
    :orders="selected"
    :action="selectedAction"
    @modal-closed="closeMenu"
    v-model="dialog"
    @data-updated="$emit('data-updated')"
  />
</template>

<script setup>
import NovaPoshtaInternetGenerationProgress from "./NovaPoshtaInternetDocumentGenerationProgress.vue";
import Tooltiped from "@/components/Tooltiped.vue";
import { orderService } from "../services/order.service";
import { ref, defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import Tooltip from "@/components/Tooltiped.vue";
const props = defineProps({
  icon: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Array,
  },
  selector: {
    type: Function,
  },
});
const emits = defineEmits(["change-overlay", "data-updated"]);
const store = useStore();

const menu = ref(false);
const dialog = ref(false);
const selectedAction = ref("");

const disabled = computed(() => props.selected.length === 0);

const closeMenu = () => {
  menu.value = false;
};

const setAction = (action) => {
  selectedAction.value = action;
  dialog.value = true;
};

const print = async (type) => {
  emits("change-overlay", true);
  try {
    var blob = await orderService.print(
      props.selected.map((x) => x.id),
      type
    );

    if (blob.type.includes("pdf")) {
      downloadSingleAccountTTNs(blob);
    } else {
      downloadMultiAccountTTNs(blob);
    }
  } catch (er) {
    console.error(er);
    await store.dispatch("addError");
  } finally {
    emits("change-overlay", false);
  }
};

const downloadSingleAccountTTNs = (blob) => {
  const data = URL.createObjectURL(blob);
  var link = document.createElement("a");
  link.href = data;
  link.target = "_blank";
  link.click();
  window.URL.revokeObjectURL(blob);
};

const downloadMultiAccountTTNs = (blob) => {
  var downloadUrl = URL.createObjectURL(blob);
  var downloadLink = document.createElement("a");
  downloadLink.href = downloadUrl;
  downloadLink.download = "files.zip";
  downloadLink.click();
  URL.revokeObjectURL(downloadUrl);
};
</script>
