import { api } from "@/services/api.service";
import { formatString } from "@/services/util";

const endpoints = { GET_ALL: "/api/facebook/auto-replies", BY_ID: "/api/facebook/auto-replies/{0}" };

function getFacebookAutoReplyService() {
  const getAll = async () => {
    return (await api.get(endpoints.GET_ALL)).data;
  };

  const create = async (reply) => {
    return (await api.post(endpoints.GET_ALL, reply)).data;
  };

  const deleteById = async (id) => {
    await api.delete(formatString(endpoints.BY_ID, id.toString()));
  };

  const updateById = async (id, patchData) => {
    return (await api.patch(formatString(endpoints.BY_ID, id.toString()), patchData)).data;
  };

  return {
    getAll,
    create,
    deleteById,
    updateById,
  };
}

export const facebookAutoReplyService = getFacebookAutoReplyService();
