import Orders from "./views/Orders.vue";
import NewOrder from "./views/NewOrder.vue";
import OrderDetails from "./views/OrderDetails.vue";
import OrderEdit from "./views/OrderEdit.vue";

export const routes = [
  {
    path: "/orders/new-order",
    name: "new-order",
    component: NewOrder,
    meta: { title: "Створення Замовлення | Weselli" },
  },
  {
    path: "/orders",
    name: "orders",
    component: Orders,
    meta: { title: "Список Замовлень | Weselli" },
  },
  {
    path: "/orders/:id",
    name: "order-details",
    component: OrderDetails,
    meta: { title: "Деталі Замовлення | Weselli" },
  },
  {
    path: "/orders/:id/edit",
    name: "order-edit",
    component: OrderEdit,
    meta: { title: "Редагування Замовлення | Weselli" },
  },
];
