<template>
  <v-dialog v-model="dialog" max-width="550px" :persistent="processing">
    <template #activator="{ props }">
      <Tooltiped :text="$t('Orders.Delivery.NovaPoshta.Tooltip.UseExistingTTN')">
        <v-btn v-bind="props" color="primary" variant="text" class="text-body-2">
          <v-icon color="primary" class="ml-1">custom:infoIcon</v-icon>
          {{ $t("Orders.Delivery.NovaPoshta.Label.AlreadyHaveTTN") }}
        </v-btn>
      </Tooltiped>
    </template>
    <v-card>
      <div class="d-flex flex-column gap24">
        <div class="d-flex justify-space-between align-center">
          <span class="btn">{{ $t("Orders.Delivery.NovaPoshta.Label.EnterExistingTTN") }}</span>

          <v-btn icon variant="plain" size="x-small" @click="closeDialog">
            <v-icon>custom:closeIcon</v-icon>
          </v-btn>
        </div>

        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-select
                :items="accountOptions"
                v-model="selectedAccount"
                :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Sender')"
                density="compact"
                variant="outlined"
                hide-details
                item-title="text"
                item-value="value"
                v-model:menu="selectMenu"
              >
                <template v-slot:selection="{ item }">
                  <span class="d-flex flex-wrap gap8">
                    <v-chip v-if="item.value.isBusiness" color="green" variant="tonal">Бізнес</v-chip>
                    <v-chip v-else color="primary" variant="tonal">{{ item.value.description }}</v-chip>
                    {{ item.title }}
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item
                    @click="
                      () => {
                        selectedAccount = item.value;
                        selectMenu = false;
                      }
                    "
                  >
                    <span class="d-flex flex-wrap gap8 justify-center">
                      {{ item.title }}
                      <v-chip v-if="item.value.isBusiness" color="green" variant="tonal" density="compact">Бізнес</v-chip>
                      <v-chip v-else color="primary" variant="tonal" density="compact">{{ item.value.description }}</v-chip>
                    </span>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="existingTTN"
                variant="outlined"
                density="compact"
                :label="$t('Orders.Delivery.NovaPoshta.Label.TTN')"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <div class="d-flex justify-center">
          <v-btn color="#2999FD" rounded variant="flat" style="color: white" @click="addExistingTTN" :disabled="processing">{{
            $t("Common.Button.Add")
          }}</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Tooltiped from "@/components/Tooltiped.vue";
import { requiredRule } from "@/services/field.rules";
import { novaPoshtaService } from "../services/novaposhta.service";
import { computed, onMounted, ref, defineEmits } from "vue";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";

const emits = defineEmits(["ttn-data-fetched"]);
const store = useStore();

const dialog = ref(false);
const processing = ref(false);
const existingTTN = ref("");
const accounts = ref([]);
const selectedAccount = ref(null);
const selectMenu = ref(false);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
};

const accountOptions = computed(() => (!accounts.value?.length ? [] : accounts.value.map((x) => ({ text: `${x.fullName} ${x.phone}`, value: x }))));

onMounted(async () => {
  accounts.value = await novaPoshtaService.getSavedDeliveryInfo();
  selectedAccount.value = accounts.value[0];
});

const form = ref(null);
const addExistingTTN = async () => {
  const isValid = (await form.value.validate()).valid;
  if (!isValid) {
    return;
  }

  try {
    processing.value = true;
    const deliveryInfo = await novaPoshtaService.getDeliveryInfoByTTN(selectedAccount.value.id, existingTTN.value);
    deliveryInfo.receiver.phoneNumber = deliveryInfo.receiver.phoneNumber.replace(/^\+?3?8?/, "");

    store.dispatch("addNotification", {
      icon: "custom:warningIcon",
      text: i18n.global.t("Orders.Delivery.NovaPoshta.Label.DeliveryAutofilled"),
      color: "primary",
      duration: 3500,
    });

    deliveryInfo.sender = { ...deliveryInfo.sender, ...selectedAccount.value };

    emits("ttn-data-fetched", deliveryInfo);

    closeDialog();
  } catch {
    await store.dispatch("addError");
  } finally {
    processing.value = false;
  }
};

const closeDialog = () => {
  form.value.resetValidation();
  existingTTN.value = "";
  dialog.value = false;
};
</script>

<style scoped>
.v-btn {
  height: 48px;
  padding: 8px 16px;
}
</style>
