<template>
  <v-list-item
    link
    :to="{ name: 'inbox-thread', params: { id: thread.id } }"
    class="thread-item border-bottom"
    style="overflow: hidden"
    :title="thread.customer.username"
    height="64"
    :class="{ unread: thread.lastMessage.unseenInRow > 0 }"
  >
    <template #prepend>
      <div class="d-flex gap8">
        <AvatarSlim :user-id="thread.assigneeId" />
        <v-divider vertical></v-divider>
        <v-avatar :image="profilePicture"></v-avatar>
      </div>
    </template>
    <template #subtitle>
      <v-icon v-if="thread.instagramId == thread.lastMessage.accountId && thread.lastMessage.unseenInRow === 0 && thread.lastMessage.readByCustomer" small>
        custom:readIcon
      </v-icon>
      <v-icon
        v-else-if="thread.instagramId == thread.lastMessage.accountId && thread.lastMessage.unseenInRow === 0 && !thread.lastMessage.readByCustomer"
        small
      >
        custom:deliveredIcon
      </v-icon>

      <p class="d-inline-block text-truncate">{{ thread.lastMessage.text }}</p>
    </template>

    <template #append>
      <span class="time"> {{ formattedTimestamp }}</span>

      <div class="d-flex justify-center thread-item-status">
        <Tooltiped :text="thread.status.title">
          <v-chip :color="thread.status.color">
            <span>{{ thread.status.title }}</span>
          </v-chip>
        </Tooltiped>
      </div>

      <v-menu class="thread-item-menu blured">
        <template #activator="{ props }">
          <v-btn v-bind="props" @click.prevent="menu = !menu" icon="custom:dotsVerticalIcon" variant="text" />
        </template>
        <v-list>
          <v-list-item link @click="toggleSeen" class="body2">
            <v-list-item-title>
              <span>{{ thread.lastMessage.unseenInRow === 0 ? $t("Inbox.Label.MarkAsUnseen") : $t("Inbox.Label.MarkAsSeen") }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-list-item>
</template>

<script setup>
import { defineProps, computed, ref } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { inboxService } from "../services/inbox.service";
import Tooltiped from "@/components/Tooltiped.vue";
import AvatarSlim from "@/components/AvatarSlim.vue";

const store = useStore();
const props = defineProps({
  thread: {
    type: Object,
  },
});

const menu = ref(false);

const profilePicture = computed(() => props.thread.customer.profilePicture || require("../../../assets/no-picture-account.jpg"));
const formattedTimestamp = computed(() =>
  moment.utc(props.thread.lastMessage.timestamp).local().isSame(moment(), "d")
    ? moment.utc(props.thread.lastMessage.timestamp).local().format("LT")
    : moment.utc(props.thread.lastMessage.timestamp).local().isSame(moment(), "year")
    ? moment.utc(props.thread.lastMessage.timestamp).local().format("DD.MM[\n]HH:mm")
    : moment.utc(props.thread.lastMessage.timestamp).local().format("DD.MM.YY[\n]HH:mm")
);
const toggleSeen = async () => {
  try {
    const seen = props.thread.lastMessage.unseenInRow === 0;
    await inboxService.toggleSeen(props.thread.id, !seen);

    props.thread.lastMessage.unseenInRow = seen ? 1 : 0;
    await store.dispatch("toggleInboxThreadSeen", !props.thread.lastMessage.unseenInRow);
  } catch {
    store.dispatch("addError");
  } finally {
    // isMenuOpen.value = false;
  }
};
</script>

<style scoped>
.unread {
  background-color: rgba(250, 251, 252, 1);
}
</style>

<style>
.thread-item {
  align-content: space-evenly;
}

.border-bottom {
  border-bottom: 1px solid rgba(90, 88, 88, 0.1);
}
.thread-item .v-list-item__content {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.thread-item.v-list-item {
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding: 12px 8px;
}

.thread-item .v-list-item-title {
  color: var(--main-secondary, #5a5858);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
  text-transform: lowercase;
}

.thread-item .v-list-item-subtitle {
  display: flex;
  gap: 4px;
  align-items: center;

  /* Body 1 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.thread-item .v-list-item-subtitle p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thread-item.unread .v-list-item-subtitle {
  color: var(--main-primary, #2999fd);
}

.thread-item .v-list-item-subtitle svg {
  min-width: 18px !important;
}
.thread-item .time {
  color: var(--main-secondary, #5a5858);
  text-align: right;
  opacity: 0.5;

  /* Body 1 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.thread-item .v-list-item__append {
  display: flex;
  gap: 16px;

  justify-content: center;
  align-content: center;
}

.thread-item-menu .v-overlay__content {
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(90, 88, 88, 0.05);
}
.thread-item-menu .v-list-item {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  background: var(--bg, #fafbfc);
  color: var(--main-secondary, #5a5858);
}
.thread-item-menu .v-list {
  padding: 0;
}

.thread-item-menu .v-list-item {
  padding: 8px;
}

.thread-item-status .v-chip__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 900px) {
  .thread-item-status,
  .thread-item-status .v-chip {
    max-width: 100px;
  }
}
@media (max-width: 671px) {
  .thread-item-status .v-chip__content {
    font-size: 0;
  }

  .thread-item-status .v-chip__content span::first-letter {
    font-size: 12px;
  }

  .thread-item-status {
    width: initial;
  }
}
</style>
