<template>
  <v-row>
    <v-col cols="12" sm="6">
      <label v-if="isSender">Обліковий запис</label>
      <v-select
        v-if="isSender"
        :items="accountOptions"
        :modelValue="selectedAccount"
        density="compact"
        variant="outlined"
        hide-details
        item-title="text"
        item-value="value"
        v-model:menu="selectMenu"
      >
        <template v-slot:selection="{ item }">
          <span class="d-flex flex-wrap gap8">
            <v-chip v-if="item.value.isBusiness" color="green" variant="tonal">Бізнес</v-chip>
            <v-chip v-else color="primary" variant="tonal">{{ item.value.description }}</v-chip>
            {{ item.title }}
          </span>
        </template>

        <template v-slot:item="{ item }">
          <v-list-item @click="onAccountSelected(item.value)">
            <span class="d-flex flex-wrap gap8 justify-center">
              {{ item.title }}
              <v-chip v-if="item.value.isBusiness" color="green" variant="tonal" density="compact">Бізнес</v-chip>
              <v-chip v-else color="primary" variant="tonal" density="compact">{{ item.value.description }}</v-chip>
            </span>
          </v-list-item>
        </template>
      </v-select>
      <template v-else>
        <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.ReceiverFullname") }}</label>

        <v-text-field
          v-model="person.fullName"
          density="compact"
          variant="outlined"
          :rules="[rules.required, rules.fullName]"
          placeholder="Введіть ПІБ отримувача"
        />
      </template>
    </v-col>
    <v-col cols="12" sm="6" v-if="!isSender">
      <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Phone") }}</label>
      <v-text-field
        density="compact"
        variant="outlined"
        :rules="[rules.required, rules.phoneNumber]"
        v-maska:[phoneNumberMaskOptions]
        placeholder="38"
        v-model="person.phoneNumber"
      />
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
            density="compact"
            variant="outlined"
            :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.City')"
            :loading="personAddress.city.loading"
            :items="personAddress.city.items"
            v-model="person.city"
            @update:search="onCitySearch"
            :rules="[rules.required]"
            :data-test="`${isSender ? 'sender' : 'receiver'}-settlement`"
            :menu-props="{ contentClass: `${isSender ? 'sender' : 'receiver'}-settlement-items` }"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            density="compact"
            variant="outlined"
            :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Warehouse')"
            :loading="personAddress.address.loading"
            :items="personAddress.address.items"
            v-model="person.address"
            :rules="[rules.required]"
            :data-test="`${isSender ? 'sender' : 'receiver'}-warehouse`"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import { phoneNumber, requiredRule } from "@/services/field.rules";
import { novaPoshtaService } from "../services/novaposhta.service";
import { computed, defineProps, onMounted, reactive, watch, ref } from "vue";
import i18n from "@/plugins/i18n";
import { vMaska } from "maska";

const phoneNumberMaskOptions = { mask: "38###-###-##-##" };

const props = defineProps({
  person: {},
  isSender: {
    type: Boolean,
    default: false,
  },
  template: {},
  accounts: {},
});

const selectedAccount = ref(null);
const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  phoneNumber: (value) => phoneNumber(value, i18n.global.t("Common.ErrorMessage.PhoneNumber")),
  fullName: (value) => /\S+\s+\S+/.test(value) || i18n.global.t("Common.ErrorMessage.FullName"),
};

const accountChanged = ref(false);
const selectMenu = ref(false);
const personAddress = reactive({
  city: {
    loading: false,
    items: [],
    search: "",
  },
  address: {
    loading: false,
    items: [],
    search: "",
  },
});

onMounted(() => {
  populatePersonValues();

  selectedAccount.value = accountOptions.value.find((x) => x.value.personId == props.person.id)?.value || accountOptions.value[0]?.value || null;
  onAccountSelected(selectedAccount.value);
});

const accountOptions = computed(() => (!props.accounts?.length ? [] : props.accounts.map((x) => ({ text: `${x.fullName} ${x.phone}`, value: x }))));
var settlements = [];

const searchSettlement = async (text) => {
  settlements = await novaPoshtaService.searchSettlements(text);

  return settlements.map((x) => {
    return { title: x.title, value: { title: x.title, id: x.id } };
  });
};

const getWarehouses = async (settlementId) => {
  personAddress.address.loading = true;
  const warehouses = settlements.find((x) => x.id === settlementId)?.warehouses || [];

  const filtered = props.isSender ? warehouses.filter((x) => x.categoryOfWarehouse != "Postomat") : warehouses;
  var mapped = filtered
    .sort((a, b) => a.number - b.number)
    .map((x) => ({ title: x.title, value: { id: x.id, title: x.title, categoryOfWarehouse: x.categoryOfWarehouse } }));

  personAddress.address.items = mapped;
  personAddress.address.loading = false;
};

const onAccountSelected = (account) => {
  if (!account) return;

  props.person.id = account.personId;
  props.person.fullName = account.fullName;
  props.person.phoneNumber = account.phone;

  selectMenu.value = false;
};

var cityTimeout = -1;
const onCitySearch = (val) => {
  if (personAddress.city.loading || !val || val === props.person.city?.title) return;

  if (cityTimeout !== -1) clearTimeout(cityTimeout);

  cityTimeout = setTimeout(async () => {
    personAddress.city.loading = true;
    try {
      props.person.address = null;

      personAddress.city.items = await searchSettlement(val);
    } catch (error) {
      console.log(error);
    } finally {
      personAddress.city.loading = false;
      clearTimeout(cityTimeout);
    }
  }, 1000);
};

watch(
  () => props.person.city?.id,
  (val) => {
    if (accountChanged.value) {
      accountChanged.value = false;
      return;
    }

    if (!val) return;

    getWarehouses(val);
  }
);

const populatePersonValues = async () => {
  if (props.person.city?.id) {
    personAddress.city.items = [
      {
        title: props.person.city.title,
        value: props.person.city,
      },
    ];
    await getWarehouses(props.person.city.id);

    props.person.city = personAddress.city.items.find((x) => x.value.id === props.person.city.id)?.value || props.person.city;
  }
  if (props.person.address?.id) {
    props.person.address = personAddress.address.items.find((x) => x.value?.id == props.person.address.id)?.value || props.person.address;
  }
};

watch(
  () => props.person.id,
  (val) => {
    if (!props.isSender || !val) return;

    selectedAccount.value = accountOptions.value.find((x) => x.value.personId == val).value;
  }
);

watch(
  () => props.template?.deliveryInfo.sender.id,
  () => {
    console.log(props.template?.deliveryInfo);
    if (props.template) {
      selectedAccount.value = accountOptions.value.find((x) => x.value.personId == props.template.deliveryInfo.sender.id)?.value || null;
      console.log(selectedAccount.value);
      onAccountSelected(selectedAccount.value);
    }
  }
);
</script>
