import { api } from "@/services/api.service";
const endpoints = {
  BASE_ROUTE: "/api/inbox/message-templates",
  SEARCH: "/api/inbox/message-templates/search",
};

function getMessageTemaplateService() {
  const getMessageTemplates = async (filter) => {
    const searchQuery = {
      page: 1,
      pageSize: 100,
      filters: filter,
    };
    return (await api.post(endpoints.SEARCH, searchQuery)).data;
  };

  const update = async (id, updates) => {
    return (await api.patch(`${endpoints.BASE_ROUTE}/${id}`, updates)).data;
  };

  const deleteTemplate = async (id) => {
    return (await api.delete(`${endpoints.BASE_ROUTE}/${id}`)).data;
  };

  const create = async (messageTemplate) => {
    return (await api.post(endpoints.BASE_ROUTE, messageTemplate)).data;
  };

  return {
    getMessageTemplates,
    update,
    deleteTemplate,
    create,
  };
}

export const messageTemplateService = getMessageTemaplateService();
