<template>
  <div class="pa-8 d-flex flex-column gap24">
    <h1 class="d-flex gap8 align-center">
      <v-btn icon="custom:arrowLeftIcon" style="font-size: 24px" variant="text" @click="goBack" />
      Налаштування Нової Пошти
    </h1>

    <v-card class="pa-6 d-flex flex-column gap24">
      <div class="d-flex flex-column gap8">
        <div class="title">Налаштування шаблонів Нова Пошта</div>
        <div class="subtitle op05">Створіть шаблон даних відправника для зручного ведення бізнесу</div>
      </div>

      <div class="d-flex flex-column gap8" style="width: fit-content">
        <div v-for="(item, index) in accounts" :key="index" class="d-flex gap16">
          <WAvatar :url="require('../../../assets/novaposhta-icon.png')" :title="item.fullName" :text="item.phone" :nova-poshta="item" />
        </div>
      </div>

      <div>
        <v-menu v-model="addAccountMenu" :close-on-content-click="false" width="300px">
          <template #activator="{ props }">
            <v-btn small v-bind="props" color="primary" rounded> <v-icon small>custom:plusIcon</v-icon> Додати акаунт</v-btn>
          </template>
          <v-card>
            <v-form ref="formRef" lazy-validation class="mt-4 mx-4">
              <v-text-field v-model="newAccountApiKey" variant="outlined" density="compact" :rules="[rules.required]" label="API-Key" />
            </v-form>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn variant="text" @click="closeAddAccountMenu" :disabled="addAccountLoading"> Cancel </v-btn>
              <v-btn color="primary" variant="text" @click="addAccount" :disabled="addAccountLoading" :loading="addAccountLoading"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>

      <div class="subtitle2 op07">Список шаблонів</div>
      <div>
        <div class="d-flex justify-space-between">
          <v-select
            class="mx-4"
            v-model="defaultTemplate"
            style="max-width: 300px"
            item-value="id"
            item-title="name"
            variant="outlined"
            density="compact"
            :items="templates"
            :label="$t('Settings.Integrations.NovaPoshtaAPI.Templates.DefaultTemplate')"
          />
          <v-btn color="primary" rounded @click="showCreateNewTemplate"> створити новий шаблон </v-btn>
        </div>
        <v-data-table :headers="headers" :items="templates" class="body1">
          <template v-slot:[`item.name`]="{ item }">
            <span class="d-flex gap8 align-center">
              <span v-if="item.default"><v-icon color="warning">mdi-star</v-icon> </span>
              <span>{{ item.name }} </span>
            </span>
          </template>

          <template v-slot:[`item.sender`]="{ item }">
            <span>
              <span>
                <v-chip v-if="getTemplateAccount(item).isBusiness" color="green" variant="tonal" density="compact">Бізнес</v-chip>
                <v-chip v-else color="primary" variant="tonal" density="compact">{{ getTemplateAccount(item).description }}</v-chip>
              </span>
              {{ item.deliveryInfo.sender.fullName }}
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex gap8">
              <v-btn icon variant="text" @click="editTemplate(item)">
                <v-icon small> custom:editIcon </v-icon>
              </v-btn>

              <Prompt @confirmed="removeTemplate" :title="removeTemplateTitle" :disabled="templates.length === 1">
                <Tooltiped :text="templates.length === 1 ? 'Необхідно мати хоча б один шаблон' : ''">
                  <v-btn icon variant="text" @click="showRemoveTemplatePrompt(item)" :disabled="templates.length === 1">
                    <v-icon class="error trash" small> custom:trashIcon </v-icon>
                  </v-btn>
                </Tooltiped>
              </Prompt>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <EditTemplateDialog
      v-model="showTemplateDialog"
      :edit-mode="!!selectedTemplate.id"
      :template="selectedTemplate"
      :accounts="accounts"
      @template-created="onTemplateCreated"
      @template-updated="getTemplates"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import _ from "underscore";
import { novaPoshtaService } from "@/modules/orders/services/novaposhta.service";
import EditTemplateDialog from "../components/integrations/NovaPoshta/CreateEditTemplateDialog.vue";
import Prompt from "@/components/Prompt.vue";
import { requiredRule } from "@/services/field.rules";
import { deliveryService } from "../services/delivery.service";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";
import WAvatar from "@/components/WAvatar.vue";
import { useRouter } from "vue-router";
import { getDeliveryInfo } from "@/modules/orders/models/create-order";
import Tooltiped from "@/components/Tooltiped.vue";

const store = useStore();
const router = useRouter();

const addAccountMenu = ref(false);
const newAccountApiKey = ref("");
const addAccountLoading = ref(false);

const accounts = ref([]);
const defaultTemplate = ref(null);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
};

const templates = computed(() => _.flatten(accounts.value.map((x) => x.templates)).sort((a, b) => b.default - a.default || b.name.localeCompare(a.name)));

const initTemplate = {
  id: "",
  name: "",
  default: false,
  novaPoshtaAccountId: 0,
  deliveryInfo: getDeliveryInfo(),
};

const selectedTemplate = ref(initTemplate);
const showTemplateDialog = ref(false);

const removeTemplateTitle = i18n.global.t("Settings.Integrations.NovaPoshtaAPI.ConfirmRemoveTemplate");

const headers = [
  {
    title: i18n.global.t("Settings.Integrations.NovaPoshtaAPI.Templates.Title"),
    align: "start",
    sortable: false,
    key: "name",
    value: "name",
  },
  {
    title: i18n.global.t("Settings.Integrations.NovaPoshtaAPI.Templates.Account"),
    align: "start",
    sortable: false,
    key: "sender",
    value: "sender",
  },
  { title: "Дії", value: "actions", key: "actions", sortable: false },
];

onMounted(async () => {
  await getTemplates();
});

const editTemplate = (template) => {
  selectedTemplate.value = template;
  showTemplateDialog.value = true;
};

const showRemoveTemplatePrompt = (template) => {
  selectedTemplate.value = template;
};

const skipPersistDefaultTemplate = ref(false);
const getTemplates = async () => {
  accounts.value = await novaPoshtaService.getSavedDeliveryInfo();

  skipPersistDefaultTemplate.value = true;
  defaultTemplate.value = templates.value.find((x) => x.default).id;
};

const removeTemplate = async () => {
  try {
    await novaPoshtaService.removeTemplate(selectedTemplate.value.id);

    const account = accounts.value.find((x) => x.templates.some((t) => t.id == selectedTemplate.value?.id));
    account.templates.splice(
      account.templates.findIndex((x) => x.id == selectedTemplate.value.id),
      1
    );

    await getTemplates();
  } catch (erorr) {
    store.dispatch("addError");
  }
};

const formRef = ref(null);
const addAccount = async () => {
  var isFormValid = (await formRef.value.validate()).valid;
  if (!isFormValid) return;

  try {
    addAccountLoading.value = true;

    await deliveryService.linkNovaPoshta(newAccountApiKey.value);
    newAccountApiKey.value = "";
    await getTemplates();
    addAccountMenu.value = false;
    formRef.value.resetValidation();
  } catch {
    store.dispatch("addError");
  } finally {
    addAccountLoading.value = false;
  }
};
const closeAddAccountMenu = () => {
  addAccountMenu.value = false;
  formRef.value.resetValidation();
};

const onTemplateCreated = (template) => {
  const account = accounts.value.find((x) => x.id == template.novaPoshtaAccountId);
  account.templates.push(template);
  showTemplateDialog.value = false;

  selectedTemplate.value = initTemplate;
};

const goBack = () => {
  router.go(-1);
};

const showCreateNewTemplate = () => {
  showTemplateDialog.value = true;
  selectedTemplate.value = initTemplate;
};

const getTemplateAccount = (template) => {
  return accounts.value.find((x) => x.templates.some((y) => y === template));
};

watch(defaultTemplate, async (newVal, oldValue) => {
  if (skipPersistDefaultTemplate.value) {
    skipPersistDefaultTemplate.value = false;
    return;
  }

  if (oldValue == defaultTemplate.value || !oldValue) return;
  try {
    await novaPoshtaService.setDefaultTemplate(defaultTemplate.value);

    templates.value.forEach((x) => {
      x.default = false;
    });

    const newTemplate = templates.value.find((x) => x.id == defaultTemplate.value);
    if (newTemplate) newTemplate.default = true;
  } catch {
    store.dispatch("addError");
  }
});
</script>
