<template>
  <div class="d-flex gap24 justify-space-between" :class="{ 'flex-column': mobile }">
    <InstagramCard :linked="integrations.includedIntegrations.some((x) => x.id === instagramIntegrationId)" @removed="instagramIntegrationId" />

    <NovaPoshtaCard
      :linked="integrations.includedIntegrations.some((x) => x.id === novaPoshtaIntegrationId)"
      @removed="removeIntegration(novaPoshtaIntegrationId)"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import InstagramCard from "./integrations/Instagram/Instagram.vue";
import NovaPoshtaCard from "./integrations/NovaPoshta/NovaPoshta.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { mobile } = useDisplay();
const store = useStore();

const instagramIntegrationId = 1;
const novaPoshtaIntegrationId = 2;

const integrations = computed(() => store.getters.integrations);

const removeIntegration = async (id) => {
  await store.dispatch("plugOffIntegration", id);
};
</script>
