<template>
  <div class="d-flex flex-column gap80 feedback-container" style="margin-top: 80px">
    <h1 class="text-center" id="feedback">Що кажуть наші <span>клієнти</span></h1>

    <v-sheet class="mx-auto" max-width="100%">
      <v-slide-group class="d-flex" show-arrows>
        <template #next>
          <v-btn icon="custom:sliderRightIcon" variant="elevated" />
        </template>
        <template #prev>
          <v-btn icon="custom:sliderLeftIcon" />
        </template>
        <v-slide-group-item key="1">
          <div class="review-container">
            <div class="review text-center gap4">
              <img :src="require('../../../assets/landing/new/serhii-review.png')" alt="Коментар Сергія" />
              <div class="reviewer-info">
                <h3>Сергій Карпинець</h3>
                <p class="subtitle highlight">@allforyou.ua</p>
              </div>
              <p class="body2 text-center">
                “Висока якість сервісу. Усі потрібні функції в один клік. Дуже задоволений CRM-системою. Постійно використовую її у роботі, дохідність зростає“
              </p>

              <div class="d-flex flex-column align-center justify-center">
                <v-rating :length="5" :size="16" :model-value="5" readonly active-color="rgba(253, 168, 41, 1)" density="comfortable" />
              </div>
            </div>
          </div>
        </v-slide-group-item>

        <v-slide-group-item key="2">
          <div class="review-container">
            <div class="review text-center gap4">
              <img :src="require('../../../assets/landing/new/hanna-review.png')" alt="Коментар Ганни" />
              <div class="reviewer-info">
                <h3>Анна Мироненко</h3>
                <p class="subtitle highlight">@beauty.her</p>
              </div>
              <p class="body2 text-center">
                “Знайома власниця інста-магазину порадила цей сервіс, і як я була здивована – раніше витрачала години на Excel, а тепер лічені хвилини!“
              </p>
              <div class="d-flex flex-column align-center justify-center">
                <v-rating :length="5" :size="16" :model-value="5" readonly active-color="rgba(253, 168, 41, 1)" density="comfortable" />
              </div>
            </div>
          </div>
        </v-slide-group-item>

        <v-slide-group-item key="3">
          <div class="review-container">
            <div class="review text-center gap4">
              <img :src="require('../../../assets/landing/new/dmytro-review.png')" alt="Коментар Ганни" />
              <div class="reviewer-info">
                <h3>Дмитро Вознесенський</h3>
                <p class="subtitle highlight">@retail_kyiv</p>
              </div>
              <p class="body2 text-center">
                “Це просто 🔥 Ще ніколи процес керування замовленнями та відправки не був таким простим та швидким. Дякую вам за цей чудо-сервіс!“
              </p>
              <div class="d-flex flex-column align-center justify-center">
                <v-rating :length="5" :size="16" :model-value="5" readonly active-color="rgba(253, 168, 41, 1)" density="comfortable" />
              </div>
            </div>
          </div>
        </v-slide-group-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>

<style>
.feedback-container .v-slide-group__content {
  gap: 40px;
}

@media (max-width: 788px) {
  .feedback-container .v-slide-group__content {
    gap: 0;
  }
}
</style>
<style scoped>
.review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 16px;

  width: 336px;
  filter: drop-shadow(0px 4px 20px rgba(90, 88, 88, 0.05));

  background-image: url(../../../assets/landing/new/review-bg.svg);
  background-size: 100% auto;
}

.reviewer-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  border-radius: 56px;
  height: 56px;
  width: 56px;
  object-fit: cover;
}

.review-container {
  padding: 16px;
}

.v-rating {
  display: flex;
  gap: 4px;
}

@media (max-width: 788px) {
  .review {
    width: calc(100vw - 136px);
  }
}
</style>
