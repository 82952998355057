<template>
  <div style="border-bottom: 1px solid var(--Divider, rgba(90, 88, 88, 0.1)); position: sticky; top: 0; background-color: white; z-index: 1000">
    <header class="header d-flex align-center justify-space-around">
      <router-link to="/" class="logo-container">
        <NamedLogoIcon style="width: 150px" class="named-logo-icon" />
        <LogoIcon style="width: 100px" class="logo-icon" />
      </router-link>

      <div class="gap40 d-none d-lg-flex">
        <a class="header-item" @click.stop.prevent="anchorHashCheck('features')" :class="{ active: route.hash === '#features' }">Функціонал</a>
        <a class="header-item" @click.stop.prevent="anchorHashCheck('tariffs')" :class="{ active: route.hash === '#tariffs' }">Тарифи</a>
        <a class="header-item" @click.stop.prevent="anchorHashCheck('feedback')" :class="{ active: route.hash === '#feedback' }">Відгуки</a>
        <a class="header-item" @click.stop.prevent="anchorHashCheck('contact-us')" :class="{ active: route.hash === '#contact-us' }">Зворотній зв'язок</a>
      </div>

      <div>
        <a href="https://t.me/weselli_news" target="_blank">
          <v-btn variant="text" color="primary" height="64" rounded class="join-telegram"
            ><TelegramSocial class="primary-icon" />Приєднуйся до <br />Телеграм-каналу</v-btn
          >
        </a>
      </div>

      <div class="gap40 d-none d-lg-flex">
        <v-btn variant="flat" color="primary" :to="{ name: 'login' }" rounded width="140px" height="48px"> Вхід </v-btn>

        <v-btn variant="outlined" color="primary" :to="{ name: 'register' }" rounded width="140px" height="48px"> Реєстрація </v-btn>
      </div>

      <v-menu v-model="menu" location="bottom" class="header-menu" :close-on-content-click="false">
        <template #activator="{ props, isActive }">
          <v-btn v-bind="props" class="hidden-lg-and-up" :icon="isActive ? 'custom:closeIcon' : 'custom:headerMenuIcon'" variant="text"></v-btn>
        </template>

        <div class="header-menu-content">
          <div></div>

          <div class="d-flex flex-column gap40 text-center">
            <a class="header-item" @click.stop.prevent="anchorHashCheck('features')">Функціонал</a>
            <a class="header-item" @click.stop.prevent="anchorHashCheck('feedback')">Відгуки</a>
            <a class="header-item" @click.stop.prevent="anchorHashCheck('tariffs')">Тарифи</a>
            <a class="header-item" @click.stop.prevent="anchorHashCheck('contact-us')">Зворотній зв'язок</a>
          </div>

          <div class="gap16 d-flex flex-column">
            <v-btn variant="flat" color="primary" :to="{ name: 'login' }" rounded width="280px" height="48px"> Вхід </v-btn>

            <v-btn variant="outlined" color="primary" :to="{ name: 'register' }" rounded width="280px" height="48px"> Реєстрація </v-btn>
          </div>
        </div>
      </v-menu>
    </header>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import NamedLogoIcon from "@/assets/NamedLogoIcon.vue";
import LogoIcon from "@/assets/LogoIcon.vue";
import TelegramSocial from "@/assets/custom-icons/TelegramSocial.vue";
import { nextTick, onMounted, onUnmounted, ref } from "vue";
const route = useRoute();
const router = useRouter();

const menu = ref(false);
onMounted(() => {
  anchorHashCheck(route.hash);
  document.addEventListener("scroll", onScroll);
});

onUnmounted(() => {
  document.removeEventListener("scroll", onScroll);
});

const onScroll = () => {
  const headings = document.querySelectorAll("h1");
  headings.forEach((ha) => {
    const rect = ha.getBoundingClientRect();
    if (ha.id && rect.top > 0 && rect.top < 150) {
      router.replace({ ...route, hash: `#${ha.id}` });
    }
  });
};

const anchorHashCheck = async (hash) => {
  menu.value = false;
  if (!hash) return;

  await nextTick();
  const el = document.getElementById(hash);
  if (el) {
    window.scrollTo({
      top: el.offsetTop + -84,
      left: 0,
      behavior: "smooth",
    });
  }
};
</script>

<style>
.header .header-item {
  color: var(--main-secondary, #5a5858);
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */

  text-decoration: none;
  cursor: pointer;
}

.header-item:hover {
  color: var(--main-primary, #2999fd);
}

.header-item.active {
  color: var(--main-primary, #2999fd);
}

header.header {
  background: var(--White, #fff);
  height: 84px;
}

.header-menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 48px;

  min-width: 100vw;
  min-height: calc(100vh - 48px);
  height: 100%;

  background: white;
}

.header-menu.v-overlay .v-overlay__content {
  position: fixed;
  top: 65px !important;
  left: 0 !important;
}

.header svg.social path {
  fill: #2999fd !important;
}

.join-telegram:hover svg.social path {
  opacity: 1 !important;
}
.logo-container {
  display: flex;
  align-items: center;
}
.named-logo-icon {
  display: block;
}
.logo-icon {
  display: none;
}
@media (max-width: 600px) {
  header.header {
    height: 64px;
  }
  .named-logo-icon {
    display: none;
  }

  .logo-icon {
    display: block;
  }
}
</style>
