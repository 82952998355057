<template>
  <AuthLayout>
    <div class="d-flex flex-column justify-space-between gap40">
      <div class="d-flex flex-column gap40">
        <div class="d-flex flex-column gap16">
          <div class="form-header">Вітаємо знову 👋🏻</div>
          <div class="pb-4 op07 form-subheader">Введіть облікові дані для отримання доступу до можливостей нашої платформи.</div>

          <div v-if="restricted">
            <p class="error">
              Ваш акаунт заблоковано. Зверніться до власника організації або напишіть нам в <a href="https://t.me/weselli_support">Телеграм</a>
            </p>
          </div>
        </div>

        <v-alert v-if="!loading && invalidCredentials" type="error" text="Пошта або пароль не вірні" variant="tonal" class="invalid-creds-alert"></v-alert>

        <div class="d-flex flex-column gap16">
          <v-form ref="formRef" id="login-form">
            <label class="body1">E-mail</label>
            <v-text-field
              class="mt-1"
              v-model="user.email"
              placeholder="Наприклад: shop@gmail.com"
              variant="outlined"
              :rules="[rules.required, rules.email]"
              density="compact"
            />

            <label class="body1">Пароль</label>
            <v-text-field
              class="mt-1"
              v-model="user.password"
              placeholder="************"
              variant="outlined"
              :rules="[rules.required]"
              :append-inner-icon="showPassword ? 'custom:eyeSlashIcon' : 'custom:eyeIcon'"
              :type="showPassword ? 'text' : 'password'"
              @click:append-inner="showPassword = !showPassword"
              :hide-details="rules.required(user.password) === true"
              density="compact"
            />
            <p class="forgot-pass"><span class="link" @click="router.push({ name: 'forgot-password' })">Забули пароль?</span></p>
          </v-form>

          <div class="d-flex flex-column gap8">
            <v-btn rounded class="form-button" @click="login" color="#2999FD" theme="dark" :loading="loading" data-test="login">{{
              $t("Login.Button.Login")
            }}</v-btn>

            <div class="not-registered">
              <span class="op07"> У вас немає акаунту? </span>
              <v-btn variant="text" color="#2999FD" :to="{ name: 'register' }" class="text-none"> Безкоштовна реєстрація </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="terms">
        Натискаючи на кнопку вище, ви погоджуєтеся з умовами
        <a href="/">Оферти</a>
      </div>
    </div>

    <!-- <v-overlay :value="confirmingEmail">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
  </AuthLayout>
</template>

<script setup>
import { requiredRule, emailRule } from "@/services/field.rules";
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import { accountService } from "../services/account.service";
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";

const router = useRouter();
const route = useRoute();
const store = useStore();

const user = reactive({
  email: null,
  password: null,
});
const showPassword = ref(false);

const restricted = ref(false);
const loading = ref(false);
const confirmingEmail = ref(false);
const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  email: (value) => emailRule(value, i18n.global.t("Common.ErrorMessage.Email")),
};

const invalidCredentials = ref(false);

onMounted(async () => {
  const token = route.query?.token?.toString();
  const id = route.query?.id?.toString();

  if (token && id) {
    try {
      confirmingEmail.value = true;
      await accountService.confirmRegistration({ token, id });
    } catch {
      store.dispatch("addNotification", {
        icon: "custom:warningIcon",
        text: i18n.global.t("Login.Label.InvalidConfirmRegisterLink"),
        color: "red",
      });
    } finally {
      confirmingEmail.value = false;
      router.replace({ query: {} });
    }
  }
});

const formRef = ref(null);
const login = async () => {
  const isFormValid = (await formRef.value.validate()).valid;
  if (!isFormValid) {
    return;
  }

  loading.value = true;
  try {
    await store.dispatch("login", user);
    await router.push({ name: "home" }).catch();
  } catch (error) {
    if (error.response?.data?.message === "restricted") restricted.value = true;

    if (error.response.status === 401) {
      invalidCredentials.value = true;
    } else {
      store.dispatch("addNotification", {
        icon: "custom:warningIcon",
        text: i18n.global.t("Login.Label.InvalidConfirmRegisterLink"),
        color: "red",
      });
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.invalid-creds-alert {
  border-radius: 50px;
}
</style>
