<template>
  <div class="d-flex flex-column justify-space-between gap40">
    <div class="d-flex flex-column gap80">
      <div class="d-flex flex-column gap16">
        <div class="form-header">{{ $t("ChangePassword.Label.ResetPassword") }}</div>
      </div>
      <div class="d-flex flex-column gap16">
        <v-form ref="formRef" id="reset-form">
          <v-text-field
            v-model="form.password"
            validate-on-blur
            variant="outlined"
            :placeholder="$t('Register.Placeholder.Password')"
            :rules="[rules.required, rules.password]"
            :append-inner-icon="showPassword ? 'custom:eyeSlashIcon' : 'custom:eyeIcon'"
            :type="showPassword ? 'text' : 'password'"
            @click:append-inner="showPassword = !showPassword"
            density="compact"
          />
          <v-text-field
            v-model="form.confirmPassword"
            validate-on-blur
            variant="outlined"
            :placeholder="$t('Register.Placeholder.ConfirmPassword')"
            :rules="[rules.required, rules.sameAsPassword]"
            :append-inner-icon="showConfirmPassword ? 'custom:eyeSlashIcon' : 'custom:eyeIcon'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append-inner="showConfirmPassword = !showConfirmPassword"
            density="compact"
          />
        </v-form>
        <v-btn rounded class="form-button" @click="changePass" color="#2999FD" :loading="loading" :disabled="loading">{{
          $t("ChangePassword.Button.ChangePassword")
        }}</v-btn>
      </div>
    </div>
    <div class="terms">
      Натискаючи на кнопку вище, ви погоджуєтеся з умовами
      <a href="/">Оферти</a>
    </div>
  </div>

  <v-dialog :model-value="successDialog" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5"> Успішно! </v-card-title>
      <v-card-text style="color: var(--main-secondary-color)">
        <p>{{ $t("ChangePassword.Label.PasswordChangedHeader") }}</p>
        <p>{{ $t("ChangePassword.Label.PasswordChangedDesc", { count: countDown }) }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn link :to="{ name: 'login' }" color="primary">{{ $t("ChangePassword.Button.GoToLogin") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { accountService } from "../services/account.service";
import { requiredRule } from "@/services/field.rules";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { reactive, ref } from "vue";
import i18n from "@/plugins/i18n";

const route = useRoute();
const router = useRouter();
const store = useStore();

const successDialog = ref(false);
const loading = ref(false);
const form = reactive({
  password: "",
  confirmPassword: "",
});
const showPassword = ref(false);
const showConfirmPassword = ref(false);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  password: (value) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value) || i18n.global.t("Register.ErrorMessage.PasswordFormatInvalid");
  },
  sameAsPassword: (value) => {
    return value === form.password || i18n.global.t("Register.ErrorMessage.ConfirmPassword");
  },
};

const formRef = ref(null);
const changePass = async () => {
  const isFormValid = (await formRef.value.validate()).valid;
  if (!isFormValid) {
    return;
  }

  const changePasswordVm = {
    password: form.password,
    passwordConfirmation: form.confirmPassword,
    token: route.query.token.toString(),
    id: route.query.id.toString(),
  };

  try {
    loading.value = true;
    await accountService.changePassword(changePasswordVm);
    countDownTimer();
    successDialog.value = true;
  } catch {
    store.dispatch("addNotification", {
      icon: "custom:warningIcon",
      text: i18n.global.t("ChangePassword.Label.CantChangePassword"),
      color: "red",
      duration: 3500,
    });
  } finally {
    loading.value = false;
  }
};

var countDown = 5;
const countDownTimer = () => {
  if (countDown > 0) {
    setTimeout(() => {
      countDown -= 1;
      countDownTimer();
    }, 1000);
  } else {
    router.push({ name: "login" });
  }
};
</script>
