import { api } from "@/services/api.service";
import { formatString } from "@/services/util";

const endpoints = {
  SEARCH: "/api/goods/search",
  POST_CREATE_GOOD: "/api/goods",
  PATCH_UPDATE_GOOD: "/api/goods/{0}",
  GET_BY_ID: "/api/goods/{0}",
  ARCHIVE_GOODS: "api/goods/soft-delete",
};

function getGoodsService() {
  const archiveGoods = async (selectedGoods) => {
    return (
      await api.post(
        endpoints.ARCHIVE_GOODS,
        selectedGoods.map((x) => x.id)
      )
    ).data;
  };

  const update = async (id, operations) => {
    return (await api.patch(formatString(endpoints.PATCH_UPDATE_GOOD, id), operations)).data;
  };

  const updatePicture = async (id, picture) => {
    const formData = new FormData();
    formData.append("file", picture);

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return (await api.patch(formatString(endpoints.PATCH_UPDATE_GOOD, id) + "/picture", formData, options)).data;
  };

  const search = async (searchQuery) => {
    const query = searchQuery || { page: 1, pageSize: 10 };
    return (await api.post(endpoints.SEARCH, query)).data;
  };

  const createGood = async (good) => {
    const formData = new FormData();

    if (good.picture) formData.append("picture", good.picture, good.picture.name);

    Object.keys(good)
      .filter((x) => x != "picture")
      .forEach((key) => {
        formData.append(key, good[key]);
      });

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return (await api.post(endpoints.POST_CREATE_GOOD, jsonToFormData(good), options)).data;
  };

  const getGoodById = async (goodId) => {
    return (await api.get(formatString(endpoints.GET_BY_ID, goodId))).data;
  };

  const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  };

  const jsonToFormData = (data) => {
    const formData = new FormData();

    buildFormData(formData, data, "");

    return formData;
  };

  return {
    archiveGoods,
    update,
    updatePicture,
    search,
    createGood,
    getGoodById,
  };
}

export const goodsService = getGoodsService();
