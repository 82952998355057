<template>
  <v-overlay :modelValue="overlay">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>

  <div v-if="config.selectable" class="d-flex flex-column gap8">
    <div class="body1">Групова дія</div>
    <div class="d-flex gap16 justify-start flex-wrap">
      <NovaPoshtaToolbarActions
        :selected="modelValue"
        :selector="(o) => o.deliveryInvoiceNumber"
        @change-overlay="changeOverlay"
        @data-updated="loadOrders(options)"
      />

      <!-- <v-btn rounded color="primary" height="36">
        <v-icon class="white-icon">custom:editIcon</v-icon>
        <span class="group-action-item">Змінити статус</span>
      </v-btn> -->

      <!-- <Prompt :title="recycleBinPromptTitle" :text="recycleBinPromptBody" @confirmed="archiveOrders(modelValue)" :disabled="!modelValue.length">
        <Tooltip :text="!modelValue.length ? $t('Orders.Label.SelectAtLeastOneOrder') : $t('Orders.Label.MoveToRecycleBinOrder')">
          <v-btn rounded color="var(--additional-fail-color)" height="36" :disabled="!modelValue.length">
            <span class="d-flex align-center" style="color: white">
              <v-icon class="trash-icon">custom:trashIcon</v-icon>

              <span class="group-action-item">Видалити</span>
            </span>
          </v-btn>
        </Tooltip>
      </Prompt> -->

      <OrderExport v-if="config?.export?.enabled">
        <v-btn rounded height="36">
          <span class="d-flex align-center gap4">
            <v-icon class="trash-icon">mdi-table-arrow-right</v-icon>

            <span class="group-action-item">Експорт</span>
          </span>
        </v-btn>
      </OrderExport>
    </div>
  </div>

  <v-data-table-server
    :headers="config.headers"
    :items="orders"
    class="body1"
    :show-select="config.selectable"
    :loading="loading"
    :modelValue="modelValue"
    @update:model-value="onOrderSelectedChange"
    @update:options="loadOrders"
    :search="options.search"
    :items-length="totalServerItems"
    :items-per-page-options="itemsPerPage"
    :page="options.page"
    return-object
    @click:row="(e, { item }) => goToOrderDetailsPage(item.id)"
    hover
  >
    <template v-slot:[`item.createdOn`]="{ item }">
      <pre style="font: inherit">{{ moment.utc(item.createdOn).local().format("DD.MM.YY[\n]HH:mm") }}</pre>
    </template>
    <template v-slot:[`item.orderLineItems`]="{ item }">
      <pre style="font: inherit">{{
        item.orderLineItems.length == 1
          ? formatOrderLineItem(item.orderLineItems[0])
          : item.orderLineItems.map((el, i) => `${i + 1}. ${formatOrderLineItem(el)}`).join("\n")
      }}</pre>
    </template>
    <template v-slot:[`item.total`]="{ item }">
      <div class="d-flex gap8">
        <span class="op05">₴</span> <span>{{ item.total }}</span>
      </div>
    </template>
    <template v-slot:[`item.fullName`]="{ item }">
      <div class="d-flex flex-column">
        <div class="d-flex" style="flex-direction: row; justify-content: space-between">
          <div>{{ item.fullName }}</div>
        </div>
        <div v-if="showUsername && item.sourceId === 1">
          <router-link @click.prevent.stop="() => {}" :to="{ name: 'inbox-thread', params: { id: item.sourceData } }">@{{ item.userName }}</router-link>
        </div>
      </div>
    </template>
    <template v-slot:[`item.deliveryInvoiceNumber`]="{ item }">
      <div class="d-flex justify-center gap8" @click.stop.prevent="copyText(item.deliveryInvoiceNumber)">
        <Tooltip :text="$t('Orders.Delivery.Tooltip.NovaPoshtaPrinted')">
          <v-icon v-if="item.printed">mdi-printer</v-icon>
        </Tooltip>

        <v-hover v-slot="{ props, isHovering }">
          <Tooltip text="Скопіювати">
            <span v-bind="props" :id="item.deliveryInvoiceNumber">{{ item.deliveryInvoiceNumber }}</span>
            <v-expand-transition>
              <v-btn v-if="isHovering" v-bind="props" icon color="black" size="22" variant="flat" class="copy-btn op05">
                <v-icon size="x-small">mdi-clipboard-outline</v-icon>
              </v-btn>
            </v-expand-transition>
          </Tooltip>
        </v-hover>
      </div>
      <template v-if="item.deliveryInvoiceNumber">
        <div class="d-flex flex-column">
          <Tooltip :text="item.deliveryStatus">
            <v-chip v-if="item.deliveryStatus" link style="max-width: 200px" :color="novaPoshtaStatusColors[item.deliveryStatusId]">
              <span
                :class="{ 'font-weight-bold': item.deliveryStatusId == 7 || item.deliveryStatusId == 8 }"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
              >
                {{ item.deliveryStatus }}
              </span>
            </v-chip>
          </Tooltip>
        </div>
        <Tooltip
          :text="getEstimatedDeliveryTooltip(item)"
          v-if="item.estimatedDeliveryDate && item.deliveryStatusId != 1 && (item.deliveryStatusId < 9 || item.deliveryStatusId > 11)"
        >
          <span style="cursor: pointer">{{ moment.utc(item.estimatedDeliveryDate, "YYYY-DD-MM").local().format("DD.MM") }}</span>
        </Tooltip>
      </template>
    </template>
    <template v-slot:[`item.createdById`]="{ item }">
      <AvatarSlim :userId="item.createdById" />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-menu>
        <template #activator="{ props }">
          <v-btn v-bind="props" icon="custom:dotsVerticalIcon" variant="text"></v-btn>
        </template>

        <v-list>
          <v-list-item link>
            <Tooltip :text="$t('Orders.Label.ViewOrder')">
              <v-btn icon @click="goToOrderDetailsPage(item.id)" variant="plain">
                <v-icon>custom:eyeOutlineIcon</v-icon>
              </v-btn>
            </Tooltip>
          </v-list-item>

          <v-list-item link>
            <Tooltip :text="$t('Orders.Label.EditOrder')">
              <v-btn icon @click="goToOrderEditPage(item.id)" variant="plain">
                <v-icon>custom:editIcon</v-icon>
              </v-btn>
            </Tooltip>
          </v-list-item>

          <v-list-item link>
            <Prompt :title="$t('Orders.Label.ConfirmRecycleBinOrders')" @confirmed="archiveOrders([item])">
              <Tooltip :text="$t('Orders.Label.MoveToRecycleBinOrder')">
                <v-btn icon variant="plain">
                  <v-icon>custom:trashIcon</v-icon>
                </v-btn>
              </Tooltip>
            </Prompt>
          </v-list-item>

          <NovaPoshtaToolbarActions
            :icon="true"
            :selected="[item]"
            :selector="(o) => o.deliveryInvoiceNumber"
            @change-overlay="changeOverlay"
            @data-updated="loadOrders(options)"
          />
        </v-list>
      </v-menu>
    </template>
  </v-data-table-server>
</template>

<script setup>
import { novaPoshtaStatusColors } from "@/modules/orders/views/order.configs";
import moment from "moment";
import { orderService } from "../services/order.service";
import Tooltip from "@/components/Tooltiped.vue";
import Prompt from "@/components/Prompt.vue";
import NovaPoshtaToolbarActions from "../components/NovaPoshtaToolbarActions.vue";
import { onMounted, reactive, ref, watch, defineProps, defineEmits, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";
import mitt from "@/services/mitt";
import measureUnits from "../models/orderLineMeasureUnits";
import OrderExport from "./OrderExport.vue";
import AvatarSlim from "@/components/AvatarSlim.vue";
import { copyText } from "@/services/util";

const emits = defineEmits(["update:modelValue"]);
const props = defineProps(["modelValue", "config", "showUsername"]);
const router = useRouter();
const route = useRoute();
const store = useStore();

const totalServerItems = ref(0);
const options = reactive({ page: 1, itemsPerPage: 10, search: "" });
const itemsPerPage = [
  { title: "10", value: 10 },
  { title: "20", value: 20 },
  { title: "30", value: 30 },
];
const orders = ref([]);
const loading = ref(true);

const recycleBinPromptTitle = i18n.global.t("Orders.Label.ConfirmRecycleBinOrders");

const recycleBinPromptBody = computed(
  () => `<h3 class="h3">Замовлення</h3><p></p><ul>${props.modelValue.map((x) => `<li><span>#${x.orderNumber}</span></li>`).join("")}<ul>`
);

const goToOrderDetailsPage = (orderId) => {
  router.push({ name: "order-details", params: { id: orderId } });
};

const goToOrderEditPage = (orderId) => {
  router.push({ name: "order-edit", params: { id: orderId } });
};

const members = store.getters.members;

onMounted(async () => {
  if (props.config.queryPaging) {
    options.search = (route.query.search || "").toString();
    options.page = +route.query.page || 1;
    options.itemsPerPage = +route.query.pageSize || 10;

    mitt.emit("set-search", options.search);
  }
  loading.value = false;

  loadOrders(options);
});

const loadOrders = async ({ page, itemsPerPage, search }) => {
  if (loading.value) return;

  emits("update:modelValue", []);

  const query = { page: page, pageSize: itemsPerPage };
  if (search) {
    query.search = search;
  }
  if (props.config.queryPaging && (route.query.page != query.page || route.query.pageSize != query.pageSize || route.query.search != query.search)) {
    router.push({
      path: route.path,
      query: query,
    });
  }

  loading.value = true;
  try {
    const data = await orderService.search({
      page: page,
      pageSize: itemsPerPage,
      filters: props.config.filters,
      search: query.search,
    });
    totalServerItems.value = data.totalCount;
    orders.value = data.results;
  } catch {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
};

const getEstimatedDeliveryTooltip = (item) => {
  return (
    item.estimatedDeliveryDate &&
    i18n.global.t("Orders.Delivery.Tooltip.EstimatedDeliveryData", { date: moment.utc(item.estimatedDeliveryDate, "YYYY-DD-MM").local().format("LL") })
  );
};

const onOrderSelectedChange = (selected) => {
  emits("update:modelValue", selected);
};

const archiveOrders = async (orders) => {
  try {
    await orderService.archiveOrders(orders);
    await loadOrders(options);

    store.dispatch("addNotification", {
      icon: "custom:warningIcon",
      text: i18n.global.t("Orders.Label.OrderArchived"),
      color: "primary",
      duration: 3500,
    });
  } catch {
    store.dispatch("addError");
  }
};

const overlay = ref(false);
const changeOverlay = (value) => {
  overlay.value = value;
};

const formatOrderLineItem = (orderLineItem) => {
  let formatted = orderLineItem.title;

  if (orderLineItem.quantity !== 1) {
    formatted += ` (${orderLineItem.quantity} ${measureUnits[orderLineItem.measureUnit]})`;
  }

  return formatted;
};

watch(
  () => props.config.searchText,
  () => {
    options.search = props.config.searchText;
  }
);
</script>

<style>
@media (max-width: 579px) {
  .group-action-item {
    display: none;
  }
}

.v-chip__content {
  overflow: hidden;
}

.trash-icon path {
  fill: white;
}
.trash-icon path:nth-child(2) {
  stroke: white;
}

.copy-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
</style>
