import i18n from "@/plugins/i18n";
export const headers = [
  {
    title: "Фото",
    align: "start",
    sortable: false,
    key: "pictureUrl",
    value: "pictureUrl",
  },
  {
    title: i18n.global.t("Warehouses.Goods.Headers.InventoryNumber"),
    align: "center",
    sortable: false,
    key: "inventoryNumber",
    value: "inventoryNumber",
  },
  {
    title: i18n.global.t("Warehouses.Goods.Headers.Title"),
    align: "start",
    sortable: false,
    key: "title",
    value: "title",
  },
  {
    title: i18n.global.t("Warehouses.Goods.Headers.Cost"),
    align: "start",
    sortable: false,
    key: "cost",
    value: "cost",
  },
  {
    title: i18n.global.t("Warehouses.Goods.Headers.UnitPrice"),
    align: "start",
    sortable: false,
    key: "unitPrice",
    value: "unitPrice",
  },
  {
    title: i18n.global.t("Warehouses.Goods.Headers.TotalAmount"),
    align: "start",
    sortable: false,
    key: "totalAmount",
    value: "totalAmount",
  },
  {
    title: i18n.global.t("Warehouses.Goods.Headers.Quantity"),
    align: "start",
    sortable: false,
    key: "quantity",
    value: "quantity",
  },
  {
    title: i18n.global.t("Warehouses.Goods.Headers.MeasureUnit"),
    align: "start",
    sortable: false,
    key: "measureUnit",
    value: "measureUnit",
  },
  {
    title: "Дія",
    align: "start",
    sortable: false,
    key: "actions",
    value: "",
  },
];

export const measureUnitGroups = [
  {
    id: "Count",
    title: i18n.global.t("Warehouses.Goods.Label.MeasureUnitGroups.Count"),
    measureUnits: [{ id: "Item", title: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Item") }],
  },
  {
    id: "Weight",
    title: i18n.global.t("Warehouses.Goods.Label.MeasureUnitGroups.Weight"),
    measureUnits: [
      { id: "Miligram", title: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Miligram") },
      { id: "Gram", title: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Gram") },
      { id: "Kilogram", title: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Kilogram") },
    ],
  },
  {
    id: "Length",
    title: i18n.global.t("Warehouses.Goods.Label.MeasureUnitGroups.Length"),
    measureUnits: [
      { id: "Milimeter", title: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Milimeter") },
      { id: "Centimeter", title: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Centimeter") },
      { id: "Meter", title: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Meter") },
    ],
  },
  {
    id: "Volume",
    title: i18n.global.t("Warehouses.Goods.Label.MeasureUnitGroups.Volume"),
    measureUnits: [
      { id: "Milliliter", title: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Milliliter") },
      { id: "Liter", title: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Liter") },
    ],
  },
];

export const measureUnits = measureUnitGroups.flatMap((x) => x.measureUnits);
