import Goods from "./views/Goods.vue";
import NewGood from "./views/NewGood.vue";
import NewComponent from "./views/NewComponent.vue";
import GoodDetails from "./views/GoodDetails.vue";
import EditGood from "./views/EditGood.vue";

export const routes = [
  {
    path: "/goods/new-good",
    name: "new-good",
    component: NewGood,
    props: { goodType: 1 },
    meta: { title: "Створення Товару | Weselli" },
  },
  {
    path: "/goods/new-good-component",
    name: "new-good-component",
    component: NewComponent,
    props: { goodType: 2 },
    meta: { title: "Створення Компоненту | Weselli" },
  },
  {
    path: "/goods",
    name: "goods",
    component: Goods,
    meta: { title: "Список Товарів та Компонентів | Weselli" },
  },
  {
    path: "/goods/:id",
    name: "good-details",
    component: GoodDetails,
    meta: { title: "Деталі Товару/Компонента | Weselli" },
  },
  {
    path: "/goods/:id/edit",
    name: "edit-good",
    component: EditGood,
    meta: { title: "Редагування Товару/Компонента | Weselli" },
  },
];
