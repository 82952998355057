<template>
  <div class="d-flex flex-column gap24">
    <h1>Оплата</h1>

    <div class="d-flex gap16 billing align-start">
      <div class="d-flex flex-column gap16 flex-grow-1 flex-shrink-0" style="min-width: 100px; max-width: 100%">
        <div class="d-flex flex-column gap8">
          <v-divider></v-divider>
          <span>Спосіб оплати</span>
          <v-radio-group v-model="paymentType" hide-details>
            <v-radio label="Оплата картою онлайн" value="card_online" />
          </v-radio-group>
          <v-divider></v-divider>
        </div>

        <div class="d-flex flex-column">
          <span class="h3">Історія оплат</span>
          <span class="body op07">
            Оплачуйте та переглядаєте історію оплат.
            <a href="https://t.me/weselli_support" target="_blank">
              <v-btn variant="text" color="primary" rounded>По питанням та пропозиціям вам сюди</v-btn>
            </a>
          </span>
        </div>
        <v-data-table :items="invoices" :headers="headers" :loading="loading">
          <template v-slot:[`item.createdOn`]="{ value }"> {{ moment.utc(value).local().format("LL") }}</template>
          <template v-slot:[`item.status`]="{ value }">
            <v-chip :color="invoiceStatusColorMapping[value.id]">
              <span>{{ value.title }}</span>
            </v-chip></template
          >
          <template v-slot:[`item.invoice`]="{ item }">
            <div class="d-flex gap8">
              <v-btn v-if="item.status.id !== 2 && item.status.id !== 3" color="primary" rounded @click="tryPay(item.invoiceId)">Оплатити</v-btn>
            </div>
          </template>
        </v-data-table>
      </div>

      <!-- <div class="d-flex flex-column gap8 subscription-info" style="min-width: 250px">
        <span>Ваш План</span>

        <span class="h2 text-white">Стандарт</span>

        <span>Оновлюється 1 Чер 2024</span>

        <v-btn variant="outlined" rounded>Змінити</v-btn>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { invoiceService } from "../services/invoice.service";
const store = useStore();
const router = useRouter();

const headers = [
  {
    key: "number",
    title: "#",
    value: "number",
  },
  {
    key: "createdOn",
    title: "Дата рахунку",
    value: "createdOn",
  },
  {
    key: "plan",
    title: "План",
    value: "billingPlan.title",
  },
  {
    key: "amount",
    title: "Сума, грн",
    value: "amount",
  },
  {
    key: "status",
    title: "Статус",
    value: "status",
  },
  {
    key: "invoice",
    title: "Дія",
    value: "",
  },
];

const invoiceStatusColorMapping = {
  1: "#FFC107",
  2: "#18b94f",
  3: "#ff3f3f",
};

const paymentType = "card_online";

const invoices = ref([]);
const loading = ref(true);

onMounted(async () => {
  try {
    invoices.value = (await invoiceService.search()).results;
  } catch (error) {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
});

const tryPay = async (invoiceId) => {
  console.log("as", invoiceId);
  try {
    window.location.href = await getCheckoutLink(invoiceId);
  } catch (error) {
    store.dispatch("addError");
  }
};

const getCheckoutLink = async (invoiceId) => {
  var checkoutData = await invoiceService.getCheckout(invoiceId);
  return checkoutData.checkoutUrl;
};
</script>

<style scoped>
.billing {
  width: 100%;
}
.payment-history {
  width: auto;
}

.subscription-info {
  width: 150px;
  background-color: var(--main-primary-color);
  border-radius: 25px;
  padding: 16px;
  color: white;
  height: min-content;
}
</style>
