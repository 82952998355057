import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import emitter from "./services/mitt";

import "vue3-emoji-picker/css";
import "./assets/base.css";
// import "@/assets/base.css";
// Vue.use(VueApexCharts);

// Vue.component("apex-chart", VueApexCharts);

// Vue.directive("longpress", {
//   bind: function (el, binding) {
//     // Define variable
//     let pressTimer = null;

//     // Define funtion handlers
//     // Create timeout ( run function after 1s )
//     const start = (e) => {
//       if (e.type === "click" && e.button !== 0) {
//         return;
//       }

//       if (pressTimer === null) {
//         pressTimer = setTimeout(() => {
//           // Run function
//           handler(e);
//         }, 400);
//       }
//     };

//     // Cancel Timeout
//     const cancel = () => {
//       // Check if timer has a value or not
//       if (pressTimer !== null) {
//         clearTimeout(pressTimer);
//         pressTimer = null;
//       }
//     };
//     // Run Function
//     const handler = (e) => {
//       binding.value(e);
//     };

//     // Add Event listeners
//     el.addEventListener("mousedown", start);
//     el.addEventListener("touchstart", start);
//     // Cancel timeouts if this events happen
//     el.addEventListener("click", cancel);
//     el.addEventListener("mouseout", cancel);
//     el.addEventListener("touchend", cancel);
//     el.addEventListener("touchcancel", cancel);
//   },
// });

// Vue.filter("truncate", function (text, length, suffix) {
//   return text.length > length ? text.substring(0, length) + suffix : text;
// });

const app = createApp(App);
app.config.globalProperties.emitter = emitter;

app.use(i18n);
app.use(store);
app.use(router);
app.use(vuetify);
app.mount("#app");
