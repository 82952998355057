<template>
  <v-btn color="primary" class="d-flex" @click="logout" style="height: 48px; padding: 8px 16px" rounded>
    <div class="d-flex gap8"><v-icon class="white">custom:logoutIcon</v-icon> Вийти з облікового запису</div>
  </v-btn>
</template>

<script setup>
import { useStore } from "vuex";

const store = useStore();

const logout = async () => {
  await store.dispatch("logout");
};
</script>
