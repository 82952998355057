import { inboxCommentsService } from "./services/inbox-comments.service";
import { inboxService } from "./services/inbox.service";

const mutation = {
  UPDATE_INBOX: "UPDATE_INBOX",
  UPDATE_INBOX_COMMENTS: "UPDATE_INBOX_COMMENTS",
  UPDATE_SEARCH_QUERY_COMMENTS: "UPDATE_SEARCH_QUERY_COMMENTS",
  UPDATE_SEARCH_QUERY: "UPDATE_SEARCH_QUERY",
  SET_ACTIVE_THREAD: "SET_ACTIVE_THREAD",
  SET_ACTIVE_THREAD_SEEN: "SET_ACTIVE_THREAD_SEEN",
  SET_UNSEEN_COUNTERS: "SET_UNSEEN_COUNTERS",
  MARK_COMMENT_AS_SEEN: "MARK_COMMENT_AS_SEEN",
  REMOVE_DELETED_COMMENT: "REMOVE_DELETED_COMMENT",
  MODIFY_INBOX_SEEN_COUNTER: "MODIFY_INBOX_SEEN_COUNTER",
  MARK_LAST_MESSAGE_AS_CUSTOMER_READ: "MARK_LAST_MESSAGE_AS_CUSTOMER_READ",
};

const state = {
  inbox: { threads: [], searchQuery: { page: 1, pageSize: 30 }, totalCount: 0, loaded: false },
  commentsData: {
    comments: [],
    searchQuery: { page: 1, pageSize: 30, search: "" },
    unseenCount: null,
    totalCount: 0,
  },
  unseenCount: null,
  activeThread: null,
  lazyLoadedDirectCount: 0,
  lazyLoadedCommentsCount: 0,
};
const getters = {
  inbox: (state) => state.inbox,
  inboxUnseenCount: (state) => (state.unseenCount !== null ? state.unseenCount : state.lazyLoadedDirectCount),
  activeThread: (state) => state.activeThread,
  commentsData: (state) => state.commentsData,
  inboxCommentUnseenCount: (state) => (state.commentsData.unseenCount !== null ? state.commentsData.unseenCount : state.lazyLoadedCommentsCount),
  totalInboxUnseenCount: (state, getters) => getters.inboxUnseenCount + getters.inboxCommentUnseenCount,
};
const actions = {
  async refreshInbox({ commit, state }, searchQuery) {
    const currentSearchQuery = searchQuery || state.inbox.searchQuery;
    if (searchQuery) commit(mutation.UPDATE_SEARCH_QUERY, searchQuery);

    const pagedList = await inboxService.search(currentSearchQuery);

    commit(mutation.UPDATE_INBOX, pagedList);
  },

  async refreshInboxComments({ commit, state }, searchQuery) {
    const currentSearchQuery = searchQuery || state.commentsData.searchQuery;
    if (searchQuery) commit(mutation.UPDATE_SEARCH_QUERY_COMMENTS, searchQuery);

    const pagedList = await inboxCommentsService.search(currentSearchQuery);

    commit(mutation.UPDATE_INBOX_COMMENTS, pagedList);
  },

  setActiveThread({ commit }, thread) {
    commit(mutation.SET_ACTIVE_THREAD, thread);
  },

  setSeenActiveThread({ commit, state }) {
    if (!state.activeThread) return;

    commit(mutation.SET_ACTIVE_THREAD_SEEN);
  },

  toggleInboxThreadSeen({ commit }, unread) {
    commit(mutation.MODIFY_INBOX_SEEN_COUNTER, unread ? -1 : 1);
  },

  async setUnseenCounters({ commit }) {
    const unseenCouters = await inboxService.getUnseenCounters();
    commit(mutation.SET_UNSEEN_COUNTERS, unseenCouters);
  },

  async updateCommentReplied({ commit }, comment) {
    await inboxCommentsService.toggleReplied(comment.id, !comment.isReplied);
    comment.isReplied = !comment.isReplied;

    commit(mutation.MARK_COMMENT_AS_SEEN, comment);
  },

  async setCommentReplied({ commit }, comment) {
    comment.isReplied = true;
    commit(mutation.MARK_COMMENT_AS_SEEN, comment);
  },

  removeMediaComment({ commit }, payload) {
    commit(mutation.REMOVE_DELETED_COMMENT, payload);
  },
  customerReadLastMessage({ commit }, threadId) {
    commit(mutation.MARK_LAST_MESSAGE_AS_CUSTOMER_READ, threadId);
  },
};
const mutations = {
  [mutation.UPDATE_INBOX](state, pagedList) {
    state.inbox.threads = pagedList.inbox.results;
    state.inbox.totalCount = pagedList.inbox.totalCount;
    state.unseenCount = pagedList.unseenCount;
    state.inbox.loaded = true;
  },
  [mutation.UPDATE_SEARCH_QUERY](state, searchQuery) {
    state.inbox.searchQuery = searchQuery;
  },
  [mutation.UPDATE_INBOX_COMMENTS](state, pagedList) {
    state.commentsData.comments = pagedList.comments.results;
    state.commentsData.totalCount = pagedList.comments.totalCount;
    state.commentsData.unseenCount = pagedList.unrepliedCount;
  },
  [mutation.UPDATE_SEARCH_QUERY_COMMENTS](state, searchQuery) {
    state.commentsData.searchQuery = searchQuery;
  },
  [mutation.SET_ACTIVE_THREAD](state, thread) {
    state.activeThread = thread;
  },
  [mutation.SET_UNSEEN_COUNTERS](state, { direct, comments }) {
    state.lazyLoadedCommentsCount = comments;
    state.lazyLoadedDirectCount = direct;
  },
  [mutation.MARK_COMMENT_AS_SEEN](state, comment) {
    if (comment.isReplied && state.commentsData.unseenCount && state.commentsData.unseenCount > 0) {
      --state.commentsData.unseenCount;
    } else if (!comment.isReplied) {
      state.commentsData.unseenCount += 1;
    }
  },
  [mutation.REMOVE_DELETED_COMMENT](state, payload) {
    const media = state.commentsData.comments.find((x) => x.id === payload.mediaId);
    const removedComments = media.comments.splice(
      media.comments.findIndex((x) => x.id === payload.commentId),
      1
    );

    if (media.comments.length == 0) {
      state.commentsData.comments.splice(state.commentsData.comments.indexOf((x) => x.id == media.id));
    }

    if (!removedComments[0].isReplied) --state.commentsData.unseenCount;
  },
  [mutation.SET_ACTIVE_THREAD_SEEN](state) {
    if (state.unseenCount && state.unseenCount > 0) {
      --state.unseenCount;
    }
  },
  [mutation.MODIFY_INBOX_SEEN_COUNTER](state, value) {
    if (state.unseenCount === null) {
      state.unseenCount = 0;
    }

    state.unseenCount = state.unseenCount + value;
  },
  [mutation.MARK_LAST_MESSAGE_AS_CUSTOMER_READ](state, threadId) {
    const foundThread = state.inbox.threads.find((x) => x.id == threadId);
    if (!foundThread) return;

    foundThread.lastMessage.readByCustomer = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
