<template>
  <div class="pa-8 d-flex flex-column gap24">
    <h1 class="d-flex gap8 align-center">
      <v-btn icon="custom:arrowLeftIcon" style="font-size: 24px" variant="text" @click="goBack" />
      Налаштування Facebook API та Instagram API
    </h1>
    <v-card class="pa-6 d-flex flex-column gap24">
      <div class="d-flex flex-column gap8">
        <div class="title">Налаштування підключених акаунтів Instagram та Facebook</div>
        <div class="subtitle op05">Налаштуйте інтегровані акаунти та бота для Facebook Messenger</div>
      </div>

      <div class="d-flex flex-column gap16">
        <div class="subtitle2 op07">Підключені акаунти</div>
        <div>
          <InstagramAccount />
        </div>
      </div>

      <div class="d-flex flex-column gap16">
        <div class="subtitle2 op07">Шаблонні відповіді для Instagram</div>
        <InstagramReplyTemplates />
      </div>

      <div class="d-flex flex-column gap16">
        <div class="subtitle2 op07">Налаштування бота автоматичних відповідей для Facebook Messenger</div>

        <v-alert border="start" type="warning" color="rgba(200, 200, 200, 0.1)" border-color="amber-darken-3">
          <template #prepend>
            <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
              <v-icon color="amber-darken-3">custom:warningIcon</v-icon>
            </v-btn>
          </template>
          <div class="d-flex flex-column gap8">
            <h4 class="subtitle2" style="color: #5a5858">Обов’язково!</h4>
            <span class="body1 op07" style="color: #5a5858">
              При відключеному боті ВСІ відповіді до повідомлень не працюють. Щоб відповіді запрацювали – включіть бота!
            </span>
          </div>
        </v-alert>

        <v-switch :label="$t('Settings.Integrations.InstagramAPI.Settings.BotStatusLabel')" inset color="primary" />
        <v-alert border="start" type="warning" color="rgba(200, 200, 200, 0.1)" border-color="amber-darken-3">
          <template #prepend>
            <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
              <v-icon color="amber-darken-3">custom:warningIcon</v-icon>
            </v-btn>
          </template>
          <div class="d-flex flex-column gap8">
            <h4 class="subtitle2" style="color: #5a5858">Увага!</h4>
            <span class="body1"> {{ $t("Settings.Integrations.InstagramAPI.Settings.BotStatusDisabledAlert") }} </span>
          </div>
        </v-alert>

        <div class="d-flex justify-end">
          <v-btn color="primary" rounded @click="openReplyDialog(newItemTemplate)"> створити авто відповідь </v-btn>
        </div>
        <v-data-table :items="autoReplies" :headers="headers" class="elevation-1" :loading="loadingReplies">
          <template v-slot:[`item.createdOn`]="{ item }">
            {{ moment.utc(item.createdOn).local().format("LLL") }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <Tooltipped :text="$t('Common.Button.Edit')">
              <v-icon color="orange" small class="mr-2" @click="openReplyDialog(item)"> custom:editIcon </v-icon>
            </Tooltipped>

            <Prompt :title="removePromptTitle" @confirmed="deleteAutoReply(item)">
              <Tooltipped :text="$t('Common.Button.Remove')">
                <v-btn icon color="red" small>
                  <v-icon> custom:trashIcon </v-icon>
                </v-btn>
              </Tooltipped>
            </Prompt>
          </template>

          <template v-slot:[`item.enabled`]="{ item }">
            <v-checkbox v-model="item.enabled" disabled />
          </template>
        </v-data-table>
      </div>
    </v-card>
    <!--<FacebookAutoReplyDialog v-model="isReplyDialogOpened" :item="currentAutoReply" @changed="changedReply" /> -->
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import moment from "moment";

import Tooltipped from "@/components/Tooltiped.vue";
import Prompt from "@/components/Prompt.vue";
import { facebookAutoReplyService } from "@/modules/settings/services/facebook-autoreply.service";
import InstagramAccount from "../components/integrations/Instagram/InstagramAccountInfo.vue";

import InstagramReplyTemplates from "../components/integrations/Instagram/InstagramReplyTemplates.vue";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();

const loadingReplies = ref(true);

const autoReplies = ref([]);
const headers = [
  {
    title: i18n.global.t("Settings.Integrations.InstagramAPI.Settings.ReplyHeaders.Title"),
    align: "start",
    key: "title",
    value: "title",
  },
  {
    title: i18n.global.t("Settings.Integrations.InstagramAPI.Settings.ReplyHeaders.KeyWords"),
    align: "start",
    key: "keywords",
    value: "keywords",
  },
  {
    title: i18n.global.t("Settings.Integrations.InstagramAPI.Settings.ReplyHeaders.Active"),
    align: "start",
    key: "enabled",
    value: "enabled",
  },
  {
    title: i18n.global.t("Settings.Integrations.InstagramAPI.Settings.ReplyHeaders.CreatedOn"),
    align: "start",
    key: "createdOn",
    value: "createdOn",
  },
  { title: i18n.global.t("Settings.Integrations.InstagramAPI.Settings.ReplyHeaders.Actions"), key: "actions", value: "actions" },
];

const removePromptTitle = i18n.global.t("Settings.Integrations.InstagramAPI.Settings.RemoveReplyPrompt");
const newItemTemplate = {
  id: 0,
  title: "",
  text: "",
  keywords: [],
  enabled: false,
  createdOn: "",
  createdBy: "",
};
const currentAutoReply = ref(newItemTemplate);
const isReplyDialogOpened = ref(false);

onMounted(async () => {
  try {
    autoReplies.value = await facebookAutoReplyService.getAll();
  } catch {
    store.dispatch("addError");
  } finally {
    loadingReplies.value = false;
  }
});

const deleteAutoReply = async (autoReply) => {
  try {
    await facebookAutoReplyService.deleteById(autoReply.id);
    autoReplies.value.splice(autoReplies.value.indexOf(autoReply), 1);
  } catch {
    store.dispatch("addError");
  }
};

const openReplyDialog = (reply) => {
  currentAutoReply.value = reply;
  isReplyDialogOpened.value = true;
};

const goBack = () => {
  router.go(-1);
};
</script>
