import CommentsVue from "./views/Comments.vue";
import Conversation from "./views/Conversation.vue";
import Inbox from "./views/Inbox.vue";
import InboxContainerVue from "./views/InboxContainer.vue";
import SalesFunnel from "./views/SalesFunnel.vue";

export const routes = [
  {
    path: "/inbox",
    component: InboxContainerVue,
    children: [
      {
        path: "",
        name: "inbox",
        component: Inbox,
        meta: { title: "Інстаграм Дірект | Weselli" },
      },
      {
        path: "comments",
        name: "inbox-comments",
        component: CommentsVue,
        meta: { title: "Інстаграм Коментарі | Weselli" },
      },
    ],
  },
  {
    path: "/inbox/:id",
    name: "inbox-thread",
    component: Conversation,
    meta: { title: "Інстаграм Діалог | Weselli" },
  },
  {
    path: "/funnel",
    name: "funnel",
    component: SalesFunnel,
    meta: { title: "Воронка Продаж | Weselli" },
  },
];
