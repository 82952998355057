<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path
      d="M5.3042 14.4355H22.5143M22.5143 14.4355C22.5143 14.4355 17.636 11.2272 16.6448 8.56592M22.5143 14.4355C22.5143 14.4355 18.2017 17.45 16.6448 20.305"
      stroke="#5A5858"
      stroke-width="2.17391"
      stroke-linecap="square"
    />
  </svg>
</template>
