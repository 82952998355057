<template>
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.30225 4.5004L9.08247 8.63004C10.2295 9.44955 11.7705 9.44955 12.9175 8.63004L18.6977 4.5004M1.88539 13.1513C1.37154 11.0819 1.37154 8.91815 1.88539 6.84875C2.55805 4.13984 4.70602 2.04534 7.43056 1.44162L7.88443 1.34105C9.9366 0.886317 12.0634 0.886318 14.1156 1.34105L14.5694 1.44162C17.294 2.04534 19.442 4.13984 20.1146 6.84876C20.6285 8.91815 20.6285 11.0819 20.1146 13.1512C19.442 15.8602 17.294 17.9547 14.5694 18.5584L14.1156 18.659C12.0634 19.1137 9.9366 19.1137 7.88443 18.659L7.43055 18.5584C4.70601 17.9547 2.55805 15.8602 1.88539 13.1513Z"
      stroke="#FFF"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
