import store from "@/store";
import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import mitt from "./mitt";

const WebSocketEvent = {
  NewMessage: 1,
  SentMessage: 2,
  NewComment: 3,
  RepliedComment: 4,
  CustomerReadMessage: 5,
};

function getApiNotificationService() {
  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.VUE_APP_BASE_URL}ws/notifications-hub`, { accessTokenFactory: () => store.getters.authToken })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  // const eventBus = new Vue();

  const initConnection = async () => {
    if (connection.state == HubConnectionState.Disconnected || connection.state == HubConnectionState.Disconnecting)
      return connection.start().then(() => {
        connection.on("ReceiveNotification", async ({ data, id }) => {
          const activeThreadId = store.getters.activeThread ? store.getters.activeThread.id : "";
          switch (id) {
            case WebSocketEvent.RepliedComment:
              await store.dispatch("refreshInboxComments");
              break;
            case WebSocketEvent.NewComment:
              await store.dispatch("refreshInboxComments");

              store.dispatch("addNotification", {
                text: "Один з ваших постів прокоментовано!",
                color: "primary",
                duration: 3500,
              });
              break;
            case WebSocketEvent.NewMessage:
              if (activeThreadId != data.slimThread.id) {
                store.dispatch("addMessage", data.slimThread);
              }

              if (!activeThreadId) {
                await store.dispatch("refreshInbox");
              }
              mitt.emit("new-message", { slimThread: data.slimThread, originalMessage: data.originalMessage });

              break;
            case WebSocketEvent.SentMessage:
              if (!activeThreadId) {
                await store.dispatch("refreshInbox");
              }

              mitt.emit("new-message", { slimThread: data.slimThread, originalMessage: data.originalMessage });
              break;

            case WebSocketEvent.CustomerReadMessage:
              await store.dispatch("customerReadLastMessage", data.threadId);
              mitt.emit("customer-read-message", data.threadId);
              break;
          }
        });
      });
  };

  const closeConnection = async () => {
    await connection.stop();

    mitt.off("logout");
    mitt.off("customer-read-message");
  };

  const connectToInstagramGroup = async () => {
    try {
      await connection.invoke("ConnectToInstagramGroup");
    } catch (error) {
      console.log("Can not connect to instagram group...");
    }
  };

  const subscribeForNewMessage = (callback) => {
    mitt.on("new-message", callback);
  };

  const unsubscribeForNewMessage = (callback) => {
    mitt.off("new-message", callback);
  };

  const subcribeForCustomerReads = (callback) => {
    mitt.on("customer-read-message", callback);
  };

  const unsubcribeForCustomerReads = (callback) => {
    mitt.off("customer-read-message", callback);
  };

  return {
    initConnection,
    closeConnection,
    connectToInstagramGroup,
    subscribeForNewMessage,
    unsubscribeForNewMessage,
    subcribeForCustomerReads,
    unsubcribeForCustomerReads,
  };
}

export const apiNotificationService = getApiNotificationService();
