<template>
  <nav>
    <v-toolbar short flat style="border-bottom: 1px solid lightgray">
      <v-toolbar-title class="text--uppercase grey--text">
        <span class="font-weight-light">Todo</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn variant="text" color="grey" @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer v-model="drawler" theme="dark" app permanent :mini-variant="!drawler" color="#33393e">
      <v-list-item class="px-2" style="color: rgba(255, 255, 255, 0.8)">
        <v-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-avatar>
        <v-list-item-title>{{ `${user.firstName} ${user.lastName}` }}</v-list-item-title>
      </v-list-item>

      <v-divider style="border-top: 1px solid rgba(255, 255, 255, 0.2)"></v-divider>

      <v-list density="compact" style="color: rgba(255, 255, 255, 0.8)">
        <v-list-item v-for="item in items" :key="item.title" link :to="{ name: item.route }">
          <template #prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>

          <div>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </div>
        </v-list-item>
      </v-list>

      <template #append>
        <v-list density="compact">
          <v-list-item link :to="{ name: 'settings' }">
            <template #prepend>
              <v-icon>custom:settingsIcon</v-icon>
            </template>
            <div>
              {{ $t("ManagerLayout.Settings") }}
            </div>
            <template #append>
              <v-icon>mdi-chevron-double-left</v-icon>
            </template>
          </v-list-item>
          <template #append v-if="!drawler">
            <v-icon @click="toggleDrawler">mdi-chevron-double-right</v-icon>
          </template>
        </v-list>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";

const store = useStore();

const drawler = ref(false);

const user = computed(() => store.getters.stateUser);

const items = [
  {
    icon: "mdi-account-group",
    title: i18n.global.t("Admin.Users.NavItemText"),
    route: "admin-user-list",
  },
];

onMounted(async () => {
  await nextTick();
  drawler.value = false;
});

const logout = async () => {
  await store.dispatch("logout");
};

const toggleDrawler = () => {
  drawler.value = !drawler.value;
};
</script>
