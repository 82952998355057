<template>
  <div v-if="comments">
    <template v-for="comment in comments" :key="comment.id">
      <v-list-item :title="comment.from.username" :subtitle="comment.text" class="media-comment">
        <template #prepend>
          <v-avatar>
            <v-img v-if="comment.from.profile_pic" :src="comment.from.profile_pic" />
            <v-img v-else src="../../../../assets/no-picture-account.jpg"></v-img>
          </v-avatar>
        </template>

        <div class="caption" style="color: #8e8e8e">
          <Tooltipped :text="moment.utc(comment.timestamp).local().format('LLLL')">
            <span class="mr-2">{{ moment.utc(comment.timestamp).local().fromNow(true) }}</span>
          </Tooltipped>
          <span class="mr-2">{{ $t("Inbox.Comments.Label.LikeCount", { count: comment.like_count }) }}</span>
          <v-btn x-small variant="text" @click="reply(comment)" color="#8e8e8e">{{ $t("Inbox.Comments.Button.Reply") }}</v-btn>
        </div>
      </v-list-item>

      <div v-if="comment.replies && comment.replies.data.length" class="ml-2">
        <v-btn x-small variant="text" color="#8e8e8e" @click="toggleCommentReplyShown(comment)">{{ getCommentRepliesText(comment) }}</v-btn>
        <instagram-media-comment-list
          :comments="comment.replies.data"
          @replying="reply"
          v-if="commentRepliesShown.indexOf(comment.id) !== -1"
          :class="{ 'comment-expanded': commentRepliesShown.indexOf(comment.id) >= 0 }"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
// TODO: comment time is not correct, showing in utc instead of local (/inbox/comments)
import moment from "moment";
import Tooltipped from "@/components/Tooltiped.vue";

import i18n from "@/plugins/i18n";
import { defineProps, defineEmits, reactive } from "vue";

defineProps(["comments"]);
const emits = defineEmits(["replying"]);
const commentRepliesShown = reactive([]);

const reply = (comment) => {
  emits("replying", comment);
};

const toggleCommentReplyShown = (comment) => {
  const index = commentRepliesShown.indexOf(comment.id);
  if (index !== -1) {
    commentRepliesShown.splice(index, 1);
  } else {
    commentRepliesShown.push(comment.id);
  }
};

const getCommentRepliesText = (comment) => {
  return commentRepliesShown.indexOf(comment.id) !== -1
    ? i18n.global.t("Inbox.Comments.Button.HideReplies")
    : i18n.global.t("Inbox.Comments.Button.ShowReplies", { count: comment.replies.data.length });
};
</script>

<style>
.media-comment .v-list-item__prepend {
  align-self: start;
}

.comment-expanded {
  border-left: 1px solid rgba(90, 88, 88, 0.3);
}
</style>
