<template>
  <div class="accounts-container flex-wrap" v-if="instagramAccount" data-test="facebook-linked-accounts">
    <v-skeleton-loader v-if="loading" type="list-item-avatar-two-line" min-width="250px"></v-skeleton-loader>

    <WAvatar :url="instagramAccount.pageProfileUrl" :title="instagramAccount.pageName" text="Facebook сторінка" />
    <WAvatar :url="instagramAccount.profilePictureUrl" :title="instagramAccount.username" text="Instagram сторінка" />
  </div>
</template>

<script setup>
import { onMounted, ref, defineEmits } from "vue";
import { inboxService } from "@/modules/inbox/services/inbox.service";
import WAvatar from "@/components/WAvatar.vue";

const emit = defineEmits(["account-loaded"]);

const instagramAccount = ref(null);
const loading = ref(true);

onMounted(async () => {
  try {
    instagramAccount.value = await inboxService.getInstagramAccount();
    emit("account-loaded", instagramAccount.value);
    loading.value = false;
  } catch {
    emit("account-loaded", null);
  }
});
</script>

<style scoped>
.accounts-container {
  display: flex;
  gap: 16px;
}
</style>
