<template>
  <div>
    <v-skeleton-loader v-bind="loaderAttributes" type="list-item-avatar-two-line"></v-skeleton-loader>
    <v-skeleton-loader v-bind="loaderAttributes" type="list-item-avatar-two-line"></v-skeleton-loader>
    <v-skeleton-loader v-bind="loaderAttributes" type="list-item-avatar-two-line"></v-skeleton-loader>
    <v-skeleton-loader v-bind="loaderAttributes" type="list-item-avatar-two-line"></v-skeleton-loader>
    <v-skeleton-loader v-bind="loaderAttributes" type="list-item-avatar-two-line"></v-skeleton-loader>
  </div>
</template>

<script setup>
const loaderAttributes = {
  class: "mb-6",
  boilerplate: true,
  elevation: 2,
};
</script>
