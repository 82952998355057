<template>
  <AuthLayout>
    <template v-if="!isChangingForm">
      <div class="d-flex flex-column justify-space-between gap40">
        <div class="d-flex flex-column gap40">
          <div class="d-flex flex-column gap16">
            <div class="form-header">{{ $t("Login.Label.ForgotPassHeader") }}</div>
            <div class="pb-4 op07 form-subheader">{{ $t("Login.Label.ForgotPassSubHeader") }}</div>
          </div>

          <div class="d-flex flex-column gap16">
            <v-form class="mt-8" ref="forgotForm" id="forgot-form">
              <label>E-mail</label>
              <v-text-field
                class="mt-1"
                validate-on-blur
                v-model="email"
                variant="outlined"
                :placeholder="$t('Login.Placeholder.Email')"
                :rules="[rules.required, rules.email]"
                density="compact"
              />
            </v-form>

            <div class="d-flex flex-column gap8">
              <v-btn rounded class="form-button" @click="sendLink" color="#2999FD" :loading="loading" :disabled="loading">{{
                $t("Login.Button.SendLink")
              }}</v-btn>

              <div class="not-registered">
                <span class="op07"> У вас немає акаунту? </span>
                <v-btn variant="text" color="#2999FD" :to="{ name: 'login' }" class="text-none"> {{ $t("Login.Button.ReturnToLogin") }} </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="terms">
          Натискаючи на кнопку вище, ви погоджуєтеся з умовами
          <a href="/">Оферти</a>
        </div>
      </div>
    </template>
    <ResetPassword v-else />
  </AuthLayout>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { accountService } from "../services/account.service";
import { emailRule, requiredRule } from "@/services/field.rules";
import i18n from "@/plugins/i18n";

import ResetPassword from "./ResetPassword.vue";
import AuthLayout from "@/components/layouts/AuthLayout.vue";

const store = useStore();
const route = useRoute();

const email = ref("");
const loading = ref(false);
const isChangingForm = ref(false);
const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  email: (value) => emailRule(value, i18n.global.t("Common.ErrorMessage.Email")),
};

onMounted(() => {
  isChangingForm.value = !!(route.query.token && route.query.id);
});

const forgotForm = ref(null);
const sendLink = async () => {
  const isFormValid = (await forgotForm.value.validate()).valid;
  if (!isFormValid) {
    return;
  }

  loading.value = true;
  try {
    await accountService.sendResetLink(email.value);

    store.dispatch("addNotification", {
      icon: "custom:messageIcon",
      text: i18n.global.t("Login.Label.SentAlert"),
      color: "primary",
      duration: 3500,
    });
  } catch {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
};
</script>
