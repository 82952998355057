import { api } from "@/services/api.service";
import { formatString } from "@/services/util";
import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import store from "@/store";

const endpoints = {
  SEARCH: "/api/orders/search",
  POST_CREATE_ORDER: "/api/orders",
  PATCH_UPDATE_ORDER: "/api/orders/{0}",
  GET_BY_ID: "/api/orders/{0}",
  REFRESH_DELIVERY_INFO: "/api/orders/{0}/refresh-delivery",
  CREATE_INTERNET_DOCUMENTS: "api/orders/internet-documents",
  NOVAPOSHTA_PRINT: "api/orders/novaposhta-print?type={0}",
  ARCHIVE_ORDERS: "api/orders/soft-delete",
  EXPORT_ORDERS: "api/orders/export",
  STATISTICS_ORDERS: "api/orders/statistics",
  CHART_DATA_ORDERS: "api/orders/chart-data",
};

function getOrderService() {
  const archiveOrders = async (selectedOrders) => {
    return (
      await api.post(
        endpoints.ARCHIVE_ORDERS,
        selectedOrders.map((x) => x.id)
      )
    ).data;
  };

  const print = async (orderIds, type) => {
    return (await api.post(formatString(endpoints.NOVAPOSHTA_PRINT, type), orderIds, { responseType: "blob" })).data;
  };
  const refreshDeliveryInfo = async (id) => {
    return (await api.post(formatString(endpoints.REFRESH_DELIVERY_INFO, id))).data;
  };
  const update = async (id, operations) => {
    return (await api.patch(formatString(endpoints.PATCH_UPDATE_ORDER, id), operations)).data;
  };
  const search = async (searchQuery) => {
    const query = searchQuery || { page: 1, pageSize: 10 };
    const orders = (await api.post(endpoints.SEARCH, query)).data;
    orders.results.forEach((o) => {
      try {
        o.sourceData = JSON.parse(o.sourceData);
      } catch (e) {
        //
      }
    });

    return orders;
  };
  const createOrder = async (order) => {
    const orderToCreate = JSON.parse(JSON.stringify(order)); // to eliminate side effects
    orderToCreate.deliveryInfo.receiver.phoneNumber = order.deliveryInfo.receiver.phoneNumber.replaceAll("-", "");
    return (await api.post(endpoints.POST_CREATE_ORDER, orderToCreate)).data;
  };

  const getOrderById = async (orderId) => {
    return (await api.get(formatString(endpoints.GET_BY_ID, orderId))).data;
  };

  const _connection = new HubConnectionBuilder()
    .withUrl(`${process.env.VUE_APP_BASE_URL}ws/orders-hub`, { accessTokenFactory: () => store.getters.authToken })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  const getSignalROrderConnection = async () => {
    if (_connection.state !== HubConnectionState.Disconnected && _connection.state !== HubConnectionState.Disconnecting) {
      return _connection;
    }

    await _connection.start();

    return _connection;
  };

  const createInternetDocuments = async (orderIds) => {
    return (await api.post(endpoints.CREATE_INTERNET_DOCUMENTS, orderIds)).data;
  };

  const exportOrders = async (dateFrom, dateTo) => {
    return (await api.post(endpoints.EXPORT_ORDERS, { dateFrom, dateTo }, { responseType: "blob" })).data;
  };

  const getOrderStatistics = async () => {
    return (await api.get(endpoints.STATISTICS_ORDERS)).data;
  };

  const getOrderChartData = async (from, to, type) => {
    return (await api.post(endpoints.CHART_DATA_ORDERS, { from, to, type })).data;
  };

  return {
    createInternetDocuments,
    getSignalROrderConnection,
    archiveOrders,
    print,
    refreshDeliveryInfo,
    update,
    search,
    createOrder,
    getOrderById,
    exportOrders,
    getOrderStatistics,
    getOrderChartData,
  };
}

export const orderService = getOrderService();
