<template>
  <Tooltiped :text="tooltipText">
    <v-menu v-model="menu" :close-on-content-click="false" close-on-click close-delay="300">
      <template #activator="{ props }">
        <v-icon v-bind="props" color="#363636" :disabled="disabled || !messageTemplates.length" size="24" class="op07"> custom:savedIcon </v-icon>
      </template>
      <v-card class="d-flex align-start">
        <v-list max-height="200px" style="overflow-y: auto" nav density="compact">
          <v-list-item
            :modelValue="selectedTemplateIndex"
            v-for="template in messageTemplates"
            @mouseover="previewTemplate(template)"
            :key="template.id"
            link
            @click="selectTemplate(template)"
          >
            <v-list-item-title>{{ template.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-card
          width="350px"
          elevation="0"
          style="align-self: center; display: flex; align-content: center; justify-content: center"
          @click="selectTemplate(selectedTemplate)"
        >
          <TextMessage class="ma-2" :message="{ message: selectedTemplate.text }" :sent="true" />
        </v-card>
      </v-card>
    </v-menu>
  </Tooltiped>
</template>

<script setup>
import Tooltiped from "@/components/Tooltiped.vue";
import { messageTemplateService } from "../services/message-template.service";
import { ReplyTo } from "../models/comment";
import TextMessage from "../components/TextMessage.vue";
import { onMounted, ref, defineProps, defineEmits } from "vue";
import i18n from "@/plugins/i18n";

const props = defineProps(["disabled", "target"]);
const emits = defineEmits(["template-selected"]);

const menu = ref(false);
const messageTemplates = ref([]);
const selectedTemplate = ref({ id: "", title: "", text: "", target: ReplyTo.Direct });
const selectedTemplateIndex = ref(0);

const tooltipText = ref("");

onMounted(async () => {
  messageTemplates.value = await messageTemplateService.getMessageTemplates([{ field: "Target", operation: "equals", value: props.target }]);
  selectedTemplate.value = messageTemplates.value[0] || { id: "", title: "", text: "" };
  tooltipText.value = i18n.global.t(messageTemplates.value.length ? "Inbox.Label.SavedTemplates" : "Inbox.Label.NoSavedTemplates").toString();
});

const previewTemplate = (template) => {
  selectedTemplate.value = template;
};

const selectTemplate = (template) => {
  menu.value = false;
  emits("template-selected", template);
};
</script>
