<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.66675 15.9998H23.3334M23.3334 15.9998L17.2158 10.6665M23.3334 15.9998L17.2158 21.3332"
      stroke="#2999FD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
