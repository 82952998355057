import { formatString } from "@/services/util";
import { api } from "../../../services/api.service";

const endpoints = {
  GET_MEMBERS: "/api/team/members",
  REMOVE_MEMBER: "/api/team/members/{0}",
  INVITE: "/api/team/invites",
  CANCEL_INVITE: "/api/team/invites/{0}",
  GET_ROLES: "/api/users/roles",
  GET_REGISTRATION_STATISTICS: "/api/users/statistics/registration",
  GET_INVITE: "/api/team/invites/{0}/tenant/{1}",
  ACCEPT_INVITE: "/api/team/invites/{0}/tenant/{1}",
};
function getTeamService() {
  const getMembers = async () => {
    const paginated = (await api.get(endpoints.GET_MEMBERS)).data;
    return paginated;
  };

  const inviteUser = async (email, roleId) => {
    const invitation = (await api.post(endpoints.INVITE, { email, roleId })).data;
    return invitation;
  };

  const cancelInvitation = async (invitationId) => {
    return await api.delete(formatString(endpoints.CANCEL_INVITE, invitationId));
  };

  const removeMember = async (userId) => {
    return await api.delete(formatString(endpoints.REMOVE_MEMBER, userId));
  };

  const getInvite = async (inviteId, tenantId) => {
    return (await api.get(formatString(endpoints.GET_INVITE, inviteId, tenantId))).data;
  };

  const acceptInvite = async (inviteId, tenantId, data) => {
    return (await api.post(formatString(endpoints.ACCEPT_INVITE, inviteId, tenantId), data)).data;
  };

  return {
    getMembers,
    inviteUser,
    cancelInvitation,
    removeMember,
    getInvite,
    acceptInvite,
  };
}

export const teamService = getTeamService();
