import i18n from "@/plugins/i18n";

export const requiredRule = (value) => {
  if (typeof value === "number") return true;
  if (typeof value === "boolean") return value || i18n.global.t("Common.ErrorMessage.Required");
  if (value instanceof File) return true;

  return !!(!!value && (value.length || value.id)) || i18n.global.t("Common.ErrorMessage.Required");
};

export const emailRule = (value) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value?.trim()) || i18n.global.t("Common.ErrorMessage.Email");
};

export const phoneNumber = (value, errorMessage) => {
  const pattern = /^\+?3?8?0?((\d{9})|(\d{2}-\d{3}-\d{2}-\d{2}))$/;

  return pattern.test(value) || errorMessage;
};

export const greaterOrEqualThanRule = (value, num, errorMessage) => {
  if (typeof value !== "number") value = Number(value);

  return value >= num || errorMessage;
};

export const between = (value, min, max, errorMessage) => {
  if (typeof min !== "number") min = Number(min);
  if (typeof max !== "number") max = Number(max);

  return (value >= min && value <= max) || errorMessage;
};

export const integerNumberFieldMask = (value) => {
  const numbers = value.replace(/([^0-9]|-)/g, "");
  return [numbers];
};

export const maxLength = (value, maxLength) => {
  return !value || value?.length <= maxLength || `Забагато символів (макс: ${maxLength})`;
};
