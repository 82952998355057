<template>
  <div class="column-item">
    <v-list-item
      link
      :to="{ name: 'inbox-thread', params: { id: props.thread.id } }"
      class="thread-item"
      :title="thread.customer.username"
      :prepend-avatar="profilePicture"
      height="64"
      :class="{ unread: thread.lastMessage.unseenInRow > 0 }"
      style="border-radius: 16px; width: 100%"
    >
      <template #subtitle>
        <v-icon v-if="thread.instagramId == thread.lastMessage.accountId && thread.lastMessage.unseenInRow === 0 && thread.lastMessage.readByCustomer" small>
          custom:readIcon
        </v-icon>
        <v-icon
          v-else-if="thread.instagramId == thread.lastMessage.accountId && thread.lastMessage.unseenInRow === 0 && !thread.lastMessage.readByCustomer"
          small
          >custom:deliveredIcon
        </v-icon>

        <p>{{ thread.lastMessage.text }}</p>
      </template>

      <template #append>
        <span class="time"> {{ formatedTimestamp }}</span>
      </template>
    </v-list-item>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import moment from "moment";

const props = defineProps(["thread"]);

const profilePicture = computed(() => props.thread.customer.profilePicture || require("../../../../assets/no-picture-account.jpg"));
const formatedTimestamp = computed(() =>
  moment.utc(props.thread.lastMessage.timestamp).local().isSame(moment(), "d")
    ? moment.utc(props.thread.lastMessage.timestamp).local().format("LT")
    : moment.utc(props.thread.lastMessage.timestamp).local().isSame(moment(), "year")
    ? moment.utc(props.thread.lastMessage.timestamp).local().format("DD.MM[\n]HH:mm")
    : moment.utc(props.thread.lastMessage.timestamp).local().format("DD.MM.YY[\n]HH:mm")
);
</script>

<style>
.column-item {
  border-radius: 16px;
  border: 1px solid var(--Divider, rgba(90, 88, 88, 0.1));
  background: var(--White, #fff);

  /* Drop Shadow */
  box-shadow: 0px 4px 20px 0px rgba(90, 88, 88, 0.05);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.column-item .v-list-item__prepend > .v-avatar {
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

.column-item svg {
  min-width: 18px;
}
</style>
