<template>
  <div class="content-container">
    <v-container id="content1">
      <div class="d-flex flex-column">
        <h1><span>Надійна CRM</span> для Вашого<br />insta-магазину</h1>
        <p class="mt-12 mb-8 main-content-subtitle">Готове рішення для малого та середнього бізнесу</p>

        <div class="d-flex flex-column gap24 main-content">
          <v-btn variant="flat" color="primary" :to="{ name: 'login' }" rounded class="content-btn"> Спробувати безкоштовно </v-btn>

          <div class="d-flex flex-column gap8">
            <span><v-icon>custom:checkIcon</v-icon>Дірект</span>
            <span><v-icon>custom:checkIcon</v-icon>Воронка продажу</span>
            <span><v-icon>custom:checkIcon</v-icon>Автоматизація бізнес-процесів</span>
            <span><v-icon>custom:checkIcon</v-icon>Швидке створення ТТН</span>
            <span><v-icon>custom:checkIcon</v-icon>Задачі</span>
          </div>
          <span> — все в одній СRM системі.</span>
        </div>
      </div>
    </v-container>
    <div class="cropped">
      <img :src="require('../../../assets/landing/new/main-img.png')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#content1 {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  min-height: 100vh;
  background-image: url("../../../assets/landing/new/bg-elements.png");
}

.content-btn {
  width: 320px;
  height: 48px;
}

.main-content-subtitle {
  color: var(--main-secondary, #5a5858);
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
}

.main-content {
  min-width: max-content;
}

.cropped {
  position: absolute;
  top: 164px;
  right: 0;
  max-width: 45%;
  overflow: hidden;
}

.cropped img {
  width: 880px;
  height: 588px;
}

@media (max-width: 640px) {
  .content-btn {
    width: 100%;
  }
}

@media (max-width: 840px) {
  #content1 {
    justify-content: start;
    min-height: 100%;
  }
  .cropped {
    position: initial;
    max-width: initial;
  }

  .cropped img {
    object-fit: fill;
    height: 216px;
    width: auto;
  }

  .content-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
</style>
