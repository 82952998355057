<template>
  <div class="d-flex flex-column">
    <p class="h2 mb-8">Інтеграція з Facebook та Instagram</p>
    <p class="subtitle op07">
      Наша система дозволяє вам звʼязати ваш обліковий запис Інстаграм й автоматично отримувати повідомлення та коментарі. Це дозволяє легко відстежувати всі
      ваші взаємодії в Інстаграмі та відповідати на них з одного місця.
    </p>

    <ol class="ml-4 my-4">
      <li>Авторизуйтеся в акаунті Facebook</li>
      <li>Підʼєднайте бізнес-сторінку, яка звʼязана з Iнстаграмом.</li>
    </ol>

    <v-alert border="start" type="warning" color="rgba(200, 200, 200, 0.1)" border-color="amber-darken-3">
      <template #prepend>
        <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
          <v-icon class="warning" color="amber-darken-3">custom:warningIcon</v-icon>
        </v-btn>
      </template>
      <div class="d-flex flex-column gap8">
        <h4 class="subtitle2" style="color: #5a5858">Обов’язково!</h4>
        <span
          class="body1 op07"
          style="color: #5a5858"
          v-html="
            'В налаштуваннях Інстаграму дайте доступ до повідомлень. Для цього перейдіть у <b>Налаштування</b>, далі знайдіть <b>Конфіденційність, Повідомлення – Дозволити доступ до повідомлень</b>.'
          "
        />
      </div>
    </v-alert>

    <div class="mt-4">
      <InstagramAccount v-if="(!accountLoaded && instagramConnected === null) || instagramConnected" @account-loaded="onAccountLoaded" />
      <template v-else>
        <div class="d-flex flex-column gap16">
          <p>
            Підключаючи Фейсбук акаунт ви підтверджуєте ознайомлення з
            <router-link :to="{ name: 'privacy-policy' }" target="_blank">Політикою Конфіденційності</router-link>
          </p>

          <div>
            <FacebookLoginButton @connected="instagramConnected = true" />
          </div>
        </div>
      </template>
    </div>
    <div class="buttons">
      <v-btn @click="$emit('cancel')" variant="outlined" color="var(--main-secondary-color)" rounded>Повернутись</v-btn>
      <v-btn @click="$emit('proceed')" :disabled="!instagramConnected" color="primary" rounded data-test="stepper-next">Продовжити</v-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import FacebookLoginButton from "../integrations/Instagram/FacebookLoginButton.vue";
import InstagramAccount from "../integrations/Instagram/InstagramAccountInfo.vue";

defineEmits(["proceed", "cancel"]);

const instagramConnected = ref(null);
const accountLoaded = ref(false);

const onAccountLoaded = (account) => {
  accountLoaded.value = true;
  instagramConnected.value = !!account;
};
</script>
