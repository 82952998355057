<template>
  <v-badge :modelValue="reaction" bordered bottom color="error" icon="mdi-cards-heart" offset-x="16">
    <div class="text-message message-container">
      <div class="d-flex align-end gap8">
        <div v-if="message.is_unsupported">
          <span>{{ unsupportedMessageText }}</span>
        </div>
        <div v-else v-html="formatedText" style="overflow-wrap: anywhere"></div>
        <div class="d-flex message-time">
          {{ messageTime }}
        </div>

        <v-icon v-if="sent && last && seenByCustomer" size="x-small">custom:readIcon</v-icon>
        <v-icon v-if="sent && last && !seenByCustomer" size="x-small">custom:deliveredIcon</v-icon>
      </div>
    </div>
  </v-badge>
</template>

<script setup>
import { defineProps, computed } from "vue";
import i18n from "@/plugins/i18n";
import moment from "moment";
import linkifyHtml from "linkify-html";

const props = defineProps({
  message: {
    required: true,
  },
  sent: {
    type: Boolean,
    default: false,
  },
  last: {
    type: Boolean,
    default: false,
  },
  seenByCustomer: {
    type: Boolean,
    default: false,
  },
});

const unsupportedMessageText = i18n.global.t("Inbox.Label.MessageIsUnsupported");

const reaction = computed(() => {
  return !!props.message.reactions?.data;
});

const messageTime = computed(() => {
  return moment.utc(props.message.created_time).local().format("HH:mm");
});

const formatedText = computed(() => {
  return linkifyHtml(props.message.message, { target: "_blank" });
});
</script>

<style>
.text-message.message-container svg {
  min-width: 28px;
}
.text-message.message-container path {
  stroke: white;
}
</style>
