<template>
  <v-autocomplete
    :menu-props="{ closeOnContentClick: true }"
    :label="placeholder || $t('Orders.NewOrder.Button.AddGood')"
    @update:search="onSearch"
    :search="searchText"
    :loading="goodsLoading"
    :items="goods"
    item-title="title"
    return-object
    variant="outlined"
    density="compact"
    hide-no-data
    :error-messages="errored ? 'Хоча б один товар потрібно додати' : ''"
    :error="errored"
  >
    <template v-slot:selection="data">
      <v-chip close v-bind="data.attrs" :input-value="data.selected" @click:close="remove(item)">
        <v-avatar left>
          <v-img v-if="data.item.pictureUrl" :src="data.item.pictureUrl" />
          <v-img v-else src="../../../assets/good_no_picture.png" />
        </v-avatar>
        {{ data.item.title }} ({{ data.item.inventoryNumber }})
      </v-chip>
    </template>

    <template v-slot:[`item`]="{ item }">
      <v-list-item link @click="selectGood(item.raw)">
        <template #prepend>
          <v-avatar>
            <v-img v-if="item.raw.pictureUrl" :src="item.raw.pictureUrl" />
            <v-img v-else src="../../../assets/good_no_picture.png" />
          </v-avatar>
        </template>

        <v-list-item-title>{{ item.title }} ({{ item.raw.inventoryNumber }})</v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script setup>
import { useStore } from "vuex";
import { defineProps, defineEmits, onMounted, ref } from "vue";
import { goodsService } from "../services/goods.service";

const emits = defineEmits(["good-selected", "no-goods"]);
const props = defineProps(["errored", "defaultFilters", "placeholder"]);
const store = useStore();

const goodsLoading = ref(false);
const pageSize = 5;
const searchText = ref("");
const goods = ref([]);

onMounted(async () => {
  await loadGoods();

  if (goods.value.length === 0) emits("no-goods");
});

const loadGoods = async () => {
  goodsLoading.value = true;
  try {
    let filters = props.defaultFilters || [];
    if (searchText.value) {
      filters = [...filters, { field: "title", operation: "contains", value: searchText.value }];
    }
    goods.value = (await goodsService.search({ page: 1, pageSize: pageSize, filters: filters })).results;
  } catch {
    store.dispatch("addError");
  } finally {
    goodsLoading.value = false;
  }
};

const selectGood = (item) => {
  if (!item) return;

  searchText.value = "";
  emits("good-selected", item);
};

var searchTimeout = -1;
const onSearch = async (search) => {
  searchText.value = search;
  if (goodsLoading.value || !searchText.value) return;
  if (searchTimeout !== -1) clearTimeout(searchTimeout);

  searchTimeout = setTimeout(async () => {
    try {
      await loadGoods();
    } finally {
      clearTimeout(searchTimeout);
    }
  }, 1000);
};
</script>
