import { api } from "../../../services/api.service";

const endpoints = {
  SEARCH: "/api/users/managers",
  CREATE: "/api/users",
  GET_ROLES: "/api/users/roles",
  GET_REGISTRATION_STATISTICS: "/api/users/statistics/registration",
};
function getUserService() {
  const searchUsers = async (searchQuery) => {
    const paginated = (await api.post(endpoints.SEARCH, searchQuery)).data;
    return paginated;
  };

  const createUser = async (userToCreate) => {
    const createdUser = (await api.post(endpoints.CREATE, userToCreate)).data;
    return createdUser;
  };

  const getRoles = async () => {
    return (await api.get(endpoints.GET_ROLES)).data;
  };

  const getRegistrationStatistic = async (from, to) => {
    return (await api.post(endpoints.GET_REGISTRATION_STATISTICS, { from, to })).data;
  };

  return {
    searchUsers,
    createUser,
    getRoles,
    getRegistrationStatistic,
  };
}

export const userService = getUserService();
