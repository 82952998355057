import i18n from "@/plugins/i18n";

export const getPersonAddressInfo = () => ({
  city: {
    loading: false,
    items: [],
    search: "",
  },
  address: {
    loading: false,
    items: [],
    search: "",
  },
});

export const deliveryInfo = {
  payerTypes: [
    {
      text: i18n.global.t("Orders.NewOrder.Delivery.NovaPoshta.Label.Sender"),
      value: "Sender",
    },
    {
      text: i18n.global.t("Orders.NewOrder.Delivery.NovaPoshta.Label.Receiver"),
      value: "Recipient",
    },
  ],
  paymentMethods: [
    {
      text: i18n.global.t("Orders.NewOrder.Delivery.NovaPoshta.PaymentMethod.Cash"),
      value: "Cash",
    },
    {
      text: i18n.global.t("Orders.NewOrder.Delivery.NovaPoshta.PaymentMethod.NonCash"),
      value: "NonCash",
    },
  ],
  remittanceReceiveForm: [
    {
      text: i18n.global.t("Orders.NewOrder.Delivery.NovaPoshta.RemittanceReceiveForm.Warehouse"),
      value: "Money",
    },
    {
      text: i18n.global.t("Orders.NewOrder.Delivery.NovaPoshta.RemittanceReceiveForm.Card"),
      value: "Card",
    },
  ],
};
