<template>
  <transition-group name="notification-list" tag="div" class="d-flex flex-column top-right gap16">
    <v-alert v-for="(alert, i) in alerts" v-bind:key="i" :icon="alert.icon" closable border="start" :border-color="alert.color">
      <div v-html="alert.text"></div>
    </v-alert>
  </transition-group>

  <v-snackbar v-model="showCopied" timeout="1000" color="success" class="copy-snackbar">
    <div class="d-flex align-center justify-center">Скопійовано!</div>
  </v-snackbar>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import mitt from "@/services/mitt";

const store = useStore();

const showCopied = ref(false);
const alerts = computed(() => store.getters.notifications);
onMounted(() => {
  mitt.on("copied", showSnackbar);
});

onBeforeUnmount(() => {
  mitt.off("copied", showSnackbar);
});

const showSnackbar = () => {
  showCopied.value = true;
};
</script>

<style lang="scss" scoped>
$margin: 64px;

.top-right {
  bottom: $margin;
  right: 16px;
  left: auto;
  width: 300px;
  position: fixed;
  opacity: 0.95;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
}
.notification-list-enter,
.notification-list-leave-active {
  opacity: 0;
  transform: translateX(-90px);
}
.notification-list-leave-active {
  position: absolute;
}

@media (max-width: 900px) {
  .top-right {
    bottom: initial;
    right: initial;
    left: 50%;
    top: 16px;
    transform: translateX(-50%);
    width: 300px;
    position: fixed;
    opacity: 0.95;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
  }

  .copy-snackbar {
    bottom: 64px;
  }
}
</style>
