<template>
  <div ref="fullview" class="d-flex align-center justify-center image-viewer">
    <img
      class="image-container"
      :src="src"
      @click.stop="openImage"
      :height="height"
      style="object-fit: cover"
      :style="{ 'border-radius': rounded ? '16px' : 'none', width: width }"
    />
    <div v-show="expanded" class="expandable-image expanded">
      <div class="d-flex gap8 expandable-image-container">
        <div :style="`transform: rotate(${rotation}deg);`">
          <img :src="src" style="min-height: 275px" />
        </div>
        <div class="d-flex buttons1">
          <div class="d-flex flex-column gap8 panel">
            <v-btn icon @click="closeImage">
              <v-icon color="#5A5858">custom:closeIcon</v-icon>
            </v-btn>
          </div>

          <div class="d-flex gap8 rotate-container">
            <v-btn icon @click.stop="rotateRight">
              <v-icon color="#5A5858">custom:rotateRightIcon</v-icon>
            </v-btn>
            <v-btn icon @click.stop="rotateLeft">
              <v-icon color="#5A5858">custom:rotateLeftIcon</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, defineProps, nextTick } from "vue";
defineProps({
  src: {
    type: String,
    required: true,
  },
  height: {},
  width: {
    default: "inherit",
    type: String,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
});
const rotation = ref(0);
const expanded = ref(false);
const fullview = ref(null);

onMounted(async () => {
  document.addEventListener("click", handleClickOutside);
  document.addEventListener("keydown", onEscPressed);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
  document.removeEventListener("keydown", onEscPressed);
});

const handleClickOutside = (event) => {
  closeImage(event);
};
const openImage = () => {
  expanded.value = true;
};
const closeImage = (event) => {
  expanded.value = false;
  event.stopPropagation();
};

const onEscPressed = (e) => {
  if (e.key == "Escape") {
    expanded.value = false;
  }
};

const rotateRight = () => {
  rotation.value += 90;
};
const rotateLeft = () => {
  rotation.value -= 90;
};
</script>

<style scoped>
.image-container:hover {
  cursor: pointer;
  filter: brightness(50%);
}

image-container img {
  max-width: 100%;
}

.expandable-image.expanded {
  position: fixed;
  z-index: 999999;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  backdrop-filter: blur(10px) brightness(0.9);
  cursor: default;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px;
}

.expandable-image.expanded img {
  width: 100%;
  max-width: 1200px;
  max-height: calc(100vh - 100px);
  object-fit: contain;
  margin: 0 auto;
  border-radius: 12px;
}

.panel {
  position: relative;
}
.rotate-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  flex-direction: column;
}

.buttons1 {
  flex-direction: column;
  align-self: flex-start;
}

@media (max-width: 600px) {
  .expandable-image-container {
    flex-direction: column-reverse;
  }

  .buttons1 {
    flex-direction: row-reverse;
    align-self: flex-end;
  }

  .rotate-container {
    top: initial;
    transform: translateX(-50%);
    left: 50%;
    position: absolute;

    flex-direction: row;
  }

  .expandable-image-container {
    display: initial;
  }
}
</style>
