import { api } from "@/services/api.service";
import { formatString } from "@/services/util";

const endpoints = {
  SEARCH: "/api/invoices/search",
  GET_CHECKOUT: "/api/invoices/{0}/checkout",
};

function getInvoiceService() {
  const search = async (searchQuery) => {
    const query = searchQuery || { page: 1, pageSize: 10 };
    const invoices = (await api.post(endpoints.SEARCH, query)).data;

    return invoices;
  };
  const getCheckout = async (invoiceId) => {
    return (await api.post(formatString(endpoints.GET_CHECKOUT, invoiceId))).data;
  };

  return {
    search,
    getCheckout,
  };
}

export const invoiceService = getInvoiceService();
