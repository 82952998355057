<template>
  <div>
    <v-overlay :modelValue="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-card>

    <div v-else-if="order" class="d-flex flex-column gap24 pa-8">
      <h1 class="h1 d-flex gap16 align-center">
        <Tooltiped :text="$t('Common.Label.Back')">
          <v-btn icon style="font-size: 24px" variant="text" @click="goBack">
            <v-icon large>custom:arrowLeftIcon</v-icon>
          </v-btn>
        </Tooltiped>
        <div>
          {{ $t("Orders.Label.OrderDetails") }} <span>#{{ order.orderNumber }} ({{ order.orderStatus.title }})</span>
        </div>
      </h1>

      <v-card class="pa-6 d-flex flex-column gap16">
        <div class="title d-flex justify-space-between">
          <span> Деталі замовлення </span>

          <v-btn style="border-radius: 16px" size="40" icon color="primary" @click="goToOrderEdit">
            <v-icon size="24" class="white-icon"> custom:editIcon </v-icon></v-btn
          >
        </div>
        <!-- <v-card-title>
          <v-row v-if="order.note">
            <v-col>
              <p>
                <span>{{ $t("Common.Label.Note") }} : </span> {{ order.note }}
              </p>
            </v-col>
          </v-row>
        </v-card-title> -->
        <div class="d-flex flex-column gap16">
          <h5 class="subtitle2 op05">Товари</h5>

          <GoodsTable :config="{ headers: goodsTableHeaders }" :items="order.orderLineItems" />

          <h3 class="text-right title mt-3" v-html="$t('Orders.Label.OrderTotal', { total: orderTotal.toFixed(2) })"></h3>

          <div class="d-flex flex-column gap16">
            <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Header") }}</h5>

            <v-data-table class="body1" :headers="novaPoshtaTableHeaders" :items="[order.deliveryInfo]" hide-default-footer disable-sort disable-filtering>
              <template v-slot:[`item.id`]="{}">
                <NovaPoshtaToolbarActions
                  icon
                  :selected="[order]"
                  @data-updated="loadOrderDetails"
                  :selector="(o) => o.deliveryInfo.deliveryNumber"
                  @change-overlay="changeOverlay"
                />
              </template>
              <template v-slot:[`item.remittance.total`]="{ item }">
                {{ item.remittance.active ? item.remittance.total : "-" }}
              </template>
              <template v-slot:[`item.createdOn`]="{ item }">
                {{ moment.utc(item.createdOn).local().format("LLLL") }}
              </template>

              <template v-slot:[`item.statusTitle`]="{ item }">
                <Tooltiped :text="item.statusTitle" v-if="order.deliveryInfo.deliveryNumber">
                  <v-chip link style="max-width: 200px" :color="novaPoshtaStatusColors[item.statusId]">
                    <span
                      :class="{ 'font-weight-bold': item.statusId == 7 || item.statusId == 8 }"
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                    >
                      {{ item.statusTitle }}
                    </span>
                  </v-chip>
                </Tooltiped>
              </template>
            </v-data-table>

            <v-row>
              <v-col class="d-flex flex-column gap16">
                <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.SenderInfo") }}</h5>

                <v-card class="pa-4 body1">
                  <p>{{ order.deliveryInfo.sender.fullName }} {{ order.deliveryInfo.sender.phoneNumber }}</p>
                  <p>{{ order.deliveryInfo.sender.city.title }}</p>
                  <p>
                    {{ order.deliveryInfo.sender.address.title }}
                  </p>
                </v-card>
              </v-col>
              <v-col class="d-flex flex-column gap16">
                <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.ReceiverInfo") }}</h5>

                <v-card class="pa-4 body1">
                  <p>{{ order.deliveryInfo.receiver.fullName }} {{ order.deliveryInfo.receiver.phoneNumber }}</p>
                  <p>{{ order.deliveryInfo.receiver.city.title }}</p>
                  <p>
                    {{ order.deliveryInfo.receiver.address.title }}
                  </p>
                </v-card>
              </v-col>
              <v-col class="d-flex flex-column gap16">
                <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryInfo") }}</h5>
                <v-card class="pa-4 body1">
                  <p>
                    <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Description") }}: </span> {{ order.deliveryInfo.description }}
                  </p>
                  <p>
                    <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.AnnouncementPrice") }}: </span> {{ order.deliveryInfo.cost }}
                  </p>
                  <p>
                    <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Payer") }}: </span>
                    {{ order.deliveryInfo.payerType == senderPayer ? order.deliveryInfo.sender.fullName : order.deliveryInfo.receiver.fullName }}
                  </p>
                  <p>
                    <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryPayForm") }}: </span>
                    {{ paymentMethod[order.deliveryInfo.paymentMethod] }}
                  </p>
                </v-card>
              </v-col>
            </v-row>
            <div v-if="order.deliveryInfo.remittance.active" class="d-flex flex-column gap16">
              <h5 class="subtitle2 op05">{{ $t("Orders.Delivery.Label.AdditionalServices") }}</h5>
              <v-card class="pa-4 body1">
                <v-row>
                  <v-col>
                    <p>
                      <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Remittance") }}: </span>
                      {{ order.deliveryInfo.remittance.total }}грн
                      <span class="text-lowercase">{{
                        deliveryInfo.remittanceReceiveForm.find((x) => x.value == order.deliveryInfo.remittance.recieveForm).text
                      }}</span>
                      . {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.RemittanceDeliveryPayer") }}
                      {{ deliveryInfo.payerTypes.find((x) => x.value == order.deliveryInfo.remittance.payer).text }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script setup>
import { goodsTableHeaders, novaPoshtaTableHeaders, novaPoshtaStatusColors } from "./order.configs";
import { PayerType } from "../models/create-order";
import { deliveryInfo } from "../models/novaposhta";
import { orderService } from "../services/order.service";
import moment from "moment-timezone";
import NovaPoshtaToolbarActions from "../components/NovaPoshtaToolbarActions.vue";
import { useRoute, useRouter } from "vue-router";
import GoodsTable from "@/modules/warehouse/components/GoodsTable.vue";
import { computed, onMounted, reactive, ref } from "vue";
import Tooltiped from "@/components/Tooltiped.vue";
import i18n from "@/plugins/i18n";

const route = useRoute();
const router = useRouter();

const order = ref(null);
const loading = ref(true);
const error = ref(false);

const paymentMethod = reactive({});
const senderPayer = PayerType.Sender;

const orderTotal = computed(() => {
  if (order.value.orderLineItems.length == 0) return 0;

  return order.value.orderLineItems.map((order) => order.unitPrice * order.quantity).reduce((prev, curr) => prev + curr);
});

onMounted(async () => {
  deliveryInfo.paymentMethods.forEach((x) => {
    paymentMethod[x.value] = x.text;
  });

  await loadOrderDetails();
});

const loadOrderDetails = async () => {
  try {
    order.value = await orderService.getOrderById(route.params.id);
  } catch (requestError) {
    if (requestError.response.status === 404) router.push({ name: "orders" });
  } finally {
    loading.value = false;
  }
};

const goToOrderEdit = () => {
  if (order.value?.id) router.push({ name: "order-edit", params: { id: order.value.id } });
};

const goBack = () => {
  router.go(-1);
};

const overlay = ref(false);
const changeOverlay = (value) => {
  overlay.value = value;
};
</script>
