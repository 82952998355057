<template>
  <div class="d-flex align-center drop-zone" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop="handleDrop">
    <div v-if="!modelValue || modelValue.length === 0" class="d-flex flex-column gap8 placeholder">
      <span class="subtitle2"> Оберіть файл або перетягніть його сюди </span>
      <v-icon color="#2999FD">custom:uploadIcon</v-icon>
      <input
        type="file"
        ref="fileInput"
        style="position: absolute; width: 100%; height: 100%; top: 0; opacity: 0"
        @change="handleFileInput"
        :multiple="multiple"
        :accept="formats"
      />
    </div>
    <template v-if="modelValue">
      <div class="preview" v-if="!multiple" style="width: 100%; height: 100%">
        <img :src="preview" class="single-preview" />
        <div class="close-icon">
          <v-btn icon variant="text" @click="removeFile(index)" color="var(--additional-fail-color)">
            <v-icon>custom:closeIcon</v-icon>
          </v-btn>
        </div>
      </div>

      <div v-else class="d-flex gap24" style="flex-wrap: wrap">
        <div v-for="(file, index) in modelValue" :key="index" class="file-item">
          <div class="preview">
            <div>
              <v-img :src="file.preview" alt="Preview" class="file-preview" />
            </div>

            <div class="close-icon">
              <v-btn icon variant="text" @click="removeFile(index)" color="var(--additional-fail-color)">
                <v-icon>custom:closeIcon</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {},
  multiple: {
    type: Boolean,
    default: false,
  },
  formats: {
    type: String,
  },
});
const emits = defineEmits(["update:modelValue"]);

const preview = ref("");
// Function to handle file drop
const handleDrop = (event) => {
  event.preventDefault();
  const files = event.dataTransfer.files;
  uploadFiles(files);
};

// Function to handle file selection from input (fallback for non-drag-and-drop upload)
const handleFileInput = (event) => {
  const files = event.target.files;
  uploadFiles(files);
};

// Function to handle file upload and update the uploadedFiles array
const uploadFiles = (files) => {
  if (props.multiple) {
    emits("update:modelValue", [...files]);
  } else {
    emits("update:modelValue", files[0]);
  }
};

const removeFile = (index) => {
  if (props.multiple) {
    emits("update:modelValue", [...props.modelValue.filter((el, i) => i !== index)]);
  } else {
    emits("update:modelValue", null);
  }
};

watch(
  () => props.modelValue,
  () => {
    if (!props.modelValue) return;

    const reader = new FileReader();
    if (!props.multiple) {
      try {
        reader.onload = () => {
          preview.value = reader.result;
        };
        reader.readAsDataURL(props.modelValue);
      } catch (err) {
        console.log(err);
      }
    } else {
      for (let i = 0; i < props.modelValue.length; i++) {
        const file = props.modelValue[i];

        try {
          reader.onload = () => {
            file.preview = reader.result;
          };
          reader.readAsDataURL(file);
        } catch {
          //
        }
      }
    }
  }
);
</script>

<style scoped>
.drop-zone {
  position: relative;
  height: 100%;
  max-height: 296px;
  border-radius: 8px;
  border: 3px dashed var(--main-primary, #2999fd);
  background: var(--light-blue, #f4f6fa);

  padding: 20px;
  cursor: pointer;
  justify-content: center;
}

input[type="file"]:hover {
  cursor: pointer;
}

.drop-zone span {
  color: var(--main-primary, #2999fd);
  text-align: center;
}

.placeholder {
  align-items: center;
  justify-content: center;
}

.file-preview {
  width: 80px;
  height: 80px;
  margin: 5px 0;
  border-radius: 8px;
}

.preview {
  position: relative;
}
.close-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.single-preview {
  object-fit: cover;
  height: inherit;
  width: -webkit-fill-available;
  border-radius: 16px;
}
</style>
