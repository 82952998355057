<template>
  <div class="d-flex search-bar-container">
    <div class="search-container">
      <v-icon class="search-icon">custom:searchIcon</v-icon>
      <input class="body1" type="search" :placeholder="placeholder" :value="search" @input="onSearchChanged" />
      <v-scroll-x-reverse-transition>
        <v-btn
          v-show="search"
          icon="custom:closeIcon"
          variant="text"
          class="close-icon"
          @click="
            search = '';
            mitt.emit('search', '');
          "
        />
      </v-scroll-x-reverse-transition>
    </div>
    <v-menu v-if="!hideFilter" v-model="isMenuOpen" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn icon v-bind="props" color="#F4F6FA" elevation="0">
          <v-icon class="op07" :class="{ primary: filters.length }">custom:filterIcon</v-icon>
        </v-btn>
      </template>
      <v-card class="pa-4" style="border-radius: 16px" min-width="250px">
        <div class="d-flex flex-column" style="gap: 24px">
          <div class="d-flex justify-space-between align-center">
            <span>ФІЛЬТР</span>
            <v-btn icon variant="plain" @click="isMenuOpen = false" size="x-small">
              <v-icon>custom:closeIcon</v-icon>
            </v-btn>
          </div>
          <slot name="filter">
            <v-switch v-model="filter" label="Тільки непрочитані" color="#2999FD" hide-details></v-switch>

            <div v-if="route.path.endsWith('inbox')" class="d-flex flex-column gap8">
              <label for="">По менеджерам</label>
              <v-select :items="memberList" v-model="managerId" density="compact" variant="outlined" hide-details item-title="text" item-value="value" />
            </div>
          </slot>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch, defineProps, reactive } from "vue";
import mitt from "@/services/mitt";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

defineProps({
  placeholder: {
    type: String,
    default: "Пошук...",
  },
  hideFilter: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const store = useStore();

const memberList = [
  {
    text: "Всі",
    value: "0",
  },
  {
    text: "Без менеджера",
    value: null,
  },
  ...Object.values(store.getters.members).map((x) => ({ text: x.fullName, value: x.id })),
];
const managerId = ref("0");

const filter = ref(false);
const search = ref("");
const isMenuOpen = ref(false);
const filters = reactive([]);

onMounted(() => {
  mitt.on("set-search", setSearchValue);
});

onUnmounted(() => {
  mitt.off("set-search", setSearchValue);
});

const setSearchValue = (newVal) => {
  search.value = newVal;
};

var searchTimeout = -1;
const onSearchChanged = (val) => {
  search.value = val.target.value;

  if (searchTimeout !== -1) clearTimeout(searchTimeout);

  searchTimeout = setTimeout(async () => {
    try {
      const trimmedValue = val.target.value?.trim();
      mitt.emit("search", trimmedValue);
    } finally {
      clearTimeout(searchTimeout);
      searchTimeout = -1;
    }
  }, 1000);
};

watch(filter, (val) => {
  if (val) {
    if (route.path.includes("inbox/comments")) {
      filters.push({ field: "IsReplied", operation: "Equals", value: false });
    } else {
      filters.push({ field: "LastMessage.UnseenInRow", operation: "DoesntEqual", value: 0 });
    }
  } else {
    if (route.path.includes("inbox/comments")) {
      filters.splice(
        filters.findIndex((x) => x.field == "IsReplied"),
        1
      );
    } else {
      filters.splice(
        filters.findIndex((x) => x.field == "LastMessage.UnseenInRow"),
        1
      );
    }
  }

  mitt.emit("inbox-filter", filters);
});

watch(managerId, (val) => {
  filters.splice(
    filters.findIndex((x) => x.field == "AssignedToId"),
    1
  );

  if (val !== "0") {
    filters.push({ field: "AssignedToId", operation: "Equals", value: val });
  }
  mitt.emit("inbox-filter", filters);
});
</script>

<style scoped>
.search-bar-container {
  width: 100%;
  gap: 16px;
  padding: 20px 32px;
}
.search-container {
  position: relative;
  width: 100%;
}
input[type="search"]::placeholder {
  width: 100%;
  opacity: 0.5;
  /* padding: 12px; */
}

input[type="search"] {
  border-radius: 16px;
  background: var(--light-blue, #f4f6fa);
  padding: 12px;

  display: flex;
  width: 100%;

  padding: 14px;
  padding-left: 40px;
  align-items: flex-start;
  gap: 12px;

  border: none;
  text-overflow: ellipsis;
}

input[type="search"]:focus {
  outline: none;
}

.search-icon {
  position: absolute;
  opacity: 0.5;
  top: 12px;
  left: 12px;
}

.close-icon {
  position: absolute;
  right: 12px;
  top: 1px;
  opacity: 0.5;
}

.icon-container {
  padding: 12px;

  background: var(--bg, #fafbfc);
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 24px;
  border-radius: 1000px;
}

.icon-container svg {
  height: 18px;
  width: 18px;
}

.menu-filter {
  border-radius: 16px;
  background: var(--white, #fff);
  padding: 16px;
  gap: 24px;

  /* Drop Shadow */
  box-shadow: 0px 4px 20px 0px rgba(90, 88, 88, 0.05);
}

.filter-title {
  color: var(--main-secondary, #5a5858);
  opacity: 0.7;
}
.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body2 {
  color: var(--main-secondary, #5a5858);
  /* Body 2 */
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
</style>
