import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Terms from "../views/Terms.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";

import { inboxRoutes } from "@/modules/inbox/module";
import { settingsRoutes } from "@/modules/settings/module";
import { adminRoutes } from "@/modules/admin/module";
import { authRoutes } from "@/modules/auth/module";
import { ordersRoutes } from "@/modules/orders/module";
import { warehouseRoutes } from "@/modules/warehouse/module";

const DEFAULT_TITLE = "CRM для малого та середнього бізнесу | CRM-система для e-commerce";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { guest: true, title: "Домашня | Weselli" },
  },
  {
    path: "/terms-and-uses",
    name: "about",
    component: Terms,
    meta: { guest: true },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: { guest: true },
  },
  ...authRoutes,
  ...settingsRoutes,
  ...adminRoutes,
  ...inboxRoutes,
  ...ordersRoutes,
  ...warehouseRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (store.getters.stateUser?.roles.includes("Admin")) {
      if (to.matched.some((x) => x.path.startsWith("/admin"))) {
        next();
        return;
      } else {
        next({ name: "admin-home" });
        return;
      }
    }

    document.title = to.meta.title || DEFAULT_TITLE;
    if (to.matched.some((record) => record.meta.guest) && to.name != "home" && to.name != "privacy-policy") {
      next({ name: "home" });
      return;
    }
    next();
    return;
  }

  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated && to.name != "home") {
      document.title = DEFAULT_TITLE;
      next("/");
      return;
    }

    document.title = to.meta.title || DEFAULT_TITLE;
    next();
    return;
  } else {
    next({ name: "login" });
    return;
  }
});

export default router;
