<template>
  <div>
    <InboxLinkInstagram v-if="!isInstagramLinked" />
    <template v-else>
      <v-row v-if="firstLoading">
        <v-col> <InboxLoader /> </v-col>
      </v-row>
      <template v-else>
        <v-progress-linear class="my-1" v-if="loading" indeterminate color="#2999FD"></v-progress-linear>

        <template v-if="inbox.threads.length > 0">
          <v-data-table-server
            :headers="[]"
            :items="inbox.threads"
            @update:options="loadInbox"
            :items-length="store.getters.inbox.totalCount"
            :items-per-page="options.itemsPerPage"
            :items-per-page-options="itemsPerPage"
            :page="options.page"
            :search="options.search"
          >
            <template #body>
              <v-list lines="one" class="d-flex flex-column thread-list">
                <ThreadItem v-for="thread in inbox.threads" :key="thread.id" :thread="thread" />
              </v-list>
              <v-progress-linear class="my-1" v-if="loading" indeterminate color="#2999FD"></v-progress-linear>
            </template>
          </v-data-table-server>
        </template>
        <EmptyInbox v-else :text="$t('Inbox.Label.NoThreads')" />
      </template>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, reactive, onBeforeUnmount } from "vue";
import InboxLinkInstagram from "../components/LinkInstagramPropose.vue";
import InboxLoader from "../components/InboxLoader.vue";
import EmptyInbox from "../components/EmptyInbox.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import ThreadItem from "../components/ThreadItem.vue";
import mitt from "@/services/mitt";

const store = useStore();
const route = useRoute();
const router = useRouter();

const firstLoading = ref(true);
const instagramNotLinked = ref(false);
const loading = ref(false);
const options = reactive({ page: 1, itemsPerPage: 30, search: "" });
const itemsPerPage = [
  { title: "30", value: 30 },
  { title: "50", value: 50 },
];

const selectedFilters = ref([]);

const inbox = computed(() => store.getters.inbox);
const isInstagramLinked = computed(() => store.getters.isInstagramLinked);

onMounted(async () => {
  mitt.on("search", onSearchChanged);
  mitt.on("inbox-filter", filterItems);
  try {
    options.search = (route.query.search || "").toString();
    if (options.search) {
      mitt.emit("set-search", options.search);
    }
    options.page = +route.query.page || 1;
    options.itemsPerPage = +route.query.pageSize || 30;
    await loadInbox(options);
    firstLoading.value = false;
  } catch {
    instagramNotLinked.value = true;
  }
});

onBeforeUnmount(() => {
  mitt.off("search", onSearchChanged);
  mitt.off("inbox-filter", filterItems);
});

const loadInbox = async ({ page, itemsPerPage, search }) => {
  if (!isInstagramLinked.value) return;

  const query = { page: page, pageSize: itemsPerPage };
  if (search) {
    query.search = search;
  }
  if (route.query.page != query.page || route.query.pageSize != query.pageSize || route.query.search != query.search) {
    router.replace({
      path: route.path,
      query: query,
    });
  }
  loading.value = true;
  await store.dispatch("refreshInbox", {
    page,
    pageSize: itemsPerPage,
    filters: selectedFilters.value,
    search,
  });
  loading.value = false;
};

const filterItems = async (filters) => {
  selectedFilters.value = filters;

  await loadInbox(options);
};

const onSearchChanged = async (val) => {
  options.search = val;
  options.page = 1;
};
</script>

<style scoped>
.thread-list {
  width: calc(100vw - 140px);
}

@media (max-width: 1280) {
  .thread-list {
    width: calc(100vw - 64px);
  }
}

@media (max-width: 800px) {
  .thread-list {
    width: calc(100vw - 32px);
  }
}
</style>
