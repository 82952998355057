<template>
  <v-dialog :modelValue="modelValue" @update:model-value="setDialogValue" width="800">
    <v-card>
      <div class="d-flex justify-space-between align-center mb-8">
        <span class="h2">Запрошуйте колег до своєї команди</span>

        <v-btn icon variant="plain" size="x-small" @click="setDialogValue(false)">
          <v-icon>custom:closeIcon</v-icon>
        </v-btn>
      </div>
      <div class="d-flex flex-column gap16 px-4 pb-4">
        <v-alert v-if="limitExceeded" border="start" type="info" color="rgba(200, 200, 200, 0.1)" border-color="primary">
          <template #prepend>
            <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
              <v-icon color="primary" class="info">custom:warningIcon</v-icon>
            </v-btn>
          </template>
          <div class="d-flex flex-column gap8">
            <h4 class="subtitle2" style="color: #5a5858">Зверніть увагу!</h4>
            <span class="body1" style="color: #5a5858" v-html="'Ви досягли ліміту в кількості користувачів вашої організації.'"> </span>
          </div>
        </v-alert>

        <div class="d-flex flex-column gap16">
          <h4 class="subtitle">Запросити поштою</h4>
          <v-form ref="formRef" class="d-flex gap16" :validate-on="formValidateOn">
            <v-row>
              <v-col cols="12" sm="4" md="5">
                <v-text-field
                  v-model="email"
                  variant="outlined"
                  density="compact"
                  single-line
                  placeholder="email@address.com"
                  :rules="[rules.required, rules.email]"
                  :error-messages="errorMessage ? [errorMessage] : []"
              /></v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="role"
                  variant="outlined"
                  density="compact"
                  :items="[
                    { text: 'Адміністратор', value: '7175DF74-08A6-48E9-AF94-9C47D222CD87' },
                    { text: 'Менеджер', value: '9D1B420A-91CD-41C1-BB57-4CE3C1BBBBF9' },
                  ]"
                  label="Роль в команді"
                  solo
                  item-title="text"
                  item-value="value"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <div>
                  <v-btn rounded color="primary" :loading="inviting" @click="inviteUser" width="100%" :disabled="limitExceeded || inviting">
                    <div class="d-flex gap8"><v-icon>custom:emailIcon</v-icon> Запросити</div>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <hr />
        <!-- <div class="d-flex flex-column gap16">
          <h5 class="subtitle">Запросити посиланням</h5>
          <div class="d-flex gap16">
            <v-text-field value="https://dribbble.com/shots/17543845-Invite-Members" readonly variant="outlined" density="compact" hide-details></v-text-field>
            <v-btn rounded color="primary" :disabled="limitExceeded">
              <div class="d-flex gap8"><v-icon>mdi-link</v-icon> Копіювати посилання</div>
            </v-btn>
          </div>
          <span class="body2 op07">Будь-хто, хто має доступ до цього посилання, може приєднатися до вашої команди</span>
        </div> -->
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import i18n from "@/plugins/i18n";
import { ref, defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import { teamService } from "../../services/team.service";
import { emailRule, requiredRule } from "@/services/field.rules";

const props = defineProps({
  modelValue: {},
});
const emits = defineEmits(["update:modelValue", "invited"]);
const store = useStore();

const formRef = ref(null);
const touched = ref(false);
const formValidateOn = computed(() => (touched.value ? "input" : "submit"));
const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  email: (value) => emailRule(value, i18n.global.t("Common.ErrorMessage.Email")),
};

const email = ref(null);
const role = ref("9D1B420A-91CD-41C1-BB57-4CE3C1BBBBF9");
const inviting = ref(false);

const errorMessage = ref(null);
const limitExceeded = ref(false);

const inviteUser = async () => {
  errorMessage.value = "";
  touched.value = true;

  email.value = email.value.trim();

  const isValid = (await formRef.value.validate()).valid;

  if (!isValid) return;

  try {
    inviting.value = true;
    const invite = await teamService.inviteUser(email.value, role.value);
    emits("update:modelValue", false);
    emits("invited", invite);
    email.value = "";
    role.value = "9D1B420A-91CD-41C1-BB57-4CE3C1BBBBF9";

    formRef.value.reset();
  } catch (error) {
    if (error.response?.data) {
      if (error.response.data.code === "members-1") {
        errorMessage.value = "Ви не можете запросити цього користувача.";
      }
      if (error.response.data.code === "members-2") {
        errorMessage.value = "Користувач уже у вашій команді.";
      }
      if (error.response.data.code === "members-3") {
        errorMessage.value = "Ви вже запросили цього користувача";
      }
      if (error.response.data.code === "members-4") {
        limitExceeded.value = true;
      }
    } else store.dispatch("addError");
  } finally {
    inviting.value = false;
  }
};

const setDialogValue = () => emits("update:modelValue", false);
</script>
