<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn color="primary" small class="mb-2" @click="openCreateDialog" rounded> створити шаблон відповіді </v-btn>
    </div>

    <v-data-table class="body1" :headers="headers" :items="messageTemplates" :loading="loading">
      <template v-slot:[`item.target`]="{ item }">
        {{ targetMepping[item.target] }}
      </template>
      <template v-slot:[`item.text`]="{ item }">
        <div class="message-container">
          <TextMessage class="my-2" sent :message="{ message: item.text }" />
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex gap8">
          <v-icon small class="mr-2" @click="editTemplate(item)" on> custom:editIcon </v-icon>

          <Prompt @confirmed="deleteMessageTemplate" :title="removePromptTitle">
            <v-icon small @click="showRemoveTemplatePrompt(item)"> custom:trashIcon </v-icon>
          </Prompt>
        </div>
      </template>
    </v-data-table>
    <EditTemplateMessage :creating="!selectedTemplate.id" v-model="showTemplateDialog" :message="selectedTemplate" @template-created="onTemplateCreated" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";
import { messageTemplateService } from "@/modules/inbox/services/message-template.service";
import { ReplyTo } from "@/modules/inbox/models/comment";
import TextMessage from "@/modules/inbox/components/TextMessage.vue";
import Prompt from "@/components/Prompt.vue";
import EditTemplateMessage from "../../EditTemplateMessage.vue";

const store = useStore();
const messageTemplates = ref([]);
const loading = ref(true);
const targetMepping = {
  [ReplyTo.Direct]: i18n.global.t("Inbox.Comments.Label.Direct"),
  [ReplyTo.Comment]: i18n.global.t("Inbox.Comments.Label.Comment"),
};
const newItemTemplate = {
  id: "",
  title: "",
  text: "",
  target: ReplyTo.Direct,
};
const removePromptTitle = i18n.global.t("Settings.Integrations.InstagramAPI.Settings.RemoveTemplatePrompt");
const selectedTemplate = ref(newItemTemplate);
const showTemplateDialog = ref(false);

const headers = [
  {
    title: i18n.global.t("Settings.Integrations.InstagramAPI.Settings.Templates.Title"),
    align: "start",
    sortable: false,
    key: "title",
    value: "title",
  },
  {
    title: i18n.global.t("Settings.Integrations.InstagramAPI.Settings.Templates.Target"),
    align: "start",
    sortable: false,
    key: "target",
    value: "target",
  },
  {
    title: i18n.global.t("Settings.Integrations.InstagramAPI.Settings.Templates.Text"),
    align: "start",
    sortable: false,
    key: "text",
    value: "text",
  },
  { title: "Дії", value: "actions", key: "actions", sortable: false },
];

onMounted(async () => {
  try {
    messageTemplates.value = await messageTemplateService.getMessageTemplates([]);
  } finally {
    loading.value = false;
  }
});

const onTemplateCreated = (template) => {
  messageTemplates.value.push(template);
  selectedTemplate.value = newItemTemplate;

  showTemplateDialog.value = false;
};

const openCreateDialog = () => {
  selectedTemplate.value = newItemTemplate;
  showTemplateDialog.value = true;
};

const showRemoveTemplatePrompt = (template) => {
  selectedTemplate.value = template;
};

const deleteMessageTemplate = async () => {
  try {
    await messageTemplateService.deleteTemplate(selectedTemplate.value.id);
    messageTemplates.value.splice(messageTemplates.value.indexOf(selectedTemplate.value), 1);
  } catch {
    store.dispatch("addError");
  }
};

const editTemplate = (template) => {
  selectedTemplate.value = template;
  showTemplateDialog.value = true;
};
</script>

<style scoped>
.message-container {
  border-radius: 16px;
  background: var(--divider, rgba(90, 88, 88, 0.1));
  padding: 8px;
  width: fit-content;
}
</style>
