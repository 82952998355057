<template>
  <div>
    <v-card v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-card>
    <div v-else class="d-flex flex-column gap24 pa-8">
      <h1 class="h1 d-flex gap16 align-center">
        <Tooltiped :text="$t('Common.Label.Back')">
          <v-btn icon style="font-size: 24px" variant="text" @click="goBack">
            <v-icon large>custom:arrowLeftIcon</v-icon>
          </v-btn>
        </Tooltiped>
        <div>
          {{ $t("Warehouses.Goods.Label.GoodDetails") }} <span>{{ good.inventoryNumber }}</span>
        </div>
      </h1>
      <v-card class="pa-6 d-flex flex-column gap16">
        <div class="title d-flex justify-space-between">
          <span> Деталі товару </span>

          <v-btn style="border-radius: 16px" size="40" icon color="primary" @click="goToGoodEdit">
            <v-icon size="24" class="white-icon"> custom:editIcon </v-icon></v-btn
          >
        </div>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column gap8">
            <label> {{ $t("Warehouses.Goods.Headers.Picture") }}</label>
            <div class="good-picture">
              <img v-if="good.pictureUrl" :src="good.pictureUrl" class="single-preview" />
              <v-img v-else src="../../../assets/good_no_picture.png" />
            </div>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="12" md="4" class="d-flex flex-column gap8">
                <label> {{ $t("Warehouses.Goods.Headers.Title") }}</label>
                <v-text-field v-model="good.title" variant="outlined" density="compact" single-line readonly />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column gap8">
                <label> {{ $t("Warehouses.Goods.Headers.InventoryNumber") }}</label>
                <v-text-field v-model="good.inventoryNumber" readonly variant="outlined" density="compact" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4" class="d-flex flex-column gap8">
                <label> {{ $t("Warehouses.Goods.Headers.Cost") }}</label>
                <v-text-field v-model="good.cost" variant="outlined" density="compact" single-line readonly prefix="₴" type="number" />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column gap8">
                <label> {{ $t("Warehouses.Goods.Headers.UnitPrice") }}</label>
                <v-text-field v-model="good.unitPrice" variant="outlined" density="compact" single-line readonly prefix="₴" type="number" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4" class="d-flex flex-column gap8">
                <label> {{ $t("Warehouses.Goods.Headers.TotalAmount") }}</label>
                <v-text-field v-model="good.totalAmount" variant="outlined" density="compact" single-line readonly type="number" />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column gap8">
                <label> {{ $t("Warehouses.Goods.Headers.Quantity") }}</label>
                <v-text-field v-model="good.quantity" variant="outlined" density="compact" single-line readonly type="number" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <GoodComponents v-if="good.components.length" v-model="good.components" readonly />
      </v-card>
    </div>
  </div>
</template>

<script setup>
import { goodsService } from "../services/goods.service";
import GoodComponents from "../components/GoodComponents.vue";
import Tooltiped from "@/components/Tooltiped.vue";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const route = useRoute();

const good = ref(null);

const loading = ref(true);

onMounted(async () => {
  try {
    good.value = await goodsService.getGoodById(route.params.id);
  } catch (requestError) {
    if (requestError.response.status === 404) {
      router.push({ name: "goods" });
      return;
    }

    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
});

const goToGoodEdit = () => {
  if (good.value.id) router.push({ name: "edit-good", params: { id: good.value.id.toString() } });
};

const goBack = () => {
  router.go(-1);
};
</script>

<style>
.good-picture {
  height: 100%;
  max-height: 320px;
}

.good-picture img {
  border-radius: 16px;
}

.single-preview {
  object-fit: cover;
  height: inherit;
  width: -webkit-fill-available;
  border-radius: 16px;
}
</style>
