<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" transition="slide-y-transition" :close-on-back="false">
    <template #activator="{ props }">
      <Tooltip :text="$t('Funnel.Button.AddStatus')">
        <v-btn class="create-order-fab" icon size="64" color="primary" v-bind="props">
          <v-icon>custom:plusIcon</v-icon>
        </v-btn>
      </Tooltip>
    </template>

    <v-card class="pa-4" width="352px">
      <div class="d-flex flex-column gap24">
        <div class="d-flex justify-space-between align-center">
          <span>Створення нового статуса</span>

          <v-btn icon variant="plain" size="x-small" @click="menu = false">
            <v-icon>custom:closeIcon</v-icon>
          </v-btn>
        </div>

        <v-form ref="formRef">
          <label>{{ $t("Funnel.Label.StatusTitle") }}</label>
          <v-text-field
            density="compact"
            variant="outlined"
            :placeholder="$t('Funnel.Label.StatusTitlePlaceholder')"
            v-model="statusTitle"
            class="mb-2"
            :rules="[rules.required]"
          />

          <label>{{ $t("Funnel.Label.ChooseColor") }}</label>
          <v-text-field v-model="color" density="compact" hide-details class="ma-0 pa-0 mb-2" variant="outlined">
            <template #append>
              <v-menu :model-value="colorMenu" :close-on-content-click="false">
                <template #activator="{ props }">
                  <div :style="swatchStyle" v-bind="props" />
                </template>

                <v-color-picker v-model="color" dot-size="25" hide-mode-switch hide-sliders mode="hexa" show-swatches swatches-max-height="200" />
              </v-menu>
            </template>
          </v-text-field>

          <label>{{ $t("Funnel.Label.ChooseStatusGroup") }}</label>
          <v-select v-model="statusGroupId" :items="statusGroups" density="compact" variant="outlined" item-title="text" item-value="value"></v-select>
        </v-form>

        <div class="d-flex justify-center">
          <v-btn color="primary" rounded variant="flat" style="color: white" @click="createThreadStatus" :disabled="creating" :loading="creating">{{
            $t("Common.Button.Create")
          }}</v-btn>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script setup>
import { requiredRule } from "@/services/field.rules";
import { funnelService } from "../../services/funnel.service";

import { defineProps, defineEmits, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";

const props = defineProps(["order"]);
const emits = defineEmits(["created"]);
const store = useStore();

const menu = ref(false);
const statusTitle = ref("");
const color = ref("#1976D2");
const statusGroupId = ref(1);
const colorMenu = ref(false);
const creating = ref(false);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
};

const formRef = ref(null);
const createThreadStatus = async () => {
  const isValid = (await formRef.value.validate()).valid;
  if (!isValid) {
    return;
  }

  try {
    creating.value = true;
    const createdInboxThreadStatus = await funnelService.create(statusTitle.value, props.order, color.value, statusGroupId.value);
    emits("created", createdInboxThreadStatus);
    menu.value = false;
    statusTitle.value = "";
  } catch {
    store.dispatch("addError");
  } finally {
    creating.value = false;
  }
};

const statusGroups = computed(() => [
  {
    value: 1,
    text: "Не в обробці",
  },
  {
    value: 2,
    text: "В обробці",
  },
  {
    value: 3,
    text: "Завершений",
  },
]);

const swatchStyle = computed(() => ({
  backgroundColor: color.value,
  cursor: "pointer",
  height: "30px",
  width: "30px",
  borderRadius: colorMenu.value ? "50%" : "12px",
  transition: "border-radius 200ms ease-in-out",
}));

watch(menu, () => {
  if (!menu.value) formRef.value.resetValidation();
});
</script>

<style>
.add-status.v-btn {
  height: 48px;
  padding: 8px 16px;
}
</style>
