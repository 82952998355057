import Settings from "./views/Settings.vue";
import Integrations from "./components/Integrations.vue";
import General from "./components/General.vue";
import TeamList from "./components/team/TeamList.vue";
import Billing from "./views/Billing.vue";
import Account from "./components/Account.vue";
import FacebookSettings from "./views/FacebookSettings.vue";
import NovaPoshtaSettings from "./views/NovaPoshtaSettings.vue";

export const routes = [
  {
    path: "/settings/integrations/facebook-instagram",
    name: "settings-facebook-instagram",
    component: FacebookSettings,
    meta: { title: "Налаштування Фейсбук та Інстаграм | Weselli" },
  },
  {
    path: "/settings/integrations/novaposhta",
    name: "settings-novaposhta",
    component: NovaPoshtaSettings,
    meta: { title: "Налаштування Нової Пошти | Weselli" },
  },
  {
    path: "/settings",
    component: Settings,
    children: [
      {
        path: "",
        name: "settings",
        component: Integrations,
        meta: { title: "Налаштування | Weselli" },
      },
      {
        path: "integrations",
        name: "settings-integrations",
        component: Integrations,
        meta: { title: "Налаштування | Weselli" },
      },
      {
        path: "general",
        component: General,
        meta: { title: "Основні | Weselli" },
      },
      {
        path: "team",
        component: TeamList,
        meta: { title: "Команда | Weselli" },
      },
      {
        path: "billing",
        component: Billing,
        name: "billing",
        meta: { title: "Рахунок та Оплата | Weselli" },
      },
      {
        path: "account",
        component: Account,
        meta: { title: "Профіль | Weselli" },
      },
    ],
  },
];
