<template>
  <div class="d-flex flex-column gap16">
    <div class="d-flex gap8 align-center">
      <Prompt :title="backPromptTitle" @confirmed="setViewState">
        <Tooltipped :text="$t('Common.Label.BackToList')">
          <v-btn icon> <v-icon>custom:arrowLeftIcon</v-icon> </v-btn>
        </Tooltipped>
      </Prompt>
      <h4 class="d-inline">{{ $t("Inbox.Label.CustomerCreateOrder") }}</h4>
      <v-spacer></v-spacer>
      <v-btn v-if="fullscreen" @click="fullscreen = false" variant="text" color="primary">
        <span class="d-flex align-center gap8"> Сховати розширену форму <v-icon class="max-min">custom:minimizeIcon</v-icon> </span>
      </v-btn>
      <v-btn v-else @click="fullscreen = true" variant="text" color="primary">
        <span class="d-flex align-center gap8"> Розширена форма <v-icon class="max-min">custom:maximizeIcon</v-icon> </span>
      </v-btn>
    </div>

    <v-form ref="form" lazy-validation class="d-flex flex-column gap16">
      <OrderGoodsComponent v-model="orderData.orderLineItems" :touched="isFormSubmitted" />

      <v-divider></v-divider>
      <v-card elevation="0" class="px-2">
        <v-overlay :model-value="!isNovaPoshtaAvailable" contained class="d-flex justify-center align-center">
          <v-card class="d-flex flex-column gap16 pa-4 align-self-center" width="400px">
            <h1 class="h2">
              {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.NotLinkedHeader") }}
            </h1>
            <p class="subtitle op07">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.NotLinkedDesc") }}</p>
            <v-btn color="primary" link :to="{ name: 'settings-integrations' }" target="_blank">{{ $t("Inbox.Button.LinkInstagram") }}</v-btn>
            <Tooltipped :text="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.NotLinkedRefresh')" :top="true">
              <div class="d-flex justify-center">
                <v-btn @click="setNovaPoshta" variant="text" class="link"> Я вже підключи(в/ла) </v-btn>
              </div>
            </Tooltipped>
          </v-card>
        </v-overlay>
        <h5 class="my-4">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.ReceiverInfo") }}</h5>
        <NovaPoshtaPersonInfo :isSender="false" :person="orderData.deliveryInfo.receiver" />
        <label>{{ $t("Common.Label.Note") }}</label>
        <v-textarea variant="outlined" v-model="orderData.comment" auto-grow rows="1" row-height="15"></v-textarea>
      </v-card>
      <v-card elevation="0" class="px-2" v-if="fullscreen">
        <h5 class="my-4">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryInfo") }}</h5>
        <v-row>
          <v-col cols="12" sm="6" v-if="!showSeats">
            <v-text-field
              v-model.number="orderData.deliveryInfo.weight"
              density="compact"
              variant="outlined"
              :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Weight')"
              type="number"
              :rules="[rules.required, rules.greaterOrEqualThan]"
            >
              <template #append> {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.KG") }}</template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="orderData.deliveryInfo.cost"
              density="compact"
              variant="outlined"
              :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.AnnouncementPrice')"
              type="number"
              :rules="[rules.required, rules.greaterOrEqualThan]"
            >
              <template #append> {{ $t("Common.Label.Currency") }}</template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6"> <OrderDescriptionComponent v-model="orderData.deliveryInfo.description" /> </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox inset hide-details v-model="infoRegClientBarcodes" color="primary">
              <template #label>
                {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.InfoRegClientBarcodes") }}
                <Tooltipped :text="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.InfoRegClientBarcodesInfo')">
                  <v-icon>custom:infoIcon</v-icon>
                </Tooltipped>
              </template>
            </v-checkbox>

            <v-checkbox inset hide-details v-model="additionalInformation" color="primary">
              <template #label>
                {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.AdditionalInformation") }}
                <Tooltipped :text="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.AdditionalInformationInfo')">
                  <v-icon>custom:infoIcon</v-icon>
                </Tooltipped>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12" sm="6">
            <v-select
              density="compact"
              variant="outlined"
              :items="deliveryInfo.payerTypes"
              v-model="orderData.deliveryInfo.payerType"
              :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Payer')"
              :rules="[rules.required]"
              item-title="text"
              item-value="value"
              hide-details
            />
          </v-col>
          <v-col v-if="isBusinessAccount" cols="12" sm="6" md="4">
            <v-checkbox
              color="primary"
              label="Контроль оплати ?"
              density="compact"
              variant="outlined"
              v-model="orderData.deliveryInfo.paymentControl"
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <v-switch
              v-model="orderData.deliveryInfo.remittance.active"
              :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Remittance')"
              color="primary"
              hide-details
            >
            </v-switch>
          </v-col>
          <v-col cols="12" v-if="orderData.deliveryInfo.remittance.active">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  type="number"
                  v-model="orderData.deliveryInfo.remittance.total"
                  :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.RemittanceSum')"
                  :rules="[rules.required, rules.equalsToCost]"
                >
                  <template #append> {{ $t("Common.Label.Currency") }}</template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  density="compact"
                  variant="outlined"
                  :items="deliveryInfo.payerTypes.filter((x) => x.value != 'ThirdPerson')"
                  v-model="orderData.deliveryInfo.remittance.payer"
                  :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.RemittanceDeliveryPayer')"
                  :rules="[rules.required]"
                  item-title="text"
                  item-value="value"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="createOrder" :disabled="!isCreateOrderBtnActive" :loading="creating"> {{ $t("Common.Button.Create") }}</v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, nextTick, watch } from "vue";
import { getCreateOrderVm, getDeliveryInfo } from "@/modules/orders/models/create-order";
import { greaterOrEqualThanRule, requiredRule } from "@/services/field.rules";
import Tooltipped from "@/components/Tooltiped.vue";
import Prompt from "@/components/Prompt.vue";
import NovaPoshtaPersonInfo from "@/modules/orders/components/NovaPoshtaPersonInfo.vue";
import { orderService } from "@/modules/orders/services/order.service";
import { novaPoshtaService } from "@/modules/orders/services/novaposhta.service";
import _ from "underscore";
import { deliveryInfo } from "@/modules/orders/models/novaposhta";
import OrderDescriptionComponent from "@/modules/orders/components/OrderDescriptionComponent.vue";
import OrderGoodsComponent from "@/modules/orders/components/OrderGoodsComponent.vue";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";

const props = defineProps({
  customerId: {},
  threadId: {},
});
const emits = defineEmits(["set-state"]);
const store = useStore();

const backPromptTitle = i18n.global.t("Orders.NewOrder.Label.UnsavedDataPrompt");
const orderData = ref(getCreateOrderVm());
const creating = ref(false);
const fullscreen = ref(false);
const infoRegClientBarcodes = ref(false);
const additionalInformation = ref(false);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  greaterOrEqualThan: (value) => greaterOrEqualThanRule(value, 0.1, i18n.global.t("Common.ErrorMessage.GreaterThanOrEqual", { value: 0.1 })),
  equalsToCost: (value) =>
    value == orderData.value.deliveryInfo.cost || i18n.global.t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryCostNotEqualsRemittance"),
};

const showSeats = computed(() => orderData.value.deliveryInfo.receiver?.address?.categoryOfWarehouse == "Postomat");
const isNovaPoshtaAvailable = computed(() => store.getters.isNovaPoshtaLinked);
const isCreateOrderBtnActive = computed(() => isNovaPoshtaAvailable.value && !creating.value);
const orderTotal = computed(() => orderData.value.orderLineItems.reduce((prev, current) => prev + current.quantity * current.unitPrice, 0));

onMounted(async () => {
  const deliveryData = await getNovaPosthaTemplateData();

  orderData.value.deliveryInfo = { ...deliveryData, receiver: orderData.value.deliveryInfo.receiver };

  infoRegClientBarcodes.value = !!deliveryData.infoRegClientBarcodes;
  additionalInformation.value = !!deliveryData.additionalInformation;
});

const isFormSubmitted = ref(false);
const form = ref(null);
const createOrder = async () => {
  const isValid = (await form.value.validate()).valid;
  isFormSubmitted.value = true;
  if (!isValid || orderData.value.orderLineItems.length < 1) {
    await nextTick();
    var firstError = form.value.querySelector(".v-input--error");
    if (firstError) window.scroll({ top: firstError.offsetTop + firstError.scrollHeight - 32, behavior: "smooth" });

    return;
  }

  creating.value = true;
  try {
    orderData.value.customerId = props.customerId;
    orderData.value.sourceData = props.threadId;
    orderData.value.sourceId = 1;

    await orderService.createOrder(orderData.value);
    setViewState();
  } catch {
    store.dispatch("addError");
  } finally {
    creating.value = false;
  }
};

const isBusinessAccount = ref(false);
const getNovaPosthaTemplateData = async () => {
  try {
    const accounts = await novaPoshtaService.getSavedDeliveryInfo();

    const templates = _.flatten(accounts.map((x) => x.templates));
    const defaultTemplate = templates.find((x) => x.default);

    let deliveryInfo = defaultTemplate.deliveryInfo || getDeliveryInfo();
    isBusinessAccount.value = accounts.find((x) => x.templates.some((t) => t === defaultTemplate))?.isBusiness;

    return deliveryInfo;
  } catch {
    return getDeliveryInfo();
  }
};

watch(infoRegClientBarcodes, (value) => {
  orderData.value.deliveryInfo.infoRegClientBarcodes = value ? "{{ order.OrderNumber }}" : "";
});

watch(additionalInformation, (value) => {
  orderData.value.deliveryInfo.additionalInformation = value ? "{{ for item in order.OrderItems }}{{item.Title}} ({{item.Quantity}});{{ end }}" : "";
});

watch(
  () => orderData.value.deliveryInfo.cost,
  (val) => {
    orderData.value.deliveryInfo.remittance.total = val;
  }
);

watch(orderTotal, () => {
  orderData.value.deliveryInfo.cost = orderTotal.value;
});

const setViewState = () => {
  const viewState = 1;

  emits("set-state", viewState);
};
</script>

<style scoped>
.good-label {
  display: none;
}

.goods-headers {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .goods-headers {
    display: none;
  }
  .good-label {
    display: inline-block;
  }
}
</style>
