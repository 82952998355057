import { api } from "@/services/api.service";

const endpoints = {
  LINK_INSTAGRAM: "/api/inbox/instagram/link",
  UNLINK_INSTAGRAM: "/api/inbox/instagram/unlink",
};

function getFacebookService() {
  const apiAuthenticate = async (authResponse) => {
    await api.post(endpoints.LINK_INSTAGRAM, authResponse);
  };

  const removeIntergrationAndLogout = async (removeInstagramSettings) => {
    await api.post(endpoints.UNLINK_INSTAGRAM, { removeSettings: removeInstagramSettings });
  };

  return {
    apiAuthenticate,
    removeIntergrationAndLogout,
  };
}

// need for instagram comment Webhooks (Enable Page Subscriptions)
// pages_manage_metadata -
// instagram_manage_comments - for Comments and @Mentions
// pages_read_engagement
// pages_show_list

// conversation API
// instagram_basic
// instagram_manage_messages

// manage_pages

// comment reply
// instagram_basic
// instagram_manage_comments
// pages_show_list
// page_read_engagement
// ads_management
// pages_read_engagement
// business_management

export const facebookService = getFacebookService();
