import Forbidden from "./views/Forbidden.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import AcceptInvite from "./views/AcceptInvite";

export const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { guest: true, title: "Вхід | Weselli" },
  },
  {
    path: "/registration",
    name: "register",
    component: Register,
    meta: { guest: true, title: "Реєстрація | Weselli" },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: { guest: true, title: "Скидання Паролю | Weselli" },
  },
  {
    path: "/accept-invitation",
    name: "accept-invite",
    component: AcceptInvite,
    meta: { guest: true, title: "Приєднатись | Weselli" },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: Forbidden,
  },
];
