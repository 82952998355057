import store from "@/store";
import axios from "axios";

const axiosApiInstance = axios.create();

axiosApiInstance.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${store.getters.authToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const config = error?.config;

    if (config && config.url == "/api/account/refresh" && error.response.status == 401) {
      await store.dispatch("logout");
      return Promise.reject(error);
    }

    if (error.response && error.response.status == 401 && config.url != "/api/account/login" && !config?._retry) {
      config._retry = true;

      await store.dispatch("refresh");
      axiosApiInstance.defaults.headers.common["Authorization"] = `Bearer ${store.getters.authToken}`;
      return await axiosApiInstance(config);
    }
    return Promise.reject(error);
  }
);

export const api = axiosApiInstance;
