<template>
  <div>
    <SearchableHeader placeholder="Пошук за ПІБ, телефоном, накладною, товарами..." hide-filter />

    <div class="pa-8 d-flex flex-column gap16">
      <div class="d-flex flex-column gap8">
        <div class="title mb-2">{{ $t("Orders.Headers.Orders") }}</div>
        <div v-if="!mobile" class="subtitle op05 mb-4">Скористайтеся функцією фільтрації або пошуком вище, щоб знайти потрібне замовлення</div>
        <!-- <OrdersFilter :filters="filters" @filtering="filterItems" /> -->
      </div>
      <OrdersTable :config="config" v-model="selectedOrders" :show-username="true" />
      <FloatingBtn />
    </div>
  </div>
</template>

<script setup>
import SearchableHeader from "@/components/layouts/SearchableHeader.vue";
import FloatingBtn from "../components/OrderFloatingButton.vue";

import OrdersFilter from "@/components/CommonFilter.vue";
import { headers, filters } from "./order.configs";
import OrdersTable from "../components/OrdersTable.vue";
import { onBeforeUnmount, onMounted, reactive, ref } from "vue";
import mitt from "@/services/mitt";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { mobile } = useDisplay();

const config = reactive({
  headers: headers,
  filters: [],
  selectable: true,
  queryPaging: true,
  searchText: "",
  export: {
    enabled: true,
  },
});

const selectedOrders = ref([]);

onMounted(() => {
  mitt.on("search", onSearchChanged);
});

onBeforeUnmount(() => {
  mitt.off("search", onSearchChanged);
});

const filterItems = async (filters) => {
  config.filters = filters;
};

const onSearchChanged = async (val) => {
  config.searchText = val;
};
</script>
