import store from "@/store";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

function getApiSettingsService() {
  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.VUE_APP_BASE_URL}ws/settings-hub`, { accessTokenFactory: () => store.getters.authToken })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  const initConnection = async () => {
    await connection.start();

    connection.on("IntegrationPluggedIn", async ({ data }) => {
      store.dispatch("plugInIntegration", data);
    });

    connection.on("IntegrationPluggedOff", async ({ data }) => {
      store.dispatch("plugOffIntegration", data);
    });
  };

  return {
    initConnection,
  };
}

export const apiSettingsService = getApiSettingsService();
