import { api } from "@/services/api.service";
import { formatString } from "@/services/util";

const endpoints = {
  GET_ALL_STATUSES: "/api/inbox/thread-statuses",
  STATUS_ID: "/api/inbox/thread-statuses/{0}",
  UPDATE_ORDER: "/api/inbox/thread-statuses/{0}/change-order",
  GET_FUNNEL: "/api/inbox/funnel?search={0}",
  GET_FUNNEL_ITEMS_BATCH: "/api/inbox/funnel/{0}/threads?page={1}&search={2}",
};

function getFunnelService() {
  const update = async (statusId, operations) => {
    return api.patch(formatString(endpoints.STATUS_ID, statusId.toString()), operations);
  };

  const getAllStatuses = async () => {
    return (await api.get(endpoints.GET_ALL_STATUSES)).data;
  };

  const create = async (title, order, color, statusGroupId) => {
    return (await api.post(endpoints.GET_ALL_STATUSES, { title, order, color, statusGroupId })).data;
  };

  const deleteStatus = (statusId) => {
    return api.delete(formatString(endpoints.STATUS_ID, statusId.toString()));
  };

  const changeStatusOrder = async (statusId, order) => {
    return (await api.post(formatString(endpoints.UPDATE_ORDER, statusId.toString()), { newOrder: order })).data;
  };

  const getFunnel = async (search) => {
    return (await api.get(formatString(endpoints.GET_FUNNEL, search))).data;
  };

  const getColumnFunnelBatch = async (statusId, page, search) => {
    return (await api.get(formatString(endpoints.GET_FUNNEL_ITEMS_BATCH, statusId.toString(), page.toString(), search))).data;
  };

  return {
    update,
    getAllStatuses,
    create,
    deleteStatus,
    changeStatusOrder,
    getFunnel,
    getColumnFunnelBatch,
  };
}

export const funnelService = getFunnelService();
