<template>
  <transition-group name="notification-list" tag="div" class="top-right">
    <v-alert v-for="(thread, i) in threads" v-bind:key="i" class="pa-0 rounded-16 newmessage-alert">
      <v-list-item
        :active="false"
        lines="one"
        height="75px"
        :title="thread.customer.username"
        :subtitle="thread.lastMessage.text"
        :prepend-avatar="thread.customer.profilePicture"
      >
        <template #append>
          <v-btn icon="custom:arrowRightIcon" variant="text" @click="goTo(thread)" />
        </template>
      </v-list-item>
    </v-alert>
  </transition-group>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const threads = computed(() => store.getters.threads);

const goTo = async (thread) => {
  threads.value.forEach(async (thread) => {
    await store.dispatch("dismissMessage", thread);
  });
  await router.push({ name: "inbox-thread", params: { id: thread.id } });
};
</script>

<style lang="scss" scoped>
$margin: 64px;

.top-right {
  bottom: $margin;
  right: 16px;
  left: auto;
  width: 300px;
  position: fixed;
  opacity: 0.95;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
}
.notification-list-enter,
.notification-list-leave-active {
  opacity: 0;
  transform: translateX(-90px);
}
.notification-list-leave-active {
  position: absolute;
}

.rounded-16 {
  border-radius: 16px;
  border: thin solid lightgray;
}

.v-alert .v-list-item {
  background-color: white;
}
@media (max-width: 900px) {
  .top-right {
    bottom: initial;
    right: initial;
    left: 50%;
    top: 16px;
    transform: translateX(-50%);
    width: 300px;
    position: fixed;
    opacity: 0.95;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
