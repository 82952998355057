<template>
  <footer>
    <div class="footer-inner-container d-flex justify-space-between">
      <div class="content-container">
        <div class="logo-info-container">
          <div class="logo-container gap24">
            <LogoIcon style="width: 150px" color="var(--main-primary-color)" />
            <p>Надійна CRM для Вашого магазину.</p>
          </div>

          <div class="info-container">
            <!-- <p>+380 099 999 99 99</p> -->
            <p>
              <span class="highlight"><a href="mailto:info@weselli.com">info@weselli.com</a></span>
            </p>
          </div>
        </div>

        <div class="socials-consult-container">
          <div class="socials">
            <a href="https://t.me/weselli_news" target="_blank"><TelegramSocial /></a>
            <a href="https://www.instagram.com/weselli_crm" target="_blank"><InstaSocial /></a>
            <!-- <a  href="https://www.instagram.com/weselli_crm" target="_blank"><MetaSocial /></a>
          <a  href="https://www.instagram.com/weselli_crm" target="_blank"><YouTubeSocialVue /></a> -->
          </div>
        </div>
      </div>

      <v-btn class="contact-us" variant="outlined" color="primary" rounded height="48" @click="scrollToContactUs">замовити консультацію</v-btn>
    </div>

    <div class="d-flex bottom-links">
      <span class="op05">Weselli Copyright © 2023</span>
      <div class="d-flex gap16 op07 lin">
        <span><a href="/terms-and-uses">Умови Користування</a></span>
        <span><a href="/privacy-policy">Політика Конфіденційності</a></span>
      </div>
    </div>
  </footer>
</template>

<script setup>
import LogoIcon from "@/assets/FooterLogo.vue";
import TelegramSocial from "@/assets/custom-icons/TelegramSocial.vue";
import InstaSocial from "@/assets/custom-icons/InstaSocial.vue";
// import MetaSocial from "@/assets/custom-icons/MetaSocial.vue";
// import YouTubeSocialVue from "@/assets/custom-icons/YouTubeSocial.vue";

const scrollToContactUs = () => {
  const el = document.getElementById("contact-us");
  if (el) {
    window.scrollTo({
      top: el.offsetTop + -64,
      left: 0,
      behavior: "smooth",
    });
  }
};
</script>

<style lang="scss" scoped>
footer {
  background: var(--BG, #fafbfc);
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.logo-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 336px;
}
.info-container {
  width: 152px;
  align-items: center;
}
.logo-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logo-container svg path {
  fill: var(--main-primary-color);
}
.content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.socials-consult-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 40px;
  align-items: center;

  margin: 0 auto;
}
.socials {
  display: flex;
  flex-wrap: wrap;
}

.bottom-links {
  justify-content: space-between;
}

a {
  text-decoration: none;
  color: var(--primary);
}

.contact-us {
  width: 312px;
}

.footer-inner-container {
  align-items: center;
}

@media (max-width: 600px) {
  .contact-us {
    width: 100%;
  }

  .lin {
    font-size: 15px;
  }

  .logo-container {
    width: 278px;
  }

  .lin span {
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
}

@media (max-width: 788px) {
  .footer-inner-container {
    gap: 16px;
  }
}

@media (max-width: 788px) {
  footer {
    padding: 16px;
    gap: 24px;
  }
  .logo-container {
    gap: 0;
  }
  .bottom-links {
    flex-direction: column-reverse;
    align-items: center;
  }
  .logo-info-container {
    flex-direction: column;
    gap: 16px;
    align-items: initial;
  }
}

@media (max-width: 1088px) {
  .footer-inner-container {
    flex-direction: column;
    gap: 40px;
    align-items: initial;
  }
}
</style>
