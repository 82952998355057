import { api } from "@/services/api.service";

const landingEndpoint = {
  SEND_APPLICATION: "/api/landing/application",
};

function getLandingService() {
  const sendApplication = async (application) => {
    return (await api.post(landingEndpoint.SEND_APPLICATION, application)).data;
  };

  return {
    sendApplication,
  };
}

export const landingService = getLandingService();
