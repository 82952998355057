<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template #activator="{ props }">
      <slot v-bind="{ props }"></slot>
    </template>
    <v-card>
      <div class="d-flex justify-space-between align-center">
        <span class="btn">Перегляд активності</span>

        <v-btn icon variant="plain" size="x-small" @click="dialog = false">
          <v-icon>custom:closeIcon</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <div class="d-flex flex-column gap16">
          <div class="d-flex flex-column gap8">
            <v-label>Відповідальний менеджер</v-label>
            <v-select
              :items="memberList"
              v-model="assignee"
              density="compact"
              variant="outlined"
              hide-details
              item-title="text"
              item-value="value"
              placeholder="Назначте відповідального"
              clearable
            />
          </div>
          <hr />
          <v-label>Історія змін менеджера</v-label>
          <v-timeline side="end" style="max-height: 300px; overflow: scroll">
            <v-timeline-item dot-color="pink" size="small" v-for="(assign, i) in thread.assigns" :key="i">
              <v-list>
                <v-list-item height="56">
                  <v-list-item-title>
                    <div class="d-flex gap8 align-center" style="min-height: 48px">
                      <template v-if="assign.assigneeId">
                        <AvatarSlim :user-id="assign.assignedById" /> назначив <AvatarSlim :user-id="assign.assigneeId" />
                      </template>

                      <template v-else> <AvatarSlim :user-id="assign.assignedById" /> зняв менеджера </template>

                      <span class="subtitle op07">{{ formatTimestamp(assign.createdOn) }}</span>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="assignThread" :loading="loading" color="primary" rounded variant="flat" style="height: 48px; padding: 8px 16px">{{
          $t("Common.Button.OK")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { inboxService } from "../../services/inbox.service";
import AvatarSlim from "@/components/AvatarSlim.vue";
const emits = defineEmits(["assignment-changed"]);
const props = defineProps({
  thread: {},
});
const store = useStore();

const memberList = Object.values(store.getters.members).map((x) => ({ text: x.fullName, value: x.id }));
const assignee = ref(null);

const dialog = ref(false);
const loading = ref(false);

const formatTimestamp = (timestamp) =>
  moment.utc(timestamp).local().isSame(moment(), "d")
    ? moment.utc(timestamp).local().format("LT")
    : moment.utc(timestamp).local().isSame(moment(), "year")
    ? moment.utc(timestamp).local().format("DD.MM[\n]HH:mm")
    : moment.utc(timestamp).local().format("DD.MM.YY[\n]HH:mm");

onMounted(() => {
  console.log(props.thread.assigneeId);
  assignee.value = memberList.find((x) => x.value === props.thread.assigneeId);
});

const assignThread = async () => {
  if (assignee.value === props.thread.assigneeId) return;

  try {
    loading.value = true;
    await inboxService.assignUser(props.thread.id, assignee.value);
    emits("assignment-changed", assignee.value);
    dialog.value = false;
  } catch {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
};
</script>
