import { api } from "@/services/api.service";

const endpoints = {
  LINK_NOVA_POSHTA: "api/orders/delivery/nova-poshta/link",
  UNLINK_NOVA_POSHTA: "api/orders/delivery/nova-poshta/unlink",
};
function getDeliveryService() {
  const unlinkNovaPoshta = async (removeNovaPoshtaSettings) => {
    return (await api.post(endpoints.UNLINK_NOVA_POSHTA, { removeSettings: removeNovaPoshtaSettings })).data;
  };

  const linkNovaPoshta = async (key) => {
    return (await api.post(endpoints.LINK_NOVA_POSHTA, { key })).data;
  };

  return {
    unlinkNovaPoshta,
    linkNovaPoshta,
  };
}

export const deliveryService = getDeliveryService();
