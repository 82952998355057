<template>
  <v-container fill-height>
    <div class="d-flex gap24 contact-us" id="contact-us">
      <v-img :src="require('../../../assets/landing/new/contact-us.png')" alt="Зв‘яжіться з нами" width="640" />
      <div class="d-flex flex-column gap24 form-container">
        <div class="d-flex flex-column gap8">
          <h1 class="text-left"><span>Зв‘яжіться </span> з нами</h1>
          <p>Для цього заповніть форму</p>
        </div>
        <v-form class="contact-us-form" ref="form">
          <label>Прізвище, імʼя, по-батькові</label>
          <v-text-field
            v-model="formData.fullName"
            placeholder="Введіть ПІБ"
            rounded
            variant="outlined"
            :rules="[rules.required]"
            density="compact"
          ></v-text-field>

          <label>Електронна пошта</label>
          <v-text-field
            v-model="formData.email"
            placeholder="example@mail.com"
            rounded
            variant="outlined"
            :rules="[rules.required, rules.email]"
            density="compact"
          />

          <label>Номер телефону</label>
          <v-text-field v-model="formData.phone" rounded variant="outlined" v-maska:[phoneNumberMaskOptions] density="compact">
            <template #prepend-inner>
              <span class="op05">+380</span>
            </template>
          </v-text-field>

          <label>Коментар</label>
          <v-textarea v-model="formData.comment" rounded variant="outlined" rows="3" no-resize :rules="[rules.commentLength]"> </v-textarea>

          <v-alert
            v-if="isApplicationSent"
            class="my-4"
            border="start"
            type="success"
            variant="tonal"
            title="Успішно"
            text="Наш менеджер зв‘яжеться з Вами в найближчий час!"
          />

          <v-btn color="primary" rounded width="100%" height="48" @click="sendForm" :loading="loading" :disabled="loading">відправити</v-btn>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script setup>
import { emailRule, requiredRule, maxLength } from "@/services/field.rules";
import i18n from "@/plugins/i18n";
import { ref } from "vue";
import { useStore } from "vuex";
import { vMaska } from "maska";
import { landingService } from "../services/landing.service";

const phoneNumberMaskOptions = { mask: "##-###-##-##" };
const store = useStore();

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  email: (value) => emailRule(value, i18n.global.t("Common.ErrorMessage.Email")),
  commentLength: (value) => maxLength(value, 100),
};
const form = ref(null);
const loading = ref(false);
const formData = ref({ fullName: null, email: null, phone: null, comment: null });
const isApplicationSent = ref(false);

const sendForm = async () => {
  const isFormValid = (await form.value.validate()).valid;
  if (!isFormValid) {
    return;
  }

  loading.value = true;
  try {
    if (formData.value.phone?.length > 0) {
      formData.value.phone = `380${formData.value.phone}`;
    }
    await landingService.sendApplication(formData.value);

    isApplicationSent.value = true;
    setTimeout(() => {
      isApplicationSent.value = false;
    }, 5000);

    formData.value = { fullName: null, email: null, phone: null, comment: null };
    form.value.reset();
  } catch (e) {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.contact-us {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.contact-us-form {
  padding: 24px;
  width: 480px;
  border-radius: 20px;
  border: 1px solid var(--Divider, rgba(90, 88, 88, 0.1));
  background: var(--White, #fff);

  /* Drop Shadow */
  box-shadow: 0px 4px 20px 0px rgba(90, 88, 88, 0.05);
}

@media (max-width: 600px) {
  .contact-us-form {
    width: auto;
  }
  .form-container {
    width: 100%;
  }
}
</style>
