<template>
  <div class="d-flex gap24 conversation">
    <div class="direct-container">
      <div class="direct-toolbar" style="gap: 16px">
        <template v-if="!loading">
          <div class="d-flex conversation-username">
            <Tooltipped :text="$t('Inbox.Label.ToList')">
              <v-btn icon variant="flat">
                <v-icon @click="goBack"> custom:arrowLeftIcon </v-icon>
              </v-btn>
            </Tooltipped>
            <div class="d-flex align-center gap8">
              <v-avatar size="40">
                <v-img v-if="customer.profilePicture" :src="customer.profilePicture" :alt="customer.username"></v-img>
                <v-img v-else src="../../../assets/no-picture-account.jpg"></v-img>
              </v-avatar>
              <span> @{{ customer.username }} </span>
            </div>
          </div>

          <div class="d-flex gap8 align-center">
            <ConversationStatusCard v-if="thread && thread.status" v-model="thread.status" :threadId="thread.id" />
            <ThreadAssignmentHistory :thread="thread" @assignment-changed="onAssignment">
              <template #default="{ props }">
                <v-btn v-if="thread.assigneeId" icon v-bind="props">
                  <AvatarSlim :user-id="thread.assigneeId" :key="thread.assigneeId" />
                </v-btn>
                <v-btn v-else icon class="op07" v-bind="props">
                  <v-icon size="40" color="rgb(244, 67, 54)">mdi-alert-circle</v-icon>
                </v-btn>
              </template>
            </ThreadAssignmentHistory>
          </div>
        </template>
      </div>
      <template v-if="loading">
        <v-skeleton-loader type="list-item-avatar-three-line, image, article"></v-skeleton-loader>
      </template>

      <template v-else>
        <div
          class="d-flex flex-column justify-space-between pa-4"
          style="gap: 24px"
          :style="{ height: mobile ? 'calc(100vh - 186px)' : 'calc(100vh - 130px)' }"
        >
          <div class="d-flex flex-column justify-space-between" ref="messageContainer" style="overflow-y: auto; gap: 16" @scroll="onScrollToTop">
            <v-progress-linear v-show="batchLoading" indeterminate color="cyan"></v-progress-linear>

            <div>
              <div v-for="(messageGroup, name, i) in groupedMessages" :key="i" class="d-flex flex-column gap16">
                <h4 class="message-group" style="text-align: center">{{ name }}</h4>
                <div
                  v-for="message in messageGroup"
                  :key="message.id"
                  class="thread-message"
                  ref="messages"
                  :class="{ sent: message.from.id === thread.accountId }"
                >
                  <AttachmentMessage v-if="message.attachments && message.attachments.data" :message="message" />
                  <ShareMessage v-else-if="message.shares" :message="message" />
                  <StoryMessage v-else-if="message.story" :message="message" :sent="message.from.id === thread.accountId" />
                  <TextMessage
                    v-else
                    :message="message"
                    :sent="message.from.id === thread.accountId"
                    :last="message == lastMessage"
                    :seenByCustomer="thread.seenByCustomer"
                  />
                </div>
              </div>
            </div>
          </div>
          <SendPanel :thread="thread" :lastMessageTime="lastMessageTime" @message-sent="onNewMessage"></SendPanel>
        </div>
      </template>
    </div>

    <div class="d-flex flex-column gap24 cards-container">
      <CustomerOrdersCard :customer="customer" :thread-id="route.params.id" />

      <CustomerCommentsCard v-if="customer" :customer="customer" />
      <v-skeleton-loader v-else type="card" loading elevation="2"> </v-skeleton-loader>
    </div>

    <InstagramThreadDeletedDialog :modelValue="showInstagramThreadDeletedDeleted" />
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import SendPanel from "../components/SendPanel.vue";
import Tooltipped from "@/components/Tooltiped.vue";
import { inboxService } from "../services/inbox.service";
import { apiNotificationService } from "@/services/api-notifications.service";
import TextMessage from "../components/TextMessage.vue";
import AttachmentMessage from "../components/AttachmentMessage.vue";
import ShareMessage from "../components/ShareMessage.vue";
import StoryMessage from "../components/StoryMessage.vue";
import CustomerCommentsCard from "../components/comments/CustomerCommentsCard.vue";
import CustomerOrdersCard from "../components/conversation/CustomerOrdersCard.vue";
import moment from "moment";
import _ from "underscore";
import InstagramThreadDeletedDialog from "../components/conversation/InstagramThreadDeletedDialog.vue";
import ConversationStatusCard from "../components/conversation/ConversationStatusCard.vue";
import ThreadAssignmentHistory from "../components/conversation/ThreadAssignmentHistory.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";
import AvatarSlim from "@/components/AvatarSlim.vue";
const { mobile } = useDisplay();

const store = useStore();
const router = useRouter();
const route = useRoute();

const thread = ref(null);
const loading = ref(true);
const showInstagramThreadDeletedDeleted = ref(false);
const batchLoading = ref(false);

const lastMessageTime = computed(() => (thread.value?.messages.length ? thread.value?.messages[thread.value?.messages.length - 1].created_time : ""));
const customer = computed(() => thread.value?.customer);
const groupedMessages = computed(() => {
  if (!thread.value?.messages) {
    return [];
  }
  return _.groupBy(thread.value?.messages, (message) => moment.utc(message.created_time).local().startOf("day").format("LL"));
});
const lastMessage = computed(() => thread.value?.messages[thread.value.messages.length - 1]);

onMounted(async () => {
  try {
    thread.value = await inboxService.getThread(route.params.id);
    loading.value = false;
    apiNotificationService.subscribeForNewMessage(({ slimThread, originalMessage }) => onNewMessage(slimThread, originalMessage));
    apiNotificationService.subcribeForCustomerReads(onCustomerReadLastMessage);
    await store.dispatch("setActiveThread", thread.value);
    await scrollToEnd();
  } catch (error) {
    if (error.response?.data?.errorType === "Deleted") {
      showInstagramThreadDeletedDeleted.value = true;
      return;
    }
    router.go(-1);
    store.dispatch("addError");
  }
});

const goBack = () => {
  router.go(-1);
};

const messageContainer = ref(null);
const scrollToEnd = async () => {
  await nextTick();
  if (messageContainer.value) messageContainer.value.scroll({ top: messageContainer.value.scrollHeight, left: 0, behavior: "smooth" });
};

const onNewMessage = async (thread1, threadMessage) => {
  if (thread.value?.id !== thread1.id) return;
  if (thread.value?.messages.some((x) => x.id === threadMessage.id)) return;
  if (threadMessage.from.id == thread.value.accountId) {
    await store.dispatch("setSeenActiveThread");
  }

  thread.value.seenByCustomer = threadMessage.from.id != thread.value.accountId;

  thread.value?.messages.push(threadMessage);

  if (!thread.value.assigneeId) {
    thread.value.assigneeId = store.getters.currentUserId;
    thread.value.assigns.unshift({
      assignedById: store.getters.currentUserId,
      assigneeId: store.getters.currentUserId,
      createdOn: new Date().toISOString(),
    });
  }

  await scrollToEnd();
};

const onCustomerReadLastMessage = (threadId) => {
  if (thread.value.id != threadId) return;

  thread.value.seenByCustomer = true;
};

onBeforeUnmount(async () => {
  await store.dispatch("setActiveThread", null);
  apiNotificationService.unsubscribeForNewMessage(onNewMessage);
  apiNotificationService.unsubcribeForCustomerReads(onCustomerReadLastMessage);
});

const getNextBatch = async () => {
  try {
    batchLoading.value = true;
    const nextBatch = await inboxService.getMessagesBatch(thread.value.externalId, thread.value.cursorLink);
    thread.value.cursorLink = nextBatch.cursorLink;
    thread.value?.messages.unshift(...nextBatch.messages);

    return nextBatch.messages.length;
  } catch {
    store.dispatch("addError");
    return 0;
  } finally {
    batchLoading.value = false;
  }
};

const threadMessage = ref(null);

const onScrollToTop = async (e) => {
  var scrollBar = e.target;

  if (scrollBar.scrollTop == 0 && thread.value?.cursorLink) {
    const length = await getNextBatch();
    await nextTick();

    if (threadMessage.value) {
      var messageToReturn = threadMessage.value[length];
      messageToReturn.scrollIntoView();
    }
  }
};

const onAssignment = (assigneeId) => {
  thread.value.assigneeId = assigneeId;
  thread.value.assigns.unshift({
    assigneeId: assigneeId,
    assignedById: store.getters.stateUser.id,
    createdOn: new Date().toUTCString(),
  });
};
</script>

<style>
.conversation {
  background: var(--bg, #fafbfc);
  padding: 32px;
}

.direct-container {
  width: 50%;
  border-radius: 16px;
  border: 1px solid var(--divider, rgba(90, 88, 88, 0.1));
  background: var(--white, #fff);
}
.cards-container {
  width: 50%;
}

.direct-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-radius: 16px 16px 0px 0px;
  background: var(--white, #fff);
  border-bottom: 1px solid rgba(90, 88, 88, 0.1);
  padding: 16px;
  color: var(--main-secondary, #5a5858);
}
.conversation-username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.thread-message {
  width: max-content;
  max-width: 80%;
}
.sent {
  display: flex;
  align-self: flex-end;
  color: var(--white, #fff);
}
.sent .message-time {
  color: var(--white, #fff);
}

.message-time {
  color: var(--main-secondary, #5a5858);
  text-align: right;
  opacity: 0.5;
  align-items: end;
}

.conversation .message-container {
  display: flex;
  padding: 16px;
  border-radius: 16px 16px 16px 4px;
  background: var(--light-blue, #f4f6fa);
}
.conversation .image-container img {
  border-radius: 16px 16px 16px 4px;
}

.conversation .sent .message-container {
  display: flex;
  text-align: left;
  border-radius: 16px 16px 4px 16px;
  background: var(--main-primary, #2999fd);
}

.conversation .sent .image-container img {
  border-radius: 16px 16px 4px 16px;
}

@media only screen and (max-width: 600px) {
  .thread-message {
    max-width: 100%;
  }
  .thread-message.sent {
    margin-left: initial;
  }
  .conversation {
    padding: 8px;
  }
}

@media screen and (max-width: 1227px) {
  .conversation {
    flex-direction: column;
  }
  .direct-container {
    width: 100%;
  }
  .cards-container {
    width: 100%;
  }
}
</style>
