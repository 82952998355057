<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_275_4405)">
      <path d="M3.00004 2.99996L13 12.9999M13 2.99996L3.00004 12.9999" stroke="#5A5858" stroke-width="1.5" stroke-linecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_275_4405">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
