<template>
  <div class="steps" data-test="stepper">
    <div class="step-headers mb-10 gap16">
      <div class="d-flex align-center gap16">
        <v-avatar size="48" :color="activeStep == 0 ? 'var(--main-primary-color)' : '#fafbfc'">
          <span class="h3" :style="{ color: activeStep == 0 ? '#fff' : 'var(--main-secondary-color)' }"> 1 </span>
        </v-avatar>

        <span class="step-title"> Вітаємо </span>
      </div>

      <v-divider />

      <div class="d-flex align-center gap16">
        <v-avatar size="48" :color="activeStep == 1 ? 'var(--main-primary-color)' : '#fafbfc'">
          <span class="h3" :style="{ color: activeStep == 1 ? '#fff' : 'var(--main-secondary-color)' }"> 2 </span>
        </v-avatar>

        <span class="step-title"> Facebook, Instagram </span>
      </div>

      <v-divider />

      <div class="d-flex align-center gap16">
        <v-avatar size="48" :color="activeStep == 2 ? 'var(--main-primary-color)' : '#fafbfc'">
          <span class="h3" :style="{ color: activeStep == 2 ? '#fff' : 'var(--main-secondary-color)' }"> 3 </span>
        </v-avatar>
        <span class="step-title"> Нова Пошта </span>
      </div>
      <v-divider />

      <div class="d-flex align-center gap16">
        <v-avatar size="48" :color="activeStep == 3 ? 'var(--main-primary-color)' : '#fafbfc'">
          <span class="h3" :style="{ color: activeStep == 3 ? '#fff' : 'var(--main-secondary-color)' }"> 4 </span>
        </v-avatar>
        <span class="step-title"> Початок роботи </span>
      </div>
    </div>
    <div class="step" v-if="activeStep == 0">
      <IntroStep @proceed="nextStep" />
    </div>

    <div class="step" v-if="activeStep == 1">
      <FacebookInstagramStep @proceed="nextStep" @cancel="previousStep" />
    </div>

    <div class="step" v-if="activeStep == 2">
      <NovaPoshtaStep @proceed="nextStep" @cancel="previousStep" :novaPoshtaAccounts="novaPoshtaAccounts" />
    </div>

    <div class="step" v-show="activeStep == 3">
      <StartWorkingStep @proceed="refreshPage" @cancel="previousStep" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import IntroStep from "./Stepper/IntroStep.vue";
import FacebookInstagramStep from "./Stepper/FacebookInstagramStep.vue";
import NovaPoshtaStep from "./Stepper/NovaPoshtaStep.vue";
import StartWorkingStep from "./Stepper/StartWorkingStep.vue";

import { useRouter } from "vue-router";

defineProps({
  activeStep: {
    type: Number,
    default: 0,
  },
  novaPoshtaAccounts: {},
});

const router = useRouter();

const activeStep = ref(0);

const nextStep = () => {
  if (activeStep.value < 4) {
    activeStep.value++;
  }
};
const previousStep = () => {
  if (activeStep.value > 0) {
    activeStep.value--;
  }
};

const refreshPage = () => {
  router.go();
};
</script>

<style>
.steps {
  display: flex;
  flex-direction: column;
  margin: 0 10%;
  padding: 32px;
}

.step {
  margin-bottom: 20px;
}

.step-title {
  color: var(--main-secondary-color);
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.step-headers {
  height: 72px;
  width: 100%;
  align-items: center;
  display: flex;
}

@media (max-width: 700px) {
  .step-title {
    display: none;
  }
}

.stepper-container {
  max-width: 770px;
  padding: 20px;
  align-self: flex-start;
}

p {
  text-align: start;
}
.step {
  margin-bottom: 20px;
}

.h2 {
  color: var(--main-secondary, #5a5858);
}

.subtitle {
  color: var(--main-secondary, #5a5858);
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.feature {
  display: flex;
  align-items: center;
}

.nova-title {
  font-size: 18px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-self: center;

  margin-top: 48px;
  gap: 32px;
}

.subtitle .link {
  color: var(--main-secondary-color);
}
</style>

<style>
.steps svg.info g path,
.steps svg.info g circle {
  stroke: #2999fd !important;
}

svg.warning g path,
svg.warning g circle {
  stroke: rgba(253, 168, 41, 1);
}

svg.error.trash g path {
  fill: #ff3f3f;
}
svg.error.trash g path:nth-child(2) {
  stroke: #ff3f3f;
}
</style>
