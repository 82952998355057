<template>
  <v-dialog v-model="dialog" max-width="650px" :persistent="removing">
    <template #activator="{ props }">
      <v-btn icon v-bind="props" variant="text" color="var(--main-secondary-color)">
        <Tooltiped :text="$t('Settings.Integrations.NovaPoshtaAPI.TurnOff')">
          <v-icon>custom:closeOutlineIcon</v-icon>
        </Tooltiped>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("Settings.Integrations.NovaPoshtaAPI.TurnOffTitle") }}
      </v-card-title>
      <v-card-text>
        <v-switch v-model="removeNovaPoshtaSettings" :label="$t('Settings.Integrations.NovaPoshtaAPI.TurnOffLabel')"></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="cancel" :disabled="removing"> {{ $t("Common.Button.Cancel") }} </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" variant="text" :disabled="removing" @click="remove"> {{ $t("Common.Button.TurnOff") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useStore } from "vuex";
import { deliveryService } from "../../../services/delivery.service";
import Tooltiped from "@/components/Tooltiped.vue";
import i18n from "@/plugins/i18n";

const emits = defineEmits(["removed"]);
const store = useStore();

const dialog = ref(false);

const removeNovaPoshtaSettings = ref(false);
const removing = ref(false);

const cancel = () => {
  removeNovaPoshtaSettings.value = false;
  dialog.value = false;
};

const remove = async () => {
  try {
    removing.value = true;
    await deliveryService.unlinkNovaPoshta(removeNovaPoshtaSettings.value);
    emits("removed");

    dialog.value = false;
  } catch {
    store.dispatch("addNotification", {
      icon: "custom:warningIcon",
      text: i18n.global.t("Common.ErrorMessage.Error"),
      color: "red",
      duration: 3500,
    });
  } finally {
    removing.value = false;
  }
};
</script>
