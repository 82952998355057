<template>
  <v-dialog :modelValue="modelValue" @update:model-value="setDialogValue" :persistent="saving">
    <v-card id="create-edit-template">
      <div class="d-flex justify-space-between align-center">
        <span class="btn">{{ editMode ? "редагування шаблону" : "створення нового шаблону" }}</span>

        <v-btn icon variant="plain" size="x-small" @click="setDialogValue(false)">
          <v-icon>custom:closeIcon</v-icon>
        </v-btn>
      </div>
      <v-form ref="formRef">
        <NovaPoshtaTemplateComponent :template="template" :accounts="accounts" />
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="processTemplate" :loading="saving" color="primary" rounded variant="flat" style="height: 48px; padding: 8px 16px">{{
          $t("Common.Button.Save")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { novaPoshtaService } from "@/modules/orders/services/novaposhta.service";
import { ref, defineProps, defineEmits, nextTick } from "vue";
import { useStore } from "vuex";
import NovaPoshtaTemplateComponent from "./NovaPoshtaTemplate.vue";
import i18n from "@/plugins/i18n";

const props = defineProps({
  modelValue: {},
  template: {},
  accounts: {},
  editMode: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["update:modelValue", "template-created", "template-updated"]);
const store = useStore();

const formRef = ref(null);
const saving = ref(false);

const processTemplate = async () => {
  const isFormValid = (await formRef.value.validate()).valid;
  if (!isFormValid) {
    await nextTick();
    var firstError = document.body.querySelector(".v-input--error");
    var dialog = document.getElementById("create-edit-template");
    if (firstError) {
      dialog.scroll({ top: firstError.offsetTop, left: 0, behavior: "smooth" });
    }
    return;
  }

  saving.value = true;
  try {
    if (props.editMode) {
      await updateTemplate();
    } else {
      await createTemplate();
    }
  } catch {
    store.dispatch("addError");
  } finally {
    saving.value = false;
  }
};

const updateTemplate = async () => {
  await novaPoshtaService.updateTemplate(props.template.id, props.template);
  store.dispatch("addNotification", {
    icon: "custom:messageIcon",
    text: i18n.global.t("Common.Label.Saved"),
    color: "primary",
    duration: 3500,
  });
  emits("update:modelValue", false);
  emits("template-updated");
};

const setDialogValue = (value) => {
  emits("update:modelValue", value);
};

const createTemplate = async () => {
  props.template.novaPoshtaAccountId = props.accounts.find((x) => x.personId == props.template.deliveryInfo.sender.id).id;
  const template = await novaPoshtaService.createTemplate(props.template);

  emits("template-created", template);
};
</script>
