<template>
  <AuthLayout>
    <div v-if="invalidInvitation">
      <div class="d-flex flex-column gap16">
        <div class="form-header">Вітаємо у <span>Weselli</span> 👋🏻</div>
        <div class="pb-4 op07 form-subheader">Схоже, посилання запрошення не працює. Перевірте цілісність всього URL. Якщо це повторються - напишіть нам!</div>
        <div class="d-flex flex-column gap8">
          <v-btn rounded class="form-button" @click="goHome" color="#2999FD" theme="dark">На головну</v-btn>
        </div>
      </div>
    </div>
    <div v-else-if="invitationAlreadyAccepted">
      <div class="d-flex flex-column gap16">
        <div class="form-header">Вітаємо у <span>Weselli</span> 👋🏻</div>
        <div class="pb-4 op07 form-subheader">Схоже, ви вже прийняли запрошення.</div>
        <div class="d-flex flex-column gap8">
          <v-btn rounded class="form-button" @click="goLogin" color="#2999FD" theme="dark">Вхід</v-btn>
        </div>
      </div>
    </div>
    <div v-else class="d-flex flex-column justify-space-between gap40">
      <div class="d-flex flex-column gap16">
        <div class="d-flex flex-column gap16">
          <div class="form-header">Вітаємо у <span>Weselli</span> 👋🏻</div>
        </div>

        <div class="d-flex flex-column gap16">
          <v-form ref="formRef" id="register-form">
            <label>{{ $t("Register.Label.FullName") }}</label>
            <v-text-field
              class="mt-1"
              v-model="user.fullName"
              :rules="[rules.required]"
              :placeholder="$t('Register.Placeholder.FullName')"
              variant="outlined"
              validate-on-blur
              density="compact"
            />

            <label>{{ $t("Register.Label.Email") }}</label>
            <v-text-field
              class="mt-1"
              readonly
              v-model="user.email"
              :rules="[rules.required, rules.email]"
              validate-on-blur
              :placeholder="$t('Register.Placeholder.Email')"
              name="email"
              variant="outlined"
              density="compact"
            />

            <label>{{ $t("Register.Label.Phone") }}</label>
            <v-text-field
              class="mt-1"
              v-maska:[phoneNumberMaskOptions]
              v-model="user.phoneNumber"
              :rules="[rules.required, rules.phoneNumber]"
              :placeholder="$t('Register.Placeholder.PhoneNumber')"
              variant="outlined"
              validate-on-blur
              density="compact"
            />

            <label>{{ $t("Register.Label.Password") }}</label>
            <v-text-field
              class="mt-1"
              v-model="user.password"
              :rules="[rules.required, rules.password]"
              :placeholder="$t('Register.Placeholder.Password')"
              variant="outlined"
              :append-inner-icon="showPassword ? 'custom:eyeSlashIcon' : 'custom:eyeIcon'"
              :type="showPassword ? 'text' : 'password'"
              @click:append-inner="showPassword = !showPassword"
              validate-on-blur
              density="compact"
            />
          </v-form>
          <div class="d-flex flex-column gap8">
            <v-btn rounded class="form-button" @click="register" color="#2999FD" theme="dark" :loading="accepting">Приєднатись</v-btn>
          </div>
        </div>
      </div>
      <div class="terms">
        Натискаючи на кнопку вище, ви погоджуєтеся з умовами
        <a href="/">Оферти</a>
      </div>
    </div>
  </AuthLayout>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";

import { accountService } from "../services/account.service";
import { emailRule, requiredRule } from "@/services/field.rules";
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import i18n from "@/plugins/i18n";
import { vMaska } from "maska";
import { teamService } from "@/modules/settings/services/team.service";
import { useRoute, useRouter } from "vue-router";

const phoneNumberMaskOptions = { mask: "38##########" };
const store = useStore();
const route = useRoute();
const router = useRouter();

const user = reactive({
  fullName: "",
  email: "",
  password: "",
  phoneNumber: "38",
  agreementAccepted: true,
});

const showPassword = ref(false);
const accepting = ref(false);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  email: (value) => emailRule(value, i18n.global.t("Common.ErrorMessage.Email")),
  password: (value) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value) || i18n.global.t("Register.ErrorMessage.PasswordFormatInvalid");
  },
  phoneNumber: (value) => {
    return /^38\d{10}$/.test(value) || i18n.global.t("Register.ErrorMessage.PhoneFormatInvalid");
  },
};

const formRef = ref(null);
const isNotRegistered = ref(true);

const invalidInvitation = ref(false);
const invitationAlreadyAccepted = ref(false);

onMounted(async () => {
  var inviteId = route.query?.invitationId;
  var tenantId = route.query?.tenantId;
  if (!inviteId || !tenantId) {
    invalidInvitation.value = true;
  }

  try {
    var invite = await teamService.getInvite(inviteId, tenantId);

    user.email = invite.email;
  } catch (error) {
    if (error.response) {
      if (error.response.data.code === "members-4") {
        invitationAlreadyAccepted.value = true;
      } else {
        invalidInvitation.value = true;
      }
    }
  }
});

const register = async () => {
  const isFormValid = (await formRef.value.validate()).valid;
  if (!isFormValid) {
    return;
  }

  try {
    var inviteId = route.query?.invitationId;
    var tenantId = route.query?.tenantId;

    accepting.value = true;
    user.confirmPassword = user.password;
    await teamService.acceptInvite(inviteId, tenantId, user);

    await store.dispatch("login", user);

    await router.push({ name: "home" }).catch();
  } catch {
    store.dispatch("addError");
  } finally {
    accepting.value = false;
  }
};

const goHome = () => {
  router.push({ name: "home" });
};

const goLogin = () => {
  router.push({ name: "login" });
};
</script>
