<template>
  <v-dialog v-model="dialog" max-width="650px" :persistent="removing">
    <template #activator="{ props }">
      <v-btn icon v-bind="props" color="var(--main-secondary-color)" variant="text">
        <Tooltiped :text="$t('Settings.Integrations.InstagramAPI.TurnOff')">
          <v-icon>custom:closeOutlineIcon</v-icon>
        </Tooltiped>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("Settings.Integrations.InstagramAPI.TurnOffTitle") }}
      </v-card-title>
      <v-card-text>
        <v-switch v-model="removeInstagramSettings" :label="$t('Settings.Integrations.InstagramAPI.TurnOffLabel')"></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="cancel" :disabled="removing"> {{ $t("Common.Button.Cancel") }} </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" variant="text" :disabled="removing" @click="remove"> {{ $t("Common.Button.TurnOff") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Tooltiped from "@/components/Tooltiped.vue";
import { ref, defineEmits } from "vue";
import { useStore } from "vuex";
import { facebookService } from "../../../services/facebook.service";
import i18n from "@/plugins/i18n";

const emits = defineEmits(["removed"]);
const store = useStore();
const dialog = ref(false);
const removeInstagramSettings = ref(false);
const removing = ref(false);

const cancel = () => {
  removeInstagramSettings.value = false;
  dialog.value = false;
};

const remove = async () => {
  try {
    removing.value = true;
    await facebookService.removeIntergrationAndLogout(removeInstagramSettings.value);
    emits("removed");

    dialog.value = false;
  } catch (error) {
    store.dispatch("addNotification", {
      icon: "custom:warningIcon",
      text: i18n.global.t("Common.ErrorMessage.Error"),
      color: "red",
      duration: 3500,
    });
  } finally {
    removing.value = false;
  }
};
</script>
