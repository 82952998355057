import { api } from "@/services/api.service";

const endpoints = {
  GET_ALL: "/api/integrations",
};

function getIntegrationService() {
  const getAllIntegrations = async () => {
    const integrations = (await api.get(endpoints.GET_ALL)).data;
    return integrations;
  };

  return {
    getAllIntegrations,
  };
}

export const integrationService = getIntegrationService();
