<template>
  <v-card style="flex: 1 1 50%" class="pa-6">
    <div class="d-flex justify-space-between gap24">
      <div>
        <InstagramDisable v-if="linked && hasAdminRights" @removed="instagramRemoved" />
      </div>

      <v-btn icon v-if="linked" variant="text" color="var(--main-secondary-color)" @click="router.push({ name: 'settings-facebook-instagram' })">
        <v-icon>custom:settingsIcon</v-icon></v-btn
      >
    </div>
    <div class="d-flex gap32 justify-center">
      <v-img src="../../../../../assets/instagram-logo.png" alt="Instagram" max-width="120px" />
      <v-img src="../../../../../assets/facebook-logo.png" alt="Facebook" max-width="120px" />
    </div>

    <div class="d-flex flex-column gap24 subtitle mt-7">
      <p>Вивести свій маркетинг на новий рівень легко!</p>
      <p>
        Конвертуйте всі звернення клієнтів з Instagram у замовлення. Ваші менеджери зможуть у режимі реального часу відповідати із CRM-систем клієнтам у
        Instagram Direct та відповідати на коментарі.
      </p>
      <p>Підключайте багато облікових записів.</p>
    </div>

    <v-card-actions style="justify-content: space-between">
      <v-dialog v-if="!linked" width="650" v-model="showLinkModal">
        <template #activator="{ props }">
          <v-btn color="primary" v-bind="props"> {{ $t("Inbox.Button.LinkInstagram") }} </v-btn>
        </template>

        <v-card class="pa-2">
          <v-card-title>{{ $t("Settings.Integrations.InstagramAPI.LinkModalTitle") }}</v-card-title>
          <v-card-text>
            Вивести свій маркетинг на новий рівень легко! Конвертуйте всі звернення клієнтів з Instagram в замовлення. Ваші менеджери зможуть в режимі реального
            часу відповідати із CRM-системи клієнтам на Instagram Директ та коментарі. Підключайте багато облікових записів.
          </v-card-text>
          <v-card-actions>
            <v-btn variant="text" @click="showLinkModal = false"> {{ $t("Common.Button.Cancel") }} </v-btn>
            <v-spacer></v-spacer>
            <FacebookLoginButton />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import InstagramDisable from "./InstagramDisable.vue";
import FacebookLoginButton from "./FacebookLoginButton.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

const hasAdminRights = store.getters.isAdmin;
// TODO: додати вимоги до підключення Інстаграму та Фейсбуку
// TODO: повиносити конфіг до Фейсбук в енв змінні
const emits = defineEmits(["removed"]);
defineProps(["linked"]);

const showLinkModal = ref(false);

const instagramRemoved = () => {
  window.FB?.logout(function () {
    // user is now logged out
  });

  emits("removed");
};
</script>
