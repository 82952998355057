<template>
  <v-dialog :modelValue="modelValue" @update:model-value="changeModelValue" max-width="450px">
    <v-card>
      <v-card-title>{{ $t("Inbox.Label.ThreadDeletedTitle") }}</v-card-title>

      <v-alert border="start" type="warning" color="rgba(200, 200, 200, 0.1)" border-color="amber-darken-3">
        <template #prepend>
          <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
            <v-icon color="amber-darken-3">custom:warningIcon</v-icon>
          </v-btn>
        </template>

        {{ $t("Inbox.Label.ThreadDeletedText") }}
      </v-alert>

      <v-card-actions class="justify-center">
        <v-btn color="#2999FD" @click="goToInbox" rounded variant="elevated" style="color: white"> {{ $t("Inbox.Button.GoToInbox") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useRouter } from "vue-router";
import { defineProps, defineEmits } from "vue";

defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue"]);

const router = useRouter();

const goToInbox = () => {
  router.push({ name: "inbox" });
};

const changeModelValue = (newVal) => {
  emits("update:modelValue", newVal);
};
</script>
