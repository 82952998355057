<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12 22.001C17.5228 22.001 22 17.5238 22 12.001C22 6.47813 17.5228 2.00098 12 2.00098C6.47715 2.00098 2 6.47813 2 12.001C2 17.5238 6.47715 22.001 12 22.001Z"
        stroke="#5A5858"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 14.001C8 14.001 9.5 16.001 12 16.001C14.5 16.001 16 14.001 16 14.001"
        stroke="#5A5858"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M9 9.00098H9.01" stroke="#5A5858" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15 9.00098H15.01" stroke="#5A5858" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
  </svg>
</template>
