<template>
  <v-btn class="create-order-fab" icon size="64" color="primary" @click="goToNewOrderPage">
    <v-icon>custom:plusIcon</v-icon>
  </v-btn>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const goToNewOrderPage = () => {
  router.push({ name: "new-order" });
};
</script>

<style>
.create-order-fab {
  position: fixed;
  bottom: 32px;
  right: 32px;
}

@media (max-width: 1280px) {
  .create-order-fab {
    bottom: 64px;
  }
}
</style>
