import uk from "./locales/uk.json";
import moment from "moment-timezone";
import { createI18n } from "vue-i18n";

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || "uk",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "uk",
  messages: {
    uk: {
      ...uk,
    },
  },
  warnHtmlMessage: false,
});

// https://momentjs.com/docs/#/i18n/
moment.tz.setDefault("Europe/Kiev");

moment.locale("uk", {
  relativeTime: {
    past: "%s назад",
    future: "через %s",
    s: "щойно",
    mm: "%d хв",
    m: "%d хв",
    hh: "%d г",
    h: "%d г",
    dd: "%d д",
    d: "%d д",
    MM: "%d м",
    M: "%d м",
    y: "%d р",
    yy: "%d р",
  },
  months: ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"],
  monthsShort: ["Січ", "Лют", "Бер", "Кві", "Тра", "Чер", "Лип", "Сер", "Вер", "Жов", "Лис", "Гру"],
  weekdays: ["неділя", "понеділок", "вівторок", "середа", "четвер", "п'ятниця", "субота"],
  weekdaysShort: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  weekdaysMin: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD.MM HH:mm",
    LL: "D MMMM YYYY",
    LLL: "D-MM-YYYY HH:mm",
    LLLL: "HH:mm Do MMMM YYYY",
  },
});
