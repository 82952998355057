<template>
  <SearchableHeader placeholder="Пошук за артикулом, назвою">
    <template #filter>
      <label>Тип товару</label>

      <v-select
        :items="goodTypeSelectItems"
        v-model="selectedGoodType"
        @update:model-value="onGoodTypeFilterChanged"
        item-title="text"
        item-value="value"
        variant="outlined"
      >
      </v-select>
    </template>
  </SearchableHeader>

  <div class="pa-8 d-flex flex-column gap16">
    <div class="d-flex flex-column gap8">
      <div class="title mb-2">{{ $t("Warehouses.Headers.Warehouses") }}</div>
      <div v-if="!mobile" class="subtitle op05 mb-4">Скористайтеся функцією фільтрації або пошуком вище, щоб знайти потрібний товар</div>
      <GoodsTable :config="config" v-model="selectedGoods" />
    </div>
    <WarehouseFloatingButton />
  </div>
</template>

<script setup>
import WarehouseFloatingButton from "../components/WarehouseFloatingButton.vue";
import GoodsFilter from "@/components/CommonFilter.vue";
import { headers } from "./goods.config";
import { reactive, ref, onMounted, onBeforeUnmount } from "vue";
import SearchableHeader from "@/components/layouts/SearchableHeader.vue";
import GoodsTable from "../components/GoodsTable.vue";
import mitt from "@/services/mitt";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { mobile } = useDisplay();

const selectedGoods = ref([]);
const config = reactive({ headers, filters: [], selectable: true });

const selectedGoodType = ref("Any");
const goodTypeSelectItems = [
  {
    text: "Будь-який",
    value: "Any",
  },
  {
    text: "Товар",
    value: "Good",
  },
  {
    text: "Компонент",
    value: "GoodComponent",
  },
];

onMounted(() => {
  mitt.on("search", onSearchChanged);
});

onBeforeUnmount(() => {
  mitt.off("search", onSearchChanged);
});

const filterItems = (filters) => {
  config.filters = filters;
};

const onSearchChanged = async (val) => {
  config.searchText = val;
};

const onGoodTypeFilterChanged = (val) => {
  switch (val) {
    case "Good":
      config.filters = [{ value: val, field: "Type", operation: "Equals" }];
      break;
    case "GoodComponent":
      config.filters = [{ value: val, field: "Type", operation: "Equals" }];
      break;
    default:
      config.filters = [];
  }
};
</script>
