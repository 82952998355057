<template>
  <div class="d-flex flex-column gap16">
    <UserRegisteredChart class="align-self-center" />
    <v-toolbar elevation="1" density="compact">
      <Tooltip text="Видалити користавача та його організацію з системи">
        <v-btn icon="mdi-delete" color="error" variant="text" />
      </Tooltip>
      <!-- <CreateUserComponent @user-created="onUserCreated">
        <Tooltiped text="Create new user">
          <v-btn icon>
            <v-icon>custom:plusIcon-outline</v-icon>
          </v-btn>
        </Tooltiped>
      </CreateUserComponent> -->
    </v-toolbar>
    <v-data-table
      :headers="headers"
      show-select
      :items="users"
      class="elevation-1"
      :loading="loading"
      :server-items-length="totalUsersCount"
      :page="options.page"
      :items-per-page="options.itemsPerPage"
    >
      <!-- <template v-slot:[`header.id`]="{ header }">
        {{ header.text }}
        <v-menu offset :close-on-content-click="false">
          <template #activator="{ props }">
            <v-btn icon v-bind="props">
              <v-icon small> custom:filterIcon </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-text-field class="pa-4" type="number" label="Enter the search term"> </v-text-field>

            <v-btn small variant="text" color="primary" class="ml-2 mb-2"> Clean </v-btn>
          </div>
        </v-menu>
      </template> -->
      <template v-slot:[`item.integrations`]="{ value }">
        <span v-html="value.map((x) => `${x.title} (${moment.utc(x.createdOn).local().format('LLL')})`).join('</br>')"></span>
      </template>
      <template v-slot:[`item.createdOn`]="{ value }">
        {{ moment.utc(value).local().format("LLLL") }}
      </template>
    </v-data-table>
  </div>
</template>

<script setup>
import moment from "moment";
import { userService } from "../services/user.service";
import Tooltip from "@/components/Tooltiped.vue";
// import CreateUserComponent from "../components/CreateUser.vue";
// import UserRegisteredChart from "../components/charts/UserRegisteredChart.vue";
import { onMounted, reactive, ref, watch } from "vue";

const users = ref([]);
const totalUsersCount = ref(0);
const headers = [
  {
    title: "Full Name",
    align: "start",
    sortable: false,
    key: "fullName",
    value: "fullName",
  },
  {
    title: "Телефон",
    align: "start",
    sortable: false,
    key: "phone",
    value: "phone",
  },
  {
    title: "Email",
    align: "start",
    sortable: false,
    key: "email",
    value: "email",
  },
  {
    title: "Підключено",
    align: "start",
    sortable: false,
    key: "integrations",
    value: "integrations",
  },
  {
    title: "Registration Date",
    align: "start",
    sortable: false,
    key: "createdOn",
    value: "createdOn",
  },
];
const loading = ref(false);
const firstLoading = ref(true);
const options = reactive({
  page: 1,
  itemsPerPage: 20,
});

onMounted(async () => {
  try {
    const paginated = await userService.searchUsers({ page: 1, pageSize: 100, sortQuery: { field: "createdOn", order: "desc" } });
    users.value = paginated.results;
    totalUsersCount.value = paginated.totalCount;
  } finally {
    firstLoading.value = false;
  }
});

const onUserCreated = (createdUser) => {
  users.value.unshift(createdUser);
};

watch(options, async () => {
  try {
    loading.value = true;
    const paginated = await userService.searchUsers({ page: 1, pageSize: 100, sortQuery: { field: "createdOn", order: "desc" } });
    users.value = paginated.results;
    totalUsersCount.value = paginated.totalCount;
  } finally {
    loading.value = false;
  }
});
</script>
