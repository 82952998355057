import { api } from "@/services/api.service";
import { formatString } from "@/services/util";
const endpoints = {
  POST_SETTLEMENT: "api/orders/delivery/nova-poshta/settlements/search",
  GET_WAREHAUSE: "api/orders/delivery/nova-poshta/settlements/{0}",
  GET_SAVED_DELIVERY_INFO: "api/orders/delivery/nova-poshta/templates",
  CREATE_TEMPLATE: "api/orders/delivery/nova-poshta/templates",
  TEMPLATE_BY_ID: "api/orders/delivery/nova-poshta/templates/{0}",
  SET_DEFAULT_TEMPLATE: "api/orders/delivery/nova-poshta/templates/{0}/set-default",
  REFRESH_INTERNET_DOCUMENT: "api/orders/delivery/nova-poshta/internet-documents/{0}/refresh",
  GET_INTERNET_DOCUMENT_BY_DELVIERY_NUMBER: "api/orders/delivery/nova-poshta/{0}/internet-documents/{1}",
};

function getNovaPostaService() {
  const setDefaultTemplate = async (id) => {
    return await api.post(formatString(endpoints.SET_DEFAULT_TEMPLATE, id));
  };

  const removeTemplate = async (id) => {
    await api.delete(formatString(endpoints.TEMPLATE_BY_ID, id));
  };

  const createTemplate = async (template) => {
    const templateToCreate = { ...template };
    delete templateToCreate.id;

    return (await api.post(endpoints.CREATE_TEMPLATE, templateToCreate)).data;
  };

  const getDeliveryInfoByTTN = async (novaPoshtaAccountId, existingTTN) => {
    return (await api.get(formatString(endpoints.GET_INTERNET_DOCUMENT_BY_DELVIERY_NUMBER, novaPoshtaAccountId.toString(), existingTTN))).data;
  };

  const refreshDeliveryInfo = async (deliveryNumber) => {
    return (await api.post(formatString(endpoints.REFRESH_INTERNET_DOCUMENT, deliveryNumber))).data;
  };

  const searchSettlements = async (text) => {
    return (await api.post(endpoints.POST_SETTLEMENT, { text })).data;
  };

  const getWarehouses = async (settlementId) => {
    return (await api.get(formatString(endpoints.GET_WAREHAUSE, settlementId))).data;
  };

  const getSavedDeliveryInfo = async () => {
    return (await api.get(endpoints.GET_SAVED_DELIVERY_INFO)).data;
  };
  const updateTemplate = async (templateId, template) => {
    return (await api.patch(formatString(endpoints.TEMPLATE_BY_ID, templateId), template)).data;
  };

  const getBlockingEntities = async (accountId) => {
    return (await api.get(formatString("api/orders/delivery/nova-poshta/{0}/validation", accountId))).data;
  };

  return {
    setDefaultTemplate,
    createTemplate,
    removeTemplate,
    getDeliveryInfoByTTN,
    refreshDeliveryInfo,
    searchSettlements,
    getWarehouses,
    getSavedDeliveryInfo,
    updateTemplate,
    getBlockingEntities,
  };
}

export const novaPoshtaService = getNovaPostaService();
