import { createStore } from "vuex";
import notification from "./notification.module";
import { inboxStore } from "@/modules/inbox/module";
import { settingsStore } from "@/modules/settings/module";
import { adminStore } from "@/modules/admin/module";
import { authStore } from "@/modules/auth/module";
import { warehouseStore } from "@/modules/warehouse/module";

export default createStore({
  modules: { auth: authStore, notification, inbox: inboxStore, settings: settingsStore, admin: adminStore, warehouse: warehouseStore },
});
