import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labs from "vuetify/lib/labs/components";
import i18n from "./i18n";
import { useI18n } from "vue-i18n";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { mdi } from "vuetify/iconsets/mdi";
import { customSVGs } from "./custom-svg-icons";

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#2999FD",
    "primary-darken-1": "#3700B3",
    secondary: "#5A5858",
    "secondary-darken-1": "#018786",
    error: "#FF3F3F",
    info: "#2999FD",
    success: "#18B94F",
    warning: "#FDA829",
  },
};

const vuetify = createVuetify({
  components: {
    ...components,
    ...labs,
  },
  directives,
  labs,
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
});

export default vuetify;
