import { api } from "@/services/api.service";

const accountEndpoint = {
  REFRESH_TOKEN: "/api/account/refresh",
  LOGIN: "/api/account/login",
  FORGOT_PASSWORD: "/api/account/forgot-password",
  CHANGE_PASSWORD: "/api/account/reset-password",
  REGISTER: "/api/account/register",
  CONFIRM_REGISTRATION: "/api/account/emailconfirm",
};

function getAccountService() {
  const login = async (user) => {
    return (await api.post(accountEndpoint.LOGIN, user)).data;
  };

  const refreshToken = async (data) => {
    return (await api.post(accountEndpoint.REFRESH_TOKEN, data)).data;
  };

  const sendResetLink = async (email) => {
    return (await api.post(accountEndpoint.FORGOT_PASSWORD, { email })).data;
  };

  const changePassword = async (data) => {
    return (await api.post(accountEndpoint.CHANGE_PASSWORD, data)).data;
  };

  const register = async (data) => {
    return (await api.post(accountEndpoint.REGISTER, data)).data;
  };

  const confirmRegistration = async (data) => {
    return (await api.post(accountEndpoint.CONFIRM_REGISTRATION, data)).data;
  };

  return {
    login,
    refreshToken,
    sendResetLink,
    changePassword,
    register,
    confirmRegistration,
  };
}

export const accountService = getAccountService();
