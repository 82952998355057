<template>
  <SearchableHeader placeholder="Введіть назву акаунту Інстаграм, щоб шукати..." hide-filter />
  <div v-if="true" class="sales-funnel">
    <draggable
      v-if="!loading"
      :list="funnelItems"
      emptyInsertThreshold="500"
      group="statuses"
      draggable=".funnel-column-draggable"
      class="board-container"
      @change="onStatusOrderChanged"
      :force-fallback="true"
      :scroll-sensitivity="200"
      item-key="status.id"
    >
      <template #item="{ element }">
        <CreateNewStatus v-if="element.status.id === -1" @created="addStatus" />

        <v-card v-else class="mx-4 my-2 board-card" :class="{ 'funnel-column-draggable': element.status.id > 1 }">
          <v-form :ref="'form' + element.status.id">
            <v-card-title id="board-card-title" class="d-flex align-center px-4 py-2 gap8">
              <ThreadStatusSlim :status="element.status" />
              <v-text-field
                v-on:keydown.enter.prevent
                variant="solo"
                :value="element.status.title"
                density="compact"
                @update:modelValue="(newVal) => onTitleUpdated(newVal, element.status.title, element.status)"
                hide-details
              />
              <ColumnContextMenu
                v-if="element.status.id != 1"
                :threadStatus="element.status"
                @removed="onStatusRemoved"
                :threadsExists="element.threads.length > 0"
              />
            </v-card-title>
          </v-form>

          <draggable class="board-card-items" group="threads" :list="element.threads" @change="onThreadStatusChanged(element, $event)" item-key="id">
            <template #item="{ element }">
              <ColumnItem :thread="element" />
            </template>
          </draggable>

          <v-btn
            class="more-button"
            v-if="funnelItemPaging[element.status.id].moreItems && !funnelItemPaging[element.status.id].loading"
            color="primary"
            @click="getNextBatch(element)"
            variant="tonal"
            rounded
          >
            {{ $t("Common.Label.ShowMore") }}
          </v-btn>

          <v-progress-linear v-if="funnelItemPaging[element.status.id].loading" indeterminate color="cyan"></v-progress-linear>
        </v-card>
      </template>
    </draggable>

    <div v-else class="d-flex gap24 pa-4">
      <v-skeleton-loader
        type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
      />
      <v-skeleton-loader
        type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
      />
      <v-skeleton-loader
        type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
      />
    </div>
  </div>

  <HorizontalSalesFunnel v-else :funnel="funnelItems" class="sales-funnel"></HorizontalSalesFunnel>
</template>

<script setup>
import draggable from "vuedraggable";
import mitt from "@/services/mitt";
import SearchableHeader from "@/components/layouts/SearchableHeader.vue";
import { funnelService } from "../services/funnel.service";
import CreateNewStatus from "../components/funnel/CreateNewStatus.vue";
import ColumnContextMenu from "../components/funnel/ColumnContextMenu.vue";
import ColumnItem from "../components/funnel/ColumnItem.vue";
import { inboxService } from "../services/inbox.service";
import { onMounted, onBeforeUnmount, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import HorizontalSalesFunnel from "../components/funnel/HorizontalSalesFunnel.vue";
import ThreadStatusSlim from "../components/funnel/ThreadStatusSlim.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();

const loading = ref(true);

const funnelItems = ref([]);
const funnelItemsBatchSize = 10;
const funnelItemPaging = ref({});
const options = reactive({ search: "" });

onMounted(async () => {
  mitt.on("search", onSearchChanged);

  options.search = (route.query.search || "").toString();
  if (options.search) {
    mitt.emit("set-search", options.search);
  }
  await loadFunnel();
});

const loadFunnel = async () => {
  router.replace({
    path: route.path,
    query: options.search ? { search: options.search } : {},
  });

  loading.value = true;
  try {
    funnelItems.value = await funnelService.getFunnel(options.search);
    funnelItems.value.push({ status: { id: -1 }, threads: [] });
    funnelItems.value.forEach((x) => {
      funnelItemPaging.value[x.status.id] = {
        page: 1,
        loading: false,
        moreItems: x.threads.length && x.threads.length % funnelItemsBatchSize == 0,
      };
    });
  } catch {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
};

const onThreadStatusChanged = async (funnelItem, { added }) => {
  if (!added) return;

  const patchItem = { path: "StatusId", op: "replace", value: funnelItem.status.id };
  await inboxService.updateThread(added.element.id, [patchItem]);
};

const onStatusOrderChanged = async ({ moved }) => {
  try {
    const newOrder = moved.newIndex + 1;
    const incrementValue = newOrder > moved.element.status.order ? -1 : 1;

    var maxOrder = Math.max(newOrder, moved.element.status.order);
    var minOrder = Math.min(newOrder, moved.element.status.order);
    funnelItems.value
      .filter((x) => x.status.order >= minOrder && x.status.order <= maxOrder)
      .forEach((element) => {
        element.status.order += incrementValue;
      });

    moved.element.status.order = newOrder;
    await funnelService.changeStatusOrder(moved.element.status.id, newOrder);
  } catch {
    store.dispatch("addError");
  }
};

const addStatus = (status) => {
  funnelItemPaging.value[status.id] = {
    page: 1,
    loading: false,
    moreItems: false,
  };
  funnelItems.value.splice(funnelItems.value.length - 1, 0, { status: status, threads: [] });
};

const onStatusRemoved = (status) => {
  funnelItems.value.splice(
    funnelItems.value.findIndex((x) => x.status === status),
    1
  );

  delete funnelItemPaging.value[status.id];
};

var statusTitleUpdates = {};

const onTitleUpdated = async (newVal, oldVal, status) => {
  status.title = newVal;
  if (statusTitleUpdates[status.id]?.loading) return;

  if (statusTitleUpdates[status.id]?.timeout > -1) clearTimeout(statusTitleUpdates[status.id].timeout);

  statusTitleUpdates[status.id] = statusTitleUpdates[status.id] || {};
  statusTitleUpdates[status.id].timeout = setTimeout(async () => {
    statusTitleUpdates[status.id].loading = true;
    if (!newVal.trim()) {
      status.title = oldVal;
      return;
    }

    try {
      await funnelService.update(status.id, [{ op: "replace", path: "/title", value: newVal }]);
    } catch {
      store.dispatch("addError");
    } finally {
      clearTimeout(statusTitleUpdates[status.id].timeout);
      statusTitleUpdates[status.id].loading = false;
    }
  }, 1000);
};

const getNextBatch = async (funnelItem) => {
  const funnelPagingElement = funnelItemPaging.value[funnelItem.status.id];
  if (!funnelPagingElement) return;

  try {
    funnelPagingElement.loading = true;
    const nextBatch = await funnelService.getColumnFunnelBatch(funnelItem.status.id, funnelPagingElement.page + 1, options.search);
    funnelItem.threads.push(...nextBatch);

    funnelPagingElement.moreItems = nextBatch.length == funnelItemsBatchSize;

    funnelPagingElement.page++;
  } catch {
    store.dispatch("addError");
  } finally {
    funnelPagingElement.loading = false;
  }
};

const onSearchChanged = async (val) => {
  options.search = val;
  await loadFunnel();
};

onBeforeUnmount(() => {
  mitt.off("search", onSearchChanged);
});
</script>

<style scoped>
.board-card {
  display: inline-block;
  min-width: 296px;
  max-width: 296px;
  cursor: pointer;

  position: relative;
  overflow: initial;
}
.board-card-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  height: calc(100vh - 140px);
  padding: 8px;
}
.sales-funnel {
  padding: 24px;
  width: calc(100vw - 110px);
  overflow: auto;
}

@media only screen and (max-width: 1279px) {
  .sales-funnel {
    width: 100vw;
    padding: 16px;
  }
}
.board-container {
  /* overflow-x: auto; */
  display: flex;
}

#board-card-title .v-input__slot {
  box-shadow: none;
}
#board-card-title .v-input--is-focused .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

#board-card-title {
  align-items: baseline;
  display: flex;
  flex-wrap: nowrap;
  border-radius: 10px 10px 0 0;
  background: var(--Light-Blue, #f4f6fa);
}

.board-card .v-btn {
  padding: 16px 24px;
  height: 48px;
}

.more-button {
  position: absolute;
  bottom: -48px;
  right: 50%;
  transform: translate(50%, -50%);
  background: var(--Light-Blue, #f4f6fa);
}
</style>
