<template>
  <div class="flex-column border-bottom">
    <v-list-item link :key="comment.id" :class="{ unread: !comment.isReplied }" :title="comment.customer.username" :subtitle="comment.text" class="thread-item">
      <template #prepend>
        <div class="d-flex justify-center align-center" style="gap: 16px">
          <PostViewer class="pa-1" :mediaId="comment.media.id" :src="comment.media.mediaUrl" :height="50" :width="50" />

          <v-avatar size="48" v-if="!conversation">
            <v-img v-if="comment.customer.profilePicture" :src="comment.customer.profilePicture"></v-img>
            <v-img v-else src="../../../../assets/no-picture-account.jpg"></v-img>
          </v-avatar>
        </div>
      </template>

      <template #append>
        <Tooltipped :text="moment.utc(comment.timestamp).local().format('LLLL')">
          <span class="time"> {{ formatedTimestamp }}</span>
        </Tooltipped>

        <v-menu v-if="!conversation" class="thread-item-menu blured">
          <template #activator="{ props }">
            <v-btn v-bind="props" icon="custom:dotsVerticalIcon" variant="text"></v-btn>
          </template>
          <v-list>
            <v-list-item link @click="toggleReplied">
              <v-list-item-title>
                <v-icon v-if="comment.isReplied">custom:deliveredIcon</v-icon>
                <v-icon v-else>custom:readIcon</v-icon>
                {{ comment.isReplied ? $t("Inbox.Label.MarkAsUnseen") : $t("Inbox.Label.MarkAsSeen") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item link @click="replyTo(commentReply)" density="compact">
              <v-list-item-title>
                <v-icon>custom:commentIcon</v-icon>
                Відповісти у коментар
              </v-list-item-title>
            </v-list-item>

            <Tooltipped :text="getDirectReplyTooltip">
              <v-list-item link @click="replyTo(directReply)" :disabled="isCommentReplyToDirectDisabled" density="compact">
                <v-list-item-title class="comment-send-icon">
                  <v-icon>custom:sendIcon</v-icon>
                  Відповісти у дірект
                </v-list-item-title>
              </v-list-item>
            </Tooltipped>
          </v-list>
        </v-menu>
      </template>
    </v-list-item>
    <div v-if="comment.replies && comment.replies.length">
      <v-btn x-small variant="text" color="#8e8e8e" @click="toggleCommentReplyShown" class="replies-label">
        <v-icon density="compact">mdi-eye{{ commentRepliesShown.indexOf(comment.id) == -1 ? "" : "-off" }}-outline</v-icon>
        {{ getCommentRepliesText }}</v-btn
      >
      <div v-if="commentRepliesShown.indexOf(comment.id) !== -1" class="px-6 py-2" style="background: var(--white, #fff)">
        <template v-for="reply in comment.replies" :key="reply.id">
          <v-list-item lines="two" :subtitle="reply.text">
            <template #prepend>
              <v-avatar size="36">
                <v-img src="../../../../assets/no-picture-account.jpg"></v-img>
              </v-avatar>
            </template>

            <template #title>
              <v-list-item-title>
                <div class="d-flex gap8">
                  <span> @{{ account.username }}</span>
                  <v-chip size="small" color="#2999FD" style="background-color: #f4f6fa">
                    <span style="color: #2999fd">{{ reply.commentReplyTarget.toUpperCase() }}</span>
                  </v-chip>
                  <Tooltipped class="" :text="moment.utc(reply.createdOn).local().format('LLLL')">
                    <span class="time">{{ formatDate(reply.createdOn) }}</span>
                  </Tooltipped>
                </div>
              </v-list-item-title>
            </template>
          </v-list-item>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ReplyTo } from "../../models/comment";
import PostViewer from "./PostViewer.vue";
import Tooltipped from "@/components/Tooltiped.vue";
import moment from "moment";
import { inboxService } from "../../services/inbox.service";
import { defineProps, defineEmits, reactive, ref, computed } from "vue";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";

const props = defineProps({
  comment: {},
  conversation: { type: Boolean, default: false },
});
const emits = defineEmits(["replying"]);
const store = useStore();

const account = ref(null);

const commentRepliesShown = reactive([]);

const directReply = ReplyTo.Direct;
const commentReply = ReplyTo.Comment;

const formatedTimestamp = computed(() => formatDate(props.comment.timestamp));

const formatDate = (date) => {
  return moment.utc(date).local().isSame(moment(), "d")
    ? moment.utc(date).local().format("LT")
    : moment.utc(date).local().isSame(moment(), "year")
    ? moment.utc(date).local().format("DD.MM[\n]HH:mm")
    : moment.utc(date).local().format("DD.MM.YY[\n]HH:mm");
};

const isCommentReplyToDirectDisabled = computed(
  () => moment.utc(props.comment.timestamp).local().isBefore(moment().subtract(7, "d")) || props.comment.replies.some((x) => x.commentReplyTarget == "Direct")
);

const getDirectReplyTooltip = computed(() => {
  return moment.utc(props.comment.timestamp).local().isBefore(moment().subtract(7, "d"))
    ? i18n.global.t("Inbox.Comments.Label.ReplyAvailabilityDuration")
    : props.comment.replies.some((x) => x.commentReplyTarget == "Direct")
    ? i18n.global.t("Inbox.Comments.Label.CanNotReplyToDirectTwice")
    : "";
});

const getCommentRepliesText = computed(() => {
  return commentRepliesShown.indexOf(props.comment.id) !== -1
    ? i18n.global.t("Inbox.Comments.Button.HideReplies")
    : i18n.global.t("Inbox.Comments.Button.ShowReplies", { count: props.comment.replies.length });
});

const toggleReplied = async () => {
  try {
    await store.dispatch("updateCommentReplied", props.comment);
  } catch {
    store.dispatch("addError");
  }
};

const toggleCommentReplyShown = async () => {
  if (!account.value) account.value = await inboxService.getInstagramAccount();
  const index = commentRepliesShown.indexOf(props.comment.id);
  if (index !== -1) {
    commentRepliesShown.splice(index, 1);
  } else {
    commentRepliesShown.push(props.comment.id);
  }
};

const replyTo = (replyTo) => {
  if (replyTo == ReplyTo.Direct && isCommentReplyToDirectDisabled.value) return;
  emits("replying", props.comment, replyTo);
};
</script>

<style>
.unread {
  background-color: rgba(250, 251, 252, 1);
}

.thread-item {
  display: flex;
  gap: 8px;

  align-content: space-evenly;
}

.thread-item.v-list-item {
  padding-inline-start: 8px !important;
  padding-inline-end: 8px !important;
  padding: 12px 8px;
}

.thread-item .v-list-item__append {
  margin-left: auto;
}

.thread-item .v-list-item__content {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.thread-item.v-list-item {
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding: 12px 0;
}

.thread-item .v-list-item-title {
  color: var(--main-secondary, #5a5858);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
  text-transform: lowercase;
}

.thread-item .v-list-item-subtitle {
  display: flex;
  gap: 4px;
  align-items: center;

  /* Body 1 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.thread-item .v-list-item-subtitle p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thread-item.unread .v-list-item-subtitle {
  color: var(--main-primary, #2999fd);
}

.time {
  color: var(--main-secondary, #5a5858);
  text-align: right;
  opacity: 0.5;

  /* Body 1 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.thread-item .v-list-item__append {
  display: flex;
  gap: 16px;

  justify-content: center;
  align-content: center;
}

.replies-label {
  width: 100%;
  justify-content: start;

  color: var(--main-secondary, #5a5858);

  /* btn small */
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .media-preview {
    display: block;
  }
}

.comment-send-icon svg {
  width: 24px !important;
  height: 24px !important;
}
</style>
