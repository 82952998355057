<template>
  <div class="d-flex flex-column gap8">
    <v-alert v-if="sendOverdue" border="start" type="warning" color="rgba(200, 200, 200, 0.1)" border-color="amber-darken-3">
      <template #prepend>
        <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
          <v-icon color="amber-darken-3" class="warning">custom:warningIcon</v-icon>
        </v-btn>
      </template>
      <div class="d-flex flex-column gap8">
        <h4 style="color: #5a5858">{{ $t("Inbox.Label.SendOverdueHeader") }}</h4>
        <span style="color: #5a5858" class="op07">
          {{ $t("Inbox.Label.SendOverdueDesc") }}
        </span>
      </div>
    </v-alert>
    <template v-else>
      <v-form ref="form" v-model="isFormValid" id="form" class="d-flex">
        <v-textarea
          v-model="message"
          :disabled="sending || sendOverdue"
          variant="outlined"
          rows="3"
          :placeholder="$t('Inbox.Label.WriteText')"
          density="compact"
          class="mb-1"
          color="grey"
          counter
          :counter-value="(x) => `${x.length}/999`"
          style="max-height: 150px; overflow-y: auto"
          @keyup.enter.exact="sendMessage"
          :rules="[rules.maxLength]"
          hide-details
        >
          <template #append>
            <v-icon class="send-icon op07" :disabled="sending || sendOverdue" color="#363636" @click="sendMessage"> {{ sendIcon }} </v-icon>
          </template>
        </v-textarea>
      </v-form>
      <div class="d-flex gap8">
        <SendAttachmentsModal :threadId="thread.id" :disabled="sendOverdue" @media-sent="onAttachmentSent" />
        <Tooltipped :text="$t('Inbox.Label.Emoji')">
          <v-menu :close-on-content-click="false">
            <template #activator="{ props }">
              <v-icon v-bind="props" color="#363636" :disabled="sendOverdue" size="24">custom:emojiIcon</v-icon>
            </template>
            <EmojiPicker :native="true" @select="addEmoji" />
          </v-menu>
        </Tooltipped>
        <MessageTemplateSelector @template-selected="insertTemplate" :disabled="sendOverdue" :target="directTarget" />
      </div>
    </template>
  </div>
</template>

<script setup>
import { inboxService } from "../services/inbox.service";
import { defineProps, onMounted, ref, defineEmits } from "vue";
import SendAttachmentsModal from "../components/SendAttachmentsModal.vue";
import Tooltipped from "@/components/Tooltiped.vue";
import EmojiPicker from "vue3-emoji-picker";
import { requiredRule } from "@/services/field.rules";
import moment from "moment";
import MessageTemplateSelector from "./MessageTemplateSelector.vue";
import { ReplyTo } from "../models/comment";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";

const props = defineProps(["thread", "lastMessageTime"]);
const emits = defineEmits(["message-sent"]);
const store = useStore();

const directTarget = ReplyTo.Direct;
const message = ref("");
const sendIcon = ref("custom:sendIcon");
const sending = ref(false);
const isFormValid = ref(true);
const sendOverdue = ref(false);
const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  maxLength: (value) => !!(!value || value.length < 1000) || i18n.global.t("Inbox.Label.MaxTextLength"),
};

onMounted(() => {
  sendOverdue.value = props.lastMessageTime ? moment().isAfter(moment.utc(props.lastMessageTime).add(7, "days").local()) : false;
});

// todo: add 1000 charachters limit
const sendMessage = async () => {
  message.value = message.value.trim();
  if (!message.value) {
    return;
  }

  try {
    sending.value = true;
    sendIcon.value = "mdi-send-clock";

    const messageId = await inboxService.sendMessage(props.thread.id, { text: message.value });

    const inboxMessage = {
      id: messageId,
      message: message.value,
      created_time: new Date().toUTCString(),
      from: {
        id: props.thread.accountId,
      },
    };
    emits("message-sent", props.thread, inboxMessage);
    sendIcon.value = "mdi-send-check";
  } catch {
    store.dispatch("addError");
  } finally {
    clearMessage();

    setTimeout(() => {
      sendIcon.value = "custom:sendIcon";
    }, 1000);
    sending.value = false;
  }
};

const clearMessage = () => {
  message.value = "";
};

const addEmoji = (value) => {
  message.value += value.i;
};

const insertTemplate = (template) => {
  message.value = template.text;
};

const onAttachmentSent = (mediaMessage) => {
  const newMessage = {
    from: {
      id: props.thread.accountId,
    },
    attachments: {
      data: [],
    },
  };

  if (mediaMessage.isVideo) {
    newMessage.attachments.data.push({
      video_data: {
        preview_url: mediaMessage.url,
      },
    });
  } else {
    newMessage.attachments.data.push({
      image_data: {
        preview_url: mediaMessage.url,
      },
    });
  }

  emits("message-sent", props.thread, newMessage);
};
</script>

<style>
#form .v-input__append {
  align-items: center;
}

svg.send-icon {
  height: 32px !important;
  width: 32px !important;
  opacity: 1 !important;
}
svg.send-icon path {
  stroke: var(--main-primary-color);
}
</style>
