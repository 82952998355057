<template>
  <v-card v-if="customer" class="px-4 py-6">
    <div class="h2 d-flex justify-space-between mb-2 align-center" v-if="state === viewState">
      <span>{{ $t("Inbox.Label.CustomerOrders") }}</span>

      <Tooltip :text="$t('Inbox.Label.CustomerCreateOrder')">
        <v-btn icon @click="setState(2)" color="primary">
          <v-icon>custom:plusIcon</v-icon>
        </v-btn>
      </Tooltip>
    </div>

    <OrderList :customerId="customer.id" v-if="state === viewState" @set-state="setState" />
    <CreateOrderSlim v-else :customerId="customer.id" @set-state="setState" :thread-id="threadId" />
  </v-card>
  <v-skeleton-loader v-else type="card" loading elevation="2"> </v-skeleton-loader>
</template>

<script setup>
import { defineProps, ref } from "vue";
import OrderList from "./OrdersList.vue";
import CreateOrderSlim from "./CreateSlimOrder.vue";
import Tooltip from "@/components/Tooltiped.vue";

defineProps({
  customer: {},
  threadId: {},
});

const viewState = 1;
const state = ref(viewState);

const setState = (newState) => {
  state.value = newState;
};
</script>
