<template>
  <div class="d-flex flex-column" :style="{ 'align-items': sent ? 'flex-end' : 'flex-start' }">
    <span>{{ additionalText }}</span>
    <v-hover v-slot="{ hover }">
      <v-card :elevation="hover ? 12 : 2" height="150px" width="100px" link @click="showOverlay">
        <VideoAttachment v-if="isVideo" :data="{ url: assetUrl }" :preview="true" />
        <v-img v-else :src="assetUrl" height="150px" width="100px">
          <template v-slot:placeholder>
            <v-skeleton-loader type="image" />
          </template>
        </v-img>
        <v-fade-transition>
          <v-overlay v-if="hover" absolute>
            <v-btn icon>
              <v-icon>{{ isVideo ? "mdi-play-outline" : "custom:eyeOutlineIcon" }}</v-icon>
            </v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </v-hover>
    <v-overlay v-model="overlay" color="black" opacity="0.8" close-on-content-click>
      <template v-if="isVideo">
        <div class="video-overlay">
          <div class="video-container">
            <VideoAttachment v-if="isVideo" :data="{ url: assetUrl }" preview />
            <div class="close-btn">
              <Tooltipped :text="$t('Common.Button.Close')">
                <v-btn icon @click="closeOverlay">
                  <v-icon>custom:closeIcon</v-icon>
                </v-btn>
              </Tooltipped>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <v-img :src="assetUrl" height="500px" max-width="500px">
          <template v-slot:placeholder>
            <v-progress-circular indeterminate color="grey" />
          </template>
        </v-img>
      </template>
    </v-overlay>
    <div>
      <TextMessage v-if="message.message" :message="message" :sent="sent" class="my-2" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onBeforeUnmount, onMounted, ref } from "vue";
import Tooltipped from "@/components/Tooltiped.vue";
import VideoAttachment from "./VideoAttachment.vue";
import TextMessage from "./TextMessage.vue";
import { inboxService } from "../services/inbox.service";
import i18n from "@/plugins/i18n";
const props = defineProps(["message", "sent"]);

const overlay = ref(false);
const isVideo = ref(false);
const additionalText = ref("");

onMounted(async () => {
  const textForMention = props.sent ? "Inbox.Label.YouMentionStory" : "Inbox.Label.YouMentionedInStory";
  const textForStoryReply = props.sent ? "Inbox.Label.YouReplyStory" : "Inbox.Label.YouRepliededInStory";
  additionalText.value = i18n.global.t(isMention.value ? textForMention : textForStoryReply).toString();

  try {
    isVideo.value = !(await inboxService.isAssetImage(encodeURIComponent(assetUrl.value)));
  } catch {
    isVideo.value = true;
  }

  window.addEventListener("keydown", onKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", onKeydown);
});

const onKeydown = (e) => {
  if (e.key == "Escape") {
    overlay.value = false;
  }
};

const isMention = computed(() => !!props.message.story?.mention);
// const reaction = computed(() => !!props.message.reactions?.data);
const assetUrl = computed(() => (isMention.value ? props.message.story.mention.link : props.message.story.reply_to.link));

const showOverlay = () => {
  overlay.value = true;
};

const closeOverlay = () => {
  overlay.value = false;
};
</script>

<style scoped>
.video-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.video-container {
  position: relative;
  gap: 16px;
  display: flex;
}

.close-btn {
  align-self: flex-start;
}

@media (max-width: 600px) {
  .video-container {
    flex-direction: column-reverse;
  }

  .close-btn {
    align-self: flex-end;
  }
}
</style>
