import { h } from "vue";
import calendarIcon from "@/assets/custom-icons/CalendarIcon.vue";
import eyeIcon from "@/assets/custom-icons/EyeIcon.vue";
import eyeOutlineIcon from "@/assets/custom-icons/EyeOutline.vue";
import eyeSlashIcon from "@/assets/custom-icons/EyeSlashIcon.vue";
import inboxIcon from "@/assets/custom-icons/InboxIcon.vue";
import ordersIcon from "@/assets/custom-icons/OrdersIcon.vue";
import funnelIcon from "@/assets/custom-icons/FunnelIcon.vue";
import warehouseIcon from "@/assets/custom-icons/WarehouseIcon.vue";
import settingsIcon from "@/assets/custom-icons/SettingsIcon";
import editIcon from "@/assets/custom-icons/EditIcon";
import trashIcon from "@/assets/custom-icons/TrashIcon";
import closeIcon from "@/assets/custom-icons/Close";
import closeOutlineIcon from "@/assets/custom-icons/CloseIcon";
import plusIcon from "@/assets/custom-icons/PlusIcon";
import uploadIcon from "@/assets/custom-icons/Upload";
import chevronDownIcon from "@/assets/custom-icons/ChevronDown";
import searchIcon from "@/assets/custom-icons/Search";
import filterIcon from "@/assets/custom-icons/Filter";
import galleryIcon from "@/assets/custom-icons/Gallery";
import emojiIcon from "@/assets/custom-icons/Emoji";
import savedIcon from "@/assets/custom-icons/Saved";
import warningIcon from "@/assets/custom-icons/WarningIcon";
import messageIcon from "@/assets/custom-icons/Message";
import readIcon from "@/assets/custom-icons/Read";
import deliveredIcon from "@/assets/custom-icons/Delivered";
import arrowLeftIcon from "@/assets/custom-icons/ArrowLeft";
import infoIcon from "@/assets/custom-icons/Info";
import menuIcon from "@/assets/custom-icons/Menu";
import dotsVerticalIcon from "@/assets/custom-icons/DotsVertical";
import userIcon from "@/assets/custom-icons/User";
import usersIcon from "@/assets/custom-icons/Users";
import plusOutlinedIcon from "@/assets/custom-icons/PlusOutlined";
import rotateRightIcon from "@/assets/custom-icons/RotateRight";
import rotateLeftIcon from "@/assets/custom-icons/RotateLeft";
import commentIcon from "@/assets/custom-icons/Comment";
import sendIcon from "@/assets/custom-icons/Send";
import maximizeIcon from "@/assets/custom-icons/MaximizeIcon";
import minimizeIcon from "@/assets/custom-icons/MinimizeIcon";
import logoutIcon from "@/assets/custom-icons/Logout";
import arrowRightIcon from "@/assets/custom-icons/ArrowRight";
import minusIcon from "@/assets/custom-icons/Minus";
import headerMenuIcon from "@/assets/custom-icons/HeaderMenu";
import checkIcon from "@/assets/custom-icons/CheckIcon";
import sliderLeftIcon from "@/assets/custom-icons/SliderLeft";
import sliderRightIcon from "@/assets/custom-icons/SliderRight";
import emailIcon from "@/assets/custom-icons/Email";
import billingIcon from "@/assets/custom-icons/Billing";

const customSvgNameToComponent = {
  calendarIcon,
  eyeIcon,
  eyeSlashIcon,
  eyeOutlineIcon,
  inboxIcon,
  ordersIcon,
  funnelIcon,
  warehouseIcon,
  settingsIcon,
  editIcon,
  trashIcon,
  closeIcon,
  plusIcon,
  closeOutlineIcon,
  uploadIcon,
  chevronDownIcon,
  searchIcon,
  filterIcon,
  galleryIcon,
  emojiIcon,
  savedIcon,
  warningIcon,
  messageIcon,
  readIcon,
  deliveredIcon,
  arrowLeftIcon,
  infoIcon,
  menuIcon,
  dotsVerticalIcon,
  userIcon,
  usersIcon,
  plusOutlinedIcon,
  rotateRightIcon,
  rotateLeftIcon,
  commentIcon,
  sendIcon,
  maximizeIcon,
  minimizeIcon,
  logoutIcon,
  arrowRightIcon,
  minusIcon,
  headerMenuIcon,
  checkIcon,
  sliderLeftIcon,
  sliderRightIcon,
  emailIcon,
  billingIcon,
};

const customSVGs = {
  component: (props) => h(customSvgNameToComponent[props.icon]),
};

export { customSVGs };
