<template>
  <v-dialog max-width="825px">
    <template #activator="{ props: dialog }">
      <v-hover>
        <template #default="{ isHovering, props }">
          <v-card v-bind="props" :height="height" :width="width" link>
            <v-img :src="src" :aspect-ratio="width / height" cover>
              <template v-slot:placeholder>
                <v-skeleton-loader type="image" />
              </template>
            </v-img>
            <v-overlay v-bind="dialog" @click="showOverlay" :model-value="isHovering" contained class="align-center justify-center">
              <v-icon color="white" class="op07">custom:eyeOutlineIcon</v-icon>
            </v-overlay>
          </v-card>
        </template>
      </v-hover>
    </template>

    <v-card v-if="media" ref="postContainerRef" class="d-flex">
      <div class="d-flex post-container" style="gap: 24px">
        <v-img :src="media.media_url" width="400px" height="480px" style="border-radius: 16px" cover />
        <div class="d-flex flex-column justify-space-between comments-section" style="gap: 16px">
          <div class="d-flex flex-column gap8">
            <span class="font-weight-bold mr-2">{{ media.username }}</span> <span class="caption">{{ media.caption }}</span>

            <div>
              <Tooltipped :text="moment.utc(media.timestamp).local().format('LLLL')" top>
                <span class="time">{{ moment.utc(media.timestamp).local().fromNow(true) }}</span>
              </Tooltipped>
            </div>
            <v-divider />
          </div>
          <InstagramMediaCommentList :comments="media.comments.data" @replying="reply" />

          <div class="d-flex align-end gap8">
            <Tooltipped :text="$t('Inbox.Label.Emoji')">
              <v-menu :close-on-content-click="false" class="emoji-menu">
                <template #activator="{ props }">
                  <v-btn icon @click="postReply" v-bind="props" :disabled="sending" variant="plain">
                    <v-icon size="x-large">custom:emojiIcon</v-icon>
                  </v-btn>
                </template>
                <EmojiPicker native @select="addEmoji" :offset="100" />
              </v-menu>
            </Tooltipped>
            <v-textarea ref="replyInput" v-model="replyText" variant="outlined" rows="1" hide-details color="#2999FD" auto-grow max-rows="5"></v-textarea>
            <Tooltipped :text="$t('Inbox.Comments.Button.Post')">
              <v-btn icon @click="postReply" :disabled="sending" variant="plain">
                <v-icon size="x-large" class="send-icon"> {{ sendIcon }} </v-icon>
              </v-btn>
            </Tooltipped>
          </div>
        </div>
      </div>
    </v-card>
    <v-progress-circular v-else indeterminate class="d-flex align-self-center" color="#2999FD" size="64" bg-color="white"></v-progress-circular>
  </v-dialog>
</template>

<script setup>
import Tooltipped from "@/components/Tooltiped.vue";
import { inboxCommentsService } from "../../services/inbox-comments.service";
import EmojiPicker from "vue3-emoji-picker";
import InstagramMediaCommentList from "./InstagramMediaCommentList.vue";
import { ReplyTo } from "../../models/comment";
import { ref, defineProps } from "vue";
import { useStore } from "vuex";
import moment from "moment";

const props = defineProps(["src", "width", "height", "mediaId"]);
const store = useStore();

const media = ref(null);
const replyText = ref("");
const sendIcon = ref("custom:sendIcon");
const sending = ref(false);
const selectedComment = ref(null);

const showOverlay = async () => {
  try {
    media.value = await inboxCommentsService.getMediaData(props.mediaId);
  } catch {
    store.dispatch("addError");
  }
};

const replyInput = ref(null);
const postContainerRef = ref(null);
const reply = (comment) => {
  selectedComment.value = comment;
  replyText.value = `@${comment.from.username} `;

  replyInput.value.focus();
  var container = postContainerRef.value.sc;
  if (container) container.scroll({ top: container.scrollTop, left: 0, behavior: "smooth" });
};

const addEmoji = (value) => {
  replyText.value += value.i;
};

const postReply = async () => {
  if (!selectedComment.value) {
    return;
  }

  sending.value = true;
  try {
    sendIcon.value = "mdi-send-clock";
    const createdCommentId = await inboxCommentsService.replyComment(selectedComment.value.id, replyText.value, ReplyTo.Comment);

    var parentComment = selectedComment.value.parent_id
      ? media.value?.comments.data.find((x) => x.id === selectedComment.value.parent_id)
      : selectedComment.value;

    if (!parentComment) return;

    if (!parentComment.replies?.data) {
      parentComment.replies = {
        data: [],
      };
    }

    parentComment.replies.data.unshift({
      id: createdCommentId,
      text: replyText.value,
      like_count: 0,
      from: {
        id: "",
        username: media.value?.username || "",
      },
      replies: { data: [] },
      timestamp: new Date(),
    });

    media.value.comments.data;
    sendIcon.value = "mdi-send-check";
    selectedComment.value = null;
    replyText.value = "";
  } catch {
    store.dispatch("addError");
  } finally {
    sending.value = false;
    sendIcon.value = "custom:sendIcon";
  }
};
</script>

<style scoped>
.comments-section {
  max-height: 480px;
  overflow: auto;
}

@media (max-width: 870px) {
  .post-container {
    flex-direction: column;
  }
}
</style>
