<template>
  <v-dialog v-model="exportDialog" max-width="550px" :persistent="loading">
    <template #activator="{ props }">
      <span v-bind="props">
        <slot></slot>
      </span>
    </template>

    <v-card>
      <div>
        <div class="d-flex justify-space-between align-center">
          <div>
            <span class="h4"> Експорт замовлень в форматі Excel </span>
          </div>

          <v-btn icon variant="plain" size="x-small" @click="exportDialog = false">
            <v-icon>custom:closeIcon</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="d-flex flex-column gap16 mt-1">
        <div class="body1">Для експорту замовлень введіть критерії пошуку.</div>

        <v-form ref="form" validate-on="lazy submit">
          <div class="export-filters">
            <div>
              <label>Починаючи з</label>
              <v-text-field v-model="dateFrom" type="date" variant="outlined" style="width: 150px" :error-messages="errorMessage" density="compact" />
            </div>

            <div>
              <label>Закінчуючи по</label>
              <v-text-field v-model="dateTo" type="date" variant="outlined" style="width: 150px" density="compact" />
            </div>
          </div>
        </v-form>
      </div>

      <div class="d-flex justify-center">
        <v-btn color="primary" rounded @click="exportOrders" :loading="loading" :disabled="loading">Експортувати</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { orderService } from "../services/order.service";

const store = useStore();
const loading = ref(false);

var exportDialog = ref(false);
const dateFrom = ref();
const dateTo = ref();

const form = ref(null);

const rules = {
  dateFrom: (value) => {
    console.log(+new Date(value) <= +new Date(dateTo.value));
    return +new Date(value) <= +new Date(dateTo.value) || "Перевірте дати";
  },
};

const errorMessage = computed(() => {
  return !dateFrom.value || !dateTo.value || +new Date(dateFrom.value) <= +new Date(dateTo.value) ? [] : ["Перевірте дати"];
});
const exportOrders = async () => {
  if (!(await form.value.validate()).valid) {
    return;
  }

  try {
    loading.value = true;
    const blob = await orderService.exportOrders(dateFrom.value, dateTo.value);

    var a = document.createElement("a");
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `Експорт_${dateFrom.value}_${dateTo.value}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
    exportDialog.value = false;

    store.dispatch("addNotification", {
      icon: "custom:messageIcon",
      text: "Перевірте завантаження!",
      color: "primary",
      duration: 3500,
    });
  } catch {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
};
</script>

<style>
.export-filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media (max-width: 400px) {
  .export-filters {
    flex-direction: column;
    gap: 0px;
  }
}
</style>
