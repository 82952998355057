import i18n from "@/plugins/i18n";
const mutation = {
  NOTIFICATION_DISMISSED: "NOTIFICATION_DISMISSED",
  NOTIFICATION_ADDED: "NOTIFICATION_ADDED",
  MESSAGE_DISMISSED: "MESSAGE_DISMISSED",
  MESSAGE_ADDED: "MESSAGE_ADDED",
};

const state = { notifications: [], threads: [] };
const getters = {
  notifications: (state) => state.notifications.map((x) => x.obj),
  threads: (state) => state.threads.map((x) => x.obj),
};
const actions = {
  addError({ commit }, text) {
    const notification = {
      icon: "custom:warningIcon",
      text: text || i18n.global.t("Common.ErrorMessage.Error"),
      color: "var(--additional-fail-color)",
      duration: 2500,
    };

    const timeoutId = setTimeout(function () {
      commit(mutation.NOTIFICATION_DISMISSED, { notification, timeoutId });
    }, notification.duration);

    commit(mutation.NOTIFICATION_ADDED, {
      obj: notification,
      timeoutId: timeoutId,
    });
  },
  addNotification({ commit }, notification) {
    if (!notification) {
      notification = {
        icon: "custom:warningIcon",
        text: i18n.global.t("Common.ErrorMessage.Error"),
        color: "red",
        duration: 3500,
      };
    }
    const defaultDurationMs = 3500;
    const duration = notification.duration || defaultDurationMs;

    const timeoutId = setTimeout(function () {
      commit(mutation.NOTIFICATION_DISMISSED, { notification, timeoutId });
    }, duration);

    commit(mutation.NOTIFICATION_ADDED, {
      obj: notification,
      timeoutId: timeoutId,
    });
  },
  dismissNotification(context, notification) {
    context.commit(mutation.NOTIFICATION_DISMISSED, { notification });
  },
  addMessage({ commit }, thread) {
    const defaultDurationMs = 3000;
    const timeoutId = setTimeout(function () {
      commit(mutation.MESSAGE_DISMISSED, thread);
    }, defaultDurationMs);

    commit(mutation.MESSAGE_ADDED, { obj: thread, timeoutId });
  },
  dismissMessage(context, thread) {
    context.commit(mutation.MESSAGE_DISMISSED, thread);
  },
};
const mutations = {
  [mutation.NOTIFICATION_ADDED](state, notification) {
    state.notifications.unshift(notification);
  },
  [mutation.NOTIFICATION_DISMISSED](state, { timeoutId }) {
    const i = state.notifications.findIndex((el) => el.timeoutId === timeoutId);
    if (i == -1) {
      return;
    }

    clearTimeout(timeoutId);
    state.notifications.splice(i, 1);
  },
  [mutation.MESSAGE_ADDED](state, thread) {
    state.threads[0] = thread;
  },
  [mutation.MESSAGE_DISMISSED](state, thread) {
    const i = state.threads.map((n) => n.obj).findIndex((el) => el.id === thread.id);
    if (i == -1) {
      return;
    }

    clearTimeout(state.threads[i].timeoutId);
    state.threads.splice(i, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
