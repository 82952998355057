import mitt from "@/services/mitt";

export function formatString(str, ...val) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function hexToRgbA(hex) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
  }

  return [255, 255, 255];
}

export function textColor(color) {
  const rgba = hexToRgbA(color);

  if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
    return "black";
  } else {
    return "white";
  }
}

export const copyText = async (id) => {
  let text = document.getElementById(id).innerHTML;

  await navigator.clipboard.writeText(text);
  mitt.emit("copied");
};

export const initials = (fullName) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  const initials = [...fullName.matchAll(rgx)] || [];

  return ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();
};

export const stringToHslColor = (str) => {
  var hash = 0;
  const s = 50;
  const l = 80;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i);
    hash += (hash << 5) - hash;
  }

  var h = hash % 360;
  return `hsl(${h},${s}%,${l}%)`;
};
