<template>
  <v-dialog :modelValue="modelValue" @update:model-value="changeDialogState" width="500" :persistent="sending">
    <v-card>
      <div class="reply-dialog">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex" style="gap: 4px">
            <v-icon>custom:messageIcon</v-icon>
            <span class="body1">{{
              $t("Inbox.Comments.Label.ReplyToTarget", {
                target: $t(replyTarget === replyToDirect ? "Inbox.Comments.Label.Direct" : "Inbox.Comments.Label.Comment"),
              }).toUpperCase()
            }}</span>
          </div>

          <v-btn icon variant="plain" size="x-small" @click="changeDialogState(false)">
            <v-icon>custom:closeIcon</v-icon>
          </v-btn>
        </div>

        <v-list>
          <v-list-item lines="two" :title="comment.customer.username" :subtitle="comment.text">
            <template #prepend>
              <v-avatar size="48">
                <v-img v-if="comment.customer.profilePicture" :src="comment.customer.profilePicture"></v-img>
                <v-img v-else src="../../../../assets/no-picture-account.jpg"></v-img>
              </v-avatar>
            </template>

            <template #title>
              <div class="d-flex gap8">
                <span class="username">{{ comment.customer.username }}</span>
                <Tooltipped class="" :text="moment.utc(comment.timestamp).local().format('LLLL')">
                  <span class="time">{{ moment.utc(comment.timestamp).local().fromNow(true) }}</span>
                </Tooltipped>
              </div>
            </template>
          </v-list-item>
          <div class="d-flex flex-column gap8">
            <div class="d-flex align-end">
              <v-textarea ref="replyInput" v-model="message" variant="outlined" rows="1" hide-details color="#2999FD" auto-grow max-rows="5"></v-textarea>
              <Tooltipped :text="$t('Inbox.Comments.Button.Post')">
                <v-btn icon @click="reply" :disabled="sending" variant="plain">
                  <v-icon class="send-icon"> {{ sendIcon }} </v-icon>
                </v-btn>
              </Tooltipped>
            </div>
            <div class="d-flex gap8">
              <Tooltipped :text="$t('Inbox.Label.Emoji')">
                <v-menu :close-on-content-click="false" class="emoji-menu">
                  <template #activator="{ props }">
                    <v-icon v-bind="props">custom:emojiIcon</v-icon>
                  </template>
                  <EmojiPicker native @select="addEmoji" :offset="100" />
                </v-menu>
              </Tooltipped>
              <MessageTemplateSelector @template-selected="insertTemplate" :target="commentReplyTarget" />
            </div>
          </div>
        </v-list>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ReplyTo } from "../../models/comment";
import { inboxCommentsService } from "../../services/inbox-comments.service";
import Tooltipped from "@/components/Tooltiped.vue";
import EmojiPicker from "vue3-emoji-picker";

import moment from "moment";
import MessageTemplateSelector from "../MessageTemplateSelector.vue";
import { onMounted, ref, defineEmits, defineProps, watch } from "vue";
import { useStore } from "vuex";

const props = defineProps(["modelValue", "comment", "replyTarget", "mediaId"]);
const emits = defineEmits(["update:modelValue", "replied", "comment-deleted"]);
const store = useStore();

const replyToDirect = ReplyTo.Direct;
const commentReplyTarget = ReplyTo.Comment;

const message = ref("");
const sendIcon = ref("custom:sendIcon");
const sending = ref(false);

onMounted(() => {
  message.value = `@${props.comment.customer.username} `;
});

const reply = async () => {
  if (!message.value) {
    return;
  }

  try {
    sending.value = true;
    sendIcon.value = "mdi-send-clock";

    await inboxCommentsService.replyComment(props.comment.id, message.value, props.replyTarget);
    emits("replied", props.comment);

    sendIcon.value = "mdi-send-check";
    changeDialogState(false);
    message.value = "";
  } catch (error) {
    if (error.response?.data?.errorType === "Deleted") {
      emits("comment-deleted", props.comment.id, props.comment.mediaId);
      changeDialogState(false);
      return;
    }
    store.dispatch("addError");
  } finally {
    sending.value = false;
    sendIcon.value = "custom:sendIcon";
  }
};

const insertTemplate = (template) => {
  message.value = template.text;
};

const addEmoji = (value) => {
  message.value += value.i;
};

const changeDialogState = (value) => {
  emits("update:modelValue", value);
};

watch(
  () => props.comment?.id,
  () => {
    message.value = `@${props.comment.customer.username} `;
  }
);
</script>

<style>
.reply-dialog.v-card {
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  border-radius: 16px;
  background: var(--white, #fff);

  /* Drop Shadow */
  box-shadow: 0px 4px 20px 0px rgba(90, 88, 88, 0.05);
}

.v-overlay.v-menu.emoji-menu,
.v-overlay.v-dialog {
  background: none;
  backdrop-filter: none;
}
</style>
