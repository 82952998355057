<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.35488 2.1665C2.75212 2.1665 1.90667 4.04648 2.97886 5.22627L5.65072 8.16628C6.01229 8.56413 6.21235 9.08045 6.21235 9.61571V12.1665C6.21235 13.087 6.96584 13.8332 7.8953 13.8332C8.82477 13.8332 9.57825 13.087 9.57825 12.1665V9.66221C9.57825 9.10434 9.79553 8.56797 10.1849 8.16478L12.983 5.26689C14.1091 4.10066 13.2745 2.1665 11.6451 2.1665H4.35488Z"
      fill="#5A5858"
    />
  </svg>
</template>
