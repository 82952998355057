<template>
  <v-card v-if="customer" class="px-4 py-6">
    <div class="h2 mb-2">
      {{ $t("Inbox.Label.Comments") }}
    </div>

    <v-progress-linear class="my-1" v-if="loading" indeterminate color="#2999FD"></v-progress-linear>

    <div v-else-if="comments.length">
      <CommentItem v-for="comment in comments" :key="comment.id" :comment="comment" conversation />
    </div>
    <div v-else class="subtitle op07" v-html="$t('Inbox.Comments.Label.UserComments', { username: customer.username })"></div>
  </v-card>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import { inboxCommentsService } from "../../services/inbox-comments.service";
import CommentItem from "./CommentItem.vue";

const props = defineProps(["customer"]);

const comments = ref([]);
const loading = ref(true);

onMounted(async () => {
  try {
    comments.value = (await inboxCommentsService.searchByCustomer({ page: 1, pageSize: 20, customerId: props.customer.id })).results;
  } finally {
    loading.value = false;
  }
});
</script>
