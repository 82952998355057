<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader type="table-thead,table-row,table-row"></v-skeleton-loader>
    </div>
    <div v-else class="d-flex flex-column justify-end gap16">
      <template v-if="hasAdminRights">
        <v-alert v-if="members.length === 3" border="start" type="info" color="rgba(200, 200, 200, 0.1)" border-color="primary">
          <template #prepend>
            <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
              <v-icon color="primary" class="info">custom:warningIcon</v-icon>
            </v-btn>
          </template>
          <div class="d-flex flex-column gap8">
            <h4 class="subtitle2" style="color: #5a5858">Зверніть увагу!</h4>
            <span class="body1" style="color: #5a5858" v-html="'Ви досягли ліміту в кількості користувачів вашої організації.'"> </span>
          </div>
        </v-alert>
        <div class="text-end" v-else>
          <v-btn rounded @click="inviteUserDialog = true" color="primary">Запросити в команду</v-btn>
        </div>
      </template>
      <v-data-table :headers="config.headers" :items="members" :loading="loading" class="body1" hover>
        <template #no-data>У вашій організації ви єдиний робітник</template>

        <template v-slot:[`item.active`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-chip v-if="item.active" color="#18b94f">активний</v-chip>
            <v-chip v-else color="#5a5858">не підтверджений</v-chip>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <Prompt v-if="currentUserId !== item.id" :text="removePromptText(item)" :title="removePromptTitle(item)" @confirmed="removeMember(item)">
            <Tooltiped :text="isInvitation(item) ? 'Скасувати запрошення' : $t('Common.Button.Remove')" class="mt-1 align-start">
              <v-btn icon variant="text">
                <v-icon class="error"> custom:trashIcon </v-icon>
              </v-btn>
            </Tooltiped>
          </Prompt>
        </template>
      </v-data-table>
    </div>
  </div>

  <InviteUserDialog v-model="inviteUserDialog" @invited="onInviteCreated" />
</template>

<script setup>
import i18n from "@/plugins/i18n";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { teamService } from "../../services/team.service";
import InviteUserDialog from "./InviteUserDialog.vue";
import Tooltiped from "@/components/Tooltiped.vue";
import Prompt from "@/components/Prompt.vue";

const store = useStore();

const members = ref([]);
const loading = ref(true);
const inviteUserDialog = ref(false);
const hasAdminRights = store.getters.isAdmin;
const currentUserId = store.getters.stateUser.id;

const config = {
  headers: [
    {
      key: "active",
      title: "Статус",
      align: "start",
      sortable: false,
      value: "active",
    },
    {
      key: "fullName",
      title: i18n.global.t("Orders.Headers.FullName"),
      align: "start",
      sortable: false,
      value: "fullName",
    },
    {
      key: "email",
      title: "Пошта",
      align: "start",
      sortable: false,
      value: "email",
    },
    {
      key: "createdOn",
      title: "Дата створення",
      align: "start",
      sortable: false,
      value: "createdOn",
    },
  ],
};

if (hasAdminRights) {
  config.headers.push({
    key: "actions",
    title: "",
  });
}

onMounted(async () => {
  try {
    loading.value = true;
    members.value = await teamService.getMembers();
  } catch {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
});

const removePromptTitle = (member) => {
  return isInvitation(member) ? "Скасувати запрошення?" : "Видалити користувача?";
};

const removePromptText = (member) => {
  return isInvitation(member)
    ? `Скасувавши запрошення, ${member.email} не зможе приєднатись до вашої команди`
    : `Видаливши корстувача ${member.fullName} (${member.email}), він(вона) втратить доступ до вашої команди`;
};

const removeMember = async (member) => {
  try {
    if (isInvitation(member)) await teamService.cancelInvitation(member.id);
    else await teamService.removeMember(member.id);

    members.value.splice(members.value.indexOf(member), 1);
  } catch {
    store.dispatch("addError");
  }
};

const isInvitation = (member) => !member.active && !member.fullName.trim();

const onInviteCreated = (invite) => {
  members.value.unshift(invite);
};
</script>
