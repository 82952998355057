<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M19 19.2684V7.84594C19 5.64245 17.4253 3.74586 15.2391 3.31619C13.1006 2.8959 10.8994 2.8959 8.76089 3.31619C6.57467 3.74586 5 5.64245 5 7.84593V19.2684C5 20.6048 6.46752 21.4365 7.63416 20.7613L10.8211 18.9169C11.5492 18.4955 12.4508 18.4955 13.1789 18.9169L16.3658 20.7613C17.5325 21.4365 19 20.6048 19 19.2684Z"
        stroke="#5A5858"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
