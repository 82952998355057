<template>
  <div class="d-flex flex-column landing gap40">
    <Header />
    <Content1 />
    <Features />
    <Tariffs />
    <div class="bg2 d-flex flex-column gap40">
      <Feedbacks />
      <InDevelopment />
      <div class="bg1">
        <ContactUs />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script setup>
import Header from "./Header.vue";
import Content1 from "./Content1.vue";
import Features from "./Features.vue";
import Feedbacks from "./Feedbacks.vue";
import ContactUs from "./ContactUs.vue";
import Tariffs from "./Tariffs.vue";
import InDevelopment from "./InDevelopment.vue";
import Footer from "./Footer.vue";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
onMounted(() => {
  anchorHashCheck();
});

const anchorHashCheck = () => {
  if (window.location.hash === route.hash) {
    const el = document.getElementById(route.hash.slice(1));
    if (el) {
      window.scrollTo({
        top: el.offsetTop + 240,
        left: 0,
        behavior: "smooth",
      });
    }
  }
};
</script>

<style>
.landing .v-container {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.landing h1 span,
.landing p span.highlight,
p.highlight {
  color: var(--main-primary, #2999fd);
}
.landing h1 {
  color: var(--main-secondary, #5a5858);

  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 62.4px */
}

.bg2 {
  background-image: url(../../../assets/landing/new/bg-elements2.png);
  background-size: cover;
}

.bg1 {
  background-image: url("../../../assets/landing/new/bg-elements.png");
}
</style>
