import i18n from "@/plugins/i18n";

export const headers = [
  {
    key: "orderNumber",
    title: "#",
    align: "start",
    sortable: false,
    value: "orderNumber",
  },
  {
    key: "createdOn",
    title: i18n.global.t("Orders.Headers.OrderDate"),
    align: "start",
    sortable: false,
    value: "createdOn",
  },
  {
    key: "fullName",
    title: i18n.global.t("Orders.Headers.FullName"),
    align: "start",
    sortable: false,
    value: "fullName",
  },
  {
    key: "phoneNumber",
    title: i18n.global.t("Orders.Headers.Phone"),
    align: "start",
    sortable: false,
    value: "phoneNumber",
  },
  {
    key: "orderLineItems",
    title: i18n.global.t("Orders.Headers.Goods"),
    align: "start",
    sortable: false,
    value: "orderLineItems",
  },
  {
    key: "total",
    title: i18n.global.t("Orders.Headers.Total"),
    align: "start",
    sortable: false,
    value: "total",
  },
  {
    key: "deliveryInvoiceNumber",
    title: i18n.global.t("Orders.Headers.DeliveryInvoiceNumber"),
    align: "center",
    sortable: false,
    value: "deliveryInvoiceNumber",
  },
  /*   {
      text: i18n.global.t("Orders.Headers.Status"),
      align: "start",
      sortable: false,
      value: "status",
    },
    {
      text: i18n.global.t("Orders.Headers.DeliveryService"),
      align: "start",
      sortable: false,
      value: "deliveryService",
    }, */
  {
    key: "comment",
    title: i18n.global.t("Orders.Headers.Comments"),
    align: "start",
    sortable: false,
    value: "comment",
  },
  {
    key: "createdById",
    title: i18n.global.t("Orders.Headers.Manager"),
    align: "center",
    sortable: false,
    value: "createdById",
  },
  {
    key: "actions",
    title: "Дія",
    align: "start",
    sortable: false,
    value: "id",
  },
];

export const filters = [
  {
    text: i18n.global.t("Orders.Headers.OrderNumber"),
    value: {
      type: "string",
      field: "OrderNumber",
      operators: [
        {
          text: "=",
          value: "equals",
        },
        {
          text: "містить",
          value: "contains",
        },
      ],
    },
  },
  {
    text: i18n.global.t("Orders.Headers.FullName"),
    value: {
      type: "string",
      field: "Customer.FullName",
      operators: [
        {
          text: "містить",
          value: "contains",
        },
      ],
    },
  },
  {
    text: i18n.global.t("Orders.Headers.Phone"),
    value: {
      type: "string",
      field: "Customer.PhoneNumber",
      operators: [
        {
          text: "=",
          value: "equals",
        },
        {
          text: "містить",
          value: "contains",
        },
      ],
    },
  },
  {
    text: i18n.global.t("Orders.Headers.Goods"),
    value: {
      type: "string",
      field: "OrderItems",
      operators: [
        {
          text: "містить",
          value: "contains",
        },
      ],
    },
  },
  {
    text: i18n.global.t("Orders.Headers.Total"),
    value: {
      type: "number",
      field: "TotalPrice",
      operators: [
        {
          text: ">",
          value: "greaterThan",
        },
        {
          text: ">=",
          value: "GreaterThanOrEqual",
        },
        {
          text: "<",
          value: "lessThan",
        },
        {
          text: "<=",
          value: "LessThanOrEqual",
        },
        {
          text: "=",
          value: "equals",
        },
      ],
    },
  },
  {
    text: i18n.global.t("Orders.Headers.DeliveryInvoiceNumber"),
    value: {
      type: "string",
      field: "OrderDeliveryInfo.DeliveryNumber",
      operators: [
        {
          text: "містить",
          value: "contains",
        },
      ],
    },
  },
  {
    text: i18n.global.t("Orders.Headers.Status"),
    value: {
      type: "dropdown",
      field: "OrderStatus.Id",
      operators: [
        {
          text: "=",
          value: "equals",
        },
      ],
      options: [
        {
          text: "Чорновик",
          value: "1",
        },
        {
          text: "В обробці",
          value: "2",
        },
        {
          text: "Очікує оплату",
          value: "3",
        },
        {
          text: "Очікує відправлення",
          value: "4",
        },
        {
          text: "В дорозі",
          value: "5",
        },
        {
          text: "Відмова",
          value: "6",
        },
        {
          text: "Повернення",
          value: "7",
        },
        {
          text: "Не вистачає товару",
          value: "8",
        },
        {
          text: "Завершено",
          value: "9",
        },
      ],
    },
  },
  {
    text: i18n.global.t("Orders.Headers.DeliveryService"),
    value: {
      type: "dropdown",
      field: "OrderDeliveryInfo.DeliveryTypeId",
      operators: [
        {
          text: "=",
          value: "equals",
        },
      ],
      options: [
        {
          text: "Нова пошта",
          value: "2",
        },
        {
          text: "Укр пошта",
          value: "3",
        },
        {
          text: "Justin",
          value: "4",
        },
        {
          text: "Самовивіз",
          value: "1",
        },
      ],
    },
  },
  {
    text: i18n.global.t("Orders.Headers.OrderDate"),
    value: {
      type: "datetime",
      field: "CreatedOn",
      operators: [
        {
          text: ">",
          value: "greaterThan",
        },
        {
          text: ">=",
          value: "GreaterThanOrEqual",
        },
        {
          text: "<",
          value: "lessThan",
        },
        {
          text: "<=",
          value: "LessThanOrEqual",
        },
        {
          text: "=",
          value: "equals",
        },
      ],
    },
  },
  {
    text: i18n.global.t("Orders.Headers.Comments"),
    value: {
      type: "string",
      field: "Note",
      operators: [
        {
          text: "містить",
          value: "contains",
        },
      ],
    },
  },
];

export const goodsTableHeaders = [
  {
    title: "Фото",
    key: "pictureUrl",
    value: "pictureUrl",
    align: "center",
    width: "66px",
  },
  {
    title: i18n.global.t("Orders.NewOrder.Label.Good"),
    key: "title",
    value: "title",
    align: "center",
  },
  {
    title: i18n.global.t("Orders.NewOrder.Label.UnitPrice"),
    key: "unitPrice",
    value: "unitPrice",
    align: "center",
  },
  {
    title: i18n.global.t("Orders.NewOrder.Label.Quantity"),
    key: "quantity",
    value: "quantity",
    align: "center",
  },
  {
    title: i18n.global.t("Orders.NewOrder.Label.Total"),
    key: "total",
    value: "total",
    align: "center",
  },
];

export const novaPoshtaStatusColors = {
  1: "", // Відправник самостійно створив цю накладну, але ще не надав до відправки",
  2: "#FFC107", // "Видалено",
  3: "#FFC107", // "Номер не знайдено",
  4: "#2196F3", // "Відправлення у місті ХХXХ.",
  5: "#2196F3", // "Відправлення прямує до міста YYYY",
  6: "#2196F3", // "Відправлення у місті YYYY, орієнтовна доставка до ВІДДІЛЕННЯ-XXX dd-mm. Очікуйте додаткове повідомлення про прибуття",
  7: "#A5D6A7", //"Прибув на відділення",
  8: "#A5D6A7", //"Прибув на відділення (завантажено в Поштомат)",
  9: "#4CAF50", // "Відправлення отримано",
  10: "#4CAF50", // "Відправлення отримано %DateReceived%. Протягом доби ви одержите SMS-повідомлення про надходження грошового переказу та зможете отримати його в касі відділення «Нова пошта»",
  11: "#4CAF50", // "Відправлення отримано %DateReceived%. Грошовий переказ видано одержувачу.",
  12: "#90CAF9", // "Нова Пошта комплектує ваше відправлення",
  101: "#2196F3", // "На шляху до одержувача",
  102: "#F44336", // "Відмова одержувача (створено заявку на повернення)",
  103: "#F44336", // "Відмова одержувача (отримувач відмовився від відправлення)",
  104: "#E6EE9C", //"Змінено адресу",
  105: "#F44336", // "Припинено зберігання",
  106: "#4CAF50", //"Одержано і створено ЄН зворотньої доставки",
  111: "#F44336", // Невдала спроба доставки через відсутність Одержувача на адресі або зв'язку з ним",
  112: "#E6EE9C", // "Дата доставки перенесена Одержувачем",
  default: "#fff",
};

export const novaPoshtaTableHeaders = [
  {
    title: "Дія",
    key: "id",
    value: "id",
  },
  {
    title: "№ Накладної",
    align: "center",
    key: "deliveryNumber",
    value: "deliveryNumber",
  },
  {
    title: i18n.global.t("Orders.Delivery.Label.Status"),
    align: "center",
    key: "statusTitle",
    value: "statusTitle",
  },
  {
    title: i18n.global.t("Orders.Delivery.Label.RemittanceSum"),
    align: "center",
    key: "remittance.total",
    value: "remittance.total",
  },
  {
    title: i18n.global.t("Orders.Delivery.Label.DeliveryCost"),
    align: "center",
    key: "deliveryCost",
    value: "deliveryCost",
  },
  {
    title: i18n.global.t("Orders.Delivery.Label.EstimatedDeliveryDate"),
    align: "center",
    key: "estimatedDeliveryDate",
    value: "estimatedDeliveryDate",
  },
  {
    title: i18n.global.t("Orders.Delivery.Label.CreatedOn"),
    align: "center",
    key: "createdOn",
    value: "createdOn",
  },
];
