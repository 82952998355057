import { settingsConstants } from "./models/constants";
import { integrationService } from "./services/integration.service";

const mutation = {
  SET_INTEGRATIONS: "SET_INTEGRATIONS",
  PLUG_IN_INTEGRATION: "PLUG_IN_INTEGRATION",
  PLUG_OFF_INTEGRATION: "PLUG_OFF_INTEGRATION",
};

const state = {
  integrations: { availableIntegrations: [], includedIntegrations: [] },
};
const getters = {
  integrations: (state) => state.integrations,
  isInstagramLinked: (state) => state.integrations.includedIntegrations.some((x) => x.id === settingsConstants.InstagramIntegrationId),
  isNovaPoshtaLinked: (state) => state.integrations.includedIntegrations.some((x) => x.id === settingsConstants.NovaPoshtaIntegrationId),
};
const actions = {
  async getIntegrations({ commit }) {
    const integrations = await integrationService.getAllIntegrations();

    commit(mutation.SET_INTEGRATIONS, integrations);
  },
  plugInIntegration({ commit, state }, integrationId) {
    const integration = state.integrations.availableIntegrations.find((x) => x.id === integrationId);
    if (!integration) return;
    commit(mutation.PLUG_IN_INTEGRATION, integration);
  },
  plugOffIntegration({ commit, state }, integrationId) {
    const integration = state.integrations.includedIntegrations.find((x) => x.id === integrationId);
    if (!integration) return;
    commit(mutation.PLUG_OFF_INTEGRATION, integration);
  },
};
const mutations = {
  [mutation.SET_INTEGRATIONS](state, integrations) {
    state.integrations = integrations;
  },
  [mutation.PLUG_IN_INTEGRATION](state, integration) {
    const index = state.integrations.availableIntegrations.findIndex((x) => x === integration);
    if (index === -1) return;

    state.integrations.availableIntegrations.splice(index, 1);
    state.integrations.includedIntegrations.push(integration);
  },
  [mutation.PLUG_OFF_INTEGRATION](state, integration) {
    const index = state.integrations.includedIntegrations.findIndex((x) => x === integration);
    if (index === -1) return;

    state.integrations.includedIntegrations.splice(index, 1);
    state.integrations.availableIntegrations.push(integration);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
