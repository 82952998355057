<template>
  <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Description") }}</label>
  <v-textarea
    :modelValue="modelValue"
    density="compact"
    variant="outlined"
    rows="1"
    auto-grow
    :rules="skipValidation ? [] : [rules.required]"
    @change="onInputChanged"
    :hide-details="skipValidation"
    placeholder="Введіть опис відправлення"
  />
</template>

<script setup>
import { requiredRule } from "@/services/field.rules";
import { defineProps, defineEmits } from "vue";
import i18n from "@/plugins/i18n";

defineProps(["modelValue", "skipValidation"]);
const emits = defineEmits(["update:modelValue"]);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
};

const onInputChanged = (value) => {
  emits("update:modelValue", value.target.value);
};
</script>
