<template>
  <div v-if="!loading">
    <manager-side-bar-vue></manager-side-bar-vue>
    <v-container class="main-container" fluid>
      <router-view />
    </v-container>
  </div>
</template>

<script setup>
import { apiSettingsService } from "@/modules/settings/services/settings-ws.service";
import { apiNotificationService } from "@/services/api-notifications.service";
import { api } from "@/services/api.service";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

import ManagerSideBarVue from "./ManagerSideBar.vue";

const store = useStore();

const loading = ref(true);

const islogged = computed(() => store.getters.isAuthenticated);

onMounted(async () => {
  await api.get("api/account/ping");
  if (!islogged.value) return;

  // load integrations to see what is available
  await store.dispatch("getIntegrations");

  await store.dispatch("setMembers");

  loading.value = false;

  if (store.getters.isInstagramLinked) {
    // fetch unseen inbox and comments counts
    await store.dispatch("setUnseenCounters");
  }

  apiSettingsService.initConnection().catch(() => console.log("Cant connect establish settings connection....."));
  apiNotificationService.initConnection().catch(() => console.log("Cant connect establish notification connection....."));
});
</script>

<style>
.v-container {
  padding: 0;
}
.main-container {
  background: #fafbfc;
  min-height: 100vh;
  height: 100%;
}

@media only screen and (max-width: 1279px) {
  .main-container {
    min-height: calc(100vh - 56px);
  }
}
</style>
