<template>
  <VideoAttachment class="message-container container" v-if="isVideo" :data="message.attachments.data[0].video_data" :preview="true" />

  <div v-else class="message-container overlay-container" style="position: relative">
    <div class="conversation-image-container">
      <ImageViewer :src="attachmentPreviewUrl" :height="340" width="100%"></ImageViewer>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import moment from "moment";

import ImageViewer from "@/components/ImageViewer.vue";
import VideoAttachment from "./VideoAttachment.vue";
const props = defineProps({
  message: {
    required: false,
  },
});

const messageTime = computed(() => moment.utc(props.message.created_time).local().format("HH:mm"));

const attachmentPreviewUrl = computed(
  () => props.message.attachments?.data[0].image_data?.preview_url || props.message.attachments?.data[0].video_data?.preview_url
);

const isVideo = computed(() => !!props.message.attachments?.data[0].video_data);

const reaction = computed(() => !!props.message.reactions?.data);
</script>
