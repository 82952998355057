<template>
  <v-navigation-drawer v-if="!mobile" color="#F4F6FA" permanent expand-on-hover rail border="none" class="pa-4" rail-width="76">
    <template #prepend>
      <v-list-item link @click="$router.push({ name: 'home' })" class="pa-0 mb-12 ml-n1">
        <template #prepend> <LogoIcon style="width: 40px; margin-right: 28px" /></template>

        <v-list-item-title>Weselli</v-list-item-title>
      </v-list-item>
    </template>

    <v-list class="sidebar-list">
      <v-list-item link :to="{ name: 'inbox' }" class="pa-2 d-flex" min-height="0">
        <template #prepend>
          <v-badge :model-value="unseenInboxItems > 0" :content="unseenInboxItems" color="rgba(255, 63, 63, 1)" floating offset-x="14" offset-y="10">
            <v-icon size="x-large">custom:inboxIcon</v-icon>
          </v-badge>
        </template>

        <v-list-item-title>{{ $t("ManagerLayout.Inbox") }}</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'orders' }" class="pa-2 d-flex" min-height="0">
        <template #prepend>
          <v-icon size="x-large">custom:ordersIcon</v-icon>
        </template>

        <v-list-item-title>{{ $t("ManagerLayout.Orders") }}</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'funnel' }" class="pa-2 d-flex" min-height="0">
        <template #prepend>
          <v-icon size="x-large">custom:funnelIcon</v-icon>
        </template>

        <v-list-item-title>{{ $t("ManagerLayout.Funnel") }}</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'goods' }" class="pa-2 d-flex" min-height="0">
        <template #prepend>
          <v-icon size="x-large">custom:warehouseIcon</v-icon>
        </template>

        <v-list-item-title>{{ $t("ManagerLayout.Warehouse") }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <template #append>
      <v-list-item link :to="{ name: 'settings' }" class="pa-2 d-flex" min-height="0">
        <template #prepend>
          <v-badge :model-value="store.getters.isNotBilled" dot color="rgba(255, 63, 63, 1)">
            <v-icon size="x-large">custom:settingsIcon</v-icon>
          </v-badge>
        </template>

        <v-list-item-title>{{ $t("ManagerLayout.Settings") }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-navigation-drawer>

  <v-bottom-navigation v-else mode="shift" color="#2999fd" bg-color="#F4F6FA" border="none" elevation="0" class="rounded-t-xl">
    <v-btn :to="{ name: 'inbox' }">
      <v-badge :model-value="unseenInboxItems > 0" :content="unseenInboxItems" color="rgba(255, 63, 63, 1)" floating offset-x="10" offset-y="10">
        <v-icon size="large">custom:inboxIcon</v-icon>
      </v-badge>
    </v-btn>

    <v-btn :to="{ name: 'orders' }">
      <v-icon size="x-large">custom:ordersIcon</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'funnel' }">
      <v-icon size="x-large">custom:funnelIcon</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'goods' }">
      <v-icon size="x-large">custom:warehouseIcon</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'settings' }">
      <v-badge :model-value="store.getters.isNotBilled" dot color="rgba(255, 63, 63, 1)">
        <v-icon size="x-large">custom:settingsIcon</v-icon>
      </v-badge>
    </v-btn>
  </v-bottom-navigation>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import LogoIcon from "@/assets/LogoIcon.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { mobile } = useDisplay();
const store = useStore();

const unseenInboxItems = computed(() => (store.getters.totalInboxUnseenCount > 99 ? "+99" : store.getters.totalInboxUnseenCount));
</script>
<style scoped>
.sidebar-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.v-list-item {
  border-radius: 8px;
  padding: 6px !important;
}

.v-list-item,
:global(.v-bottom-navigation__content) {
  color: rgba(90, 88, 88, 1);
}
.v-list-item--active {
  color: rgba(255, 255, 255, 1);
  background: var(--main-primary, #2999fd);
}

.v-list-item__icon {
  width: 28px;
}

:global(.v-btn--selected .v-btn__overlay) {
  background: none;
}
</style>
