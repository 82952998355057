<template>
  <v-card v-if="loading">
    <v-progress-linear indeterminate color="primary"></v-progress-linear>
  </v-card>
  <div v-else class="d-flex flex-column gap24 pa-8">
    <div class="d-flex align-center gap8">
      <Tooltiped :text="$t('Common.Label.Back')">
        <v-btn icon style="font-size: 24px" variant="text" @click="goBack">
          <v-icon large>custom:arrowLeftIcon</v-icon>
        </v-btn>
      </Tooltiped>
      <h1 class="h1">
        <span v-if="editMode">
          {{ $t("Warehouses.Goods.Headers.EditGood") }} <span>#{{ good.inventoryNumber }}</span>
        </span>
        <span v-else>
          {{ goodType == 1 ? $t("Warehouses.Goods.Headers.NewGood") : $t("Warehouses.Goods.Headers.NewGoodComponent") }}
        </span>
      </h1>
    </div>

    <v-form ref="formRef">
      <v-card class="pa-4">
        <div class="title mb-1">
          <span v-if="editMode">
            {{ goodType == 1 ? $t("Warehouses.Goods.Headers.EditGood") : $t("Warehouses.Goods.Headers.EditGoodComponent") }}
          </span>
          <span v-else> {{ goodType == 1 ? "Додавання товару" : "Додавання компоненти товару" }} </span>
        </div>

        <div v-if="!editMode" class="subtitle op05 mb-6">
          Заповніть поля нижче, щоб додати
          {{ goodType == 1 ? "новий товар" : "нову компоненту товару" }}
        </div>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="3">
            <label> {{ $t("Warehouses.Goods.Headers.Picture") }}</label>
            <FileInput v-model="good.picture" :formats="fileFormats" />
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="12" md="4">
                <label> {{ $t("Warehouses.Goods.Headers.Title") }}</label>
                <v-text-field
                  v-model="good.title"
                  variant="outlined"
                  density="compact"
                  single-line
                  :rules="[rules.required]"
                  placeholder="Введіть назву товару"
                />
              </v-col>
              <v-col cols="12" md="4">
                <label> {{ $t("Warehouses.Goods.Headers.InventoryNumber") }}</label>
                <v-text-field v-model="good.inventoryNumber" variant="outlined" density="compact" persistent-hint placeholder="Введіть артикул">
                  <template #append>
                    <Tooltiped :text="$t('Warehouses.Goods.Messages.InventoryNumberHint')">
                      <v-icon color="var(--main-secondary-color)">custom:infoIcon</v-icon>
                    </Tooltiped>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <label> {{ $t("Warehouses.Goods.Headers.Cost") }}</label>
                <v-text-field
                  v-model="good.cost"
                  variant="outlined"
                  density="compact"
                  single-line
                  :rules="[rules.required]"
                  prefix="₴"
                  type="number"
                  placeholder="Введіть собівартість товару"
                />
              </v-col>
              <v-col cols="12" md="4">
                <label> {{ $t("Warehouses.Goods.Headers.UnitPrice") }}</label>
                <v-text-field
                  v-model="good.unitPrice"
                  variant="outlined"
                  density="compact"
                  single-line
                  :rules="[rules.required]"
                  prefix="₴"
                  type="number"
                  placeholder="Введіть ціну товару"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <label> {{ $t("Warehouses.Goods.Headers.MeasureUnitGroup") }}</label>
                <v-select
                  v-model="selectedMeasureUnitGroup"
                  :items="measureUnitGroups"
                  item-title="title"
                  return-object
                  variant="outlined"
                  density="compact"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <label> {{ $t("Warehouses.Goods.Headers.MeasureUnit") }}</label>
                <v-select
                  v-model="good.measureUnit"
                  :items="selectedMeasureUnitGroup.measureUnits"
                  item-title="title"
                  item-value="id"
                  variant="outlined"
                  density="compact"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <!-- <v-col cols="12" md="4">
                <label> {{ $t("Warehouses.Goods.Headers.TotalAmount") }}</label>
                <v-text-field
                  v-model="good.totalAmount"
                  variant="outlined"
                  density="compact"
                  single-line
                  type="number"
                  placeholder="Введіть кількість товару"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" md="4">
                <label> {{ $t("Warehouses.Goods.Headers.Quantity") }}</label>
                <v-text-field
                  v-model="good.quantity"
                  variant="outlined"
                  density="compact"
                  single-line
                  type="number"
                  placeholder="Введіть наявну кількість товару"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-switch
          v-if="goodType == 1 || good.type === 'Good'"
          v-model="includeComponents"
          label="Додаткові компоненти товару ?"
          hide-details
          inset
          color="primary"
        />

        <div v-if="includeComponents" class="ma-4">
          <GoodComponents v-model="goodComponents" />
        </div>
      </v-card>
    </v-form>
    <div class="d-flex justify-center flex-wrap-reverse gap16">
      <v-btn width="200px" height="48px" variant="outlined" rounded @click="goToGoodsList"> {{ $t("Common.Button.Cancel") }} </v-btn>
      <v-btn
        width="200px"
        height="48px"
        @click="processSave"
        :disabled="!isSaveGoodBtnActive"
        :loading="saving"
        color="#2999FD"
        rounded
        variant="flat"
        style="color: white"
      >
        {{ editMode ? $t("Common.Button.Save") : $t("Common.Button.Create") }}</v-btn
      >
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, nextTick, onMounted, reactive, watch, ref } from "vue";
import { requiredRule } from "@/services/field.rules";
import { goodsService } from "../services/goods.service";
import GoodComponents from "../components/GoodComponents.vue";
import { measureUnitGroups } from "../views/goods.config";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/plugins/i18n";
import FileInput from "@/components/form/FileInput.vue";
import Tooltiped from "@/components/Tooltiped.vue";
import axios from "axios";
import { createPatch } from "rfc6902";

const store = useStore();
const router = useRouter();
const route = useRoute();

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false,
  },
  goodType: {},
});

const good = reactive({ measureUnit: 1, components: [], totalAmount: 0 });
const saving = ref(false);
const isSaveGoodBtnActive = computed(() => !saving.value);
const loading = ref(false);
const fileFormats = "image/jpeg, image/png";

const includeComponents = ref(false);
const goodComponents = ref([]);

const selectedMeasureUnitGroup = ref({});

const formRef = ref(null);
const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
};

onMounted(() => {});

onMounted(async () => {
  try {
    if (props.editMode) {
      await initEditForm();
    } else {
      good.type = props.goodType;
      selectedMeasureUnitGroup.value = measureUnitGroups[0];

      good.measureUnit = measureUnitGroups[0].measureUnits[0].id;
    }
  } finally {
    loading.value = false;
  }
});

var goodSnapshot = null;
var sourcePicture = null;
const initEditForm = async () => {
  try {
    const goodDetails = await goodsService.getGoodById(route.params.id);
    includeComponents.value = goodDetails.components.length > 0;
    goodComponents.value = [...goodDetails.components];

    selectedMeasureUnitGroup.value = measureUnitGroups.find((x) => x.measureUnits.some((m) => m.id == goodDetails.measureUnit));

    if (goodDetails.pictureUrl) {
      try {
        goodDetails.picture = await toDataUrl(goodDetails.pictureUrl);
        sourcePicture = goodDetails.picture;
      } catch {
        //
      }
    }
    goodSnapshot = JSON.parse(JSON.stringify(goodDetails));

    // as good is reactive need to copy all stuff from goodDetails
    for (var k in goodDetails) good[k] = goodDetails[k];
  } catch (requestError) {
    if (requestError.response.status === 404) router.push({ name: "goods" });
  }
};

const processSave = async () => {
  const isValid = (await formRef.value.validate()).valid;
  if (!isValid) {
    await nextTick();
    var firstError = formRef.value.querySelector(".v-input--error");
    if (firstError) window.scroll({ top: firstError.offsetTop + firstError.scrollHeight - 32, behavior: "smooth" });

    return;
  }

  saving.value = true;
  try {
    good.components = goodComponents;
    if (props.editMode) {
      const updateOps = [];
      var operations = createPatch(goodSnapshot, good).filter((x) => x.path !== "/picture");

      if (!good.picture) {
        operations.push({ op: "replace", path: "pictureUrl", value: null });
      }

      if (operations.length) {
        updateOps.push(goodsService.update(good.id, operations));
      }

      if (sourcePicture !== good.picture) {
        updateOps.push(goodsService.updatePicture(good.id, good.picture));
      }

      await Promise.all(updateOps);
    } else {
      await goodsService.createGood(good);
    }

    router.go(-1);
  } catch {
    store.dispatch("addError");
  } finally {
    saving.value = false;
  }
};

const goToGoodsList = () => {
  router.push({ name: "goods" });
};

watch(
  () => selectedMeasureUnitGroup.value.id,
  () => {
    good.measureUnit = selectedMeasureUnitGroup.value.measureUnits[0].id;
  }
);

const toDataUrl = async (url) => {
  return new File([(await axios.get(url, { responseType: "blob" })).data], "filename");
};

const goBack = () => {
  router.go(-1);
};
</script>
