<template>
  <div>
    <InboxLinkInstagram v-if="!isInstagramLinked" />
    <template v-else>
      <v-row v-if="firstLoading">
        <v-col> <InboxLoader /> </v-col>
      </v-row>
      <template v-else>
        <v-progress-linear class="my-1" v-if="loading" indeterminate color="#2999FD"></v-progress-linear>
        <template v-if="commentsData.comments.length > 0">
          <v-data-table-server
            :headers="[]"
            :items="commentsData.comments"
            @update:options="loadComments"
            :items-length="store.getters.commentsData.totalCount"
            :items-per-page="options.itemsPerPage"
            :items-per-page-options="itemsPerPage"
            :page="options.page"
            :search="options.search"
          >
            <template #body>
              <v-list lines="one" class="flex-column column-list" bg-color="#fafbfc">
                <CommentItem v-for="comment in commentsData.comments" :key="comment.id" :comment="comment" @replying="onReplying" />
              </v-list>
              <v-progress-linear class="my-1" v-if="loading" indeterminate color="#2999FD"></v-progress-linear>
            </template>
          </v-data-table-server>
        </template>

        <EmptyInbox v-else :text="$t('Inbox.Label.NoComments')" />
      </template>
    </template>

    <CommentReplyDialog
      v-if="selectedComment"
      v-model="replyDialogShown"
      :comment="selectedComment"
      :replyTarget="replyTarget"
      @comment-deleted="onCommentDeleted"
    />

    <CommentDeletedDialog v-model="commentDeletedDialog" />
  </div>
</template>

<script setup>
import InboxLinkInstagram from "../components/LinkInstagramPropose.vue";
import InboxLoader from "../components/InboxLoader.vue";
import EmptyInbox from "../components/EmptyInbox.vue";
import { ReplyTo } from "../models/comment";
import CommentReplyDialog from "../components/comments/CommentReplyDialog.vue";
import CommentDeletedDialog from "../components/comments/CommentDeleted.vue";
import CommentItem from "../components/comments/CommentItem.vue";
import { computed, onMounted, reactive, ref, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import mitt from "@/services/mitt";

const route = useRoute();
const router = useRouter();
const store = useStore();

var firstLoading = ref(true);
const loading = ref(true);
const options = reactive({ page: 1, itemsPerPage: 30, search: "" });
const itemsPerPage = [
  { title: "30", value: 30 },
  { title: "50", value: 50 },
];

const replyDialogShown = ref(false);
const selectedComment = ref(null);
const replyTarget = ref(ReplyTo.Direct);
const commentDeletedDialog = ref(false);
const selectedFilters = ref([]);

const isInstagramLinked = computed(() => store.getters.isInstagramLinked);

onMounted(async () => {
  mitt.on("search", onSearchChanged);
  mitt.on("inbox-filter", filterItems);

  if (!isInstagramLinked.value) return;

  options.page = +route.query.page || 1;
  options.itemsPerPage = +route.query.pageSize || 30;

  options.search = (route.query.search || "").toString();
  if (options.search) {
    mitt.emit("set-search", options.search);
  }

  await loadComments(options);
});

onBeforeUnmount(() => {
  mitt.off("search", onSearchChanged);
  mitt.off("inbox-filter", filterItems);
});

const commentsData = computed(() => store.getters.commentsData);

const loadComments = async ({ page, itemsPerPage, search }) => {
  const query = { page: page, pageSize: itemsPerPage };
  if (search) {
    query.search = search;
  }
  if (route.query.page != query.page || route.query.pageSize != query.pageSize || route.query.search != query.search) {
    router.replace({
      path: route.path,
      query: query,
    });
  }

  try {
    loading.value = true;
    await store.dispatch("refreshInboxComments", {
      page,
      pageSize: itemsPerPage,
      filters: selectedFilters.value,
      search,
    });
    loading.value = false;
    firstLoading.value = false;
  } catch {
    store.dispatch("addError");
  }
};

const filterItems = async (filters) => {
  selectedFilters.value = filters;

  await loadComments(options);
};

const onCommentDeleted = (commentId, mediaId) => {
  commentDeletedDialog.value = true;

  store.dispatch("removeMediaComment", { mediaId, commentId });
};

const onReplying = (comment, target) => {
  selectedComment.value = comment;
  replyTarget.value = target;
  replyDialogShown.value = true;
};

const onSearchChanged = async (val) => {
  options.search = val;
  options.page = 1;
};
</script>

<style scoped>
.column-list {
  width: calc(100vw - 140px);
}

@media (max-width: 1280) {
  .column-list {
    width: calc(100vw - 64px);
  }
}

@media (max-width: 800px) {
  .column-list {
    width: calc(100vw - 32px);
  }
}
</style>
