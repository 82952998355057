<template>
  <v-dialog width="650px" :modelValue="modelValue" :persistent="processing" @update:model-value="setValue">
    <v-card>
      <div class="d-flex flex-column gap24">
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="text-h5 grey lighten-2"> {{ title }} </v-card-title>

          <v-btn icon variant="plain" size="x-small" @click="setValue(false)">
            <v-icon>custom:closeIcon</v-icon>
          </v-btn>
        </div>

        <div class="d-flex flex-column gap16">
          <v-progress-linear v-if="processing" color="light-blue" height="10" :modelValue="generationProgress" striped></v-progress-linear>

          <div v-if="processed" class="d-flex flex-column gap8">
            <div class="subtitle op07">{{ $t("Common.Label.Report") }}</div>
            <div class="body1">
              <p>{{ $t("Orders.Delivery.Label.UsedInReport", { count: orderInternetDocumentStatuses.length }) }}</p>
              <p>{{ $t("Orders.Delivery.Label.SucceedInReport", { count: orderInternetDocumentStatuses.filter((x) => x.status).length }) }}</p>
              <p>{{ $t("Orders.Delivery.Label.FailedInReport", { count: orderInternetDocumentStatuses.filter((x) => !x.status).length }) }}</p>
            </div>
          </div>

          <v-data-table :items="orderInternetDocumentStatuses" :headers="headers" :loading="processing" hide-default-footer disable-sort class="body1">
            <template v-slot:[`item.deliveryNumber`]="{ item }">
              <div @click.stop.prevent="copyText(item.deliveryNumber)">
                <v-hover v-slot="{ props, isHovering }">
                  <Tooltiped text="Скопіювати">
                    <span v-bind="props" :id="item.deliveryInvoiceNumber">{{ item.deliveryNumber }}</span>
                    <v-expand-transition>
                      <v-btn v-if="isHovering" v-bind="props" icon color="black" size="22" variant="flat" class="copy-btn" style="opacity: 0.5">
                        <v-icon size="x-small">mdi-clipboard-outline</v-icon>
                      </v-btn>
                    </v-expand-transition>
                  </Tooltiped>
                </v-hover>
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-icon v-if="item.status === null">custom:deliveredIcon</v-icon>
              <v-icon v-else-if="item.status" color="green">custom:deliveredIcon</v-icon>
              <Tooltiped v-else :text="item.errors">
                <v-icon color="red">custom:closeIcon</v-icon>
              </Tooltiped>
            </template>
          </v-data-table>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-center">
          <v-btn v-if="!processed" color="#2999FD" rounded variant="flat" style="color: white" @click="process" :disabled="processing">{{
            buttonActionTitle
          }}</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { orderService } from "../services/order.service";
import Tooltiped from "@/components/Tooltiped.vue";
import { computed, onBeforeUnmount, onMounted, ref, watch, defineProps, defineEmits } from "vue";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";
import { copyText } from "@/services/util";

const props = defineProps(["modelValue", "orders", "action"]);
const emits = defineEmits(["data-updated", "update:modelValue", "modal-closed"]);
const store = useStore();

var connection = null;

const processing = ref(false);
const processed = ref(false);
const orderInternetDocumentStatuses = ref([]);

const title = computed(() => {
  switch (props.action) {
    case "generate": {
      return i18n.global.t("Orders.Delivery.Label.CreateTTN");
    }
    case "delete": {
      return i18n.global.t("Orders.Delivery.Label.DeleteTTN");
    }
    case "refresh": {
      return i18n.global.t("Orders.Delivery.Label.RefreshTTN");
    }
    default: {
      return props.action;
    }
  }
});

const buttonActionTitle = computed(() => {
  switch (props.action) {
    case "generate": {
      return i18n.global.t("Common.Button.Create");
    }
    case "delete": {
      return i18n.global.t("Common.Button.Remove");
    }
    case "refresh": {
      return i18n.global.t("Common.Button.Refresh");
    }
    default: {
      return props.action;
      // throw new Error(`Action: ${props.action} is not supported`);
    }
  }
});

const generationProgress = computed(
  () => (orderInternetDocumentStatuses.value.filter((x) => x.status !== null).length / orderInternetDocumentStatuses.value.length) * 100
);

let processedCount = 0;

const headers = [
  {
    title: i18n.global.t("Orders.Delivery.Label.OrderNumber"),
    key: "orderNumber",
    value: "orderNumber",
    align: "center",
  },
  {
    title: i18n.global.t("Orders.Delivery.Label.DeliveryNumber"),
    key: "deliveryNumber",
    value: "deliveryNumber",
    align: "center",
  },
  {
    title: i18n.global.t("Orders.Delivery.Label.Status"),
    key: "status",
    value: "status",
    align: "center",
  },
];

onMounted(() => {
  onOrdersChanged();
});

onBeforeUnmount(() => {
  connection?.off("InternetDocumentActionProgress", updateProgress);
  if (props.modelValue) {
    onOrdersChanged();
  }
});

const process = async () => {
  try {
    connection = await orderService.getSignalROrderConnection();
    connection.on("InternetDocumentActionProgress", updateProgress);

    processing.value = true;
    connection.send(
      "StartInternetDocumentProcessing",
      props.action,
      props.orders.map((x) => x.id)
    );
  } catch {
    store.dispatch("addError");
    processing.value = false;
  }
};

const updateProgress = (generationProgress) => {
  const item = orderInternetDocumentStatuses.value.find((x) => x.orderId === generationProgress.orderId);
  if (!item) return;

  item.deliveryNumber = generationProgress.deliveryNumber;
  item.status = generationProgress.success;
  item.errors = generationProgress.errors;

  const order = props.orders.find((x) => x.id === item.orderId);
  if (order) order.deliveryInvoiceNumber = item.deliveryNumber;

  if (generationProgress.success || generationProgress.value === 100) {
    setTimeout(() => emits("data-updated"), 500);
  }

  processedCount++;
  if (processedCount >= orderInternetDocumentStatuses.value.length) {
    processing.value = false;
    processed.value = true;
  }
};

const setValue = (value) => {
  if (!value) {
    processed.value = false;
    processing.value = false;
    orderInternetDocumentStatuses.value = [];
  }
  emits("update:modelValue", value);
};

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) onOrdersChanged();
    else if (!props.modelValue) emits("modal-closed");
  }
);

const onOrdersChanged = () => {
  processedCount = 0;
  orderInternetDocumentStatuses.value = (props.orders || []).map((x) => {
    return { orderId: x.id, orderNumber: x.orderNumber, deliveryNumber: x.deliveryInvoiceNumber, status: null };
  });
};
</script>
