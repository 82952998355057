<template>
  <v-dialog :modelValue="modelValue" persistent max-width="550px">
    <v-card>
      <div class="d-flex justify-space-between align-center">
        <span class="text-h5">{{ $t(creating ? "Settings.Communications.Label.CreateTemplate" : "Settings.Communications.Label.EditTemplate") }}</span>

        <v-btn icon variant="plain" size="x-small" @click="closeDialog">
          <v-icon>custom:closeIcon</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-container>
          <v-form ref="formRef">
            <label class="">{{ $t("Settings.Communications.Placeholder.TemplateName") }}</label>
            <v-text-field
              v-model="message.title"
              :placeholder="$t('Settings.Communications.Placeholder.TemplateName')"
              variant="outlined"
              density="compact"
              :rules="[rules.required]"
            />

            <label>{{ $t("Settings.Communications.Placeholder.Target") }}</label>
            <v-select v-model="message.target" :items="targets" density="compact" variant="outlined" item-title="text" item-value="value"></v-select>

            <label>{{ $t("Settings.Communications.Placeholder.TemplateText") }}</label>
            <v-textarea
              v-model="message.text"
              density="compact"
              variant="outlined"
              :placeholder="$t('Settings.Communications.Placeholder.TemplateText')"
              :rules="[rules.required]"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" :disabled="saving" @click="saveChanges" rounded variant="flat" height="48" width="152">
          {{ $t(creating ? "Common.Button.Create" : "Common.Button.Save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { messageTemplateService } from "@/modules/inbox/services/message-template.service";
import { createPatch } from "rfc6902";
import { requiredRule } from "@/services/field.rules";
import { ReplyTo } from "@/modules/inbox/models/comment";
import i18n from "@/plugins/i18n";
import { defineProps, defineEmits, watch, ref } from "vue";
import { useStore } from "vuex";

const props = defineProps(["modelValue", "message", "creating"]);
const emits = defineEmits(["update:modelValue", "template-created"]);
const store = useStore();

const targets = [
  { text: i18n.global.t("Inbox.Comments.Label.Direct"), value: 1 },
  { text: i18n.global.t("Inbox.Comments.Label.Comment"), value: 2 },
];

var messageCopy = {
  id: "",
  title: "",
  text: "",
  target: ReplyTo.Direct,
};
const saving = ref(false);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
};

const closeDialog = () => {
  emits("update:modelValue", false);
};

const formRef = ref(null);
const saveChanges = async () => {
  const isValid = (await formRef.value.validate()).valid;
  if (!isValid) {
    return;
  }

  if (props.creating) {
    await createMessageTemplate();
    return;
  }
  var operations = createPatch(messageCopy, props.message);
  if (operations.length) {
    try {
      saving.value = true;
      await messageTemplateService.update(props.message.id, operations);
      closeDialog();
      store.dispatch("addNotification", {
        icon: "custom:warningIcon",
        text: i18n.global.t("Settings.Communications.Label.TemplateUpdated"),
        color: "primary",
        duration: 3500,
      });
    } catch {
      store.dispatch("addError");
    } finally {
      saving.value = false;
    }
  } else {
    closeDialog();
  }
};

const createMessageTemplate = async () => {
  try {
    saving.value = true;
    const createdMessage = await messageTemplateService.create(props.message);
    store.dispatch("addNotification", {
      icon: "custom:warningIcon",
      text: i18n.global.t("Settings.Communications.Label.TemplateCreated"),
      color: "primary",
      duration: 3500,
    });
    emits("template-created", createdMessage);
    closeDialog();
  } catch {
    store.dispatch("addError");
  } finally {
    saving.value = false;
  }
};

watch(props.message, () => {
  messageCopy = JSON.parse(JSON.stringify(props.message));
});
</script>
