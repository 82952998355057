<template>
  <div class="d-flex flex-column gap16 justify-end">
    <OrdersTable v-if="ready" v-model="selectedOrders" :config="config" />
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

import { headers } from "./order.configs";
import OrdersTable from "@/modules/orders/components/OrdersTable.vue";

const props = defineProps(["customerId"]);

const selectedOrders = ref([]);

const config = ref({ filters: [], headers: [], selectable: true });
const ready = ref(false);

onMounted(() => {
  config.value = {
    filters: [{ field: "Customer.Id", operation: "equals", value: props.customerId }],
    headers: headers,
    selectable: true,
    export: {
      enabled: false,
    },
  };
  ready.value = true;
});
</script>
