<template>
  <div class="inbox-container">
    <SearchableHeader placeholder="Введіть назву акаунту Інстаграм, щоб шукати..." />
    <div class="inbox-tab">
      <v-tabs>
        <v-tab :to="{ name: 'inbox' }">
          <v-badge floating bordered color="rgba(255, 63, 63, 1)" :content="directUnseenCount" :modelValue="directUnseenCount > 0"> повідомлення </v-badge>
        </v-tab>
        <v-tab :to="{ name: 'inbox-comments' }">
          <v-badge floating bordered color="rgba(255, 63, 63, 1)" :content="commentUnseenCount" :modelValue="commentUnseenCount > 0"
            >{{ $t("Inbox.Label.Comments") }}
          </v-badge>
        </v-tab>
      </v-tabs>
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SearchableHeader from "@/components/layouts/SearchableHeader.vue";
const store = useStore();

const directUnseenCount = computed(() => store.getters.inboxUnseenCount || null);
const commentUnseenCount = computed(() => store.getters.inboxCommentUnseenCount || null);
</script>

<style scoped>
.v-tab {
  font-weight: 600;
  color: rgba(90, 88, 88, 1);
}
.v-tab--selected {
  color: #2999fd;
}

.inbox-container {
  background: var(--bg, #fafbfc);
}

.inbox-tab {
  padding-right: 32px;
  padding-left: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (max-width: 800px) {
  .inbox-tab {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
</style>
