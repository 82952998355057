<template>
  <v-dialog :model-value="isDisplayed" persistent max-width="500">
    <v-card title="Ваш акаунт обмежений">
      <v-card-text>Ми обмежили ваші дії до моменту оплати зв використання сервісу. Щоб продовжити безперешкодне користування - оплатіть.</v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn :to="{ name: 'billing' }" variant="elevated" height="36" rounded color="primary" text="Перейти до оплати"></v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment";

const store = useStore();
const route = useRoute();

const today = moment();

const isDisplayed = computed(
  () =>
    store.getters.isAuthenticated &&
    !store.getters.stateUser?.roles.includes("Admin") &&
    store.getters.isNotBilled &&
    store.getters.isNotBilled &&
    !route.path.includes("settings") &&
    route.name != "home" &&
    today > moment(store.getters.billingLastDate)
);
</script>
