<template>
  <v-tooltip :top="top" :bottom="!left && !top" :left="left" max-width="500px" v-if="text">
    <template #activator="{ props }">
      <span v-bind="props" style="position: relative">
        <slot />
      </span>
    </template>
    <span v-html="html" style="color: white"></span>
  </v-tooltip>
  <span v-else>
    <slot />
  </span>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps(["text", "left", "top"]);

const html = computed(() => `<span>${props.text}</span>`);
</script>
