import { api } from "@/services/api.service";
import { formatString } from "@/services/util";
const endpoints = {
  SEARCH: "/api/inbox/search",
  SEND: "/api/inbox/threads/{0}/send-message",
  SEND_ATTACHMENTS: "/api/inbox/threads/{0}/send-attachments",
  GET_BY_ID: "/api/inbox/threads/{0}",
  GET_UNSEEN_COUNTERS: "/api/inbox/unseen",
  PATCH_UPDATE_SEEN: "/api/inbox/threads/{0}/seen",
  CHECK_IS_IMAGE: "/api/inbox/instagram/is-image",
  GET_INSTAGRAM_ACCOUNT: "/api/inbox/instagram",
  GET_INSTAGRAM_MESSAGES_BATCH: "/api/inbox/instagram/threads/{0}/next-batch?cursor={1}",
  ASSIGN_THREAD: "/api/inbox/threads/{0}/assign",
};

function getInboxService() {
  const getMessagesBatch = async (externalId, cursorLink) => {
    return (await api.get(formatString(endpoints.GET_INSTAGRAM_MESSAGES_BATCH, externalId, cursorLink))).data;
  };
  const updateThread = async (id, arg1) => {
    return (await api.patch(formatString(endpoints.GET_BY_ID, id), arg1)).data;
  };
  const toggleSeen = async (threadId, seen) => {
    return (await api.patch(formatString(endpoints.PATCH_UPDATE_SEEN, threadId), seen)).data;
  };

  const search = async (searchQuery) => {
    return (await api.post(endpoints.SEARCH, searchQuery)).data;
  };

  const sendMessage = async (threadId, message) => {
    return (await api.post(formatString(endpoints.SEND, threadId), message)).data;
  };

  const sendAttachments = async (threadId, files) => {
    return (await api.post(formatString(endpoints.SEND_ATTACHMENTS, threadId), files)).data;
  };

  const getThread = async (threadId) => {
    return (await api.get(formatString(endpoints.GET_BY_ID, threadId))).data;
  };

  const isAssetImage = async (url) => {
    return (await api.get(`${endpoints.CHECK_IS_IMAGE}/${escape(url)}`)).data;
  };

  const getUnseenCounters = async () => {
    return (await api.get(endpoints.GET_UNSEEN_COUNTERS)).data;
  };

  const getInstagramAccount = async () => {
    return (await api.get(endpoints.GET_INSTAGRAM_ACCOUNT)).data;
  };

  const getLastActivity = async () => {
    return (await api.get("/api/inbox/last-activity")).data;
  };

  const assignUser = async (threadId, assigneeId) => {
    return await api.post(formatString(endpoints.ASSIGN_THREAD, threadId), { assigneeId: assigneeId });
  };

  return {
    getMessagesBatch,
    updateThread,
    toggleSeen,
    search,
    sendAttachments,
    sendMessage,
    getThread,
    isAssetImage,
    getUnseenCounters,
    getInstagramAccount,
    getLastActivity,
    assignUser,
  };
}

export const inboxService = getInboxService();
