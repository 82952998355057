<template>
  <AuthLayout>
    <div v-if="isNotRegistered" class="d-flex flex-column justify-space-between gap40">
      <div class="d-flex flex-column gap16">
        <div class="d-flex flex-column gap16">
          <div class="form-header">Вітаємо у <span>Weselli</span> 👋🏻</div>
        </div>

        <div class="d-flex flex-column gap16">
          <v-form ref="formRef" id="register-form">
            <label>{{ $t("Register.Label.FullName") }}</label>
            <v-text-field
              class="mt-1"
              v-model="user.fullName"
              :rules="[rules.required]"
              :placeholder="$t('Register.Placeholder.FullName')"
              variant="outlined"
              validate-on-blur
              density="compact"
            />

            <label>{{ $t("Register.Label.Email") }}</label>
            <v-text-field
              class="mt-1"
              v-model="user.email"
              :rules="[rules.required, rules.email]"
              validate-on-blur
              :placeholder="$t('Register.Placeholder.Email')"
              name="email"
              variant="outlined"
              density="compact"
            />

            <label>{{ $t("Register.Label.Phone") }}</label>
            <v-text-field
              class="mt-1"
              v-maska:[phoneNumberMaskOptions]
              v-model="user.phoneNumber"
              :rules="[rules.required, rules.phoneNumber]"
              :placeholder="$t('Register.Placeholder.PhoneNumber')"
              variant="outlined"
              validate-on-blur
              density="compact"
            />

            <label>{{ $t("Register.Label.Password") }}</label>
            <v-text-field
              class="mt-1"
              v-model="user.password"
              :rules="[rules.required, rules.password]"
              :placeholder="$t('Register.Placeholder.Password')"
              variant="outlined"
              :append-inner-icon="showPassword ? 'custom:eyeSlashIcon' : 'custom:eyeIcon'"
              :type="showPassword ? 'text' : 'password'"
              @click:append-inner="showPassword = !showPassword"
              validate-on-blur
              density="compact"
            />
          </v-form>
          <div class="d-flex flex-column gap8">
            <v-btn rounded class="form-button" @click="register" color="#2999FD" theme="dark" :loading="loading" data-test="register-button">{{
              $t("Register.Button.Register")
            }}</v-btn>

            <div class="not-registered">
              <span class="op07"> Вже зареєстровані ? </span>
              <v-btn variant="text" color="#2999FD" :to="{ name: 'login' }" class="text-none"> Вхід у акаунт</v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="terms">
        Натискаючи на кнопку вище, ви погоджуєтеся з умовами
        <a href="/">Оферти</a>
      </div>
    </div>
    <div v-else class="d-flex flex-column align-center justify-center gap40">
      <div class="d-flex gap16 flex-column" data-test="check-email">
        <div class="form-header">Перевірте пошту!</div>
        <div class="pb-4 op07 form-subheader">
          На вашу пошту було надіслано лист. Для підтвердження вашої пошти перейдіть за посиланням, що вказано в листі.
        </div>
      </div>
      <v-btn variant="text" color="#2999FD" :to="{ name: 'login' }" class="text-none"> Вхід у акаунт</v-btn>
    </div>
  </AuthLayout>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useStore } from "vuex";

import { accountService } from "../services/account.service";
import { emailRule, requiredRule } from "@/services/field.rules";
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import i18n from "@/plugins/i18n";
import { vMaska } from "maska";

const phoneNumberMaskOptions = { mask: "38##########" };
const store = useStore();

const user = reactive({
  fullName: "",
  email: "",
  password: "",
  phoneNumber: "38",
  agreementAccepted: true,
});

const showPassword = ref(false);
const loading = ref(false);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  email: (value) => emailRule(value, i18n.global.t("Common.ErrorMessage.Email")),
  password: (value) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value) || i18n.global.t("Register.ErrorMessage.PasswordFormatInvalid");
  },
  phoneNumber: (value) => {
    return /^38\d{10}$/.test(value) || i18n.global.t("Register.ErrorMessage.PhoneFormatInvalid");
  },
};

const formRef = ref(null);
const isNotRegistered = ref(true);

const register = async () => {
  const isFormValid = (await formRef.value.validate()).valid;
  if (!isFormValid) {
    return;
  }

  try {
    loading.value = true;
    user.confirmPassword = user.password;
    await accountService.register(user);

    isNotRegistered.value = false;

    user.fullName = "";
    user.email = "";
    user.password = "";
    user.phoneNumber = "38";
    user.agreementAccepted = true;
    formRef.value.reset();
  } catch {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
};
</script>
