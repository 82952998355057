<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path
      d="M22.6958 14.4355H5.48568M5.48568 14.4355C5.48568 14.4355 10.364 11.2272 11.3552 8.56592M5.48568 14.4355C5.48568 14.4355 9.79826 17.45 11.3552 20.305"
      stroke="#5A5858"
      stroke-width="2.17391"
      stroke-linecap="square"
    />
  </svg>
</template>
