<template>
  <v-dialog v-model="dialog" min-width="350">
    <template #activator="{ props }">
      <Tooltiped text="Додати вкладення">
        <v-icon v-bind="props" size="24" class="op07">custom:galleryIcon</v-icon>
      </Tooltiped>
    </template>

    <v-card width="350" class="d-flex align-self-center">
      <div class="d-flex flex-column gap24 content">
        <div class="d-flex justify-space-between align-center" style="width: 100%">
          <v-card-title class="text-h5 grey lighten-2"> {{ $t("Inbox.Label.SentAttachment") }} </v-card-title>

          <v-btn icon variant="plain" size="x-small" @click="dialog = false">
            <v-icon>custom:closeIcon</v-icon>
          </v-btn>
        </div>

        <div class="drop-zone" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop="handleDrop">
          <div v-if="!uploadedFiles.length" class="d-flex-column gap8 placeholder">
            <div class="">
              <span class="subtitle2"> Оберіть файл або перетягніть його сюди </span>
            </div>

            <div>
              <v-icon color="#2999FD">custom:uploadIcon</v-icon>
            </div>
            <input
              type="file"
              ref="fileInput"
              style="position: absolute; width: 100%; height: 100%; top: 0; opacity: 0; left: 0"
              @change="handleFileInput"
              :accept="allowedFileFormats"
            />
          </div>
          <div v-if="uploadedFiles.length" class="d-flex gap24" style="flex-wrap: wrap">
            <div v-for="(file, index) in uploadedFiles" :key="index" class="file-item">
              <div class="preview">
                <div v-if="file.type.startsWith('image/')">
                  <img :src="file.preview" alt="Preview" class="file-preview" />
                </div>
                <div v-else>
                  {{ file.name }}
                </div>

                <div class="close-icon">
                  <v-btn icon variant="plain" @click="removeFile(index)">
                    <v-icon>custom:closeIcon</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <v-btn rounded @click="uploadFile" :loading="uploading" :disabled="uploading" color="primary">надіслати вкладення</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, reactive } from "vue";
import Tooltiped from "@/components/Tooltiped.vue";
import axios from "axios";
import { inboxService } from "../services/inbox.service";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  threadId: {
    type: String,
  },
});
const emits = defineEmits(["media-sent"]);

const allowedFileFormats = "image/heic, image/png, image/jpeg, image/gif, video/mp4, video/wav";

const dialog = ref(false);
// Use ref to store the uploaded files
const uploadedFiles = ref([]);
const uploadedFileUrls = reactive([]);
const uploading = ref(false);

// Function to handle file drop
const handleDrop = (event) => {
  event.preventDefault();
  const files = event.dataTransfer.files;
  uploadFiles(files);
};

// Function to handle file selection from input (fallback for non-drag-and-drop upload)
const handleFileInput = (event) => {
  const files = event.target.files;
  uploadFiles(files);
};

// Function to handle file upload and update the uploadedFiles array
const uploadFiles = async (files) => {
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const reader = new FileReader();

    const fsize = Math.round(file.size / 1024);

    // Use FileReader to read the selected file and create a preview for images
    if (file.type.startsWith("image/")) {
      // The size of the file.
      if (fsize >= 8000) {
        alert("Фото завелике, максимальний розмір - 8MB");
        return;
      }

      reader.onload = () => {
        file.preview = reader.result;
        uploadedFiles.value.push(file);
      };
      reader.readAsDataURL(file);
    } else {
      // For non-image files, only store the file in the uploadedFiles array
      uploadedFiles.value.push(file);

      if (fsize >= 25000) {
        alert("Відео завелике, максимальний розмір - 25MB");
        return;
      }
    }

    const formData = new FormData();
    formData.append("file", file);
    const response = (await axios.post("https://tmpfiles.org/api/v1/upload", formData)).data;
    const formattedUrl = response.data.url.slice(0, 21) + "dl/" + response.data.url.slice(21);
    uploadedFileUrls.push(formattedUrl);
  }
};

const removeFile = (index) => {
  uploadedFiles.value.splice(index, 1);
  uploadedFileUrls.value.splice(index, 1);
};

const uploadFile = async () => {
  uploading.value = true;
  try {
    for (let i in uploadedFileUrls) {
      await inboxService.sendAttachments(props.threadId, [uploadedFileUrls[i]]);

      const extension = uploadedFileUrls[i].substring(uploadedFileUrls[i].lastIndexOf(".") + 1);
      const isVideo = extension === "wav" || extension === "mp4";
      emits("media-sent", { url: uploadedFileUrls[i], isVideo: isVideo });
    }

    dialog.value = false;
    uploadedFileUrls.splice(0, uploadedFileUrls.length);
    uploadedFiles.value = [];
  } finally {
    uploading.value = false;
  }
};
</script>

<style scoped>
.drop-zone {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  min-height: 200px;
  border-radius: 8px;
  border: 3px dashed var(--main-primary, #2999fd);
  background: var(--light-blue, #f4f6fa);

  padding: 20px;
  text-align: center;
  cursor: pointer;
  min-width: 100%;
}

input[type="file"]:hover {
  cursor: pointer;
}

.drop-zone span {
  color: var(--main-primary, #2999fd);
  text-align: center;
}

.placeholder,
.content {
  align-items: center;
  justify-content: center;
}

.file-preview {
  width: 80px;
  height: 80px;
  margin: 5px 0;
  border-radius: 8px;
}

.preview {
  position: relative;
}
.close-icon {
  height: min-content;
  display: flex;
  margin-left: auto;

  position: absolute;
  top: 0;
  right: 0;
  margin-top: 4px;
}
.close-icon:hover {
  cursor: pointer;
  background: lightgray;
  border-radius: 10px;
}
</style>
