<template>
  <v-app>
    <v-main v-if="!loading">
      <component :is="layout"></component>
      <NotificationSystem />
      <NewMessage />
      <AccountRestricted />
    </v-main>
  </v-app>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import NotificationSystem from "@/components/NotificationSystem.vue";
import AccountRestricted from "@/components/AccountRestricted.vue";
import NewMessage from "@/components/NewMessage.vue";
import { useStore } from "vuex";
import { AdminLayout, EmptyLayout, ManagerLayout } from "./components/layouts";

const store = useStore();

const islogged = computed(() => store.getters.isAuthenticated);
const loading = ref(true);
const layout = computed(() => {
  if (islogged.value) {
    if (store.getters.stateUser?.roles.includes("Admin")) {
      return AdminLayout;
    }
    return ManagerLayout;
  }
  return EmptyLayout;
});

onMounted(async () => {
  await store.dispatch("init");
  loading.value = false;
});
</script>
