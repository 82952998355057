<template>
  <v-menu transition="fab-transition">
    <template v-slot:activator="{ props, isActive }">
      <v-btn class="create-order-fab" icon size="64" color="primary" v-bind="props">
        <v-icon v-if="isActive">custom:closeIcon</v-icon>
        <v-icon v-else>custom:plusIcon</v-icon>
      </v-btn>
    </template>

    <div class="d-flex flex-column gap8 align-center justify-center mb-2">
      <Tooltiped :text="$t('Warehouses.Tooltips.NewGoodComponent')" :left="true">
        <v-btn icon size="48" color="primary" @click="goToNewCombinedGoodPage">
          <v-icon>custom:plusOutlinedIcon</v-icon>
        </v-btn>
      </Tooltiped>

      <Tooltiped :text="$t('Warehouses.Tooltips.NewGood')" :left="true">
        <v-btn icon size="48" color="primary add-good-icon" @click="goToNewGoodPage">
          <v-icon>custom:plusIcon</v-icon>
        </v-btn>
      </Tooltiped>
    </div>
  </v-menu>
</template>

<script setup>
import { useRouter } from "vue-router";
import Tooltiped from "@/components/Tooltiped.vue";
const router = useRouter();

const goToNewGoodPage = () => {
  router.push({ name: "new-good" });
};

const goToNewCombinedGoodPage = () => {
  router.push({ name: "new-good-component" });
};
</script>

<style>
.create-order-fab {
  position: fixed;
  bottom: 32px;
  right: 32px;
}

@media (max-width: 1280px) {
  .create-order-fab {
    bottom: 64px;
  }
}

.create-order-fab svg path {
  stroke: white !important;
}

.add-good-icon svg {
  width: 14px !important;
  height: 14px !important;
}
</style>
