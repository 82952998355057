<template>
  <div class="d-flex flex-column gap8" v-if="config.selectable">
    <div class="body1">Групова дія</div>
    <div class="d-flex gap16 justify-start flex-wrap">
      <Prompt :title="recycleBinPromptTitle" :text="recycleBinPromptBody" @confirmed="archiveGoods(modelValue)" :disabled="!modelValue.length">
        <Tooltiped :text="!modelValue.length ? $t('Warehouses.Goods.Label.SelectAtLeastOneGood') : $t('Warehouses.Goods.Label.MoveToRecycleBinGood')">
          <v-btn rounded color="var(--additional-fail-color)" height="36" :disabled="!modelValue.length">
            <span style="color: white" class="d-flex align-center">
              <v-icon class="trash-icon">custom:trashIcon</v-icon>

              <span class="group-action-item">Видалити</span>
            </span>
          </v-btn>
        </Tooltiped>
      </Prompt>
    </div>
  </div>

  <v-data-table-server
    :headers="config.headers"
    :items="goods"
    class="body1"
    :show-select="config.selectable"
    :loading="loading"
    :modelValue="modelValue"
    @update:model-value="onGoodSelectedChanged"
    @update:options="loadGoods"
    :search="options.search"
    :items-length="totalServerItems"
    :items-per-page-options="itemsPerPage"
    return-object
    @click:row="(e, { item }) => goToGoodDetailsPage(item.productId || item.id)"
    hover
  >
    <template v-slot:[`item.pictureUrl`]="{ item }">
      <div class="my-1">
        <ImageViewer v-if="item.pictureUrl" :src="item.pictureUrl" height="64" width="64px" rounded />
        <div v-else class="d-flex align-center justify-center">
          <v-img src="../../../assets/good_no_picture.png" height="64" width="64" />
        </div>
      </div>
    </template>
    <template v-slot:[`item.measureUnit`]="{ item }">
      {{ (measureUnits.find((x) => x.id == item.measureUnit) || { title: "" }).title }}
    </template>
    <template v-slot:[`item.inventoryNumber`]="{ item }">
      <span>{{ item.inventoryNumber }}</span>
      <br />
      <v-chip x-small v-if="item.type === 'Good'" color="primary">Товар</v-chip>
      <v-chip x-small v-else color="#00BCD4">Компонент</v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-menu>
        <template #activator="{ props }">
          <v-btn v-bind="props" icon="custom:dotsVerticalIcon" variant="text"></v-btn>
        </template>

        <v-list>
          <v-list-item link>
            <Tooltiped :text="$t('Warehouses.Goods.Label.ViewGood')">
              <v-btn icon @click="goToGoodDetailsPage(item.id)" variant="plain">
                <v-icon>custom:eyeOutlineIcon</v-icon>
              </v-btn>
            </Tooltiped>
          </v-list-item>

          <v-list-item link>
            <Tooltiped :text="$t('Warehouses.Goods.Label.EditGood')">
              <v-btn icon @click="goToGoodEditPage(item.id)" variant="plain">
                <v-icon>custom:editIcon</v-icon>
              </v-btn>
            </Tooltiped>
          </v-list-item>

          <v-list-item link>
            <Prompt :title="recycleBinPromptTitle" @confirmed="archiveGoods([item])">
              <Tooltiped :text="$t('Warehouses.Goods.Label.MoveToRecycleBinGood')">
                <v-btn icon variant="plain">
                  <v-icon>custom:trashIcon</v-icon>
                </v-btn>
              </Tooltiped>
            </Prompt>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table-server>
</template>

<script setup>
import Prompt from "@/components/Prompt.vue";
import Tooltiped from "@/components/Tooltiped.vue";
import i18n from "@/plugins/i18n";
import { ref, defineProps, defineEmits, onMounted, watch, reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { goodsService } from "../services/goods.service";
import mitt from "@/services/mitt";
import { measureUnits } from "../views/goods.config";
import ImageViewer from "@/components/ImageViewer.vue";

const props = defineProps(["modelValue", "config", "items"]);
const emits = defineEmits(["update:modelValue"]);
const router = useRouter();
const route = useRoute();
const store = useStore();

const totalServerItems = ref(0);
const options = reactive({ page: 1, itemsPerPage: 10, search: "" });
const itemsPerPage = [
  { title: "10", value: 10 },
  { title: "20", value: 20 },
  { title: "30", value: 30 },
];
const goods = ref([]);
const loading = ref(true);
const recycleBinPromptTitle = i18n.global.t("Warehouses.Goods.Label.ConfirmRecycleBinGoods");

const recycleBinPromptBody = computed(
  () => `<h3 class="h3">Товар</h3><p></p><ul>${props.modelValue.map((x) => `<li><span>#${x.inventoryNumber}</span></li>`).join("")}<ul>`
);

onMounted(() => {
  if (props.config.queryPaging) {
    options.search = (route.query.search || "").toString();
    options.page = +route.query.page || 1;
    options.itemsPerPage = +route.query.pageSize || 10;

    mitt.emit("set-search", options.search);
  }
  loading.value = false;

  loadGoods(options);
});

const loadGoods = async ({ page, itemsPerPage, search }) => {
  if (props.items) {
    goods.value = props.items;
    return;
  }

  if (loading.value) return;

  emits("update:modelValue", []);

  const query = { page: page, pageSize: itemsPerPage };
  if (search) {
    query.search = search;
  }
  if (props.config.queryPaging && (route.query.page != query.page || route.query.pageSize != query.pageSize || route.query.search != query.search)) {
    router.push({
      path: route.path,
      query: query,
    });
  }

  loading.value = true;
  try {
    const data = await goodsService.search({
      page: page,
      pageSize: itemsPerPage,
      filters: props.config.filters,
      search: query.search,
    });
    totalServerItems.value = data.totalCount;
    goods.value = data.results;
  } catch {
    store.dispatch("addError");
  } finally {
    loading.value = false;
  }
};

const archiveGoods = async (goods) => {
  try {
    await goodsService.archiveGoods(goods);
    await loadGoods(options);
  } catch {
    store.dispatch("addError");
  }
};

const onGoodSelectedChanged = (selected) => {
  emits("update:modelValue", selected);
};

const goToGoodDetailsPage = (goodId) => {
  router.push({ name: "good-details", params: { id: goodId.toString() } });
};

const goToGoodEditPage = (goodId) => {
  router.push({ name: "edit-good", params: { id: goodId.toString() } });
};

watch([() => props.config.searchText, () => props.config.filters], () => {
  options.search = props.config.searchText;

  loadGoods({ page: 1, itemsPerPage: options.itemsPerPage, search: options.search });
});
</script>
