<template>
  <v-card style="flex: 1 1 50%" class="pa-6 d-flex flex-column justify-space-between">
    <div class="d-flex justify-space-between gap24">
      <div>
        <NovaPoshtaDisable v-if="linked && hasAdminRights" @removed="novaPoshtaRemoved" />
      </div>

      <v-btn v-if="linked" icon color="var(--main-secondary-color)" variant="text" @click="router.push({ name: 'settings-novaposhta' })">
        <v-icon>custom:settingsIcon</v-icon>
      </v-btn>
    </div>

    <div class="d-flex justify-center">
      <v-img src="../../../../../assets/novaposhta-logo.png" alt="Nova Poshta" height="120px" />
    </div>

    <div class="d-flex flex-column subtitle mt-7">
      <ul class="ml-6">
        <li>Створення накладної на Новій пошті разом із створенням замовлення у CRM.</li>
        <li>Автоматичне оновлення статусу замовлення у CRM на основі даних з Нової пошти.</li>
        <li>Економія часу, бо не потрібно витрачати години на оформлення відправлень.</li>
        <li>Завжди актуальні дані про стан замовлення у CRM-системі.</li>
      </ul>
    </div>

    <v-card-actions style="justify-content: space-between">
      <v-dialog v-if="!linked" v-model="novaPoshtaDialog" width="650" :persistent="linking">
        <template #activator="{ props }">
          <v-btn color="primary" v-bind="props"> {{ $t("Inbox.Button.LinkInstagram") }} </v-btn>
        </template>

        <v-card class="pa-2">
          <v-card-title>{{ $t("Settings.Integrations.NovaPoshtaAPI.EnterAPIKey") }}</v-card-title>

          <v-form ref="formRef" v-model="isFormValid" lazy-validation class="mx-4">
            <v-text-field v-model="novaPoshtaAPIKey" variant="outlined" density="compact" :rules="[rules.required]" label="API-Key" />
          </v-form>

          <v-card-actions>
            <v-btn variant="text" @click="cancel" :disabled="linking"> {{ $t("Common.Button.Cancel") }} </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="linkNovaPoshta" :disabled="linking">
              {{ $t("Settings.Integrations.NovaPoshtaAPI.LinkButton") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { requiredRule } from "@/services/field.rules";
import { deliveryService } from "../../../services/delivery.service";
import NovaPoshtaDisable from "./NovaPoshtaDisable.vue";
import { ref, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";
import { useRouter } from "vue-router";

const emits = defineEmits(["removed"]);
defineProps(["linked"]);
const store = useStore();
const router = useRouter();

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
};

const hasAdminRights = store.getters.isAdmin;

const isFormValid = ref(true);

const novaPoshtaDialog = ref(false);
const novaPoshtaAPIKey = ref("");

const linking = ref(false);
const formRef = ref(null);

const linkNovaPoshta = async () => {
  const isFormValid = (await formRef.value.validate()).valid;
  if (!isFormValid) {
    return;
  }

  linking.value = true;
  try {
    await deliveryService.linkNovaPoshta(novaPoshtaAPIKey.value);
    store.dispatch("addNotification", {
      icon: "custom:messageIcon",
      text: i18n.global.t("Settings.Integrations.NovaPoshtaAPI.LinkedText"),
      color: "primary",
      duration: 3500,
    });
    novaPoshtaDialog.value = false;
  } catch {
    store.dispatch("addError");
  } finally {
    linking.value = false;
  }
};

const cancel = () => {
  novaPoshtaDialog.value = false;
};

const novaPoshtaRemoved = () => {
  emits("removed");
};
</script>
