<template>
  <div class="home">
    <HelloWorld v-if="islogged" msg="Welcome to Your Vue.js + TypeScript App" />
    <Landing v-else></Landing>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import Landing from "../modules/auth/views/Landing.vue";
import HelloWorld from "@/components/HelloWorld.vue";

const store = useStore();

const islogged = computed(() => store.getters.isAuthenticated);
</script>
