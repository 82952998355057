<template>
  <v-dialog v-model="dialog" max-width="600px" :disabled="disabled">
    <template #activator="{ props }">
      <span v-bind="props" @click="showPrompt">
        <slot />
      </span>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> {{ title }} </v-card-title>
      <v-card-text v-html="text"> </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-center">
        <v-btn variant="outlined" color="var(--main-secondary-color)" rounded @click="cancel" height="48px" style="padding: 8px 16px">
          {{ $t("Common.Button.Cancel") }}
        </v-btn>
        <v-btn color="primary" rounded variant="flat" @click="confirm" height="48px" style="padding: 8px 16px"> {{ $t("Common.Button.OK") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";

const emits = defineEmits(["confirmed", "canceled"]);
const props = defineProps(["title", "text", "disabled"]);

const dialog = ref(false);

const confirm = () => {
  dialog.value = false;
  emits("confirmed");
};

const cancel = () => {
  dialog.value = false;
  emits("canceled");
};

const showPrompt = () => {
  if (!props.disabled) dialog.value = true;
};
</script>
