import i18n from "@/plugins/i18n";

export const headers = [
  {
    key: "createdOn",
    title: i18n.global.t("Orders.Headers.OrderDate"),
    align: "start",
    sortable: false,
    value: "createdOn",
  },
  {
    key: "orderNumber",
    title: i18n.global.t("Orders.Headers.OrderNumber"),
    align: "start",
    sortable: false,
    value: "orderNumber",
  },
  {
    key: "orderLineItems",
    title: i18n.global.t("Orders.Headers.Goods"),
    align: "start",
    sortable: false,
    value: "orderLineItems",
  },
  {
    key: "total",
    title: i18n.global.t("Orders.Headers.Total"),
    align: "start",
    sortable: false,
    value: "total",
  },
  {
    key: "deliveryInvoiceNumber",
    title: i18n.global.t("Orders.Headers.DeliveryInvoiceNumber"),
    align: "center",
    sortable: false,
    value: "deliveryInvoiceNumber",
  },
  {
    key: "createdById",
    title: i18n.global.t("Orders.Headers.Manager"),
    align: "center",
    sortable: false,
    value: "createdById",
  },
  {
    key: "actions",
    title: "Дія",
    align: "start",
    sortable: false,
    value: "id",
  },
  /*{
    title: i18n.global.t("Orders.Headers.Status"),
    align: "start",
    sortable: false,
    value: "status",
  },
  {
    title: i18n.global.t("Orders.Headers.DeliveryService"),
    align: "start",
    sortable: false,
    value: "deliveryService",
  },*/
];
