<template>
  <v-facebook-login
    :version="version"
    :login-options="{
      scope: scopes,
    }"
    :app-id="facebookAppId"
    @login="handleLogin"
    :options="options"
    data-test="facebook-login-button"
  ></v-facebook-login>
</template>

<script setup>
import { defineEmits } from "vue";
import { useStore } from "vuex";
import { facebookService } from "@/modules/settings/services/facebook.service";
import { apiNotificationService } from "@/services/api-notifications.service";
import VFacebookLogin from "vue-facebook-login-component-next";
import vuei18n from "@/plugins/i18n";

const store = useStore();
const emit = defineEmits(["connected"]);
const version = "v19.0";
const facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID;
const options = { cookie: true, xfbml: false };
const scopes =
  "pages_show_list,instagram_basic,pages_manage_metadata,instagram_manage_comments,instagram_manage_messages,pages_messaging,pages_read_engagement,business_management";

const handleLogin = async (response) => {
  try {
    if (!response) {
      return;
    }

    await facebookService.apiAuthenticate(response.authResponse);
    store.dispatch("addNotification", {
      type: "success",
      title: vuei18n.global.t("Common.Label.Success"),
      text: vuei18n.global.t("Settings.Integrations.InstagramAPI.LinkedText"),
      color: "var(--additional-success)",
      duration: 3500,
    });

    await apiNotificationService.connectToInstagramGroup();

    emit("connected");
  } catch {
    store.dispatch("addError");
  }
};
</script>

<style>
.v-facebook-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: default;
  color: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid hsla(0, 0%, 100%, 0.05);
  background-color: #3c57a4;
}
.v-facebook-login *,
.v-facebook-login :after,
.v-facebook-login :before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.v-facebook-login[disabled] {
  opacity: 0.75;
  cursor: not-allowed;
  background-color: #2d417b;
}
.v-facebook-login[disabled]:hover {
  background-color: #2d417b;
}
.v-facebook-login:hover {
  background-color: #425fb3;
}
.v-facebook-login:focus {
  outline-width: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(139, 157, 195, 0.5);
  box-shadow: 0 0 0 1px rgba(139, 157, 195, 0.5);
}
.logo {
  width: 1.25rem;
  height: 1.25rem;
}
.loader,
.logo {
  opacity: 0.925;
  margin-right: 1.25ch;
}
</style>
