import router from "@/router";
import { accountService } from "@/modules/auth/services/account.service";
import moment from "moment";
import { apiNotificationService } from "@/services/api-notifications.service";
import { USER_ROLES } from "@/modules/auth/models/user.models";
import { teamService } from "../settings/services/team.service";
import { initials } from "@/services/util";

const mutation = {
  SET_AUTH_DATA: "setAuthData",
  SET_MEMBERS: "setMembers",
  IS_SET_AUTH_DATA: "isSetAuthData",
};

const state = { authData: null, refreshPromise: null, members: {} };
const getters = {
  isAuthenticated: (state) => !!state.authData?.user,
  currentUserId: (state) => state.authData?.user.id,
  stateUser: (state) => state.authData?.user,
  authToken: (state) => state.authData?.accessToken,
  isTokenSpoiled: (state) => {
    return moment(state.authData?.expiresAt).isValid() && moment.utc(state.authData?.expiresAt).valueOf() <= moment.utc().valueOf();
  },
  isAdmin: (state) => state.authData?.user.roles.includes(USER_ROLES.ADMIN),
  members: (state) => state.members,
  isNotBilled: (state) => state.authData?.user.billing && state.authData?.user.billing.status !== "paid",
  billingLastDate: (state) => state.authData?.user.billing.dueDate,
};
const actions = {
  async init({ commit }) {
    const json = localStorage.getItem("token");
    if (json) {
      const userData = JSON.parse(json);
      commit(mutation.SET_AUTH_DATA, userData);
    }
  },
  async login({ commit }, user) {
    const authData = await accountService.login(user);
    commit(mutation.SET_AUTH_DATA, authData);
    localStorage.setItem("token", JSON.stringify(authData));
    router.go(0);
    await apiNotificationService.initConnection();
  },
  async logout({ commit }) {
    try {
      await apiNotificationService.closeConnection();
    } finally {
      localStorage.removeItem("token");

      commit(mutation.SET_AUTH_DATA, null);
      await router.push({ name: "home" });
    }
  },
  async refresh({ commit, state }) {
    try {
      let authData = null;

      if (state.refreshPromise) {
        authData = await state.refreshPromise;
      } else {
        const authDataPromise = accountService.refreshToken({ oldAuthToken: state.authData?.accessToken, refreshToken: state.authData?.refreshToken });
        commit(mutation.IS_SET_AUTH_DATA, authDataPromise);
        authData = await authDataPromise;
      }

      commit(mutation.SET_AUTH_DATA, authData);
      localStorage.setItem("token", JSON.stringify(authData));
    } finally {
      commit(mutation.IS_SET_AUTH_DATA, null);
    }
  },

  async setMembers({ commit, state }) {
    const members = (await teamService.getMembers()).reduce((a, v) => ({ ...a, [v.id]: { ...v, initials: initials(v.fullName) } }), {});
    commit(mutation.SET_MEMBERS, members);
  },
};
const mutations = {
  [mutation.SET_AUTH_DATA](state, authData) {
    state.authData = authData;
  },
  [mutation.IS_SET_AUTH_DATA](state, promise) {
    state.refreshPromise = promise;
  },
  [mutation.SET_MEMBERS](state, members) {
    state.members = members;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
