<template>
  <v-dialog :modelValue="modelValue" max-width="450px">
    <v-card>
      <v-card-title>{{ $t("Inbox.Comments.Label.CommentDeletedTitle") }}</v-card-title>
      <v-card-text>
        <v-alert border="start" type="warning" variant="outlined">{{ $t("Inbox.Comments.Label.CommentDeletedText") }} </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="changeDialogState">{{ $t("Common.Button.Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue"]);

const changeDialogState = () => {
  emits("update:modelValue", false);
};
</script>
