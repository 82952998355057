import { api } from "@/services/api.service";
import { formatString } from "@/services/util";

const endpoints = {
  SEARCH: "/api/inbox/comments/search",
  SEARCH_BY_CUSTOMER: "/api/inbox/comments/customers",
  SEARCH_BY_MEDIA: "/api/inbox/comments/search/{0}?page={1}",
  PATCH_UPDATE_IS_REPLIED: "/api/inbox/comments/{0}/replied",
  REPLY_COMMENT: "/api/inbox/instagram/comments/reply",
  GET_MEDIA_DATA: "/api/inbox/instagram/media/{0}",
};

function getInboxCommentsService() {
  const getBatch = async (mediaId, page) => {
    return (await api.post(formatString(endpoints.SEARCH_BY_MEDIA, mediaId, page.toString()))).data;
  };

  const toggleReplied = async (commentId, isReplied) => {
    return (await api.patch(formatString(endpoints.PATCH_UPDATE_IS_REPLIED, commentId), isReplied)).data;
  };
  const search = async (searchQuery) => {
    return (await api.post(endpoints.SEARCH, searchQuery)).data;
  };

  const searchByCustomer = async (searchQuery) => {
    return (await api.post(endpoints.SEARCH_BY_CUSTOMER, searchQuery)).data;
  };

  const getMediaData = async (mediaId) => {
    return (await api.post(formatString(endpoints.GET_MEDIA_DATA, mediaId))).data;
  };

  const replyComment = async (commentId, text, replyTarget) => {
    return (await api.post(endpoints.REPLY_COMMENT, { commentId, message: text, replyTarget })).data;
  };

  return {
    getBatch,
    toggleReplied,
    search,
    searchByCustomer,
    getMediaData,
    replyComment,
  };
}

export const inboxCommentsService = getInboxCommentsService();
