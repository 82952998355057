<template>
  <div class="features-container">
    <v-container fill-height fluid class="d-flex flex-column gap80">
      <h1 class="text-center" id="features">Яка користь від <span>Weselli CRM</span> ?</h1>

      <div class="d-flex flex-column gap40">
        <div class="d-flex gap80 flex-wrap justify-center">
          <div class="text-center card-container">
            <div class="card-transformed">
              <img :src="require('../../../assets/landing/new/insta-direct-feature.png')" alt="Інстаграм Direct" />
              <h3>Зручність користування Insta Direct</h3>
              <div class="subtitle op07">Сортуй всі діалоги за категоріями, тепер ти не втратиш замовлення</div>
            </div>
          </div>

          <div class="text-center card-container">
            <div class="card-transformed">
              <img :src="require('../../../assets/landing/new/insta-comments-feature.png')" alt="Інстаграм Коментарі" />
              <h3>Відстежуй коментарі під постами</h3>
              <div class="subtitle op07">Тепер всі коментарі знаходяться в одному місці, отримуй їх вивантаження прямо у діалог</div>
            </div>
          </div>

          <div class="text-center card-container">
            <div class="card-transformed">
              <img :src="require('../../../assets/landing/new/nova-feature.png')" alt="ТТН Нова Пошта" />
              <h3>Прийом замовлень та створення ТТН в одному місці</h3>
              <div class="subtitle op07">Швидке та зручне створення ТТН, тепер не потрібно переносити замовлення з телефону в CRM, excel, блокнот і т.д.</div>
            </div>
          </div>

          <div class="text-center card-container">
            <div class="card-transformed">
              <img :src="require('../../../assets/landing/new/sales-funnel-feature.png')" alt="Воронка Продажів" />
              <h3>Створення власної воронки продажів</h3>
              <div class="subtitle op07">
                Деталізована воронка продаж. Кожен етап тепер як на долоні. Слідкуйте за процесом продаж, вивчайте статистику й оцінюйте, де втрачаються гроші.
              </div>
            </div>
          </div>

          <div class="text-center card-container">
            <div class="card-transformed">
              <img :src="require('../../../assets/landing/new/orders-feature.png')" alt="Воронка Продажів" />
              <h3>Контроль всіх відправок</h3>
              <div class="subtitle op07">
                Налаштуйте та контролюйте роботу: протерміновані замовлення, виконання завдань, роботу з клієнтами, активність співробітників і багато іншого у
                реальному часі
              </div>
            </div>
          </div>
        </div>
        <p class="text-center">Зараз <span class="highlight"> СРМ система безкоштовна</span>, встигни спробувати!</p>
      </div>
    </v-container>
  </div>
</template>

<style scoped>
.card-container {
  position: relative;
  padding: 24px;

  width: 320px;
  height: 320px;
  flex-shrink: 0;

  border-radius: 20px;
  border: 1px solid var(--Divider, rgba(90, 88, 88, 0.1));
  background: var(--White, #fff);

  /* Drop Shadow */
  box-shadow: 0px 4px 20px 0px rgba(90, 88, 88, 0.05);
}

.card-transformed {
  transform: translate(0, -20%);
}

.card-container img {
  width: 220px;
  height: 160px;
}

.features-container {
  background-image: url(../../../assets/landing/new/features-bg-elements.png);
  background-position-x: right;
}
</style>
