<template>
  <v-menu transition="slide-y-transition" absolute left bottom>
    <template #activator="{ props }">
      <v-btn icon v-bind="props" variant="flat">
        <v-icon>custom:dotsVerticalIcon</v-icon>
      </v-btn>
    </template>
    <v-list>
      <Tooltiped :text="disabledRemoveText">
        <v-list-item link @click="removeStatus" :disabled="threadStatus.id == 1 || threadsExists">
          <v-list-item-title> {{ $t("Common.Button.Remove") }} </v-list-item-title>
        </v-list-item>
      </Tooltiped>
    </v-list>
  </v-menu>
</template>

<script setup>
import { funnelService } from "../../services/funnel.service";
import Tooltiped from "@/components/Tooltiped.vue";
import { computed, defineProps, defineEmits, ref } from "vue";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";

const props = defineProps(["threadStatus", "threadsExists"]);
const emits = defineEmits(["removed"]);
const store = useStore();

const deleting = ref(false);

const disabledRemoveText = computed(() =>
  i18n.global.t(
    props.threadStatus.id == 1 ? "Funnel.Message.CanNotRemoveDafaultStatus" : props.threadsExists ? "Funnel.Message.CanNotRemoveNonEmptyStatus" : ""
  )
);

const removeStatus = async () => {
  try {
    deleting.value = true;
    await funnelService.deleteStatus(props.threadStatus.id);

    emits("removed", props.threadStatus);
  } catch {
    store.dispatch("addError");
  } finally {
    deleting.value = false;
  }
};
</script>
