import AdminHome from "./views/AdminHome.vue";
import AdminUserList from "./views/AdminUserList.vue";

export const routes = [
  {
    path: "/admin",
    name: "admin-home",
    component: AdminUserList,
  },
  {
    path: "/admin/user-list",
    name: "admin-user-list",
    component: AdminUserList,
  },
];
