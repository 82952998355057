export const getPersonInfo = () => ({
  address: { id: "", title: "", categoryOfWarehouse: "Branch" },
  city: { id: "", title: "", ref: "" },
  fullName: "",
  id: "",
  phoneNumber: "",
});

export const getDeliveryInfo = () => ({
  deliveryNumber: "",
  description: "",
  cost: 0.0,
  weight: 0,
  payerType: PayerType.Recipient,
  paymentMethod: "Cash",
  sender: getPersonInfo(),
  receiver: getPersonInfo(),
  statusId: "",
  statusTitle: "",
  estimatedDeliveryDate: "",
  deliveryCost: "",
  createdOn: "",
  printed: false,
  remittance: {
    active: true,
    payer: "Recipient",
    recieveForm: "Money",
    total: 0,
  },
  additionalInformation: "",
  infoRegClientBarcodes: "",
  seats: [],
});

export const getCreateOrderVm = () => ({
  orderLineItems: [],
  deliveryInfo: getDeliveryInfo(),
  sourceData: null,
  sourceId: 0,
});

export const PayerType = {
  Sender: "Sender",
  Recipient: "Recipient",
};
