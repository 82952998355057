<template>
  <div class="my-4">
    <v-row v-for="(seat, i) in seats" :key="i">
      <v-col cols="12" md="1">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Place") }} {{ i + 1 }}</v-col>
      <v-col cols="12" sm="6" md="2">
        <v-text-field
          density="compact"
          variant="outlined"
          :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Weight')"
          v-maska:[numberMask]
          v-model="seat.weight"
          :rules="[rules.weight]"
          @change="(v) => +v"
        >
          <template #append> {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.KG") }}</template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <v-text-field
          density="compact"
          variant="outlined"
          :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Length')"
          v-maska:[numberMask]
          v-model="seat.length"
          :rules="[rules.sideSize]"
          @change="(v) => +v"
        >
          <template #append> {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.SM") }}</template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <v-text-field
          density="compact"
          variant="outlined"
          :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Width')"
          v-maska:[numberMask]
          v-model="seat.width"
          :rules="[rules.sideSize]"
          @change="(v) => +v"
        >
          <template #append> {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.SM") }}</template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <v-text-field
          density="compact"
          variant="outlined"
          :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Height')"
          v-maska:[numberMask]
          v-model="seat.height"
          :rules="[rules.sideSize]"
          @change="(v) => +v"
        >
          <template #append> {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.SM") }}</template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <v-text-field
          density="compact"
          variant="outlined"
          :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.ValuedWeight')"
          type="number"
          readonly
          disabled
          :modelValue="valuedWeight(seat)"
        >
          <template #append>
            <Tooltipped :text="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.ValuedWeightInfo')">
              <v-icon>custom:infoIcon</v-icon>
            </Tooltipped>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="1" md="1">
        <Prompt :text="removePromptText" :title="removePromptTitle" @confirmed="removeSeat(i)" v-if="seats.length > 1">
          <Tooltipped :text="$t('Common.Button.Remove')">
            <v-btn icon color="red">
              <v-icon class="error"> custom:trashIcon </v-icon>
            </v-btn>
          </Tooltipped>
        </Prompt>
      </v-col>
    </v-row>
    <v-btn x-small variant="text" color="primary" @click="addSeat" :disabled="!isAddSeatAvailable"
      ><v-icon x-small>custom:plusIcon</v-icon>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.AddPlace") }}
    </v-btn>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import i18n from "@/plugins/i18n";
import Prompt from "@/components/Prompt.vue";
import Tooltipped from "@/components/Prompt.vue";
import { between } from "@/services/field.rules";
import { vMaska } from "maska";

const props = defineProps(["seats", "templateId", "deliveryId"]);
const numberMask = {
  mask: "#*.##",
};

const removePromptTitle = i18n.global.t("Settings.Integrations.NovaPoshtaAPI.ConfirmSeatRemove");
const removePromptText = "";

const rules = {
  weight: (value) => between(value, 0, 1000, i18n.global.t("Common.ErrorMessage.Range", { min: 0, max: 1000 })),
  sideSize: (value) => between(value, 0, 999, i18n.global.t("Common.ErrorMessage.Range", { min: 0, max: 999 })),
};

const valuedWeight = (seat) => {
  const minWeight = 0.1;
  const weight = (seat.length * seat.height * seat.width) / 4000;
  return Math.round((weight < minWeight ? minWeight : weight + Number.EPSILON) * 100) / 100;
};

const addSeat = () => {
  const seat = props.templateId
    ? { weight: 0, length: 0, height: 0, width: 0, templateId: props.templateId }
    : { weight: 0, length: 0, height: 0, width: 0, novaPoshtaDeliveryInfoId: props.deliveryId };
  props.seats.push(seat);
};

const removeSeat = (index) => {
  props.seats.splice(index, 1);
};

const isAddSeatAvailable = computed(
  () => props.seats && props.seats.reduce((accum, curr) => accum && curr.weight > 0 && curr.length > 0 && curr.height > 0 && curr.width > 0, true)
);
</script>
