<template>
  <div class="tariffs-container">
    <v-container fill-height fluid class="d-flex flex-column gap40">
      <div>
        <h1 class="text-center" id="tariffs"><span>Оберіть</span> тарифний план</h1>

        <p class="text-center subtitle">
          Пробний період <span class="highlight">7 днів</span> , без обмежень на кількість клієнтів і замовлень з повним доступом.
        </p>
      </div>

      <v-card elevation="5" class="d-flex flex-column align-self-center" width="350" color="primary" border="none">
        <v-card-title class="text-center h1 pa-4">БАЗОВИЙ</v-card-title>
        <div class="d-flex justify-center align-center">
          <div style="width: 18px; height: 15px; border-radius: 25px; background-color: white; position: relative; left: -8px"></div>
          <div style="border-top: 4px dashed white; width: 100%"></div>
          <div style="width: 18px; height: 15px; border-radius: 25px; background-color: white; position: relative; left: 8px"></div>
        </div>
        <v-spacer></v-spacer>

        <ul class="pa-4">
          <li><v-checkbox color="white" :model-value="true" density="compact" hide-details readonly label="Офіційне Підключення АРІ" /></li>
          <li><v-checkbox color="white" :model-value="true" density="compact" hide-details readonly label="Інстаграм Дірект/Коментарі" /></li>
          <li><v-checkbox color="white" :model-value="true" density="compact" hide-details readonly label="Воронка" /></li>
          <li><v-checkbox color="white" :model-value="true" density="compact" hide-details readonly label="Склад" /></li>
          <li><v-checkbox color="white" :model-value="true" density="compact" hide-details readonly label="Служби Доставки" /></li>
          <li><v-checkbox color="white" :model-value="true" density="compact" hide-details readonly label="Статистика" /></li>
          <li><v-checkbox color="white" :model-value="true" density="compact" hide-details readonly label="Команда" /></li>
        </ul>

        <v-spacer></v-spacer>
        <p class="text-center h1">299 грн</p>
        <v-spacer></v-spacer>

        <v-card-actions class="d-flex pa-8">
          <v-spacer></v-spacer>
          <v-btn variant="outlined" rounded color="white" link :to="{ name: 'register' }" height="48" width="100%">Спробувати</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<style lang="css">
.tariffs-container ul {
  list-style-type: none;
}

.tariffs-container .v-label {
  opacity: 1;
}
</style>
