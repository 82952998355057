<template>
  <div class="d-flex flex-column">
    <p class="h2 mb-8">Інтеграція з Нова Пошта</p>

    <p class="subtitle op07">
      Якщо ви користуєтеся службою доставки Нова Пошта, наша система дозволяє легко відстежувати ваші замовлення та забезпечує зручний інтерфейс для керування
      доставкою.
    </p>

    <v-alert border="start" type="info" color="rgba(200, 200, 200, 0.1)" border-color="primary" class="my-4">
      <template #prepend>
        <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
          <v-icon color="primary" class="info">custom:warningIcon</v-icon>
        </v-btn>
      </template>
      <div class="d-flex flex-column gap8">
        <h4 class="subtitle2" style="color: #5a5858">Зверніть увагу!</h4>
        <span
          class="body1"
          style="color: #5a5858"
          v-html="
            'API ключ можна знайти на сайті Нової Пошти в розділі <b>Налаштування</b>, знову <b>Налаштування</b>, потім <b>Безпека</b> та натисніть <b>Створити ключ</b>.'
          "
        >
        </span>
      </div>
    </v-alert>

    <v-form v-if="!novaPoshtaAccount" ref="novaPoshtaForm">
      <div class="d-flex flex-column gap8">
        <label for=""> <b>Крок 1.</b> Введіть ваш API-ключ Нової Пошти</label>

        <div class="d-flex gap16">
          <v-text-field
            v-model="novaApiKey"
            placeholder="API-ключ"
            variant="outlined"
            :rules="[rules.required]"
            style="max-width: 310px"
            data-test="nova-apikey"
            density="compact"
          />
          <v-btn rounded @click="linkNova" color="primary" :loading="linking" :disabled="linking" data-test="nova-apikey-button">звʼязати</v-btn>
        </div>
      </div>
    </v-form>

    <div v-else class="d-flex flex-column gap16">
      <div class="d-flex flex-column gap16">
        <label for=""> <b>Крок 1.</b> Введіть ваш API-ключ Нової Пошти</label>
        <WAvatar :url="novaImage" :title="novaPoshtaAccount.fullName" :text="novaPoshtaAccount.phone" :nova-poshta="novaPoshtaAccount" />
      </div>
      <div>
        <label for=""> <b>Крок 2.</b> Створення шаблону відправника</label>
        <v-alert
          v-if="isTemplateCreated"
          border="start"
          type="success"
          variant="tonal"
          title="Шаблон створено"
          text="Переглянути/Змінити шаблон ви можете перейшовши в Налаштування->Налаштування Нової Пошти->Таблиця шаблонів"
        />
        <template v-else>
          <v-alert border="start" type="info" color="rgba(200, 200, 200, 0.1)" border-color="primary" class="my-4">
            <template #prepend>
              <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
                <v-icon color="primary" class="info">custom:warningIcon</v-icon>
              </v-btn>
            </template>
            <div class="d-flex flex-column gap8">
              <h4 class="subtitle2" style="color: #5a5858">Інформація!</h4>
              <span class="body1 op07" style="color: #5a5858" v-html="'Створіть шаблон відправника, щоб не заповнювати вручну кожного разу одне і те саме.'">
              </span>
            </div>
          </v-alert>
          <v-btn color="primary" rounded @click="showCreateTemplateDialog = true" data-test="nova-setup-template-button"> Налаштувати шаблон </v-btn>
        </template>

        <CreateNovaPoshtaTemplateDialog
          v-if="novaPoshtaAccount"
          v-model="showCreateTemplateDialog"
          :template="novaPoshtaTemplate"
          :accounts="[novaPoshtaAccount]"
          @template-updated="onTemplateUpdated"
          edit-mode
        />
      </div>
    </div>

    <div class="buttons">
      <v-btn @click="$emit('cancel')" variant="outlined" rounded color="var(--main-secondary-color)">Повернутись</v-btn>
      <v-btn @click="$emit('proceed')" rounded :disabled="!isTemplateCreated" color="primary" data-test="stepper-next">Продовжити</v-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, onMounted, defineProps } from "vue";

import CreateNovaPoshtaTemplateDialog from "@/modules/settings/components/integrations/NovaPoshta/CreateEditTemplateDialog.vue";
import WAvatar from "@/components/WAvatar.vue";

import { requiredRule } from "@/services/field.rules";
import { useStore } from "vuex";
import { novaPoshtaService } from "@/modules/orders/services/novaposhta.service";
import { deliveryService } from "@/modules/settings/services/delivery.service";
import i18n from "@/plugins/i18n";
import { getDeliveryInfo } from "@/modules/orders/models/create-order";

defineEmits(["proceed", "cancel"]);
const props = defineProps({
  novaPoshtaAccounts: {},
});
const store = useStore();

const showCreateTemplateDialog = ref(false);
const novaPoshtaTemplate = ref({
  id: "",
  name: "",
  default: false,
  novaPoshtaAccountId: 0,
  deliveryInfo: getDeliveryInfo(),
});
const isTemplateCreated = ref(false);

const novaApiKey = ref("");
// eslint-disable-next-line
const novaImage = require("../../../../assets/novaposhta-icon.png");

let novaPoshtaAccount = ref(null);
const linking = ref(false);
const rules = {
  required: requiredRule,
};

onMounted(() => {
  if (props.novaPoshtaAccounts?.length > 0) {
    novaPoshtaAccount.value = props.novaPoshtaAccounts[0];
    novaPoshtaTemplate.value = novaPoshtaAccount.value.templates[0];

    isTemplateCreated.value = novaPoshtaAccount.value.templates.some((t) => t.default && t.deliveryInfo.sender.city.id && t.deliveryInfo.sender.address.id);
  }
});

const novaPoshtaForm = ref(null);

const linkNova = async () => {
  const isFormValid = (await novaPoshtaForm.value.validate()).valid;
  if (!isFormValid) {
    return;
  }

  linking.value = true;
  try {
    await deliveryService.linkNovaPoshta(novaApiKey.value);
    store.dispatch("addNotification", {
      type: "success",
      title: i18n.global.t("Common.Label.Success"),
      text: i18n.global.t("Settings.Integrations.NovaPoshtaAPI.LinkedText"),
      color: "var(--additional-success)",
      duration: 3500,
    });

    const accounts = await novaPoshtaService.getSavedDeliveryInfo();
    novaPoshtaAccount.value = accounts[0];

    novaPoshtaTemplate.value = accounts[0].templates[0];
  } catch {
    store.dispatch("addError");
  } finally {
    linking.value = false;
  }
};

const onTemplateUpdated = async () => {
  showCreateTemplateDialog.value = false;
  isTemplateCreated.value = true;
};
</script>
