<template>
  <div class="d-flex gap24" :style="{ 'flex-direction': isSmallScreen ? 'column' : 'initial' }">
    <v-tabs v-if="isSmallScreen" v-model="activeTab" color="primary" show-arrows class="horizontal-tab-container">
      <v-tab v-for="tab of tabs" :key="tab.route" style="align-self: start" :to="tab.route">
        <v-badge :model-value="!!tab.requireAction" dot color="rgba(255, 63, 63, 1)" location="start center" offset-x="-10">
          <v-icon class="mr-2">{{ tab.icon }}</v-icon>
          {{ tab.text }}
        </v-badge>
      </v-tab>
    </v-tabs>
    <div v-else class="d-flex flex-column px-6 py-8 gap24 vertival-tab-container">
      <span class="h2"> Налаштування </span>
      <v-tabs v-model="activeTab" direction="vertical" color="primary">
        <v-tab v-for="tab of tabs" :key="tab.route" style="align-self: start" :to="tab.route">
          <v-badge :model-value="tab.requireAction" dot color="rgba(255, 63, 63, 1)" location="start center" offset-x="-10">
            <v-icon class="mr-2">{{ tab.icon }}</v-icon>
            {{ tab.text }}
          </v-badge>
        </v-tab>
      </v-tabs>
    </div>
    <v-container class="pa-8">
      <router-view />
    </v-container>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive, ref } from "vue";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";

const store = useStore();

const tabs = reactive([
  {
    route: "/settings/integrations",
    text: i18n.global.t("Settings.Label.Integrations"),
    icon: "custom:menuIcon",
    requireAction: false,
  },
  {
    name: "billing",
    route: "/settings/billing",
    text: i18n.global.t("Settings.Label.Billing"),
    icon: "custom:billingIcon",
    requireAction: false,
  },
  // {
  //   route: "/settings/general",
  //   text: i18n.global.t("Settings.Label.General"),
  //   icon: "mdi-account-cog-outline",
  // },
  // {
  //   route: "/settings/users",
  //   text: i18n.global.t("Settings.Label.Users"),
  //   icon: "mdi-account-multiple",
  // },
  // {
  //   route: "/settings/billing",
  //   text: i18n.global.t("Settings.Label.Billing"),
  //   icon: "mdi-receipt",
  // },
  {
    route: "/settings/team",
    text: i18n.global.t("Settings.Label.Team"),
    icon: "custom:usersIcon",
    requireAction: false,
  },
  {
    route: "/settings/account",
    text: i18n.global.t("Settings.Label.Account"),
    icon: "custom:userIcon",
    requireAction: false,
  },
]);

const activeTab = ref(tabs[0].route);

const isSmallScreen = ref(false);
onMounted(() => {
  const billingTab = tabs.find((x) => x.name === "billing");
  if (billingTab) billingTab.requireAction = store.getters.isNotBilled;
  onResize();

  window.addEventListener("resize", onResize, { passive: true });
});

onBeforeUnmount(() => {
  if (typeof window === "undefined") return;

  window.removeEventListener("resize", onResize);
});

const onResize = () => {
  isSmallScreen.value = window.innerWidth < 770;
};
</script>

<style>
.v-slide-group-item--active.v-tab--selected {
  color: #2999fd;
}

.vertival-tab-container {
  width: 336px;
  background-color: white;
  height: 100%;
  min-height: 100vh;
  border-right: 1px solid var(--divider, rgba(90, 88, 88, 0.1));
}

.horizontal-tab-container {
  background-color: white;
  border-bottom: 1px solid var(--divider, rgba(90, 88, 88, 0.1));
}
.horizontal-tab-container .v-slide-group__content {
  display: flex;
  justify-content: space-around;
}
</style>
