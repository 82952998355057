import i18n from "@/plugins/i18n";

export default {
  1: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Item"),
  2: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Miligram"),
  3: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Gram"),
  4: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Kilogram"),
  5: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Milimeter"),
  6: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Centimeter"),
  7: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Meter"),
  8: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Milliliter"),
  9: i18n.global.t("Warehouses.Goods.Label.MeasureUnits.Liter"),
};
