<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M12.5 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H12.5"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M6.66699 5.83268L2.50033 9.99935L6.66699 14.166" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.5 10L12.5 10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
